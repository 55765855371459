import { useState, useEffect } from 'react';
import { useTasks } from 'app/hooks/useTasks';
import { useProjects } from 'app/hooks/useProjects';
import energyStarServices from 'app/services/energy-star-services';
import syncServices from 'app/services/sync-services';
import { MainContent } from 'app/components/home/MainContent';
import type { Task } from 'app/types/task';
import type { ProjectInfoData } from 'app/services/project-services';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import { useToast } from '../../../@shadcn/hooks/use-toast';
import { extractError } from 'app/utils/appHelpers';
import { Input, Button, Badge, ScrollArea } from '@shadcn/ui';
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from 'app/components/ui/Sheet';
import { SearchIcon, Bell, ChevronDown, ChevronRight, Lock } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import type { TaskWithSubtasks, WaterMeterGroup, DraftGroup } from 'app/hooks/useTasks';

export const Home = () => {
  const { authUser } = useJumboAuth();
  const navigate = useNavigate();
  const energyStarAccountId = authUser?.relationships?.organization?.data?.energyStarAccountId;
  const accountType = authUser?.relationships?.organization?.data?.accountType;
  const isFreeAccount = accountType === 'free';

  const [energyStarProperties, setEnergyStarProperties] = useState<any[]>([]);
  const { projects, setRefreshProjects } = useProjects();
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const filteredProjects = projects.filter((project) => {
    const searchLower = searchQuery.toLowerCase();
    return (
      project.attributes.name?.toLowerCase().includes(searchLower) ||
      project.attributes.address?.toLowerCase().includes(searchLower)
    );
  });

  const handleProjectSelect = (projectId: string) => {
    setIsSearchOpen(false);
    setSearchQuery('');
    navigate(`/dashboard/project/${projectId}/building-info`);
  };

  useEffect(() => {
    const fetchEnergyStarProperties = async () => {
      if (energyStarAccountId) {
        try {
          const [properties] = await Promise.all([energyStarServices.listProperties(energyStarAccountId)]);
          setEnergyStarProperties(properties['properties']);
        } catch (error) {
          console.error('Error fetching Energy Star properties:', error);
        }
      }
    };

    fetchEnergyStarProperties();
  }, [energyStarAccountId]);

  const tasks = useTasks(authUser, projects, energyStarProperties);

  const [showProjectsTable, setShowProjectsTable] = useState(false);
  const [showEnergyStarProperties, setShowEnergyStarProperties] = useState(false);
  const [propertyScores, setPropertyScores] = useState<Record<string, number>>({});
  const [reviewingProject, setReviewingProject] = useState<{ projectId: string; isVisible: boolean } | null>(null);
  const [syncedProperties, setSyncedProperties] = useState<Set<string>>(new Set());
  const [selectedTask, setSelectedTask] = useState<Task | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const { toast } = useToast();

  const [expandedTasks, setExpandedTasks] = useState<Set<string>>(new Set());
  const [expandedGroups, setExpandedGroups] = useState<Set<string>>(new Set());

  const toggleTaskExpansion = (taskId: string) => {
    setExpandedTasks((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(taskId)) {
        newSet.delete(taskId);
      } else {
        newSet.add(taskId);
      }
      return newSet;
    });
  };

  const toggleGroupExpansion = (groupOwner: string) => {
    setExpandedGroups((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(groupOwner)) {
        newSet.delete(groupOwner);
      } else {
        newSet.add(groupOwner);
      }
      return newSet;
    });
  };

  useEffect(() => {
    if (selectedTask?.id === 'sync-energy-star') {
      setShowEnergyStarProperties(true);
      setShowProjectsTable(false);
    }
  }, [selectedTask]);

  const handleSyncWithEnergyStarClick = async () => {
    if (isFreeAccount) {
      toast({
        description:
          'Energy Star imports are only available for Pro and Enterprise accounts. Please upgrade your plan to access this feature.',
        variant: 'destructive',
      });
      return;
    }

    try {
      const [properties] = await Promise.all([energyStarServices.listProperties(energyStarAccountId)]);
      setEnergyStarProperties(properties['properties']);
      setShowEnergyStarProperties(true);
      setShowProjectsTable(false);
    } catch (error) {
      console.error('Error syncing with Energy Star Account:', error);
    }
  };

  const handleSyncEnergyStarProperty = async (customerId: string, propertyId: string, parentId?: string) => {
    if (isFreeAccount) {
      toast({
        description:
          'Energy Star imports are only available for Pro and Enterprise accounts. Please upgrade your plan to access this feature.',
        variant: 'destructive',
      });
      return;
    }

    try {
      const energyStarCustomerId = customerId || energyStarAccountId;
      await syncServices.syncWithEnergyStar(energyStarCustomerId, propertyId, parentId);
      setSyncedProperties((prev) => new Set(prev).add(propertyId));
    } catch (error) {
      console.error('Error syncing project:', error);
      handleSnackbar(extractError(error) ?? 'Failed to sync Energy Star property', 'destructive');
    }
  };

  const handleSnackbar = (message: string, variant: 'default' | 'destructive' | 'positive') => {
    toast({
      description: message,
      variant,
    });
  };

  return (
    <div className="grid min-h-screen grid-rows-[1fr_auto]">
      <div className="relative flex items-center justify-center">
        <Sheet open={isDrawerOpen} onOpenChange={setIsDrawerOpen}>
          <SheetTrigger asChild>
            <Button variant="ghost" size="icon" className="absolute right-10 top-8">
              <Bell className="h-6 w-6" />
              {tasks.length > 0 && (
                <Badge
                  variant="destructive"
                  className="absolute -right-2 -top-2 flex h-5 w-5 items-center justify-center rounded-full p-0 text-xs"
                >
                  {tasks.length}
                </Badge>
              )}
            </Button>
          </SheetTrigger>
          <SheetContent>
            <SheetHeader>
              <SheetTitle>{showEnergyStarProperties ? 'Import Energy Star Properties' : 'Pending Tasks'}</SheetTitle>
            </SheetHeader>
            <div className="mt-4 flex flex-col gap-4">
              {!showEnergyStarProperties ? (
                <ScrollArea className="h-[calc(100vh-200px)] border-b">
                  <div className="flex flex-col gap-2 pr-4">
                    {tasks.map((task: TaskWithSubtasks) => (
                      <div key={task.id} className="rounded-lg border">
                        <div
                          onClick={() => {
                            if (task.id === 'review-drafts' && isFreeAccount) {
                              toast({
                                description:
                                  'Report drafts are only available for Pro and Enterprise accounts. Please upgrade your plan to access this feature.',
                                variant: 'destructive',
                              });
                              return;
                            }
                            if (task.subtasks || task.groups) {
                              toggleTaskExpansion(task.id);
                            } else if (task.id === 'sync-energy-star') {
                              if (isFreeAccount) {
                                toast({
                                  description:
                                    'Energy Star imports are only available for Pro and Enterprise accounts. Please upgrade your plan to access this feature.',
                                  variant: 'destructive',
                                });
                                return;
                              }
                              setSelectedTask(task);
                              setShowEnergyStarProperties(true);
                              setShowProjectsTable(false);
                            }
                          }}
                          className="flex cursor-pointer items-center justify-between p-4 hover:bg-accent"
                        >
                          <div>
                            <h4 className="font-medium">{task.title}</h4>
                            <p className="text-sm text-muted-foreground">{task.subtitle}</p>
                          </div>
                          <div className="flex items-center gap-2">
                            {(task.id === 'sync-energy-star' || task.id === 'review-drafts') && isFreeAccount && (
                              <Lock className="h-4 w-4 text-muted-foreground" />
                            )}
                            {(task.subtasks || task.groups) && (
                              <div className="text-muted-foreground">
                                {expandedTasks.has(task.id) ? (
                                  <ChevronDown className="h-5 w-5" />
                                ) : (
                                  <ChevronRight className="h-5 w-5" />
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                        {expandedTasks.has(task.id) && (
                          <div className="border-t bg-accent/50">
                            {task.groups?.map((group: WaterMeterGroup | DraftGroup) => (
                              <div key={group.owner} className="border-b last:border-b-0">
                                <div
                                  onClick={() => toggleGroupExpansion(group.owner)}
                                  className="flex cursor-pointer items-center justify-between bg-accent/30 p-3"
                                >
                                  <div className="flex items-center gap-2">
                                    <div className="font-medium">{group.owner}</div>
                                    <Badge variant="outline" className="text-xs">
                                      {'projects' in group
                                        ? `${group.projects.length} ${group.projects.length === 1 ? 'property' : 'properties'}`
                                        : `${group.drafts.length} ${group.drafts.length === 1 ? 'draft' : 'drafts'}`}
                                    </Badge>
                                  </div>
                                  <div className="text-muted-foreground">
                                    {expandedGroups.has(group.owner) ? (
                                      <ChevronDown className="h-4 w-4" />
                                    ) : (
                                      <ChevronRight className="h-4 w-4" />
                                    )}
                                  </div>
                                </div>
                                {expandedGroups.has(group.owner) && (
                                  <div className="divide-y divide-border/10">
                                    {'projects' in group
                                      ? group.projects.map((project) => (
                                          <div
                                            key={project.projectId}
                                            onClick={() => {
                                              setIsDrawerOpen(false);
                                              navigate(`/dashboard/project/${project.projectId}/building-info`);
                                            }}
                                            className="cursor-pointer bg-accent/10 p-3 pl-4 hover:bg-accent/20"
                                          >
                                            <div className="ml-4 flex items-center">
                                              <span className="mr-1 text-muted-foreground/60">└</span>
                                              <span className="text-sm text-muted-foreground/80">
                                                {project.projectName}
                                              </span>
                                            </div>
                                          </div>
                                        ))
                                      : group.drafts.map((draft) => (
                                          <div
                                            key={draft.projectId}
                                            onClick={() => {
                                              setIsDrawerOpen(false);
                                              navigate(`/dashboard/project/${draft.projectId}/exports`);
                                            }}
                                            className="cursor-pointer bg-accent/10 p-3 pl-4 hover:bg-accent/20"
                                          >
                                            <div className="ml-4 flex items-center">
                                              <span className="mr-1 text-muted-foreground/60">└</span>
                                              <span className="text-sm text-muted-foreground/80">
                                                {draft.projectName}
                                              </span>
                                            </div>
                                          </div>
                                        ))}
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </ScrollArea>
              ) : null}

              <div className="flex-1 overflow-auto">
                {(showProjectsTable || showEnergyStarProperties) && (
                  <MainContent
                    tasks={tasks}
                    projects={filteredProjects}
                    showProjectsTable={showProjectsTable}
                    showEnergyStarProperties={showEnergyStarProperties}
                    propertyScores={propertyScores}
                    reviewingProject={reviewingProject}
                    energyStarProperties={energyStarProperties}
                    syncedProperties={syncedProperties}
                    setRefreshProjects={setRefreshProjects}
                    onTaskClick={(task) => {
                      setSelectedTask(task);
                      if (task.id === 'sync-energy-star') {
                        setShowEnergyStarProperties(true);
                        setShowProjectsTable(false);
                      } else if (task.id.startsWith('connect-water-meter-')) {
                        console.log('connect-water-meter-', task.id);
                        const projectId = task.id.replace('connect-water-meter-', '');
                        navigate(`/dashboard/project/${projectId}/building-info`);
                      }
                    }}
                    onReviewClick={(project: ProjectInfoData) =>
                      setReviewingProject({
                        projectId: project.id,
                        isVisible: true,
                      })
                    }
                    onSubmitScore={(propertyName: string) =>
                      setPropertyScores((prev) => ({ ...prev, [propertyName]: 75 }))
                    }
                    onPropertySync={handleSyncEnergyStarProperty}
                    onReviewClose={() => setReviewingProject(null)}
                    onSnackbar={handleSnackbar}
                    onSyncWithEnergyStar={handleSyncWithEnergyStarClick}
                    onDismissEnergyStarProperties={() => {
                      setShowEnergyStarProperties(false);
                      setSelectedTask(null);
                    }}
                  />
                )}
              </div>
            </div>
          </SheetContent>
        </Sheet>
        <div className="flex w-full max-w-2xl flex-col items-center justify-center space-y-6 px-8">
          <div className="relative w-full">
            <SearchIcon className="pointer-events-none absolute left-3 top-1/2 h-5 w-5 -translate-y-1/2 text-muted-foreground" />
            <Input
              type="text"
              placeholder="Search projects by name or address..."
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
                setIsSearchOpen(true);
              }}
              onFocus={() => setIsSearchOpen(true)}
              className="h-12 w-full rounded-xl border-muted bg-background pl-10 text-lg text-foreground shadow-[0_4px_14px] shadow-black/15 transition-shadow duration-200 hover:shadow-[0_4px_16px] hover:shadow-black/20"
            />
            {isSearchOpen && searchQuery.length > 0 && (
              <div className="absolute left-0 right-0 top-full z-50 mt-1 w-full">
                <div className="w-full rounded-md border bg-popover shadow-md">
                  {filteredProjects.length === 0 ? (
                    <div className="p-4 text-sm text-muted-foreground">No projects found.</div>
                  ) : (
                    <ScrollArea className="max-h-[300px]">
                      {filteredProjects.map((project) => (
                        <div
                          key={project.id}
                          onClick={() => handleProjectSelect(project.id)}
                          className="flex cursor-pointer flex-col items-start gap-1 p-3 hover:bg-accent"
                        >
                          <div className="font-medium">{project.attributes.name}</div>
                          {project.attributes.address && (
                            <div className="text-xs text-muted-foreground">{project.attributes.address}</div>
                          )}
                        </div>
                      ))}
                    </ScrollArea>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
