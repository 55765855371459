import { FileIcon } from 'lucide-react';
import { Tabs, TabsContent } from '@shadcn/ui';
import { NoDataFound } from 'app/components/project/NoDataFound';
import { ServiceTabs } from 'app/components/service/ServiceTabs';
import { DataAlert } from 'app/components/DataAlert';
import { DocumentsTable } from '../components/DocumentsTable';
import { Document } from './DataSources';
import { UTILITY_SERVICES } from 'app/utils/constants/utilityServices';

interface UtilityBillsTabProps {
  documents: Document[];
  onDelete: (fileId: string) => Promise<{ success: boolean }>;
  findDuplicates: (docs: Document[]) => any[];
  getServiceDocuments: (docs: Document[], service: string) => Document[];
}

export const UtilityBillsTab = ({ documents, onDelete, findDuplicates, getServiceDocuments }: UtilityBillsTabProps) => {
  const allServices = ['All', ...UTILITY_SERVICES];

  if (documents.length === 0) {
    return (
      <NoDataFound
        message="No utility bills found"
        submessage="Upload utility bills to get started"
        icon={<FileIcon className="h-8 w-8 text-muted-foreground" />}
      />
    );
  }

  return (
    <Tabs defaultValue="all" className="w-full">
      <ServiceTabs allServices={allServices} />

      {allServices.map((serviceType) => (
        <TabsContent key={serviceType} value={serviceType.toLowerCase()}>
          <DataAlert variant="duplicate" data={findDuplicates(getServiceDocuments(documents, serviceType))} />
          <DocumentsTable
            documents={getServiceDocuments(documents, serviceType)}
            onDelete={onDelete}
            selectedService={serviceType}
          />
        </TabsContent>
      ))}
    </Tabs>
  );
};
