import jwtAuthAxios from '../services/auth/jwtAuth';
import authServices from '../services/auth-services';

import * as env from './env';

const config = {
  authSetting: {
    axiosObject: jwtAuthAxios,
    fallbackPath: '/user/login',
    getAuthUserService: authServices.getCurrentUser,
    redirectNotAuthenticatedPath: '/dashboard/home',
  },
  env,
};

export { config };
