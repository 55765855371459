import { cn } from '@shadcn/utils';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ASSET_IMAGES } from 'app/utils/constants/paths';

interface LogoProps {
  mini?: boolean;
  mode?: 'light' | 'dark';
  className?: string;
}

const Logo = ({ mini = false, className }: LogoProps) => {
  return (
    <div className={cn('inline-flex', className)}>
      <RouterLink to={'/dashboard/project'}>
        {mini ? (
          <img style={{ width: '40px' }} src={`${ASSET_IMAGES}/cosmos-logo-mini.svg`} alt="Cosmos" />
        ) : (
          <img style={{ width: '30px' }} src={`${ASSET_IMAGES}/cosmos-logo.svg`} alt="Cosmos" />
        )}
      </RouterLink>
    </div>
  );
};

export default Logo;
