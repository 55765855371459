import { cn } from '@shadcn/utils';
import { VariantProps } from 'class-variance-authority';
import { X, RefreshCw, Upload } from 'lucide-react';
import { forwardRef, useEffect, useState, useRef, ChangeEvent } from 'react';
import {
  ScrollArea,
  toastVariants,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Label,
  Card,
  Button,
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  Input,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@shadcn/ui';
import { ReportPreview } from './ReportPreview';
import type { ProjectDataReturn } from 'app/hooks/useProjectData';
import { DataSource } from 'app/types/visualizations';
import reportServices from 'app/services/report-services';
import { useSearchParams } from 'react-router-dom';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';

interface ReportsProps {
  projectId: string;
  projectData: ProjectDataReturn;
  onSnackbar: (message: string, variant: VariantProps<typeof toastVariants>['variant']) => void;
  draftId?: string;
  onClose?: () => void;
}

interface ImageValidationConfig {
  file: File;
  onSuccess: (file: File, objectUrl: string) => void;
}

export const ReportBuilder = forwardRef<HTMLDivElement, ReportsProps>(
  ({ projectId, projectData, onSnackbar, draftId, onClose }, ref) => {
    const [searchParams] = useSearchParams();
    const draftIdFromParams = searchParams.get('draftId') || draftId;
    const { authUser } = useJumboAuth();
    const accountType = authUser?.relationships?.organization?.data?.accountType;
    const isFreeAccount = accountType === 'free';

    const [dataSource, setDataSource] = useState<DataSource>('utility-bills');
    const [refreshKey, setRefreshKey] = useState(0);
    const [showRefreshConfirm, setShowRefreshConfirm] = useState(false);
    const [reportName, setReportName] = useState('');
    const [showPreview, setShowPreview] = useState(false);
    const [isCardCollapsed, setIsCardCollapsed] = useState(false);
    const [logoFile, setLogoFile] = useState<File | null>(null);
    const [logoPreview, setLogoPreview] = useState<string | null>(null);
    const [buildingImageFile, setBuildingImageFile] = useState<File | null>(null);
    const [buildingImagePreview, setBuildingImagePreview] = useState<string | null>(null);
    const [draftData, setDraftData] = useState<any>(null);
    const reportBuilderRef = useRef<any>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const buildingImageInputRef = useRef<HTMLInputElement>(null);

    const getLastYearDates = () => {
      const currentYear = new Date().getFullYear();
      const lastYear = currentYear - 1;
      return {
        start: `${lastYear}-01-01`,
        end: `${lastYear}-12-31`,
      };
    };

    const lastYearDates = getLastYearDates();
    const [startDate, setStartDate] = useState<string>(lastYearDates.start);
    const [endDate, setEndDate] = useState<string>(lastYearDates.end);
    const [dateRangeError, setDateRangeError] = useState<string>('');

    useEffect(() => {
      if (draftIdFromParams) {
        loadDraft();
      }
    }, [draftIdFromParams]);

    const loadDraft = async () => {
      if (!draftIdFromParams) return;

      try {
        const draft = await reportServices.getDraft(draftIdFromParams);

        if (draft) {
          setReportName(draft.name);
          setDataSource(draft.dataSource);
          if (draft.startDate) {
            setStartDate(draft.startDate);
          }
          if (draft.endDate) {
            setEndDate(draft.endDate);
          }

          // Handle logo and building image if they exist in the draft
          if (draft.logo) {
            setLogoPreview(draft.logo);
          }
          if (draft.buildingImage) {
            setBuildingImagePreview(draft.buildingImage);
          }
          // Store the full draft data
          setDraftData(draft);
          // Set showPreview to true to display the draft content
          setShowPreview(true);
        } else {
          onSnackbar('Draft not found.', 'destructive');
        }
      } catch (error) {
        console.error('Failed to load draft:', error);
        onSnackbar('Failed to load draft report.', 'destructive');
      }
    };

    const validateAndProcessImage = async (config: ImageValidationConfig): Promise<void> => {
      const { file, onSuccess } = config;

      // Validate file type
      const validTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/svg+xml'];
      if (!validTypes.includes(file.type)) {
        onSnackbar('Invalid file type. Please upload PNG, JPEG, or SVG.', 'destructive');
        return;
      }

      // Validate file size (500KB max)
      const maxSize = 500 * 1024; // 500KB in bytes
      if (file.size > maxSize) {
        onSnackbar('File size exceeds 500KB limit.', 'destructive');
        return;
      }

      const objectUrl = URL.createObjectURL(file);
      onSuccess(file, objectUrl);
    };

    const handleLogoUpload = async (e: ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      if (!file) return;

      await validateAndProcessImage({
        file,
        onSuccess: (file, objectUrl) => {
          setLogoFile(file);
          setLogoPreview(objectUrl);
        },
      });
    };

    const removeLogo = () => {
      setLogoFile(null);
      if (logoPreview) {
        URL.revokeObjectURL(logoPreview);
        setLogoPreview(null);
      }
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    };

    const triggerLogoUpload = () => {
      fileInputRef.current?.click();
    };

    const handleBuildingImageUpload = async (e: ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      if (!file) return;

      await validateAndProcessImage({
        file,
        onSuccess: (file, objectUrl) => {
          setBuildingImageFile(file);
          setBuildingImagePreview(objectUrl);
        },
      });
    };

    const removeBuildingImage = () => {
      setBuildingImageFile(null);
      if (buildingImagePreview) {
        URL.revokeObjectURL(buildingImagePreview);
        setBuildingImagePreview(null);
      }
      if (buildingImageInputRef.current) {
        buildingImageInputRef.current.value = '';
      }
    };

    const triggerBuildingImageUpload = () => {
      buildingImageInputRef.current?.click();
    };

    const handleGeneratePreview = () => {
      if (isFreeAccount) {
        onSnackbar(
          'Report generation is only available for Pro and Enterprise accounts. Please upgrade your plan to access this feature.',
          'destructive'
        );
        return;
      }

      // Validate date range
      if (startDate && endDate) {
        const start = new Date(startDate);
        const end = new Date(endDate);
        const monthsDiff = (end.getFullYear() - start.getFullYear()) * 12 + (end.getMonth() - start.getMonth());
        if (monthsDiff < 12) {
          setDateRangeError('At least 12 months of data is required to generate a report');
          onSnackbar('At least 12 months of data is required to generate a report', 'destructive');
          return;
        }
      }

      if (showPreview) {
        setShowRefreshConfirm(true);
      } else {
        setRefreshKey((prev) => prev + 1);
        setShowPreview(true);
      }
    };

    return (
      <div ref={ref} className="flex grow animate-fade-up-in flex-col overflow-hidden px-4 pt-6">
        <div className="relative flex grow flex-col">
          <Card className="mb-6 rounded-lg border border-border bg-card p-6 shadow-sm">
            <div className="flex flex-col gap-6">
              <div className="flex items-center gap-8">
                <div className="flex items-center gap-3">
                  <Label className="text-sm font-medium">Report Name:</Label>
                  <Input
                    value={reportName}
                    onChange={(e) => setReportName(e.target.value)}
                    placeholder="Enter report name"
                    className="w-[200px]"
                  />
                </div>

                <div className="flex items-center gap-3">
                  <Label className="text-sm font-medium">Data Source:</Label>
                  <Select
                    value={dataSource}
                    onValueChange={(value) => {
                      setDataSource(value as DataSource);
                    }}
                  >
                    <SelectTrigger className="w-[180px]">
                      <SelectValue placeholder="Select data source" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="utility-bills">Utility Bills</SelectItem>
                      <SelectItem value="energy-star">Meter Readings</SelectItem>
                    </SelectContent>
                  </Select>
                </div>

                <div className="flex items-center gap-3">
                  <Label className="text-sm font-medium">Custom Date Range:</Label>
                  <div className="flex items-center gap-2">
                    <Input
                      type="date"
                      value={startDate}
                      onChange={(e) => {
                        setStartDate(e.target.value);
                        setDateRangeError('');
                      }}
                      className="w-[180px]"
                    />
                    <span>to</span>
                    <Input
                      type="date"
                      value={endDate}
                      onChange={(e) => {
                        setEndDate(e.target.value);
                        setDateRangeError('');
                      }}
                      className="w-[180px]"
                    />
                  </div>
                </div>
              </div>

              <div className="flex items-center gap-4">
                <div className="flex flex-col gap-2">
                  <Label className="text-sm font-medium">Company Logo:</Label>
                  <div className="flex items-center gap-2">
                    <input
                      type="file"
                      ref={fileInputRef}
                      onChange={handleLogoUpload}
                      className="hidden"
                      accept=".png,.jpg,.jpeg,.svg"
                    />
                    <Button variant="outline" size="sm" className="flex items-center gap-1" onClick={triggerLogoUpload}>
                      <Upload className="h-3 w-3" />
                      Upload Logo
                    </Button>
                    {logoPreview && (
                      <>
                        <div className="relative h-10 w-20 overflow-hidden rounded border">
                          <img src={logoPreview} alt="Logo Preview" className="h-full w-full object-contain" />
                        </div>
                        <Button variant="ghost" size="sm" className="h-6 w-6 p-0 text-destructive" onClick={removeLogo}>
                          <X className="h-4 w-4" />
                        </Button>
                      </>
                    )}
                  </div>
                </div>

                <div className="flex flex-col gap-2">
                  <Label className="text-sm font-medium">Building Image:</Label>
                  <div className="flex items-center gap-2">
                    <input
                      type="file"
                      ref={buildingImageInputRef}
                      onChange={handleBuildingImageUpload}
                      className="hidden"
                      accept=".png,.jpg,.jpeg,.svg"
                    />
                    <Button
                      variant="outline"
                      size="sm"
                      className="flex items-center gap-1"
                      onClick={triggerBuildingImageUpload}
                    >
                      <Upload className="h-3 w-3" />
                      Upload Building Image
                    </Button>
                    {buildingImagePreview && (
                      <>
                        <div className="relative h-10 w-20 overflow-hidden rounded border">
                          <img
                            src={buildingImagePreview}
                            alt="Building Preview"
                            className="h-full w-full object-contain"
                          />
                        </div>
                        <Button
                          variant="ghost"
                          size="sm"
                          className="h-6 w-6 p-0 text-destructive"
                          onClick={removeBuildingImage}
                        >
                          <X className="h-4 w-4" />
                        </Button>
                      </>
                    )}
                  </div>
                </div>

                {!showPreview && (
                  <div className="flex grow items-center justify-end self-end">
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <div>
                            <Button
                              onClick={handleGeneratePreview}
                              className="flex items-center gap-2"
                              disabled={!reportName.trim() || !startDate || !endDate}
                            >
                              <RefreshCw className="h-4 w-4" />
                              Generate Report Preview
                            </Button>
                          </div>
                        </TooltipTrigger>
                        <TooltipContent>
                          {!reportName.trim()
                            ? 'Please enter a report name to generate preview'
                            : !startDate || !endDate
                              ? 'Please select both start and end dates'
                              : dateRangeError
                                ? dateRangeError
                                : isFreeAccount
                                  ? 'Report generation requires a Pro or Enterprise account'
                                  : 'Generate a new report preview'}
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </div>
                )}
              </div>

              {dateRangeError && <div className="mt-2 text-sm text-destructive">{dateRangeError}</div>}
            </div>
          </Card>

          <ScrollArea orientation="vertical" className={cn('grow')}>
            <div className={cn('p-4', isCardCollapsed ? 'h-[calc(100vh-150px)]' : 'h-[calc(100vh-280px)]')}>
              {showPreview && (
                <ReportPreview
                  ref={reportBuilderRef}
                  key={refreshKey}
                  projectId={projectId}
                  onClose={() => setShowPreview(false)}
                  startDate={startDate}
                  endDate={endDate}
                  reportName={reportName}
                  dataSource={dataSource}
                  logoFile={logoFile}
                  buildingImageFile={buildingImageFile}
                  draftId={draftIdFromParams}
                  draftData={draftData}
                />
              )}
            </div>
          </ScrollArea>
        </div>

        <AlertDialog open={showRefreshConfirm} onOpenChange={setShowRefreshConfirm}>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Refresh Report Preview</AlertDialogTitle>
              <AlertDialogDescription>
                Generating Report Preview will lose any edits to the current preview. Do you wish to proceed?
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>Cancel</AlertDialogCancel>
              <AlertDialogAction
                onClick={() => {
                  setRefreshKey((prev) => prev + 1);
                  setShowPreview(true);
                  setShowRefreshConfirm(false);
                }}
              >
                Proceed
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </div>
    );
  }
);
