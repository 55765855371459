import jwtAuthAxios from './auth/jwtAuth';
import type { ProjectInfoData } from './project-services';

const taskServices = {
  get: async (): Promise<ProjectInfoData[]> => {
    const { data } = await jwtAuthAxios.get('/v1/tasks');
    return data;
  },
};

export default taskServices;
