import { ScrollArea } from '@shadcn/ui';
import { ProjectTrackingPanel } from './components/ProjectTrackingPanel';
import { MVChart } from './components/MVChart';
import { SustainabilityMetrics } from './components/SustainabilityMetrics';

export const FinancialInsights = () => {
  return (
    <div className="flex grow animate-fade-up-in flex-col overflow-hidden px-4 pt-6">
      <div className="flex h-full w-full flex-col items-center justify-between gap-2 px-4 pb-2">
        <div className="flex h-full w-full flex-col">
          {/* Main Content Area */}
          <ScrollArea className="flex-1">
            <div className="grid grid-cols-12 gap-6">
              {/* Project Tracking Panel - Takes up full width */}
              <div className="col-span-12">
                <ProjectTrackingPanel />
              </div>

              {/* M&V Chart - Takes up 2/3 width */}
              <div className="col-span-8">
                <MVChart />
              </div>

              {/* Sustainability Metrics - Takes up 1/3 width */}
              <div className="col-span-4">
                <SustainabilityMetrics />
              </div>
            </div>
          </ScrollArea>
        </div>
      </div>
    </div>
  );
};
