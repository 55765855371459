import { forwardRef } from 'react';
import { Card, CardContent, Button } from '@shadcn/ui';
import { Tooltip, TooltipContent, TooltipTrigger } from '@shadcn/ui';
import { InfoIcon } from 'lucide-react';
import { Building2Icon } from 'lucide-react';

export interface ChildPropertiesProps {
  childProperties?: Array<{
    _id?: string;
    name: string;
    energyStarId: string;
    propertyIdentifiers?: {
      BBL: string[];
    };
  }>;
}

export const ChildProperties = forwardRef<HTMLDivElement, ChildPropertiesProps>(({ childProperties }, ref) => {
  if (!childProperties?.length) return null;
  return (
    <Card className="transform rounded-xl border-2 border-gray-500 bg-gradient-to-br from-background/80 to-background/40 p-1 shadow-xl transition-all duration-200 hover:shadow-2xl">
      <CardContent className="pt-4">
        <div className="mb-3 flex flex-row items-center justify-start gap-2">
          <Tooltip>
            <TooltipTrigger>
              <InfoIcon className="h-4 w-4 text-primary/80 transition-colors duration-200 hover:text-primary" />
            </TooltipTrigger>
            <TooltipContent>Buildings associated with this campus as child properties</TooltipContent>
          </Tooltip>
          <p className="text-lg font-semibold text-foreground/90">Campus Buildings</p>
        </div>
        <div className="grid gap-2">
          {childProperties.map((child) => (
            <div
              key={child.propertyIdentifiers?.BBL[0]}
              className="group flex items-center justify-between gap-2 rounded-md bg-background/5 p-2 transition-all duration-200 hover:bg-background/10"
            >
              <div className="flex items-center gap-2">
                <Building2Icon className="h-4 w-4 text-primary/70 transition-all duration-200 group-hover:text-primary" />
                <span className="text-base font-medium">{child.name}</span>
                <span className="text-sm text-muted-foreground/70 transition-all duration-200 group-hover:text-muted-foreground">
                  (BBL: {child.propertyIdentifiers?.BBL[0]})
                </span>
              </div>
              <Button
                variant="outline"
                size="sm"
                className="ml-auto"
                onClick={() => (window.location.href = `/dashboard/project/${child._id}/building-info`)}
              >
                View
              </Button>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
});
