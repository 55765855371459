import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { PlusIcon, TrashIcon, RefreshCwIcon, ArrowUpDown, ArrowUp, ArrowDown, XIcon } from 'lucide-react';
import { CheckedState } from '@radix-ui/react-checkbox';

// TODO: consider just using either AlertDialog or Dialog
// Alert is simpler to use but doesn't close as "easily"
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TableHeader,
  ScrollArea,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@shadcn/ui';
import projectServices, { ProjectInfoData, SortField, SortOrder } from 'app/services/project-services';
import { ProjectModal } from './ProjectModal';
import { formatSquareFeet } from 'app/utils/formatters';
import syncServices from 'app/services/sync-services';

// Define the mapping between display labels and sort fields
const SORT_FIELD_LABELS: Record<SortField, string> = {
  name: 'Project Name',
  createdAt: 'Created At',
  owner: 'Owner',
  representative: 'Owner Representative',
  ll84Required: 'LL84 Required',
  ll97Required: 'LL97 Required',
};

export const Project = () => {
  const [projects, setProjects] = useState<ProjectInfoData[]>([]);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    total: 0,
    pages: 0,
  });
  const [showNewProjectModal, setShowNewProjectModal] = useState(false);
  const [refreshProjects, setRefreshProjects] = useState(false);
  const [selectedProjects, setSelectedProjects] = useState({});
  const [showDeleteFeedbackType, setShowDeleteFeedbackType] = useState<'success' | 'error' | null>(null);
  const [showSyncFeedbackType, setShowSyncFeedbackType] = useState<'success' | 'error' | null>(null);
  const [syncError, setSyncError] = useState<string>('');
  const [sortField, setSortField] = useState<SortField>('owner');
  const [sortOrder, setSortOrder] = useState<SortOrder>('asc');

  const handleNewProject = () => {
    setShowNewProjectModal(true);
  };

  const handleModalOpenChange = (newOpen: boolean) => {
    setShowNewProjectModal(newOpen);
    setRefreshProjects(!newOpen);
  };

  const getProjectPath = (project: { id: string }) => {
    return `/dashboard/project/${project.id}/building-info`;
  };

  const handleCheckboxChange = (_: CheckedState, projectId: string) => {
    setSelectedProjects((prev) => ({
      ...prev,
      [projectId]: !prev[projectId],
    }));
  };

  const handleDeleteConfirm = async () => {
    const projectsToDelete = Object.keys(selectedProjects).filter((id) => selectedProjects[id]);
    try {
      await Promise.all(projectsToDelete.map((id) => projectServices.delete(id)));
      setRefreshProjects((prev) => !prev);
      setSelectedProjects({});
      setShowDeleteFeedbackType('success');
    } catch (error) {
      setShowDeleteFeedbackType('error');
    }
  };

  const handleBulkSync = async () => {
    const projectsToSync = Object.keys(selectedProjects)
      .filter((id) => selectedProjects[id])
      .map((id) => projects.find((p) => p.id === id))
      .filter(
        (project): project is ProjectInfoData =>
          project !== undefined &&
          project.attributes.energyStarId !== undefined &&
          project.attributes.energyStarId !== null
      );

    try {
      for (const project of projectsToSync) {
        if (project.attributes.energyStarId) {
          await syncServices.syncWithEnergyStar(project.id, project.attributes.energyStarId);
        }
      }
      setRefreshProjects((prev) => !prev);
      setSelectedProjects({});
      setShowSyncFeedbackType('success');
    } catch (error) {
      console.error('Failed to sync projects with Energy Star:', error);
      setSyncError(error instanceof Error ? error.message : 'Failed to sync with Energy Star');
      setShowSyncFeedbackType('error');
    }
  };

  const handleSort = (field: SortField) => {
    if (sortField === field) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
  };

  useEffect(() => {
    let isMounted = true;
    (async () => {
      try {
        const projectsResponse = await projectServices.get({
          page: pagination.page,
          limit: pagination.limit,
          sortField,
          sortOrder,
        });
        if (isMounted) {
          setProjects(projectsResponse.data);
          setPagination((prev) => ({
            ...prev,
            total: projectsResponse.pagination.total,
            pages: projectsResponse.pagination.pages,
          }));
        }
      } catch (error) {
        console.error('Failed to fetch projects:', error);
      }
    })();

    return () => {
      isMounted = false;
    };
  }, [refreshProjects, pagination.page, pagination.limit, sortField, sortOrder]);

  const isAnyProjectSelected = Object.values(selectedProjects).some(Boolean);

  const SortButton = ({ field }: { field: SortField }) => (
    <Button variant="ghost" onClick={() => handleSort(field)} className="flex items-center gap-1 hover:bg-transparent">
      {SORT_FIELD_LABELS[field]}
      {sortField !== field && <ArrowUpDown size={16} className="opacity-50" />}
      {sortField === field && sortOrder === 'asc' && <ArrowUp size={16} />}
      {sortField === field && sortOrder === 'desc' && <ArrowDown size={16} />}
    </Button>
  );

  const tableProjects = projects;

  return (
    <div className="flex flex-col overflow-hidden px-4 pt-6">
      <div className="mb-6 flex items-center justify-between">
        <h4 className="bold text-gray-900">Projects</h4>
        <div className="flex flex-row gap-4">
          <AlertDialog>
            <AlertDialogTrigger asChild>
              <Button
                className="flex flex-row gap-2 uppercase disabled:opacity-0"
                variant="destructive"
                disabled={!isAnyProjectSelected}
              >
                <TrashIcon /> Delete Selected
              </Button>
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Delete selected projects?</AlertDialogTitle>
                <AlertDialogDescription>This action cannot be undone.</AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Cancel</AlertDialogCancel>
                <AlertDialogAction variant="destructive" onClick={handleDeleteConfirm}>
                  Continue
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>

          <Button
            className="flex flex-row gap-2 uppercase disabled:opacity-0"
            variant="outline"
            disabled={!isAnyProjectSelected}
            onClick={handleBulkSync}
          >
            <RefreshCwIcon /> Sync Selected
          </Button>

          <Button className="flex flex-row gap-2 uppercase" onClick={handleNewProject}>
            <PlusIcon /> New Project
          </Button>
        </div>
      </div>
      <ScrollArea orientation="vertical" className="grow">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>
                <Checkbox
                  color="primary"
                  checked={
                    Object.values(selectedProjects).some(Boolean) && !Object.values(selectedProjects).every(Boolean)
                      ? 'indeterminate'
                      : Object.values(selectedProjects).every(Boolean)
                  }
                  onClick={() => {
                    const newSelectedProjects = {};
                    projects.forEach((project) => {
                      newSelectedProjects[project.id] = !Object.values(selectedProjects).every(Boolean);
                    });
                    setSelectedProjects(newSelectedProjects);
                  }}
                />
              </TableHead>
              <TableHead>
                <SortButton field="owner" />
              </TableHead>
              <TableHead>
                <SortButton field="representative" />
              </TableHead>
              <TableHead>
                <SortButton field="name" />
              </TableHead>
              <TableHead>
                <SortButton field="createdAt" />
              </TableHead>
              <TableHead>
                <p className="bold text-gray-700">Client Services</p>
              </TableHead>
              <TableHead>
                <SortButton field="ll84Required" />
              </TableHead>
              <TableHead>
                <SortButton field="ll97Required" />
              </TableHead>
              <TableHead>
                <p className="bold text-gray-700"></p>
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {tableProjects.map((project) => (
              <TableRow key={project.id}>
                <TableCell>
                  <Checkbox
                    checked={!!selectedProjects[project.id]}
                    onCheckedChange={(c) => handleCheckboxChange(c, project.id)}
                    color="primary"
                  />
                </TableCell>
                <TableCell className="text-gray-700">
                  {project.attributes.buildingRepresentation?.owner || '-'}
                </TableCell>
                <TableCell className="text-gray-700">
                  {project.attributes.buildingRepresentation?.representative || '-'}
                </TableCell>
                <TableCell>
                  <RouterLink
                    className="text-gray-900 no-underline transition-colors hover:text-primary hover:no-underline"
                    to={getProjectPath(project)}
                  >
                    {project.attributes.name}
                  </RouterLink>
                </TableCell>
                <TableCell className="text-gray-700">
                  {new Date(project.attributes.createdAt).toLocaleDateString()}
                </TableCell>
                <TableCell className="text-gray-700">
                  {project.attributes.buildingRepresentation?.services?.join(', ') || '-'}
                </TableCell>
                <TableCell className="text-gray-700">
                  {project.attributes.complianceRequirements?.ll84?.required ? 'Yes' : 'No'}
                </TableCell>
                <TableCell className="text-gray-700">
                  {project.attributes.complianceRequirements?.ll97?.required ? 'Yes' : 'No'}
                </TableCell>
                <TableCell>
                  <div className="flex gap-2">
                    {project.attributes.energyStarId ? (
                      <Button
                        variant="link"
                        className="rounded-full bg-blue-100 px-3 py-1 text-xs font-medium text-blue-700 hover:bg-blue-200"
                        asChild
                      >
                        <a
                          href={`https://portfoliomanager.energystar.gov/pm/property/${project.attributes.energyStarId}#summary`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="no-underline"
                        >
                          ESPM Profile
                        </a>
                      </Button>
                    ) : (
                      '-'
                    )}
                    {project.attributes.energyStarId && (
                      <Button
                        size="icon"
                        variant="ghost"
                        className="text-primary"
                        onClick={async () => {
                          try {
                            await syncServices.syncWithEnergyStar(project.id, project.attributes.energyStarId);
                            setRefreshProjects((prev) => !prev);
                            setShowSyncFeedbackType('success');
                          } catch (error) {
                            console.error('Failed to sync with Energy Star:', error);
                            setSyncError(error instanceof Error ? error.message : 'Failed to sync with Energy Star');
                            setShowSyncFeedbackType('error');
                          }
                        }}
                        aria-label="Sync with Energy Star"
                      >
                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger asChild>
                              <RefreshCwIcon />
                            </TooltipTrigger>
                            <TooltipContent>
                              <p>Sync with Energy Star</p>
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      </Button>
                    )}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </ScrollArea>

      <div className="mt-4 flex items-center justify-between px-2">
        <div className="flex items-center gap-2 text-sm text-gray-700">
          <span>Rows per page:</span>
          <Select
            value={pagination.limit.toString()}
            onValueChange={(value) => {
              setPagination((prev) => ({ ...prev, limit: parseInt(value), page: 1 }));
            }}
          >
            <SelectTrigger className="h-8 w-[70px]">
              <SelectValue placeholder={pagination.limit.toString()} />
            </SelectTrigger>
            <SelectContent>
              {[5, 10, 20, 50].map((pageSize) => (
                <SelectItem key={pageSize} value={pageSize.toString()}>
                  {pageSize}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        <div className="flex items-center gap-2 text-sm">
          <span className="text-gray-700">
            Page {pagination.page} of {pagination.pages}
          </span>
          <div className="flex gap-1">
            <Button
              variant="outline"
              size="sm"
              onClick={() => setPagination((prev) => ({ ...prev, page: prev.page - 1 }))}
              disabled={pagination.page <= 1}
            >
              Previous
            </Button>
            <Button
              variant="outline"
              size="sm"
              onClick={() => setPagination((prev) => ({ ...prev, page: prev.page + 1 }))}
              disabled={pagination.page >= pagination.pages}
            >
              Next
            </Button>
          </div>
        </div>
      </div>

      <ProjectModal open={showNewProjectModal} setOpen={handleModalOpenChange} />

      <Dialog open={showDeleteFeedbackType !== null}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{showDeleteFeedbackType === 'success' ? 'Success' : 'Error'}</DialogTitle>
            <DialogDescription>
              {showDeleteFeedbackType === 'success'
                ? 'Project(s) deleted successfully.'
                : 'Failed to delete projects. Please try again'}
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button onClick={() => setShowDeleteFeedbackType(null)}>Continue</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Dialog open={showSyncFeedbackType !== null} onOpenChange={() => setShowSyncFeedbackType(null)}>
        <DialogContent className="sm:max-w-md">
          <div className="flex flex-col items-center justify-center gap-4 p-4 text-center">
            {showSyncFeedbackType === 'success' ? (
              <>
                <div className="rounded-full bg-green-500/20 p-3">
                  <RefreshCwIcon className="h-6 w-6 text-green-500" />
                </div>
                <div className="space-y-2">
                  <h3 className="text-lg font-medium">Sync Successful</h3>
                  <p className="text-sm text-muted-foreground">
                    Selected projects have been successfully synchronized with Energy Star.
                  </p>
                </div>
              </>
            ) : showSyncFeedbackType === 'error' ? (
              <>
                <div className="rounded-full bg-destructive/20 p-3">
                  <XIcon className="h-6 w-6 text-destructive" />
                </div>
                <div className="space-y-2">
                  <h3 className="text-lg font-medium">Sync Failed</h3>
                  <p className="text-sm text-destructive">
                    {syncError || 'Failed to sync with Energy Star. Please try again.'}
                  </p>
                </div>
              </>
            ) : null}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};
