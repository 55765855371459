import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger, Button, Label } from '@shadcn/ui';
import * as RadioGroup from '@radix-ui/react-radio-group';
import { BoltIcon, LinkIcon } from 'lucide-react';
import { useToast } from '@shadcn/hooks/use-toast';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import conedService from 'app/services/coned';
import { cn } from '@shadcn/utils';
import projectServices from 'app/services/project-services';

interface ConEdCustomerAccount {
  id: number;
  customerAccountId: number;
  streetAddress: string;
  city: string;
  state: string;
  postalCode: number;
}

interface ConEdisonAccount {
  authorizationURI: string;
  customerResourceURI: string;
  resourceURI: string;
  subscriptionId: string;
  scope: string;
  tokenExpiresAt: string;
}

export const ConEdConnectionDialog = () => {
  const { toast } = useToast();
  const { authUser } = useJumboAuth();
  const [conEdisonAccounts, setConEdAccounts] = useState<ConEdisonAccount[]>([]);
  const [conEdCustomerAccounts, setConEdCustomerAccounts] = useState<ConEdCustomerAccount[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<string | null>(null);

  const location = useLocation();

  const projectMatch = location.pathname.match(/\/project\/([^/]+)/);
  const projectId = projectMatch ? projectMatch[1] : null;

  useEffect(() => {
    if (authUser?.relationships?.organization?.data?.conEdisonAccounts) {
      setConEdAccounts(authUser.relationships.organization.data.conEdisonAccounts);
    }
  }, [authUser]);

  useEffect(() => {
    const fetchConEdAccounts = async () => {
      try {
        const response = await conedService.getConEdAccounts();
        if (response?.customerAccounts) {
          setConEdCustomerAccounts(response.customerAccounts);
        }
      } catch (error) {
        console.error('Error fetching ConEd accounts:', error);
        toast({
          description: 'Failed to fetch ConEd accounts. Please try again.',
          variant: 'destructive',
        });
      }
    };

    if (conEdisonAccounts?.length > 0) {
      fetchConEdAccounts();
    }
  }, [conEdisonAccounts, toast]);

  const handleLinkAccount = async () => {
    if (!selectedAccount) return;
    if (!projectId) return;
    await projectServices.syncConEdAccount({
      id: projectId.toString(),
      conEdisonSubscriptionId: selectedAccount,
    });
    toast({
      description: 'Account linking initiated...',
      variant: 'default',
    });
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button>
          <LinkIcon className="mr-2 h-4 w-4" />
          Connect to ConEdison Account
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-xl">
        <DialogHeader>
          <DialogTitle className="flex items-center gap-2 text-xl">
            <BoltIcon className="h-6 w-6 text-yellow-400" />
            ConEdison Real-Time Data
          </DialogTitle>
        </DialogHeader>

        <div className="mt-6 space-y-6">
          {conEdisonAccounts?.length > 0 ? (
            <div className="space-y-4">
              <p className="ml-4 text-sm font-medium text-muted-foreground">Connected Accounts:</p>
              <RadioGroup.Root value={selectedAccount || ''} onValueChange={setSelectedAccount} className="space-y-3">
                {conEdCustomerAccounts.map((account) => (
                  <div
                    key={account.id}
                    className="flex items-center space-x-4 rounded-lg border border-border/20 bg-background/5 p-4 transition-colors hover:bg-background/10"
                  >
                    <RadioGroup.Item
                      value={account.id.toString()}
                      id={`account-${account.id}`}
                      className={cn(
                        'h-4 w-4 rounded-full border border-primary',
                        'focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
                        'data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground'
                      )}
                    >
                      <RadioGroup.Indicator className="relative flex h-full w-full items-center justify-center after:block after:h-2 after:w-2 after:rounded-full after:bg-white" />
                    </RadioGroup.Item>
                    <Label htmlFor={`account-${account.id}`} className="flex grow cursor-pointer flex-col space-y-1">
                      <span className="font-medium">Subscription ID: {account.id}</span>
                      <span className="font-medium">Account ID: {account.customerAccountId}</span>
                      <span className="text-sm text-muted-foreground">
                        {account.streetAddress}, {account.city}, {account.state} {account.postalCode}
                      </span>
                    </Label>
                  </div>
                ))}
              </RadioGroup.Root>
            </div>
          ) : (
            <div className="rounded-lg border border-border/20 bg-muted/5 p-6 text-center">
              <p className="text-sm text-muted-foreground">
                There are no ConEdison accounts connected to your Cosmos account. Head to your Profile page to authorize
                ConEdison and share access to real-time utility data.
              </p>
            </div>
          )}

          {selectedAccount && (
            <div className="flex justify-center pt-4">
              <Button
                onClick={handleLinkAccount}
                className="w-full max-w-sm bg-primary/90 text-primary-foreground shadow-[0_2px_8px_rgba(124,58,237,0.35)] hover:bg-primary"
              >
                Link to ConEdison Account
              </Button>
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};
