import { Label } from '@shadcn/ui';
import { ClientService } from 'app/services/project-services';
import { cn } from '@shadcn/utils';
import {
  ClipboardCheckIcon, // For LL84 - represents compliance checklist
  FileCheckIcon, // For LL88 - represents verified document
  BarChart3Icon, // For LL97 - represents emissions reporting
  MonitorIcon, // For ESPM - represents monitoring system
  ServerIcon, // For BMS - represents building management system
  NetworkIcon, // For RTEM - represents real-time monitoring network,
  CheckIcon,
} from 'lucide-react';

const CLIENT_SERVICES = [
  {
    id: 'll84' as ClientService,
    name: 'LL84',
    icon: ClipboardCheckIcon,
    description: 'Energy and Water Benchmarking',
  },
  {
    id: 'll88' as ClientService,
    name: 'LL88',
    icon: FileCheckIcon,
    description: 'Energy Audits & Retro-commissioning',
  },
  {
    id: 'll97' as ClientService,
    name: 'LL97',
    icon: BarChart3Icon,
    description: 'Building Emissions Limits',
  },
  {
    id: 'setup-espm' as ClientService,
    name: 'Setup ESPM',
    icon: MonitorIcon,
    description: 'Energy Star Portfolio Manager Setup',
  },
  {
    id: 'setup-bms' as ClientService,
    name: 'Setup BMS',
    icon: ServerIcon,
    description: 'Building Management System Setup',
  },
  {
    id: 'setup-rtem' as ClientService,
    name: 'Setup RTEM',
    icon: NetworkIcon,
    description: 'Real-Time Energy Management Setup',
  },
];

interface ClientServicesInputProps {
  selectedServices: ClientService[];
  onChange: (services: ClientService[]) => void;
  error?: string;
  disabled?: boolean;
}

export const ClientServicesInput = ({
  selectedServices,
  onChange,
  error,
  disabled = false,
}: ClientServicesInputProps) => {
  const toggleService = (service: ClientService) => {
    if (disabled) return;

    if (selectedServices.includes(service)) {
      onChange(selectedServices.filter((s) => s !== service));
    } else {
      onChange([...selectedServices, service]);
    }
  };

  return (
    <div className="space-y-4">
      <Label className="text-md font-medium">Client Services</Label>
      <div className="grid grid-cols-2 gap-3 md:grid-cols-3 lg:grid-cols-6">
        {CLIENT_SERVICES.map((service) => {
          const isSelected = selectedServices.includes(service.id);
          const Icon = service.icon;

          return (
            <div
              key={service.id}
              onClick={() => toggleService(service.id)}
              className={cn(
                'group relative flex cursor-pointer flex-col items-center justify-start rounded-lg border p-3 transition-all duration-200',
                'hover:shadow-md',
                isSelected ? 'border-primary bg-primary/10' : 'border-border bg-background hover:border-primary/50',
                disabled && 'cursor-not-allowed opacity-60'
              )}
            >
              {isSelected && (
                <div className="absolute right-1.5 top-1.5 rounded-full bg-primary p-0.5">
                  <CheckIcon className="h-3 w-3 text-white" />
                </div>
              )}

              <Icon className={cn('mb-1.5 h-5 w-5', isSelected ? 'text-primary' : 'text-muted-foreground')} />

              <span className={cn('text-sm font-medium', isSelected ? 'text-primary' : 'text-foreground')}>
                {service.name}
              </span>
              <span className="mt-0.5 text-center text-[10px] leading-tight text-muted-foreground opacity-80 transition-opacity group-hover:opacity-100">
                {service.description}
              </span>
            </div>
          );
        })}
      </div>

      {error && <p className="mt-2 text-sm text-destructive">{error}</p>}
      {!error && <p className="mt-2 text-xs text-muted-foreground">Select all client services that apply</p>}
    </div>
  );
};
