import { Button, Checkbox, Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@shadcn/ui';
import { useState } from 'react';
import { PlusIcon } from 'lucide-react';

interface DataTableProps {
  headers: string[];
  rows: any[][];
  isCustomizable?: boolean;
  onTableChange?: (rows: any[][]) => void;
}

export const DataTable = ({ headers, rows, isCustomizable = false, onTableChange }: DataTableProps) => {
  const [tableRows, setTableRows] = useState<any[][]>(rows);
  const [visibleRows, setVisibleRows] = useState<boolean[]>(rows.map(() => true));

  const handleAddRow = () => {
    const newRow = new Array(headers.length).fill('');
    const newRows = [...tableRows, newRow];
    setTableRows(newRows);
    setVisibleRows([...visibleRows, true]);
    onTableChange?.(newRows.filter((_, i) => visibleRows[i]));
  };

  const handleRowVisibilityChange = (rowIndex: number) => {
    setVisibleRows((prev) => {
      const newVisibility = [...prev];
      newVisibility[rowIndex] = !newVisibility[rowIndex];
      onTableChange?.(tableRows.filter((_, i) => newVisibility[i]));
      return newVisibility;
    });
  };

  const handleCellChange = (rowIndex: number, cellIndex: number, value: string) => {
    setTableRows((prev) => {
      const newRows = [...prev];
      newRows[rowIndex] = [...newRows[rowIndex]];
      newRows[rowIndex][cellIndex] = value;
      onTableChange?.(newRows.filter((_, i) => visibleRows[i]));
      return newRows;
    });
  };

  return (
    <div className="overflow-x-auto">
      <Table>
        <TableHeader>
          <TableRow>
            {isCustomizable && <TableHead className="w-[80px]">Include</TableHead>}
            {headers.map((header, i) => (
              <TableHead key={i}>{header}</TableHead>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {tableRows.map((row, rowIndex) =>
            visibleRows[rowIndex] || !isCustomizable ? (
              <TableRow key={rowIndex}>
                {isCustomizable && (
                  <TableCell>
                    <Checkbox
                      checked={visibleRows[rowIndex]}
                      onCheckedChange={() => handleRowVisibilityChange(rowIndex)}
                    />
                  </TableCell>
                )}
                {row.map((cell, cellIndex) => (
                  <TableCell key={cellIndex}>
                    {isCustomizable ? (
                      <textarea
                        value={cell}
                        onChange={(e) => handleCellChange(rowIndex, cellIndex, e.target.value)}
                        className="min-h-[80px] w-full resize-y rounded-md border p-2"
                        style={{
                          height: 'auto',
                          overflow: 'hidden',
                        }}
                        onInput={(e) => {
                          // Auto-resize the textarea to fit content
                          const target = e.target as HTMLTextAreaElement;
                          target.style.height = 'auto';
                          target.style.height = `${target.scrollHeight}px`;
                        }}
                      />
                    ) : (
                      <div className="whitespace-pre-line">{cell}</div>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ) : null
          )}
        </TableBody>
      </Table>
      {isCustomizable && (
        <div className="mt-4">
          <Button variant="outline" size="sm" onClick={handleAddRow}>
            <PlusIcon className="mr-2 h-4 w-4" />
            Add New Measure
          </Button>
        </div>
      )}
    </div>
  );
};
