import { Menu } from 'app/layouts/shared/sidebar/VerticalNavbar';
import {
  ChartCandlestickIcon,
  House,
  SquareChartGanttIcon,
  SquareUserRoundIcon,
  Building2Icon,
  DatabaseIcon,
  BarChart3Icon,
  FileTextIcon,
  FileOutput,
} from 'lucide-react';

export const menus: Menu = [
  {
    label: 'Dashboard',
    type: 'section',
    children: [
      {
        uri: '/dashboard/home',
        label: 'My Cosmos',
        type: 'nav-item',
        icon: <House className="current-color size-2" />,
      },
      {
        uri: '/dashboard/project',
        label: 'Projects',
        type: 'nav-item',
        icon: <SquareChartGanttIcon className="current-color size-2" />,
      },
    ],
  },
  {
    label: 'Project Navigation',
    type: 'section',
    children: [
      {
        uri: '/dashboard/project/:id/building-info',
        label: 'Summary',
        type: 'nav-item',
        icon: <Building2Icon className="current-color size-2" />,
      },
      {
        uri: '/dashboard/project/:id/analysis',
        label: 'Energy Operations',
        type: 'nav-item',
        icon: <BarChart3Icon className="current-color size-2" />,
      },
      // {
      //   uri: '/dashboard/project/:id/operations',
      //   label: 'Operations Dashboard',
      //   type: 'nav-item',
      //   icon: <BarChart3Icon className="current-color size-2" />,
      // },
      {
        uri: '/dashboard/project/:id/financial',
        label: 'Financial Insights',
        type: 'nav-item',
        icon: <ChartCandlestickIcon className="current-color size-2" />,
      },
      {
        uri: '/dashboard/project/:id/report-builder',
        label: 'Report Builder',
        type: 'nav-item',
        icon: <FileTextIcon className="current-color size-2" />,
      },
      {
        uri: '/dashboard/project/:id/exports',
        label: 'Exports',
        type: 'nav-item',
        icon: <FileOutput className="current-color size-2" />,
      },
      {
        uri: '/dashboard/project/:id/utility-bills',
        label: 'Data Sources',
        type: 'nav-item',
        icon: <DatabaseIcon className="current-color size-2" />,
      },
    ],
  },
  {
    label: 'Account',
    type: 'section',
    children: [
      {
        uri: '/account/profile',
        label: 'Profile',
        type: 'nav-item',
        icon: <SquareUserRoundIcon className="current-color size-2" />,
      },
    ],
  },
];
