import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Button, Card, ScrollArea, Checkbox, Dialog, DialogContent, DialogHeader, DialogTitle } from '@shadcn/ui';
import {
  EditIcon,
  SaveIcon,
  XIcon,
  FileText,
  Sparkles,
  BoldIcon,
  ItalicIcon,
  UnderlineIcon,
  Heading1Icon,
  Heading2Icon,
  Heading3Icon,
  ListIcon,
  ListOrderedIcon,
  AlignLeft,
  AlignCenter,
  AlignRight,
} from 'lucide-react';
import { useToast } from '@shadcn/hooks/use-toast';
import reportServices from 'app/services/report-services';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@shadcn/ui';
import { ReportChart } from 'app/components/charts/ReportChart';
import { DataTable } from 'app/components/DataTable';
import { useEditor, EditorContent, Editor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Heading from '@tiptap/extension-heading';
import Table from '@tiptap/extension-table';
import TableRow from '@tiptap/extension-table-row';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TextAlign from '@tiptap/extension-text-align';
import Underline from '@tiptap/extension-underline';
import Image from '@tiptap/extension-image';
import { useNavigate } from 'react-router-dom';

interface ReportBuilderProps {
  projectId: string;
  onClose: () => void;
  startDate: string;
  endDate: string;
  reportName: string;
  dataSource: 'utility-bills' | 'energy-star';
  logoFile?: File | null;
  buildingImageFile?: File | null;
  draftId?: string;
  draftData?: any;
}

interface ChartData {
  type: 'line' | 'bar' | 'pie';
  data: any;
  options?: any;
}

interface ReportSectionData {
  id: string;
  title?: string;
  content: string;
  charts?: ChartData[];
  tables?: any[];
  isEditable: boolean;
  isIncluded: boolean;
}

export interface ReportPageData {
  pageNumber: number;
  title: string;
  sections: ReportSectionData[];
}

const OptionsPanel = ({
  pages,
  onToggleSection,
  onGenerateReport,
  onSaveDraft,
  reportName,
  dataSource,
  startDate,
  endDate,
  isSaving,
}: {
  pages: ReportPageData[];
  onToggleSection: (pageIndex: number, sectionId: string) => void;
  onGenerateReport: () => void;
  onSaveDraft: () => void;
  reportName: string;
  dataSource: string;
  startDate: string;
  endDate: string;
  isSaving: boolean;
}) => {
  const isExportDisabled = !reportName.trim() || !dataSource || !startDate || !endDate;

  return (
    <Card
      className="flex w-96 min-w-[24rem] flex-col overflow-hidden border-border/20 bg-background/5 shadow-lg backdrop-blur-sm"
      style={{ height: 'calc(100vh - 280px)' }}
    >
      <div className="flex items-center justify-between border-b border-border/20 p-4">
        <h2 className="text-xl font-bold">Export Options</h2>
        <div className="flex gap-2">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <div>
                  <Button
                    onClick={onSaveDraft}
                    disabled={isExportDisabled || isSaving}
                    variant="outline"
                    className="flex items-center gap-2"
                  >
                    <SaveIcon className="h-4 w-4" />
                    Save as Draft
                  </Button>
                </div>
              </TooltipTrigger>
              <TooltipContent>
                {isExportDisabled
                  ? 'Please ensure you have a report name, data source, and date range selected'
                  : 'Save the current report as a draft'}
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <div>
                  <Button onClick={onGenerateReport} disabled={isExportDisabled} className="flex items-center gap-2">
                    <FileText className="h-4 w-4" />
                    Export as PDF
                  </Button>
                </div>
              </TooltipTrigger>
              <TooltipContent>
                {isExportDisabled
                  ? 'Please ensure you have a report name, data source, and date range selected'
                  : 'Export the report with selected options'}
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </div>
      <ScrollArea className="flex-1 p-4">
        <div className="space-y-6 pb-36">
          {pages.map((page, pageIndex) => (
            <div key={page.pageNumber} className="rounded-lg border border-border/20 bg-background/5 p-4">
              <h3 className="mb-3 font-medium">{page.title}</h3>
              {page.sections.map((section) => (
                <div key={section.id} className="mb-2 flex items-center gap-2 pl-2">
                  <Checkbox
                    checked={section.isIncluded}
                    onCheckedChange={() => onToggleSection(pageIndex, section.id)}
                  />
                  <span className="text-sm">{section.title || 'Untitled Section'}</span>
                </div>
              ))}
            </div>
          ))}
        </div>
      </ScrollArea>
    </Card>
  );
};

const SectionEditor = ({
  content,
  isEditing,
  onSave,
  pageIndex,
}: {
  content: string;
  isEditing: boolean;
  onSave: (content: string) => void;
  pageIndex: number;
}) => {
  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        history: {
          depth: 10,
          newGroupDelay: 500,
        },
      }),
      Heading.configure({
        levels: [1, 2, 3],
      }),
      Table.configure({
        resizable: true,
      }),
      TableRow,
      TableCell,
      TableHeader,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      Underline,
      Image.configure({
        inline: true,
        allowBase64: true,
      }),
    ],
    content,
    editable: isEditing,
    editorProps: {
      attributes: {
        class: 'prose prose-sm max-w-none focus:outline-none min-h-[100px] prose-headings:text-muted-foreground',
      },
    },
  });

  useEffect(() => {
    if (editor && content !== editor.getHTML()) {
      editor.commands.setContent(content, false);
    }
  }, [editor, content]);

  useEffect(() => {
    if (editor) {
      editor.setEditable(isEditing);
    }
  }, [editor, isEditing]);

  if (!editor) {
    return null;
  }

  return (
    <EditorContent
      editor={editor}
      className={`prose-sm w-full max-w-none overflow-y-auto rounded-lg p-4 ${
        isEditing ? 'border border-primary ring-2 ring-primary/20' : ''
      } ${pageIndex === 0 ? 'text-center' : ''}`}
    />
  );
};

const EditorToolbar = ({ editor }: { editor: Editor | null }) => {
  if (!editor) {
    return null;
  }

  return (
    <div className="mb-2 flex flex-wrap items-center gap-1 rounded-md border border-border bg-background p-2">
      <Button
        size="sm"
        variant={editor.isActive('bold') ? 'secondary' : 'ghost'}
        onClick={() => editor.chain().focus().toggleBold().run()}
        disabled={!editor.can().chain().focus().toggleBold().run()}
      >
        <BoldIcon className="h-4 w-4" />
      </Button>
      <Button
        size="sm"
        variant={editor.isActive('italic') ? 'secondary' : 'ghost'}
        onClick={() => editor.chain().focus().toggleItalic().run()}
        disabled={!editor.can().chain().focus().toggleItalic().run()}
      >
        <ItalicIcon className="h-4 w-4" />
      </Button>
      <Button
        size="sm"
        variant={editor.isActive('underline') ? 'secondary' : 'ghost'}
        onClick={() => editor.chain().focus().toggleUnderline().run()}
        disabled={!editor.can().chain().focus().toggleUnderline().run()}
      >
        <UnderlineIcon className="h-4 w-4" />
      </Button>
      <Button
        size="sm"
        variant={editor.isActive('heading', { level: 1 }) ? 'secondary' : 'ghost'}
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
        disabled={!editor.can().chain().focus().toggleHeading({ level: 1 }).run()}
      >
        <Heading1Icon className="h-4 w-4" />
      </Button>
      <Button
        size="sm"
        variant={editor.isActive('heading', { level: 2 }) ? 'secondary' : 'ghost'}
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
        disabled={!editor.can().chain().focus().toggleHeading({ level: 2 }).run()}
      >
        <Heading2Icon className="h-4 w-4" />
      </Button>
      <Button
        size="sm"
        variant={editor.isActive('heading', { level: 3 }) ? 'secondary' : 'ghost'}
        onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
        disabled={!editor.can().chain().focus().toggleHeading({ level: 3 }).run()}
      >
        <Heading3Icon className="h-4 w-4" />
      </Button>
      <Button
        size="sm"
        variant={editor.isActive({ textAlign: 'left' }) ? 'secondary' : 'ghost'}
        onClick={() => editor.chain().focus().setTextAlign('left').run()}
        disabled={!editor.can().setTextAlign('left')}
      >
        <AlignLeft className="h-4 w-4" />
      </Button>
      <Button
        size="sm"
        variant={editor.isActive({ textAlign: 'center' }) ? 'secondary' : 'ghost'}
        onClick={() => editor.chain().focus().setTextAlign('center').run()}
        disabled={!editor.can().setTextAlign('center')}
      >
        <AlignCenter className="h-4 w-4" />
      </Button>
      <Button
        size="sm"
        variant={editor.isActive({ textAlign: 'right' }) ? 'secondary' : 'ghost'}
        onClick={() => editor.chain().focus().setTextAlign('right').run()}
        disabled={!editor.can().setTextAlign('right')}
      >
        <AlignRight className="h-4 w-4" />
      </Button>
      <Button
        size="sm"
        variant={editor.isActive('bulletList') ? 'secondary' : 'ghost'}
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        disabled={!editor.can().chain().focus().toggleBulletList().run()}
      >
        <ListIcon className="h-4 w-4" />
      </Button>
      <Button
        size="sm"
        variant={editor.isActive('orderedList') ? 'secondary' : 'ghost'}
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        disabled={!editor.can().chain().focus().toggleOrderedList().run()}
      >
        <ListOrderedIcon className="h-4 w-4" />
      </Button>
    </div>
  );
};

const ModalEditor = ({
  initialContent,
  onContentChange,
}: {
  initialContent: string;
  onContentChange: (content: string) => void;
}) => {
  const editor = useEditor({
    extensions: [
      StarterKit.configure({ history: { depth: 10, newGroupDelay: 500 } }),
      Heading.configure({ levels: [1, 2, 3] }),
      Table.configure({ resizable: true }),
      TableRow,
      TableCell,
      TableHeader,
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
      Underline,
      Image.configure({ inline: true, allowBase64: true }),
    ],
    content: initialContent,
    editable: true,
    editorProps: {
      attributes: {
        class: 'prose prose-sm max-w-none focus:outline-none p-4 h-full',
      },
    },
    onUpdate: ({ editor }) => {
      onContentChange(editor.getHTML());
    },
  });

  useEffect(() => {
    if (editor && editor.getHTML() !== initialContent) {
      editor.commands.setContent(initialContent, false);
    }
  }, [initialContent, editor]);

  useEffect(() => {
    editor?.commands.focus('end');
  }, [editor]);

  if (!editor) {
    return null;
  }

  return (
    <div className="flex h-full flex-col">
      <EditorToolbar editor={editor} />
      <div className="flex flex-1 overflow-y-auto rounded-md border border-input bg-background">
        <EditorContent editor={editor} className="w-full" />
      </div>
    </div>
  );
};

export const ReportPreview = forwardRef<{ handleGenerateReport: () => Promise<void> }, ReportBuilderProps>(
  (
    { projectId, onClose, startDate, endDate, reportName, dataSource, logoFile, buildingImageFile, draftId, draftData },
    ref
  ) => {
    const [pages, setPages] = useState<ReportPageData[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [editingSection, setEditingSection] = useState<{
      pageIndex: number;
      section: ReportSectionData;
    } | null>(null);
    const [modalEditorContent, setModalEditorContent] = useState<string>('');
    const { toast } = useToast();
    const navigate = useNavigate();

    useImperativeHandle(ref, () => ({
      handleGenerateReport: async () => {
        await handleGenerateReport();
      },
    }));

    useEffect(() => {
      fetchReportPreview();
    }, []);

    const fetchReportPreview = async () => {
      try {
        setIsLoading(true);

        if (draftData?.pages) {
          setPages(draftData.pages);
        } else {
          const previewResponse = await reportServices.getReportPreview(projectId, {
            dataSource,
            startDate,
            endDate,
          });
          setPages(previewResponse);
        }
      } catch (error: any) {
        console.error('Failed to fetch report preview:', error);
        toast({
          description: error.message || 'Failed to fetch report preview',
          variant: 'destructive',
        });
      } finally {
        setIsLoading(false);
      }
    };

    const handleEdit = (pageIndex: number, section: ReportSectionData) => {
      setEditingSection({
        pageIndex,
        section,
      });
      setModalEditorContent(section.content);
    };

    const handleCancelEdit = () => {
      setEditingSection(null);
      setModalEditorContent('');
    };

    const handleSave = () => {
      if (!editingSection) return;
      const contentToSave = modalEditorContent;

      setPages(
        pages.map((page, idx) => {
          if (idx === editingSection.pageIndex) {
            return {
              ...page,
              sections: page.sections.map((section) => {
                if (section.id === editingSection.section.id) {
                  return { ...section, content: contentToSave };
                }
                return section;
              }),
            };
          }
          return page;
        })
      );
      handleCancelEdit();
    };

    const handleToggleSection = (pageIndex: number, sectionId: string) => {
      setPages(
        pages.map((page, idx) => {
          if (idx === pageIndex) {
            return {
              ...page,
              sections: page.sections.map((section) => {
                if (section.id === sectionId) {
                  return { ...section, isIncluded: !section.isIncluded };
                }
                return section;
              }),
            };
          }
          return page;
        })
      );
    };

    const handleGenerateReport = async () => {
      try {
        const filteredPages = pages.map((page) => ({
          ...page,
          sections: page.sections.filter((section) => section.isIncluded),
        }));

        const finalPages = filteredPages.filter((page) => page.sections.length > 0);

        let logoData: string | ArrayBuffer | null = null;
        if (logoFile) {
          const reader = new FileReader();
          logoData = await new Promise<string | ArrayBuffer | null>((resolve) => {
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(logoFile);
          });
        }

        let buildingImageData: string | ArrayBuffer | null = null;
        if (buildingImageFile) {
          const reader = new FileReader();
          buildingImageData = await new Promise<string | ArrayBuffer | null>((resolve) => {
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(buildingImageFile);
          });
        }

        await reportServices.create({
          project: projectId,
          outputType: 'pdf',
          name: reportName,
          dataSource: dataSource,
          pages: finalPages,
          startDate,
          endDate,
          logo: logoData,
          buildingImage: buildingImageData,
        });

        toast({
          description: 'Exporting report...',
          variant: 'positive',
        });

        navigate(`/dashboard/project/${projectId}/exports`);
      } catch (error) {
        console.error('Failed to generate report:', error);
        toast({
          description: 'Failed to generate report.',
          variant: 'destructive',
        });
      }
    };

    const handleSaveDraft = async () => {
      try {
        setIsSaving(true);
        const filteredPages = pages.map((page) => ({
          ...page,
          sections: page.sections.filter((section) => section.isIncluded),
        }));

        const finalPages = filteredPages.filter((page) => page.sections.length > 0);

        let logoData: string | ArrayBuffer | null = null;
        if (logoFile) {
          const reader = new FileReader();
          logoData = await new Promise<string | ArrayBuffer | null>((resolve) => {
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(logoFile);
          });
        }

        let buildingImageData: string | ArrayBuffer | null = null;
        if (buildingImageFile) {
          const reader = new FileReader();
          buildingImageData = await new Promise<string | ArrayBuffer | null>((resolve) => {
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(buildingImageFile);
          });
        }

        const draftData = {
          project: projectId,
          name: reportName,
          dataSource: dataSource,
          pages: finalPages,
          startDate,
          endDate,
          logo: logoData,
          buildingImage: buildingImageData,
        };

        if (draftId) {
          await reportServices.updateDraft(draftId, draftData);
          toast({
            description: 'Draft updated successfully',
            variant: 'positive',
          });
        } else {
          await reportServices.createDraft(draftData);
          toast({
            description: 'Draft saved successfully',
            variant: 'positive',
          });
        }
      } catch (error) {
        console.error('Failed to save draft:', error);
        toast({
          description: 'Failed to save draft.',
          variant: 'destructive',
        });
      } finally {
        setIsSaving(false);
      }
    };

    const renderSection = (pageIndex: number, section: ReportSectionData) => (
      <div
        key={section.id}
        className={`mb-6 rounded-lg border border-border/10 bg-background/5 p-6 ${section.title ? 'has-title' : ''}`}
      >
        {section.title && (
          <div className="mb-4 flex items-center justify-between border-b border-border/20 pb-4">
            <h3 className="section-title font-bold">{section.title}</h3>
            {section.isEditable && (
              <div className="flex gap-2">
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button variant="ghost" size="sm" onClick={() => handleEdit(pageIndex, section)}>
                        <EditIcon className="h-4 w-4" />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Edit content</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
            )}
          </div>
        )}
        {section.content && section.content.trim().length > 0 && (
          <SectionEditor content={section.content} isEditing={false} onSave={() => {}} pageIndex={pageIndex} />
        )}
        {section.tables?.map((table, index) => (
          <div key={`${section.id}-table-${index}`} className="mb-8 mt-6 w-full">
            {table.name && <h3 className="mb-2 text-lg font-bold">{table.name}</h3>}
            {table.description && <p className="mb-3">{table.description}</p>}
            <div className="w-full overflow-x-auto">
              <DataTable
                headers={table.headers}
                rows={table.rows}
                isCustomizable={table.isCustomizable}
                onTableChange={(updatedRows) => {
                  setPages(
                    pages.map((page, idx) => {
                      if (idx === pageIndex) {
                        return {
                          ...page,
                          sections: page.sections.map((sec) => {
                            if (sec.id === section.id) {
                              return {
                                ...sec,
                                tables: sec.tables?.map((t, i) => (i === index ? { ...t, rows: updatedRows } : t)),
                              };
                            }
                            return sec;
                          }),
                        };
                      }
                      return page;
                    })
                  );
                }}
              />
            </div>
          </div>
        ))}
        {section.charts?.map((chart, index) => (
          <div key={`${section.id}-chart-${index}`} className="mb-8 mt-8">
            <ReportChart type={chart.type} data={chart.data} options={chart.options} />
          </div>
        ))}
      </div>
    );

    const loadingAnimation = () => {
      return (
        <div className="flex h-full flex-col items-center justify-center gap-4">
          <div className="relative mb-6">
            <Sparkles className="absolute -left-16 -top-16 h-8 w-8 animate-ping text-primary/40" />
            <Sparkles className="absolute -right-16 -top-16 h-8 w-8 animate-ping text-primary/40 delay-300" />
            <Sparkles className="absolute -bottom-16 -left-16 h-8 w-8 animate-ping text-primary/40 delay-500" />
            <Sparkles className="-bottom-18 absolute -right-16 h-8 w-8 animate-ping text-primary/40 delay-700" />
            <FileText className="h-16 w-16 animate-pulse text-primary/40" />
            <FileText className="absolute left-8 top-4 h-16 w-16 animate-pulse text-primary/60 delay-150" />
            <FileText className="absolute left-16 top-8 h-16 w-16 animate-pulse text-primary delay-300" />
          </div>
          <p className="mt-12 text-lg text-muted-foreground">Preparing your report preview...</p>
        </div>
      );
    };

    return (
      <div className="flex h-full gap-6">
        <div className="flex-1 overflow-hidden" style={{ maxWidth: 'calc(100% - 26rem)' }}>
          {isLoading ? (
            loadingAnimation()
          ) : (
            <ScrollArea className="h-full">
              {pages.map((page, pageIndex) => (
                <Card
                  key={page.pageNumber}
                  className="mb-4 border-border/20 bg-background/5 p-6 shadow-lg backdrop-blur-sm"
                >
                  <div className="mb-4 flex items-center justify-between border-b border-border/20 pb-2">
                    <div className="text-sm">Page {page.pageNumber}</div>
                  </div>
                  {page.sections
                    .filter((section) => section.isIncluded)
                    .map((section) => renderSection(pageIndex, section))}
                </Card>
              ))}
            </ScrollArea>
          )}
        </div>
        <div className="min-w-[24rem]">
          <OptionsPanel
            pages={pages}
            onToggleSection={handleToggleSection}
            onGenerateReport={handleGenerateReport}
            onSaveDraft={handleSaveDraft}
            reportName={reportName}
            dataSource={dataSource}
            startDate={startDate}
            endDate={endDate}
            isSaving={isSaving}
          />
        </div>

        {editingSection && (
          <Dialog open={true} onOpenChange={(open) => !open && handleCancelEdit()}>
            <DialogContent className="flex h-[80vh] !w-[90vw] max-w-[90vw] flex-col border-border/20 bg-background shadow-lg backdrop-blur-sm dark:bg-gray-900 sm:!w-[90vw] sm:max-w-[90vw]">
              <DialogHeader className="border-b border-border/20 pb-4">
                <DialogTitle className="text-xl font-semibold text-foreground dark:text-gray-100">
                  Edit {editingSection.section?.title || 'Section'}
                </DialogTitle>
              </DialogHeader>
              <div className="flex-1 overflow-hidden px-6 py-4">
                <ModalEditor
                  initialContent={editingSection.section?.content ?? ''}
                  onContentChange={setModalEditorContent}
                />
              </div>
              <div className="flex justify-end gap-3 border-t border-border/20 px-6 py-4">
                <Button variant="outline" onClick={handleCancelEdit}>
                  <XIcon className="mr-2 h-4 w-4" />
                  Cancel
                </Button>
                <Button onClick={handleSave}>
                  <SaveIcon className="mr-2 h-4 w-4" />
                  Save Changes
                </Button>
              </div>
            </DialogContent>
          </Dialog>
        )}
      </div>
    );
  }
);
