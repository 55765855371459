import { cn } from '@shadcn/utils';
import { menus } from './menus';
import { SidebarHeader } from './SidebarHeader';
import { VerticalNavbar } from './VerticalNavbar';

export const Sidebar = () => {
  return (
    <div className="flex h-full flex-col border-r border-r-border/50 bg-background pt-4">
      <div
        className={cn('flex min-w-[60px] flex-col', 'transition-all duration-150', {
          'scale-100 opacity-100': true,
        })}
      >
        <SidebarHeader />
        <VerticalNavbar items={menus} />
      </div>
    </div>
  );
};
