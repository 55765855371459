import moment from 'moment';
import momentTZ from 'moment-timezone';
import { DataGap, IntervalBlock } from './types';
export const convertUTCToEastern = (utcTimestamp: number): Date => {
  const date = new Date(utcTimestamp);
  const easternDate = momentTZ(date).tz('America/New_York');
  return easternDate.toDate();
};

export const hasDataGaps = (
  data: { startTime: string; value: number }[]
): { hasGaps: boolean; gaps: DataGap[]; intervalMinutes: number } => {
  const gaps: DataGap[] = [];
  let intervalMinutes = 5; // default to 5 minutes

  if (data.length > 1) {
    const firstFewIntervals: number[] = [];
    for (let i = 1; i < Math.min(data.length, 10); i++) {
      const timeDiff = new Date(data[i].startTime).getTime() - new Date(data[i - 1].startTime).getTime();
      firstFewIntervals.push(timeDiff / (60 * 1000)); // convert to minutes
    }

    const intervalCounts = firstFewIntervals.reduce(
      (acc, interval) => {
        const roundedInterval = Math.round(interval);
        acc[roundedInterval] = (acc[roundedInterval] || 0) + 1;
        return acc;
      },
      {} as Record<number, number>
    );

    const mostCommonInterval = Number(
      Object.entries(intervalCounts).reduce((a, b) =>
        intervalCounts[Number(a[0])] > intervalCounts[Number(b[0])] ? a : b
      )[0]
    );

    // Check against all possible intervals (5min, 1hr, 24hr)
    const possibleIntervals = [5, 60, 1440];
    intervalMinutes = possibleIntervals.reduce((closest, current) =>
      Math.abs(current - mostCommonInterval) < Math.abs(closest - mostCommonInterval) ? current : closest
    );
  }

  const expectedIntervalWithBuffer = intervalMinutes * 60 * 1000 * 1.2;

  for (let i = 1; i < data.length; i++) {
    const timeDiff = new Date(data[i].startTime).getTime() - new Date(data[i - 1].startTime).getTime();
    if (timeDiff > expectedIntervalWithBuffer) {
      gaps.push({
        start: data[i - 1].startTime,
        end: data[i].startTime,
      });
    }
  }
  return { hasGaps: gaps.length > 0, gaps, intervalMinutes };
};

export const normalizeIntervalData = (data: { [key: string]: IntervalBlock[] }) => {
  const allTimestamps = new Set<string>();
  Object.values(data).forEach((meterData) => {
    meterData.forEach((reading) => {
      allTimestamps.add(reading.startTime);
    });
  });

  const sortedTimestamps = Array.from(allTimestamps).sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

  const normalizedData = sortedTimestamps.map((timestamp) => {
    const point: any = { startTime: timestamp };
    Object.entries(data).forEach(([meterId, meterData]) => {
      const reading = meterData.find((r) => r.startTime === timestamp);
      point[meterId] = reading ? reading.value : null;
    });
    return point;
  });

  return normalizedData;
};

export const toEasternTime = (date: Date | string | number) => {
  return momentTZ(date).tz('America/New_York');
};

export const getPublishedDateRange = (startDate: string | Date, endDate: string | Date) => {
  const publishedMin = moment(startDate).format('YYYY-MM-DDTHH:mm:ss[Z]');
  const publishedMax = moment(moment.min(moment(endDate), moment()).toDate()).format('YYYY-MM-DDTHH:mm:ss[Z]');
  return { publishedMin, publishedMax };
};

export const getCurrentEasternTime = () => {
  const now = new Date();
  return convertUTCToEastern(now.getTime());
};
