import { Button, Tooltip, TooltipContent, TooltipTrigger } from '@shadcn/ui';
import { SunIcon, CloudRainIcon, ChevronUpIcon, ChevronDownIcon } from 'lucide-react';
import { WeatherPicker } from './WeatherPicker';
import { WeatherProps } from 'app/types/visualizations';
import { Documents } from 'app/types/visualizations';
import projectServices from 'app/services/project-services';
import { extractError } from 'app/utils/appHelpers';
import { cn } from '@shadcn/utils';

interface WeatherSectionProps {
  projectId: string;
  documents: Documents;
  meterReadings: Documents;
  weather: WeatherProps;
  isWeatherOpen: boolean;
  isWeatherLoading: boolean;
  isEditing: boolean;
  zipCode: string;
  startDate: string;
  endDate: string;
  onWeatherChange: (weather: WeatherProps) => void;
  onWeatherOpenChange: (isOpen: boolean) => void;
  onEditingChange: (isEditing: boolean) => void;
  onZipCodeChange: (zipCode: string) => void;
  onStartDateChange: (date: string) => void;
  onEndDateChange: (date: string) => void;
  onSnackbar: (message: string, variant: 'default' | 'destructive' | 'positive' | null | undefined) => void;
}

export const WeatherSection = ({
  projectId,
  documents,
  meterReadings,
  weather,
  isWeatherOpen,
  isWeatherLoading,
  isEditing,
  zipCode,
  startDate,
  endDate,
  onWeatherChange,
  onWeatherOpenChange,
  onEditingChange,
  onZipCodeChange,
  onStartDateChange,
  onEndDateChange,
  onSnackbar,
}: WeatherSectionProps) => {
  const hasDocuments = documents ? Object.values(documents).some((entry) => entry?.billingPeriods?.length > 0) : false;
  const hasMeterReadings = meterReadings
    ? Object.values(meterReadings).some((entry) => entry?.billingPeriods?.length > 0)
    : false;

  const toggleWeather = () => {
    onWeatherOpenChange(!isWeatherOpen);
    onEditingChange(false);
  };

  const handleAddWeather = async () => {
    try {
      const params = {
        id: projectId,
        data: { zipCode, startDate, endDate },
      };

      const savedProject = await projectServices.addWeather(params);
      if (savedProject) {
        onSnackbar('Weather data added successfully.', 'positive');
        onWeatherChange(savedProject.weather);
        onEditingChange(false);
        onWeatherOpenChange(false);
      }
    } catch (error) {
      onSnackbar(extractError(error) || 'Failed to add weather data.', 'destructive');
    }
  };

  const buttonClass = hasDocuments || hasMeterReadings ? '' : 'opacity-50';
  const tooltipContent =
    hasDocuments || hasMeterReadings ? 'Add or edit weather data' : 'Add documents to enable weather data';

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex h-12 items-center justify-end rounded-lg border border-border/20 bg-muted/5 p-3 shadow-[inset_0_1px_2px_rgba(0,0,0,0.1)] transition-all hover:border-primary/30 hover:bg-muted/10">
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                onClick={toggleWeather}
                disabled={!(hasDocuments || hasMeterReadings)}
                className={cn(
                  'flex items-center gap-2 bg-transparent p-0 text-sm font-medium text-foreground hover:bg-transparent',
                  buttonClass
                )}
              >
                Weather Data
                {weather && Object.keys(weather).length > 0 ? (
                  <SunIcon className="h-5 w-5 text-yellow-400" />
                ) : (
                  <CloudRainIcon className="h-5 w-5 text-muted-foreground" />
                )}
                {isWeatherOpen ? (
                  <ChevronUpIcon className="h-4 w-4 text-primary" />
                ) : (
                  <ChevronDownIcon className="h-4 w-4 text-primary" />
                )}
              </Button>
            </TooltipTrigger>
            <TooltipContent className="rounded-lg border border-border/20 bg-background p-2 text-sm shadow-lg">
              {tooltipContent}
            </TooltipContent>
          </Tooltip>
        </div>
      </div>

      {isWeatherOpen &&
        ((documents && Object.keys(documents).length > 0) ||
          (meterReadings && Object.keys(meterReadings).length > 0)) && (
          <div className="mt-2 animate-fade-in border-t border-border/10 px-2 py-4">
            <WeatherPicker
              zipCode={zipCode}
              startDate={startDate}
              endDate={endDate}
              isEditing={isEditing}
              isWeatherLoading={isWeatherLoading}
              hasWeatherData={weather && Object.keys(weather).length > 0}
              onZipCodeChange={onZipCodeChange}
              onStartDateChange={onStartDateChange}
              onEndDateChange={onEndDateChange}
              onSubmit={handleAddWeather}
              onStartEditing={() => onEditingChange(true)}
              onCancelEditing={() => onEditingChange(false)}
            />
          </div>
        )}
    </>
  );
};
