export const ALL = 'all';
export const ELECTRIC = 'electric';
export const GAS = 'gas';
export const OIL = 'oil';
export const STEAM = 'steam';
export const WATER = 'water';
export const UTILITY_SERVICES = [ELECTRIC, GAS, STEAM, OIL, WATER];

export const SERVICE_METRICS = {
  electric: 'total_usage_kwh',
  steam: 'total_steam_use_mlb',
  gas: 'total_gas_use_therms',
  oil: 'units',
};
