import { useState, useEffect, useMemo } from 'react';
import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LineChart, Line, Legend } from 'recharts';
import { Loader2 } from 'lucide-react';
import moment from 'moment';
import conedService from 'app/services/coned';
import {
  sharedXAxisProps,
  createYAxisProps,
  sharedCartesianGridProps,
  sharedTooltipProps,
} from 'app/components/charts/shared/chartProps';
import type { ProjectInfoData } from 'app/services/project-services';
import type { IntervalBlock } from './types';
import { convertUTCToEastern, normalizeIntervalData } from './utils';
import { useConEdisonData } from 'app/hooks/useConEdisonData';
import { useToast } from '@shadcn/hooks/use-toast';

const ChartSkeleton = () => (
  <div className="mt-8 space-y-6">
    <div className="grid grid-cols-1 gap-6">
      <div className="rounded-lg border p-4">
        <h4 className="mb-4 text-base font-medium">All Meters Comparison</h4>
        <div className="h-[400px] rounded-lg bg-muted"></div>
      </div>
      <div className="rounded-lg border p-4">
        <h4 className="mb-4 text-base font-medium">All Meters (Aggregated)</h4>
        <div className="h-[400px] rounded-lg bg-muted"></div>
      </div>
    </div>
  </div>
);

interface RealTimeIntervalDataProps {
  project: ProjectInfoData | null;
}

export const RealTimeIntervalData = ({ project }: RealTimeIntervalDataProps) => {
  const { customerMeters: conEdCustomerMeters } = useConEdisonData(project);
  const [realTimeData, setRealTimeData] = useState<{ [key: string]: IntervalBlock[] }>({});
  const [baseloads, setBaseloads] = useState<{ [key: string]: number }>({});
  const [realTimeLoading, setRealTimeLoading] = useState(true);
  const [allMeterDataLoaded, setAllMeterDataLoaded] = useState(false);
  const { toast } = useToast();

  // Move allMeters calculation into useMemo to prevent recalculation on every render
  const allMeters = useMemo(
    () =>
      conEdCustomerMeters.flatMap((customerMeter) =>
        customerMeter.serviceLocations.flatMap((location) =>
          location.meters.map((meter) => ({
            serialNumber: meter.serialNumber,
            serviceLocationId: location.serviceLocationId,
            customerAgreementId: customerMeter.customerAgreementId,
            meterReadings: meter.meterReadings,
          }))
        )
      ),
    [conEdCustomerMeters]
  );

  // Generate unique colors for each meter
  const meterColors = useMemo(() => {
    const colors = [
      'hsl(var(--chart-1))',
      'hsl(var(--chart-2))',
      'hsl(var(--chart-3))',
      'hsl(var(--chart-4))',
      'hsl(var(--chart-5))',
      'rgb(var(--chart-energy-rgb))',
      'rgb(var(--chart-temperature-rgb))',
      'rgb(var(--chart-cooling-rgb))',
      'rgb(var(--chart-heating-rgb))',
    ];
    return Object.fromEntries(
      allMeters.map((meter, index) => [meter.serialNumber.toString(), colors[index % colors.length]])
    );
  }, [allMeters]);

  // Effect for real-time data
  useEffect(() => {
    const fetchRealTimeData = async () => {
      if (!project?.attributes?.conEdisonSubscriptionId) {
        setRealTimeLoading(false);
        setRealTimeData({});
        setBaseloads({});
        setAllMeterDataLoaded(false);
        toast({
          description: 'Please connect your ConEdison account to view real-time interval data.',
          variant: 'destructive',
        });
        return;
      }

      if (allMeters.length === 0) {
        setRealTimeLoading(false);
        setRealTimeData({});
        setBaseloads({});
        setAllMeterDataLoaded(false);
        toast({
          description: 'No meters found. Please make sure your ConEdison account is properly connected.',
          variant: 'destructive',
        });
        return;
      }

      setRealTimeLoading(true);
      setAllMeterDataLoaded(false);
      try {
        const newRealTimeData: { [key: string]: IntervalBlock[] } = {};
        const newBaseloads: { [key: string]: number } = {};

        // First fetch aggregated real-time data for all meters
        const aggregatedResponse = await conedService.getAggregatedRealTimeIntervalBlocks({
          subscriptionId: project.attributes.conEdisonSubscriptionId,
        });

        const aggregatedData = aggregatedResponse.intervalBlocks.flatMap((block) =>
          block.IntervalBlock.readings.map((reading) => ({
            startTime: convertUTCToEastern(reading.timePeriod.start * 1000),
            value: reading.value / 1000,
          }))
        );
        newRealTimeData['all'] = aggregatedData;
        newBaseloads['all'] = aggregatedResponse.baseload;
        // Update state immediately after getting aggregated data
        setRealTimeData(newRealTimeData);
        setBaseloads(newBaseloads);

        // Then fetch individual meter data sequentially
        for (const meter of allMeters) {
          if (!meter.serviceLocationId) {
            console.error('Service location ID not found for meter:', meter.serialNumber);
            continue;
          }

          try {
            const response = await conedService.getRealTimeIntervalBlocks({
              subscriptionId: project.attributes.conEdisonSubscriptionId,
              usagePointId: meter.serviceLocationId,
              meterSerialNumber: meter.serialNumber.toString(),
            });

            const transformedData = response.intervalBlocks.flatMap((block) =>
              block.IntervalBlock.readings.map((reading) => ({
                startTime: convertUTCToEastern(reading.timePeriod.start * 1000),
                value: reading.value / 1000,
              }))
            );

            // Update state after each meter's data is fetched
            newRealTimeData[meter.serialNumber.toString()] = transformedData;
            newBaseloads[meter.serialNumber.toString()] = response.baseload;
            setRealTimeData({ ...newRealTimeData });
            setBaseloads({ ...newBaseloads });
          } catch (error) {
            console.error(`Error fetching data for meter ${meter.serialNumber}:`, error);
            // Continue with other meters even if one fails
          }
        }
        // Set allMeterDataLoaded to true after all meters have been processed
        setAllMeterDataLoaded(true);
      } catch (error) {
        console.error('Error fetching real-time interval data:', error);
        setRealTimeData({});
        setBaseloads({});
        setAllMeterDataLoaded(false);
      } finally {
        setRealTimeLoading(false);
      }
    };

    fetchRealTimeData();
  }, [project?.attributes?.conEdisonSubscriptionId, allMeters, toast]);

  const renderUsageChart = (meterId: string, data: IntervalBlock[]) => (
    <div key={meterId} className="rounded-lg border p-4">
      <div className="mb-4 flex items-center gap-2">
        <h4 className="text-base font-medium">{meterId === 'all' ? 'All Meters (Aggregated)' : `Meter ${meterId}`}</h4>
        {baseloads[meterId] > 0 && (
          <div className="group relative flex items-center">
            <svg
              className="h-4 w-4 cursor-help text-muted-foreground"
              fill="none"
              height="24"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="12" cy="12" r="10" />
              <path d="M12 16v-4" />
              <path d="M12 8h.01" />
            </svg>
            <div className="absolute bottom-full left-1/2 mb-2 ml-2 hidden w-[400px] -translate-x-1/2 rounded-lg bg-popover p-4 text-sm text-popover-foreground shadow-md group-hover:block">
              <p>
                Baseload represents your facility's minimum power demand - the energy used by equipment that runs
                continuously.
                <br />
                It is calculated as the 10th percentile of all energy readings, meaning 10% of your readings fall below
                this value.
                <br />A high baseload relative to your average usage may indicate opportunities to reduce energy
                consumption during off-hours.
              </p>
              <div className="absolute left-1/2 top-full -translate-x-1/2 border-8 border-transparent border-t-popover"></div>
            </div>
          </div>
        )}
      </div>
      <div className="h-[400px]">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={data} margin={{ top: 20, right: 30, left: 50, bottom: 60 }}>
            <CartesianGrid {...sharedCartesianGridProps} />
            <XAxis
              {...sharedXAxisProps}
              dataKey="startTime"
              tickFormatter={(time) => {
                if (!time) return '';
                return time instanceof Date
                  ? time.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' })
                  : '';
              }}
              label={{ value: 'Time', position: 'bottom', offset: 40 }}
              tick={
                {
                  fontSize: 12,
                  angle: -45,
                  textAnchor: 'end',
                  dy: 10,
                } as any
              }
              height={60}
            />
            <YAxis
              {...createYAxisProps('Energy Consumption (kWh)')}
              label={{
                value: 'Energy Consumption (kWh)',
                angle: -90,
                position: 'insideLeft',
                offset: -35,
                dy: 50,
                style: { fontSize: '12px' },
              }}
              tick={{ fontSize: 12 }}
            />
            <Tooltip
              {...sharedTooltipProps}
              labelFormatter={(time) => {
                if (!time) return '';
                return moment(time).format('ddd, MMMM D, h:mma');
              }}
              formatter={(value, name, props) => {
                const payload = props.payload;
                const allValues = Object.entries(payload)
                  .filter(([key]) => key !== 'startTime')
                  .map(([meterId, value]) => ({
                    meterId,
                    value: value as number,
                    name: `Meter ${meterId}`,
                    color: meterColors[meterId],
                  }))
                  .sort((a, b) => b.value - a.value);

                const currentMeter = allValues.find((item) => item.meterId === props.dataKey);
                if (!currentMeter) return ['', ''];

                return [
                  <span style={{ color: currentMeter.color }}>
                    <span
                      style={{
                        display: 'inline-block',
                        width: '10px',
                        height: '10px',
                        backgroundColor: currentMeter.color,
                        marginRight: '6px',
                        borderRadius: '50%',
                      }}
                    ></span>
                    {currentMeter.value.toFixed(2)} kWh
                  </span>,
                  currentMeter.name,
                ];
              }}
            />
            <Legend
              verticalAlign="top"
              height={36}
              wrapperStyle={{
                paddingTop: '0px',
                paddingBottom: '20px',
                fontSize: '12px',
                display: 'flex',
                flexWrap: 'wrap',
                gap: '10px',
                justifyContent: 'center',
              }}
              formatter={(value) => {
                // Keep "Baseload" as is, format only meter numbers
                if (value === 'Baseload') return value;
                // Show full meter number
                const meterNum = value.split(' ')[1];
                return `Meter ${meterNum}`;
              }}
            />
            <Line
              type="monotone"
              yAxisId="left"
              dataKey="value"
              stroke={meterId === 'all' ? 'rgb(var(--chart-energy-rgb))' : meterColors[meterId]}
              name="Real-Time Usage (kWh)"
              strokeWidth={2}
              dot={false}
              connectNulls={true}
            />
            {baseloads[meterId] > 0 && (
              <Line
                type="monotone"
                data={data.map((d) => ({ ...d, baseload: baseloads[meterId] / 1000 }))}
                dataKey="baseload"
                stroke="#000000"
                strokeWidth={2}
                dot={false}
                name="Baseload"
                connectNulls={true}
                yAxisId="left"
              />
            )}
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );

  const renderCombinedChart = (realTimeData: { [key: string]: IntervalBlock[] }) => {
    const meterData = Object.entries(realTimeData).filter(([key]) => key !== 'all');
    if (meterData.length === 0 || !allMeterDataLoaded) return null;

    const normalizedData = normalizeIntervalData(Object.fromEntries(meterData));

    return (
      <div className="rounded-lg border p-4">
        <h4 className="mb-4 text-base font-medium">All Meters Comparison</h4>
        <div className="h-[400px]">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={normalizedData} margin={{ top: 20, right: 30, left: 50, bottom: 80 }}>
              <CartesianGrid {...sharedCartesianGridProps} />
              <XAxis
                {...sharedXAxisProps}
                dataKey="startTime"
                tickFormatter={(time) => {
                  if (!time) return '';
                  return time instanceof Date
                    ? time.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' })
                    : '';
                }}
                label={{ value: 'Time', position: 'bottom', offset: 40 }}
                tick={
                  {
                    fontSize: 12,
                    angle: -45,
                    textAnchor: 'end',
                    dy: 10,
                  } as any
                }
                height={60}
              />
              <YAxis
                {...createYAxisProps('Energy Consumption (kWh)')}
                yAxisId="left"
                label={{
                  value: 'Energy Consumption (kWh)',
                  angle: -90,
                  position: 'insideLeft',
                  offset: -35,
                  dy: 50,
                  style: { fontSize: '12px' },
                }}
                tick={{ fontSize: 12 }}
              />
              <Tooltip
                {...sharedTooltipProps}
                labelFormatter={(time) => {
                  if (!time) return '';
                  return moment(time).format('ddd, MMMM D, h:mma');
                }}
                formatter={(value, name, props) => {
                  const payload = props.payload;
                  const allValues = Object.entries(payload)
                    .filter(([key]) => key !== 'startTime')
                    .map(([meterId, value]) => ({
                      meterId,
                      value: value as number,
                      name: `Meter ${meterId}`,
                      color: meterColors[meterId],
                    }))
                    .sort((a, b) => b.value - a.value);

                  const currentMeter = allValues.find((item) => item.meterId === props.dataKey);
                  if (!currentMeter) return ['', ''];

                  return [
                    <span style={{ color: currentMeter.color }}>
                      <span
                        style={{
                          display: 'inline-block',
                          width: '10px',
                          height: '10px',
                          backgroundColor: currentMeter.color,
                          marginRight: '6px',
                          borderRadius: '50%',
                        }}
                      ></span>
                      {currentMeter.value.toFixed(2)} kWh
                    </span>,
                    currentMeter.name,
                  ];
                }}
              />
              <Legend
                verticalAlign="top"
                height={36}
                wrapperStyle={{
                  paddingTop: '0px',
                  paddingBottom: '20px',
                  fontSize: '12px',
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: '10px',
                  justifyContent: 'center',
                }}
                formatter={(value) => {
                  // Keep "Baseload" as is, format only meter numbers
                  if (value === 'Baseload') return value;
                  // Show full meter number
                  const meterNum = value.split(' ')[1];
                  return `Meter ${meterNum}`;
                }}
              />
              {Object.keys(Object.fromEntries(meterData)).map((meterId) => (
                <Line
                  key={meterId}
                  type="monotone"
                  dataKey={meterId}
                  stroke={meterColors[meterId]}
                  strokeWidth={2}
                  dot={false}
                  name={`Meter ${meterId}`}
                  connectNulls={true}
                  yAxisId="left"
                />
              ))}
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    );
  };

  return (
    <div className="border-t pt-8">
      <div className="flex items-center justify-between">
        <h3 className="mb-4 text-lg font-semibold">Real-Time Interval Data (Last 24 hours)</h3>
        <div className="mr-4 text-sm text-yellow-600">
          ⚠️ Please note: Your real-time usage is not validated and may not match your bill statements.
          <br />
          Billed usage is validated and may have a multiplier applied, which will be shown on your ConEdison bill
          statement.
        </div>
      </div>

      {realTimeLoading && (
        <div className="mt-8 space-y-4">
          <div className="flex items-center gap-2 text-muted-foreground">
            <Loader2 className="h-4 w-4 animate-spin" />
            <span>Loading real-time data...</span>
          </div>
          <ChartSkeleton />
        </div>
      )}

      {!realTimeLoading && !project?.attributes?.conEdisonSubscriptionId && (
        <div className="mt-8 flex items-center justify-center rounded-lg border p-8">
          <p className="text-center text-muted-foreground">
            No ConEdison account connected. Please connect your account to view real-time interval data.
          </p>
        </div>
      )}

      {!realTimeLoading && project?.attributes?.conEdisonSubscriptionId && (
        <div className="mt-8 space-y-6">
          {/* Grid for charts */}
          <div className="grid grid-cols-1 gap-6">
            {/* Render combined chart that shows all meter trends */}
            {renderCombinedChart(realTimeData)}

            {/* Render aggregated chart */}
            {realTimeData['all'] && renderUsageChart('all', realTimeData['all'])}
          </div>
        </div>
      )}
    </div>
  );
};
