import { Tabs, TabsContent } from '@shadcn/ui';
import { ServiceTabs } from 'app/components/service/ServiceTabs';
import { EnergyStarMeters } from './EnergyStarMeters';
import { ELECTRIC, GAS, STEAM, OIL, WATER, UTILITY_SERVICES } from 'app/utils/constants/utilityServices';
import type { Meter, ConsumptionData } from 'app/types/energy-star';
import { theme } from 'app/utils/theme';

interface MetersTabProps {
  meters: Meter[];
  consumptionData: Record<string, ConsumptionData>;
  theme: typeof theme;
}

export const MetersTab = ({ meters, consumptionData, theme }: MetersTabProps) => {
  const allServices = ['All', ...UTILITY_SERVICES];

  return (
    <Tabs defaultValue="all" className="w-full">
      <ServiceTabs allServices={allServices} />

      {allServices.map((serviceType) => (
        <TabsContent key={serviceType} value={serviceType.toLowerCase()}>
          <EnergyStarMeters
            meters={
              serviceType === 'All'
                ? meters
                : meters.filter((meter) => {
                    switch (serviceType) {
                      case ELECTRIC:
                        return meter.type === 'Electric';
                      case GAS:
                        return meter.type === 'Natural Gas';
                      case STEAM:
                        return meter.type === 'District Steam';
                      case WATER:
                        return meter.type === 'Water' || meter.type === 'Municipally Supplied Potable Water - Indoor';
                      case OIL:
                        return meter.type === 'Fuel Oil No 2' || meter.type === 'Fuel Oil No 4';
                      default:
                        return false;
                    }
                  })
            }
            consumptionData={consumptionData}
            theme={theme}
            displayEmptyMeters={true}
            selectedService={serviceType}
          />
        </TabsContent>
      ))}
    </Tabs>
  );
};
