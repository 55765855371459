import { InputWrapper } from '@shadcn/custom/InputWrapper';
import { buildingInfoFields, BasicBuildingInfoData } from 'app/utils/constants/buildingInfoStructure';
import { ChangeEvent } from 'react';
import { ClientServicesInput } from './ClientServicesInput';
import { ClientService } from 'app/services/project-services';

interface RenderBuildingDataProps {
  buildingInfo: BasicBuildingInfoData;
  errors: Partial<Record<keyof BasicBuildingInfoData, string>>;
  handleInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleClientServicesChange?: (services: ClientService[]) => void;
  isEditing: boolean;
}

export const BuildingDataInputs = ({
  buildingInfo,
  errors,
  handleInputChange,
  handleClientServicesChange,
  isEditing,
}: RenderBuildingDataProps) => {
  // Helper function to get nested object value
  const getNestedValue = (obj: any, path: string) => {
    return path.split('.').reduce((acc, part) => (acc ? acc[part] : ''), obj);
  };

  return (
    <div className="grid gap-4">
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
        {buildingInfoFields.map((field) => (
          <div key={field.name}>
            <InputWrapper
              label={field.label}
              inputStartAdornment={<field.icon className="stroke-foreground" />}
              name={field.name}
              type={field.type}
              value={getNestedValue(buildingInfo, field.name)}
              onChange={handleInputChange}
              error={!!errors[field.name]}
              helperText={errors[field.name]}
              maxLength={field.maxLength}
              pattern={field.pattern}
              disabled={!isEditing}
            />
          </div>
        ))}
      </div>
      {handleClientServicesChange && (
        <div className="mt-4">
          <ClientServicesInput
            selectedServices={buildingInfo.buildingRepresentation?.services || []}
            onChange={handleClientServicesChange}
            error={errors['buildingRepresentation.services']}
            disabled={!isEditing}
          />
        </div>
      )}
    </div>
  );
};
