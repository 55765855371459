import type { VariantProps } from 'class-variance-authority';
import { useEffect, useState } from 'react';
import { LoaderCircleIcon } from 'lucide-react';
import { useToast } from '@shadcn/hooks/use-toast';
import { useParams } from 'react-router-dom';

import { toastVariants } from '@shadcn/ui';
import { extractError } from 'app/utils/appHelpers';
import projectServices from 'app/services/project-services';
import { useProjectData } from 'app/hooks/useProjectData';

import { BuildingInfo, DataSources, Analysis, ReportBuilder, Exports } from './tabs';
import type { ProjectInfoData } from 'app/services/project-services';
import type { WeatherProps } from 'app/types/visualizations';
import { OperationsDashboard } from './tabs/operations/OperationsDashboard';
import { FinancialInsights } from './tabs/financial-insights/FinancialInsights';
import { FEATURE_FLAG_EFFICIENCY_MEASURES } from 'app/utils/constants/featureFlags';
export type ProjectDetailData = {
  attributes: {
    weather: WeatherProps;
  };
  id: string;
};

enum Tab {
  BuildingInfo = 'building-info',
  DataSources = 'utility-bills',
  Analysis = 'analysis',
  ReportBuilder = 'report-builder',
  Operations = 'operations',
  Financial = 'financial',
  Exports = 'exports',
}

export const ProjectDetail = () => {
  const { id, page } = useParams();
  const [project, setProject] = useState<ProjectInfoData | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [baseTemperature, setBaseTemperature] = useState(65);

  const projectData = useProjectData(id || '', baseTemperature);
  const { toast } = useToast();

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    const fetchProject = async () => {
      try {
        return await projectServices.getById(id);
      } catch (error) {
        console.error('Error fetching project:', error);
        return null;
      }
    };

    fetchProject().then((fetchedProject) => {
      if (isMounted) {
        setProject(fetchedProject);
        setIsLoading(false);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [id, refreshTrigger]);

  if (isLoading || !id) {
    return (
      <div className="flex h-screen items-center justify-center">
        <LoaderCircleIcon className="animate-spin" />
      </div>
    );
  }

  const handleSnackbar = (message: string, variant: VariantProps<typeof toastVariants>['variant'] = 'positive') => {
    toast({
      description: message,
      variant,
    });
  };

  const handleUpdateProject = async (updatedProject) => {
    try {
      const result = await projectServices.update(updatedProject);
      if (result) {
        setProject(result);
        handleSnackbar('Project updated successfully', 'positive');
        setRefreshTrigger((prev) => prev + 1);
        return { success: true, message: 'Project updated successfully', report: result };
      } else {
        throw new Error(result?.message || 'Failed to update project.');
      }
    } catch (error) {
      handleSnackbar(extractError(error) ?? 'An error occurred while updating the project', 'destructive');
      return {
        success: false,
        message: extractError(error) || 'An error occurred while updating the project',
      };
    }
  };

  return (
    <div className="flex grow flex-col overflow-hidden">
      <div className="flex grow flex-col overflow-hidden">
        {/* Building Info */}
        {page === Tab.BuildingInfo && project && (
          <BuildingInfo
            project={project}
            meterReadings={projectData.meterReadings}
            onUpdateProject={handleUpdateProject}
            onSnackbar={handleSnackbar}
          />
        )}

        {/* Data Sources */}
        {page === Tab.DataSources && project && (
          <DataSources projectId={id} onSnackbar={handleSnackbar} projectData={projectData} />
        )}

        {/* Analysis */}
        {page === Tab.Analysis && project && (
          <Analysis projectId={id} onSnackbar={handleSnackbar} projectData={projectData} />
        )}

        {/* Operations Dashboard
        {page === Tab.Operations && project && (
          <OperationsDashboard
            projectId={id}
            documents={projectData.documents}
            meterReadings={projectData.meterReadings}
            weather={projectData.weather}
          />
        )} */}

        {/* Financial Insights */}
        {page === Tab.Financial && project && <FinancialInsights />}

        {/* Report Builder */}
        {page === Tab.ReportBuilder && project && (
          <ReportBuilder projectId={id} projectData={projectData} onSnackbar={handleSnackbar} />
        )}

        {/* Exports */}
        {page === Tab.Exports && project && <Exports projectId={id} onSnackbar={handleSnackbar} />}
      </div>
    </div>
  );
};
