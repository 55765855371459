import { forwardRef, useState } from 'react';
import { Card, CardContent, CardTitle, CardHeader } from '@shadcn/ui';
import {
  LayoutGridIcon,
  RulerIcon,
  CalendarIcon,
  HomeIcon,
  CloudFogIcon,
  BoltIcon,
  FlameIcon,
  DropletIcon,
  FuelIcon,
  ChevronDownIcon,
} from 'lucide-react';
import { Building2Icon } from 'lucide-react';
import {
  ExtendedBuildingInfoData,
  BUILDING_SYSTEM_CATEGORIES,
  CORE_BUILDING_SYSTEMS,
  BUILDING_SERVICES,
} from 'app/utils/constants/buildingInfoStructure';
import { formatSquareFeet } from 'app/utils/formatters';
import { Collapsible } from '@shadcn/custom/Collapsible';
import { cn } from '@shadcn/utils';

export interface BuildingCharacteristicsProps {
  buildingInfo: ExtendedBuildingInfoData;
}

// Add this component at the top level
const CollapsibleSystemSection = ({
  title,
  icon,
  children,
}: {
  title: string;
  icon: React.ReactNode;
  children: React.ReactNode;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="rounded-lg border border-border/30 bg-background/5">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex w-full items-center justify-between p-4 hover:bg-background/5"
      >
        <h3 className="flex items-center gap-2 text-base font-medium">
          {icon}
          {title}
        </h3>
        <ChevronDownIcon
          className={cn('h-4 w-4 text-muted-foreground transition-transform duration-200', {
            'rotate-180': isOpen,
          })}
        />
      </button>
      <Collapsible expanded={isOpen}>
        <div className="p-4 pt-0">
          <div className="grid gap-3">{children}</div>
        </div>
      </Collapsible>
    </div>
  );
};

export const BuildingCharacteristics = forwardRef<HTMLDivElement, BuildingCharacteristicsProps>(
  ({ buildingInfo }, ref) => {
    return (
      <Card className="transform rounded-xl border-2 border-gray-500 bg-gradient-to-br from-background/80 to-background/40 p-1 shadow-xl transition-all duration-200 hover:shadow-2xl">
        <CardHeader className="flex flex-row items-center justify-between pb-2 pt-4">
          <CardTitle className="flex items-center gap-2 text-lg font-semibold">
            <Building2Icon className="h-4 w-4 text-primary" />
            Building Characteristics
          </CardTitle>
        </CardHeader>
        <CardContent className="grid gap-6">
          {/* Property Uses Section */}
          <div className="rounded-lg bg-gradient-to-br from-background/40 to-transparent p-4 shadow-sm">
            <h3 className="mb-4 flex items-center gap-2 text-base font-medium text-foreground/90">
              <LayoutGridIcon className="h-4 w-4 text-primary/90" />
              Property Uses
            </h3>
            <div className="grid gap-3">
              {buildingInfo.propertyUses.length > 0 ? (
                <div className="overflow-hidden rounded-lg border border-border/30">
                  <table className="w-full border-separate border-spacing-0">
                    <thead>
                      <tr className="bg-background/10">
                        <th className="border-b border-border/30 p-2 text-left text-xs font-medium text-muted-foreground">
                          PROPERTY USE
                        </th>
                        <th className="border-b border-border/30 p-2 text-right text-xs font-medium text-muted-foreground">
                          GROSS FLOOR AREA
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {buildingInfo.propertyUses.map((use, index) => (
                        <tr key={index} className="hover:bg-background/5">
                          <td className="border-b border-border/30 p-2 text-sm">
                            <div className="flex items-center gap-2">
                              <LayoutGridIcon className="h-4 w-4 text-primary/70" />
                              <span className="font-medium">{use.propertyUse}</span>
                            </div>
                          </td>
                          <td className="border-b border-border/30 p-2 text-right text-sm">
                            {formatSquareFeet(use.totalGrossFloorArea)}
                          </td>
                        </tr>
                      ))}
                      <tr className="bg-background/10 font-medium">
                        <td className="border-b border-border/30 p-2 text-sm">
                          <div className="flex items-center gap-2">
                            <RulerIcon className="h-4 w-4 text-primary/70" />
                            <span className="font-medium">Total</span>
                          </div>
                        </td>
                        <td className="border-b border-border/30 p-2 text-right text-sm">
                          {formatSquareFeet(
                            buildingInfo.propertyUses.reduce((sum, use) => sum + use.totalGrossFloorArea, 0)
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ) : (
                <p className="text-muted-foreground">No property uses specified</p>
              )}
            </div>
          </div>

          {/* Occupancy Information Section */}
          <div className="rounded-lg bg-gradient-to-br from-background/40 to-transparent p-4 shadow-sm">
            <h3 className="mb-4 flex items-center gap-2 text-base font-medium text-foreground/90">
              <CalendarIcon className="h-4 w-4 text-primary/90" />
              Occupancy Information
            </h3>
            <div className="grid gap-3">
              <div className="overflow-hidden rounded-lg border border-border/30">
                <table className="w-full border-separate border-spacing-0">
                  <tbody>
                    <tr className="hover:bg-background/5">
                      <td className="border-b border-border/30 p-2 text-sm">
                        <div className="flex items-center gap-2">
                          <CalendarIcon className="h-4 w-4 text-primary/70" />
                          <span className="font-medium">Weekday Hours</span>
                        </div>
                      </td>
                      <td className="border-b border-border/30 p-2 text-right text-sm">
                        {buildingInfo.occupancy?.weekdayStartTime && buildingInfo.occupancy?.weekdayEndTime
                          ? `${buildingInfo.occupancy.weekdayStartTime} - ${buildingInfo.occupancy.weekdayEndTime}`
                          : 'Not specified'}
                      </td>
                    </tr>
                    <tr className="hover:bg-background/5">
                      <td className="border-b border-border/30 p-2 text-sm">
                        <div className="flex items-center gap-2">
                          <CalendarIcon className="h-4 w-4 text-primary/70" />
                          <span className="font-medium">Weekend Hours</span>
                        </div>
                      </td>
                      <td className="border-b border-border/30 p-2 text-right text-sm">
                        {buildingInfo.occupancy?.closedOnWeekends
                          ? 'Closed'
                          : buildingInfo.occupancy?.weekendStartTime && buildingInfo.occupancy?.weekendEndTime
                            ? `${buildingInfo.occupancy.weekendStartTime} - ${buildingInfo.occupancy.weekendEndTime}`
                            : 'Not specified'}
                      </td>
                    </tr>
                    <tr className="hover:bg-background/5">
                      <td className="border-b border-border/30 p-2 text-sm">
                        <div className="flex items-center gap-2">
                          <HomeIcon className="h-4 w-4 text-primary/70" />
                          <span className="font-medium">Peak Occupancy</span>
                        </div>
                      </td>
                      <td className="border-b border-border/30 p-2 text-right text-sm">
                        {buildingInfo.occupancy?.peakOccupancy
                          ? `${buildingInfo.occupancy.peakOccupancy} people`
                          : 'Not specified'}
                      </td>
                    </tr>
                    <tr className="hover:bg-background/5">
                      <td className="border-b border-border/30 p-2 text-sm">
                        <div className="flex items-center gap-2">
                          <HomeIcon className="h-4 w-4 text-primary/70" />
                          <span className="font-medium">Average Occupancy</span>
                        </div>
                      </td>
                      <td className="border-b border-border/30 p-2 text-right text-sm">
                        {buildingInfo.occupancy?.averageOccupancy
                          ? `${buildingInfo.occupancy.averageOccupancy} people`
                          : 'Not specified'}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {/* Building Systems Section */}
          <div className="rounded-lg bg-gradient-to-br from-background/40 to-transparent p-4 shadow-sm">
            <h3 className="mb-4 flex items-center gap-2 text-base font-medium text-foreground/90">
              <Building2Icon className="h-4 w-4 text-primary/90" />
              Building Systems
            </h3>
            <div className="grid gap-3">
              {/* Core Building Systems */}
              {Object.entries(BUILDING_SYSTEM_CATEGORIES).map(([category, name]) => {
                const systems = buildingInfo.buildingSystems?.[category as keyof typeof BUILDING_SYSTEM_CATEGORIES];
                if (!systems?.length) return null;

                const icon = (() => {
                  switch (category) {
                    case 'hvac':
                      return <CloudFogIcon className="h-4 w-4 text-blue-400" />;
                    case 'lighting':
                      return <BoltIcon className="h-4 w-4 text-yellow-400" />;
                    case 'waterHeating':
                      return <FlameIcon className="h-4 w-4 text-orange-400" />;
                    case 'waterFixtures':
                      return <DropletIcon className="h-4 w-4 text-blue-400" />;
                    default:
                      return null;
                  }
                })();

                return (
                  <CollapsibleSystemSection key={category} title={name} icon={icon}>
                    <div className="grid gap-3">
                      {systems.map((system) => {
                        const systemDef = system.systemId
                          ? CORE_BUILDING_SYSTEMS.find((s) => s.id === system.systemId && s.category === category)
                          : CORE_BUILDING_SYSTEMS.find((s) => s.category === category);

                        if (!systemDef) return null;

                        return (
                          <div key={system.systemId || systemDef.id} className="rounded-md bg-background/10 p-3">
                            <div className="mb-2">
                              <h4 className="text-sm font-medium">{systemDef.name}</h4>
                            </div>
                            <div className="grid gap-2">
                              {Object.entries(system.specifications).map(([specId, value]) => {
                                const spec = systemDef.specifications.find((s) => s.id === specId);
                                if (!spec || !value) return null;

                                return (
                                  <div key={specId} className="flex items-center gap-2 text-sm">
                                    <span className="text-muted-foreground">{spec.name}</span>
                                    <span className="font-medium">
                                      {spec.type === 'boolean' ? (value ? 'Yes' : 'No') : value}
                                      {spec.unit ? ` ${spec.unit}` : ''}
                                    </span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </CollapsibleSystemSection>
                );
              })}

              {/* Utility-based Systems */}
              {(['electric', 'gas', 'steam', 'oil'] as const).map((utilityType) => {
                const systems = buildingInfo.buildingSystems?.[utilityType];
                if (!systems?.length) return null;

                const icon = (() => {
                  switch (utilityType) {
                    case 'electric':
                      return <BoltIcon className="h-4 w-4 text-yellow-400" />;
                    case 'gas':
                      return <FlameIcon className="h-4 w-4 text-orange-400" />;
                    case 'steam':
                      return <CloudFogIcon className="h-4 w-4 text-blue-400" />;
                    case 'oil':
                      return <FuelIcon className="h-4 w-4 text-green-400" />;
                    default:
                      return null;
                  }
                })();

                return (
                  <CollapsibleSystemSection
                    key={utilityType}
                    title={utilityType.charAt(0).toUpperCase() + utilityType.slice(1)}
                    icon={icon}
                  >
                    <div className="grid gap-3">
                      {systems.map((system) => {
                        const systemDef = system.systemId
                          ? BUILDING_SERVICES.find((s) => s.id === utilityType)?.systems.find(
                              (s) => s.id === system.systemId
                            )
                          : BUILDING_SERVICES.find((s) => s.id === utilityType)?.systems[0];

                        if (!systemDef) return null;

                        return (
                          <div key={system.systemId || systemDef.id} className="rounded-md bg-background/10 p-3">
                            <div className="mb-2">
                              <h4 className="text-sm font-medium">{systemDef.name}</h4>
                            </div>
                            <div className="grid gap-2">
                              {Object.entries(system.specifications).map(([specId, value]) => {
                                const component = systemDef.components.find((c) => c.id === specId);
                                if (!component || !value) return null;

                                return (
                                  <div key={specId} className="flex items-center gap-2 text-sm">
                                    <span className="text-muted-foreground">{component.name}</span>
                                    <span className="font-medium">
                                      {typeof value === 'boolean' ? (value ? 'Yes' : 'No') : value}
                                    </span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </CollapsibleSystemSection>
                );
              })}
            </div>
          </div>
        </CardContent>
      </Card>
    );
  }
);
