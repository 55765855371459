import { Button } from '@shadcn/ui/button';
import { ProjectInfoData } from 'app/services/project-services';
import { ArrowUpDown } from 'lucide-react';
import { useState } from 'react';

type SortField = 'name' | 'createdAt' | 'squareFeet';
type SortOrder = 'asc' | 'desc';

interface ProjectsTableProps {
  projects: ProjectInfoData[];
  propertyScores: Record<string, number>;
  onReviewClick: (project: ProjectInfoData) => void;
  onSubmitScore: (propertyName: string) => void;
  onSync: (projectId: string) => void;
}

export const ProjectsTable = ({
  projects,
  propertyScores,
  onReviewClick,
  onSubmitScore,
  onSync,
}: ProjectsTableProps) => {
  const [sortField, setSortField] = useState<SortField>('name');
  const [sortOrder, setSortOrder] = useState<SortOrder>('asc');

  const handleSort = (field: SortField) => {
    if (sortField === field) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
  };

  const sortedProjects = [...projects].sort((a, b) => {
    let comparison = 0;
    switch (sortField) {
      case 'name':
        comparison = a.attributes.name.localeCompare(b.attributes.name);
        break;
      case 'createdAt':
        comparison = new Date(a.attributes.createdAt).getTime() - new Date(b.attributes.createdAt).getTime();
        break;
      case 'squareFeet':
        comparison = (a.attributes.squareFeet || 0) - (b.attributes.squareFeet || 0);
        break;
    }
    return sortOrder === 'asc' ? comparison : -comparison;
  });

  const SortButton = ({ field, label }: { field: SortField; label: string }) => (
    <Button variant="ghost" size="sm" className="hover:bg-transparent" onClick={() => handleSort(field)}>
      {label}
      <ArrowUpDown className={`ml-2 h-4 w-4 ${sortField === field ? 'text-primary' : 'text-muted-foreground/50'}`} />
    </Button>
  );

  return (
    <div className="w-full overflow-x-auto rounded-xl border-border/20 bg-background/5 shadow-lg backdrop-blur-sm">
      <table className="min-w-full table-auto">
        <thead>
          <tr className="border-b border-border/20">
            <th className="px-4 py-2 text-left text-muted-foreground/80">
              <SortButton field="name" label="Property" />
            </th>
            <th className="px-4 py-2 text-left text-muted-foreground/80">Energy Star ID</th>
            <th className="px-4 py-2 text-left text-muted-foreground/80">
              <SortButton field="createdAt" label="Created" />
            </th>
            <th className="px-4 py-2 text-left text-muted-foreground/80">
              <SortButton field="squareFeet" label="Square Feet" />
            </th>
            <th className="px-4 py-2 text-left text-muted-foreground/80">Benchmarks</th>
          </tr>
        </thead>
        <tbody>
          {sortedProjects.map((project) => (
            <tr
              key={project.attributes.name}
              className="border-b border-border/20 transition-colors hover:bg-background/10"
            >
              <td className="px-4 py-2">{project.attributes.name}</td>
              <td className="px-4 py-2">{project.attributes.energyStarId}</td>
              <td className="px-4 py-2">{new Date(project.attributes.createdAt).toLocaleDateString()}</td>
              <td className="px-4 py-2">{project.attributes.squareFeet?.toLocaleString() || '-'}</td>
              <td className="px-4 py-2">
                <div className="flex items-center gap-2">
                  <Button
                    variant="outline"
                    size="sm"
                    className="border-primary/30 bg-primary/10 text-primary hover:bg-primary/20"
                    onClick={() => onReviewClick(project)}
                  >
                    Review
                  </Button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
