import { Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { EnergyYearData } from 'app/types/visualizations';

interface EmissionsByServiceProps {
  sortedYears: EnergyYearData[];
}

const RADIAN = Math.PI / 180;
const ENERGY_COLORS = {
  steam: '#3B82F6', // Blue
  gas: '#F59E0B', // Amber
  electric: '#10B981', // Emerald
  oil: '#6366F1', // Indigo
  water: '#60A5FA', // Light Blue
};

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, value, name }: any) => {
  const radius = outerRadius * 1.2;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  const textAnchor = x > cx ? 'start' : 'end';

  return (
    <text x={x} y={y} fill="#6B7280" textAnchor={textAnchor} dominantBaseline="central" className="text-xs">
      {`${name}: ${value.toFixed(1)} tCO₂`}
    </text>
  );
};

const EmissionsByService = ({ sortedYears }: EmissionsByServiceProps) => {
  // Transform data for the emissions chart
  const chartData = sortedYears.map((year) => ({
    year: new Date(year.startDate).getFullYear(),
    electric: year.energyUsageTotalsByService.electric?.emissions ?? 0,
    gas: year.energyUsageTotalsByService.gas?.emissions ?? 0,
    steam: year.energyUsageTotalsByService.steam?.emissions ?? 0,
    oil: year.energyUsageTotalsByService.oil?.emissions ?? 0,
    water: year.energyUsageTotalsByService.water?.emissions ?? 0,
    emissionsThreshold: year.threshold,
  }));

  // Get current year data for pie chart
  const currentYearData = chartData[chartData.length - 1];
  const pieChartData = [
    { name: 'Electric', value: currentYearData?.electric || 0, color: ENERGY_COLORS.electric },
    { name: 'Gas', value: currentYearData?.gas || 0, color: ENERGY_COLORS.gas },
    { name: 'Steam', value: currentYearData?.steam || 0, color: ENERGY_COLORS.steam },
    { name: 'Oil', value: currentYearData?.oil || 0, color: ENERGY_COLORS.oil },
  ].filter((item) => item.value > 0);

  return (
    <div className="h-full rounded-lg border border-gray-200 bg-white p-6 shadow-sm">
      <h2 className="mb-6 text-base font-medium text-gray-700">Emissions by Service</h2>
      <div>
        <div>
          <ResponsiveContainer width="100%" height={400}>
            <PieChart>
              <Pie
                data={pieChartData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                innerRadius={80}
                outerRadius={120}
                labelLine={true}
                label={renderCustomizedLabel}
              >
                {pieChartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} stroke="#FFFFFF" strokeWidth={2} />
                ))}
              </Pie>
              <Tooltip
                contentStyle={{
                  backgroundColor: 'white',
                  border: '1px solid #E5E7EB',
                  borderRadius: '6px',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                  padding: '8px 12px',
                }}
                formatter={(value: any) => [`${value.toFixed(1)} tCO₂e`, 'Emissions']}
              />
              <Legend
                verticalAlign="bottom"
                align="center"
                wrapperStyle={{
                  paddingTop: '20px',
                  fontSize: '12px',
                  color: '#6B7280',
                }}
              />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default EmissionsByService;
