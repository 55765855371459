import { Card, CardContent, CardHeader, CardTitle } from '@shadcn/ui';
import { Badge } from '@shadcn/ui';
import { CheckCircle2, Clock, AlertTriangle } from 'lucide-react';

export const ProjectTrackingPanel = () => {
  // Dummy data for active measures
  const measures = [
    {
      id: 1,
      name: 'HVAC Optimization',
      status: 'completed',
      implementationDate: '2024-01-15',
      projectedSavings: '$45,000',
      actualSavings: '$48,500',
      roi: '215%',
      icon: <CheckCircle2 className="h-5 w-5 text-green-500" />,
    },
    {
      id: 2,
      name: 'LED Lighting Retrofit',
      status: 'in-progress',
      implementationDate: '2024-03-01',
      projectedSavings: '$28,000',
      actualSavings: '$12,400',
      roi: '180%',
      icon: <Clock className="h-5 w-5 text-blue-500" />,
    },
    {
      id: 3,
      name: 'Steam Trap Replacement',
      status: 'delayed',
      implementationDate: '2024-04-15',
      projectedSavings: '$15,000',
      actualSavings: '$0',
      roi: '150%',
      icon: <AlertTriangle className="h-5 w-5 text-yellow-500" />,
    },
  ];

  const totalMetrics = {
    projectedAnnualSavings: '$88,000',
    actualSavingsToDate: '$60,900',
    averageROI: '182%',
    completionRate: '67%',
  };

  return (
    <Card className="border border-border/50">
      <CardHeader className="flex flex-row items-center justify-between">
        <CardTitle className="flex items-center gap-2 text-xl font-semibold">
          Project Tracking
          <Badge variant="secondary" className="text-xs font-normal">
            Beta
          </Badge>
        </CardTitle>
        <div className="flex items-center gap-4 text-sm">
          <div>
            <span className="text-muted-foreground">Total Projected Savings: </span>
            <span className="font-bold text-green-500">{totalMetrics.projectedAnnualSavings}</span>
          </div>
          <div>
            <span className="text-muted-foreground">Completion Rate: </span>
            <span className="font-bold">{totalMetrics.completionRate}</span>
          </div>
        </div>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          {/* Measures List */}
          {measures.map((measure) => (
            <div
              key={measure.id}
              className="grid grid-cols-6 gap-4 rounded-lg border border-border/50 p-4 transition-colors hover:bg-muted/5"
            >
              <div className="col-span-2 flex items-center gap-3">
                {measure.icon}
                <div>
                  <h3 className="font-medium">{measure.name}</h3>
                  <p className="text-sm text-muted-foreground">Implementation: {measure.implementationDate}</p>
                </div>
              </div>
              <div className="text-center">
                <p className="text-sm text-muted-foreground">Projected Savings</p>
                <p className="font-medium">{measure.projectedSavings}</p>
              </div>
              <div className="text-center">
                <p className="text-sm text-muted-foreground">Actual Savings</p>
                <p className="font-medium">{measure.actualSavings}</p>
              </div>
              <div className="text-center">
                <p className="text-sm text-muted-foreground">ROI</p>
                <p className="font-medium text-green-500">{measure.roi}</p>
              </div>
              <div className="text-center">
                <p className="text-sm text-muted-foreground">Status</p>
                <p className="font-medium capitalize">{measure.status}</p>
              </div>
            </div>
          ))}

          {/* Summary Metrics */}
          <div className="mt-6 grid grid-cols-3 gap-4 rounded-lg border border-border/50 p-4">
            <div className="text-center">
              <p className="text-sm text-muted-foreground">Actual Savings to Date</p>
              <p className="text-xl font-bold text-green-500">{totalMetrics.actualSavingsToDate}</p>
            </div>
            <div className="text-center">
              <p className="text-sm text-muted-foreground">Average ROI</p>
              <p className="text-xl font-bold">{totalMetrics.averageROI}</p>
            </div>
            <div className="text-center">
              <p className="text-sm text-muted-foreground">Implementation Progress</p>
              <p className="text-xl font-bold">{totalMetrics.completionRate}</p>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
