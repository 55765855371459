import { useState, useCallback, useEffect } from 'react';
import projectServices from 'app/services/project-services';
import { Documents, WeatherProps } from 'app/types/visualizations';
import { PenaltyData } from 'app/types/penalty-data';
import { ProjectInfoData } from 'app/services/project-services';
import { formatDateForInput } from 'app/utils/trendDataHelpers';

interface ProjectDataState {
  baseloads: Record<string, any>;
  documents: Documents;
  degreeData: Record<string, any>;
  degreeDataByBillingPeriod: Record<string, any>;
  emissionsFromUtilityBills: PenaltyData | null;
  emissionsFromMeterReadings: PenaltyData | null;
  meterReadings: Documents;
  meterReadingDegreeDataByBillingPeriod: Record<string, any>;
  meterReadingsBaseloads: Record<string, any>;
  project: ProjectInfoData | null;
  weather: WeatherProps;
  startDate: string;
  endDate: string;
  zipCode: string;
}

export interface ProjectDataReturn extends ProjectDataState {
  isLoading: boolean;
  isEmissionsLoading: boolean;
  isUpdatingDegreeData: boolean;
  error: Error | null;
  refetch: () => Promise<void>;
}

export const useProjectData = (projectId: string, baseTemperature: number): ProjectDataReturn => {
  const [state, setState] = useState<ProjectDataState>({
    baseloads: {},
    degreeData: {},
    degreeDataByBillingPeriod: {},
    documents: {},
    emissionsFromUtilityBills: null,
    emissionsFromMeterReadings: null,
    meterReadings: {},
    meterReadingsBaseloads: {},
    meterReadingDegreeDataByBillingPeriod: {},
    project: null,
    weather: {} as WeatherProps,
    startDate: '',
    endDate: '',
    zipCode: '',
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isEmissionsLoading, setIsEmissionsLoading] = useState(true);
  const [isUpdatingDegreeData, setIsUpdatingDegreeData] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const setDatesFromData = (data: Documents) => {
    if (data && Object.keys(data).length > 0) {
      const utilityWithDates = Object.values(data).find(
        (utility) => utility?.startDate && utility?.endDate && utility.startDate !== '' && utility.endDate !== ''
      );

      if (utilityWithDates) {
        return {
          startDate: formatDateForInput(utilityWithDates.startDate),
          endDate: formatDateForInput(utilityWithDates.endDate),
        };
      }
    }
    return { startDate: '', endDate: '' };
  };

  const fetchAllProjectData = useCallback(async () => {
    setIsLoading(true);
    setError(null);

    try {
      // Fetch project data first
      const projectData = await projectServices.getById(projectId);

      // Fetch insights and meter readings in parallel
      const [insightsResponse, meterReadings] = await Promise.all([
        projectServices.fetchProjectInsights({
          id: projectId,
          data: { baseTemp: baseTemperature, degreeDataTypes: ['hdd', 'cdd'] },
        }),
        projectServices.getMeterReadings(projectId),
      ]);

      const { degreeData, degreeDataByBillingPeriod, baseloads, formattedDocuments, weather, zipCode } =
        insightsResponse;

      const dates = setDatesFromData(formattedDocuments);

      setState({
        documents: formattedDocuments,
        meterReadings: meterReadings.formattedMeterReadings,
        emissionsFromUtilityBills: null,
        emissionsFromMeterReadings: null,
        weather,
        zipCode: zipCode || '',
        startDate: dates.startDate,
        endDate: dates.endDate,
        degreeData,
        degreeDataByBillingPeriod,
        meterReadingDegreeDataByBillingPeriod: meterReadings.degreeDataByBillingPeriod,
        baseloads,
        meterReadingsBaseloads: meterReadings.baseloads,
        project: projectData,
      });
    } catch (err) {
      setError(err instanceof Error ? err : new Error('Failed to fetch project data'));
      console.error('Error fetching project data:', err);
    } finally {
      setIsLoading(false);
      setIsEmissionsLoading(false);
      setIsUpdatingDegreeData(false);
    }
  }, [projectId, baseTemperature]);

  useEffect(() => {
    fetchAllProjectData();
  }, [fetchAllProjectData]);

  return {
    ...state,
    isLoading,
    isEmissionsLoading,
    isUpdatingDegreeData,
    error,
    refetch: fetchAllProjectData,
  };
};
