import { InputWrapper } from '@shadcn/custom/InputWrapper';
import { Label } from '@shadcn/ui';
import {
  BasicBuildingInfoData,
  occupancyInfoFields,
  BuildingOccupancyInfo,
} from 'app/utils/constants/buildingInfoStructure';

interface BuildingOccupancyInputProps {
  buildingInfo: BasicBuildingInfoData;
  errors: Partial<Record<keyof BasicBuildingInfoData, string>>;
  handleOccupancyChange: (occupancy: any) => void;
  isEditing: boolean;
}

export const BuildingOccupancyInput = ({
  buildingInfo,
  errors,
  handleOccupancyChange,
  isEditing,
}: BuildingOccupancyInputProps) => {
  // Group fields by section
  const timeFields = occupancyInfoFields.filter((field) => field.type === 'time');
  const otherFields = occupancyInfoFields.filter((field) => field.type !== 'time');

  // Helper to safely get field value
  const getFieldValue = (fieldName: keyof BuildingOccupancyInfo) => {
    const value = buildingInfo.occupancy?.[fieldName];
    return value?.toString() || '';
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="mt-4">
        <Label className="mb-4 block text-lg font-medium">Building Occupancy Information</Label>

        <div className="grid gap-6">
          {/* Hours of Operation Section */}
          <div className="rounded-md bg-muted/50 p-4">
            <h3 className="text-md mb-3 font-medium">Hours of Operation</h3>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
              {timeFields.map((field) => (
                <div key={field.name}>
                  <InputWrapper
                    label={field.label}
                    inputStartAdornment={<field.icon className="stroke-foreground" />}
                    name={field.name}
                    type={field.type}
                    value={getFieldValue(field.name as keyof BasicBuildingInfoData['occupancy'])}
                    onChange={handleOccupancyChange}
                    error={!!errors[`occupancy.${field.name}` as keyof BasicBuildingInfoData]}
                    helperText={errors[`occupancy.${field.name}` as keyof BasicBuildingInfoData]}
                    disabled={field.name.includes('weekend') && buildingInfo.occupancy?.closedOnWeekends}
                  />
                </div>
              ))}

              <div className="mb-2 mt-2 flex items-center space-x-2 md:col-span-2">
                <input
                  type="checkbox"
                  id="closedOnWeekends"
                  name="closedOnWeekends"
                  className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                  checked={buildingInfo.occupancy?.closedOnWeekends || false}
                  onChange={handleOccupancyChange}
                />
                <Label htmlFor="closedOnWeekends" className="text-sm font-medium">
                  Closed on weekends
                </Label>
              </div>
            </div>
          </div>

          {/* Occupancy Pattern Section */}
          <div className="rounded-md bg-muted/50 p-4">
            <h3 className="text-md mb-3 font-medium">Occupancy Pattern</h3>
            <div className="grid grid-cols-1 gap-4">
              {otherFields.map((field) => (
                <div key={field.name}>
                  {field.isSelect ? (
                    <>
                      <Label htmlFor={field.name}>{field.label}</Label>
                      <div className="mt-1">
                        <select
                          id={field.name}
                          name={field.name}
                          className="w-full rounded-md border border-input bg-background px-3 py-2"
                          value={getFieldValue(field.name as keyof BasicBuildingInfoData['occupancy'])}
                          onChange={handleOccupancyChange}
                        >
                          <option value="daily">Daily (consistent day-to-day)</option>
                          <option value="variable">Variable (changes throughout week)</option>
                        </select>
                      </div>
                    </>
                  ) : (
                    <InputWrapper
                      label={field.label}
                      inputStartAdornment={<field.icon className="stroke-foreground" />}
                      name={field.name}
                      type={field.type}
                      value={getFieldValue(field.name as keyof BasicBuildingInfoData['occupancy'])}
                      onChange={handleOccupancyChange}
                      error={!!errors[`occupancy.${field.name}` as keyof BasicBuildingInfoData]}
                      helperText={errors[`occupancy.${field.name}` as keyof BasicBuildingInfoData]}
                      pattern={field.pattern}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
