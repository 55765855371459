import { CollapsibleChart } from 'app/components/charts/CollapsibleChart';
import type { Documents } from 'app/types/visualizations';
import { DataSource } from 'app/types/visualizations';
interface InsightCardsPanelProps {
  serviceType: string;
  documents: Documents;
  meterReadings: any;
  baseloads: any;
  meterReadingsBaseloads: any;
  renderLoadMetric: (serviceType: string) => string;
  selectedDataSource: DataSource;
}

const formatValue = (value: number | undefined, isPercentage: boolean = false) => {
  if (!value) return '0.00';

  if (isPercentage) {
    return `${(value * 100).toFixed(2)}%`;
  }
  return value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

const getMostRecentYear = (yearlyData: Record<string, any>) => {
  if (!yearlyData || Object.keys(yearlyData).length === 0) return null;

  const years = Object.keys(yearlyData)
    .filter((year) => !isNaN(Number(year)))
    .map(Number)
    .sort((a, b) => b - a);

  return years[0] || null;
};

export const InsightCardsPanel = ({
  serviceType,
  documents,
  meterReadings,
  baseloads,
  meterReadingsBaseloads,
  renderLoadMetric,
  selectedDataSource,
}: InsightCardsPanelProps) => {
  const selectedData = selectedDataSource === 'utility-bills' ? documents : meterReadings;
  const selectedDataBaseloads = selectedDataSource === 'utility-bills' ? baseloads : meterReadingsBaseloads;

  return (
    <CollapsibleChart title="Service Summary">
      {selectedDataBaseloads && selectedDataBaseloads[serviceType] && (
        <div className="mt-6">
          <h3 className="mb-4 text-lg font-semibold text-gray-700">Baseload Analysis</h3>
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
            <div className="rounded-lg border border-gray-200 bg-white p-4 shadow-sm">
              <div className="text-sm text-gray-500">Baseload</div>
              <div className="mt-1 text-xl font-semibold">
                {formatValue(selectedDataBaseloads[serviceType].baseload)}
                <span className="ml-1 text-sm text-gray-500">{renderLoadMetric(serviceType)}</span>
              </div>
            </div>
            <div className="rounded-lg border border-gray-200 bg-white p-4 shadow-sm">
              <div className="text-sm text-gray-500">Mean Usage</div>
              <div className="mt-1 text-xl font-semibold">
                {formatValue(selectedDataBaseloads[serviceType].meanUsage)}
                <span className="ml-1 text-sm text-gray-500">{renderLoadMetric(serviceType)}</span>
              </div>
            </div>
            <div className="rounded-lg border border-gray-200 bg-white p-4 shadow-sm">
              <div className="text-sm text-gray-500">R-Squared</div>
              <div className="mt-1 text-xl font-semibold">
                {formatValue(selectedDataBaseloads[serviceType].rSquared, true)}
              </div>
            </div>
            <div className="rounded-lg border border-gray-200 bg-white p-4 shadow-sm">
              <div className="text-sm text-gray-500">Temperature Sensitivity</div>
              <div className="mt-1 text-xl font-semibold">
                {formatValue(selectedDataBaseloads[serviceType].slope)}
                <span className="ml-1 text-sm text-gray-500">{renderLoadMetric(serviceType)}/°F</span>
              </div>
            </div>
          </div>
        </div>
      )}

      {Object.keys(selectedData?.yearlyData || {}).length > 0 && (
        <div className="mt-6">
          <h3 className="mb-4 text-lg font-semibold text-gray-700">Yearly Statistics</h3>
          <div className="overflow-x-auto">
            <table className="w-full border-collapse">
              <thead>
                <tr className="border-b border-gray-200 bg-gray-50">
                  <th className="px-4 py-3 text-left text-sm font-semibold text-gray-700">Year</th>
                  <th colSpan={6} className="border-b px-4 py-2 text-left text-sm font-semibold text-gray-700">
                    Energy Usage
                  </th>
                  <th colSpan={2} className="border-b px-4 py-2 text-left text-sm font-semibold text-gray-700">
                    Financial
                  </th>
                </tr>
                <tr className="border-b border-gray-200 bg-gray-50 text-sm text-gray-600">
                  <th className="px-4 py-2 text-left"></th>
                  <th className="px-4 py-2 text-left">Total Usage</th>
                  <th className="px-4 py-2 text-left">Total kBtu</th>
                  <th className="px-4 py-2 text-left">Site EUI</th>
                  <th className="px-4 py-2 text-left">Source EUI</th>
                  <th className="px-4 py-2 text-left">Emissions</th>
                  <th className="px-4 py-2 text-left">Property Emissions Limit</th>
                  <th className="px-4 py-2 text-left">Total Cost</th>
                  <th className="px-4 py-2 text-left">Months Covered</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(selectedData?.yearlyData || {})
                  .sort((a, b) => Number(b) - Number(a))
                  .map((year) => (
                    <tr key={year} className="border-b border-gray-200 hover:bg-gray-50">
                      <td className="px-4 py-4">
                        <div className="font-medium text-gray-700">{year}</div>
                        <div className="text-sm text-gray-500">
                          {selectedData?.yearlyData[year].startDate && selectedData?.yearlyData[year].endDate ? (
                            <>
                              {new Date(selectedData.yearlyData[year].startDate).toLocaleDateString()} -{' '}
                              {new Date(selectedData.yearlyData[year].endDate).toLocaleDateString()}
                            </>
                          ) : (
                            '-'
                          )}
                        </div>
                      </td>
                      <td className="px-4 py-4">
                        {formatValue(
                          Number(selectedData?.yearlyData[year].energyUsageTotalsByService[serviceType].totalUsage)
                        )}
                        <span className="text-sm text-muted-foreground"> {renderLoadMetric(serviceType)}</span>
                      </td>
                      <td className="px-4 py-4">
                        {formatValue(
                          Number(selectedData?.yearlyData[year].energyUsageTotalsByService[serviceType].totalKbtu)
                        )}
                      </td>
                      <td className="px-4 py-4">
                        {selectedData?.yearlyData[year]?.energyUsageTotalsByService[serviceType]?.siteEUI && (
                          <>
                            {formatValue(
                              Number(selectedData?.yearlyData[year].energyUsageTotalsByService[serviceType].siteEUI)
                            )}
                            <span className="text-sm text-muted-foreground"> kBtu/sqft</span>
                          </>
                        )}
                      </td>
                      <td className="px-4 py-4">
                        {selectedData?.yearlyData[year]?.energyUsageTotalsByService[serviceType]?.sourceEUI && (
                          <>
                            {formatValue(
                              Number(selectedData?.yearlyData[year].energyUsageTotalsByService[serviceType].sourceEUI)
                            )}
                            <span className="text-sm text-muted-foreground"> kBtu/sqft</span>
                          </>
                        )}
                      </td>
                      <td className="px-4 py-4">
                        {selectedData?.yearlyData[year]?.energyUsageTotalsByService[serviceType]?.emissions && (
                          <>
                            {formatValue(
                              Number(selectedData?.yearlyData[year].energyUsageTotalsByService[serviceType].emissions)
                            )}
                            <span className="text-sm text-muted-foreground">tCO₂e</span>
                          </>
                        )}
                      </td>
                      <td className="px-4 py-4">
                        {selectedData?.yearlyData[year]?.threshold && (
                          <>
                            {formatValue(Number(selectedData?.yearlyData[year].threshold))}
                            <span className="text-sm text-muted-foreground">tCO₂e</span>
                          </>
                        )}
                      </td>
                      <td className="px-4 py-4">
                        ${formatValue(Number(selectedData?.yearlyData[year].energyCostsByService[serviceType]))}
                      </td>
                      <td className="px-4 py-4">
                        {selectedData?.yearlyData[year]?.monthsCovered && (
                          <>{formatValue(Number(selectedData?.yearlyData[year].monthsCovered))}</>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </CollapsibleChart>
  );
};
