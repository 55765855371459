import { useState, useEffect, useMemo } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import moment from 'moment';
import { Button } from '@shadcn/ui';
import conedService from 'app/services/coned';
import { useConEdisonData } from 'app/hooks/useConEdisonData';
import {
  sharedXAxisProps,
  createYAxisProps,
  sharedCartesianGridProps,
  sharedTooltipProps,
} from 'app/components/charts/shared/chartProps';
import type { ConEdAnalysisProps, IntervalBlock, IntervalBlockParams, AggregatedIntervalBlockParams } from './types';
import {
  hasDataGaps,
  normalizeIntervalData,
  toEasternTime,
  getPublishedDateRange,
  getCurrentEasternTime,
} from './utils';
import { TimeRange, TimeRangeConfig } from './types';
import { TIME_RANGE_CONFIG, INTERVAL_LABELS } from './constants';
const ChartSkeleton = () => (
  <div className="space-y-8">
    <div className="mt-8 space-y-6">
      <div className="grid grid-cols-1 gap-6">
        <div className="rounded-lg border p-4">
          <h4 className="mb-4 text-base font-medium">All Meters Comparison (Normalized)</h4>
          <div className="h-[400px] rounded-lg bg-muted"></div>
        </div>
        <div className="rounded-lg border p-4">
          <h4 className="mb-4 text-base font-medium">All Meters (Aggregated)</h4>
          <div className="h-[400px] rounded-lg bg-muted"></div>
        </div>
      </div>
    </div>
  </div>
);

const CombinedChartSkeleton = () => (
  <div className="rounded-lg border p-4">
    <div className="mb-4 flex items-center gap-2">
      <div className="h-6 w-64 rounded-md bg-muted"></div>
    </div>
    <div className="h-[400px] rounded-lg bg-muted"></div>
  </div>
);

export const HistoricalIntervalData = ({ project }: Omit<ConEdAnalysisProps, 'conEdCustomerMeters'>) => {
  const { customerMeters: conEdCustomerMeters, isLoading: isLoadingMeters } = useConEdisonData(project);
  const [baseloads, setBaseloads] = useState<{ [key: string]: number }>({});
  const [selectedMeterIds, setSelectedMeterIds] = useState<string[]>([]);
  const [intervalData, setIntervalData] = useState<{ [key: string]: IntervalBlock[] }>({});
  const [loading, setLoading] = useState(false);
  const [allMeterDataLoaded, setAllMeterDataLoaded] = useState(false);

  const currentEastern = getCurrentEasternTime();

  // Set default dates to yesterday in Eastern time
  const yesterday = new Date(currentEastern.getTime() - 24 * 60 * 60 * 1000);
  const yesterdayStr = yesterday.toISOString().slice(0, 19).replace('T', ' ');
  const [startDate, setStartDate] = useState<string>(yesterdayStr);
  const [endDate, setEndDate] = useState<string>(yesterdayStr);
  const [dateError, setDateError] = useState<string>('');

  // Initialize time range
  const [timeRange, setTimeRange] = useState<TimeRange>('day');

  // Remove intervalType state and use computed value instead
  const intervalType = useMemo(() => {
    return TIME_RANGE_CONFIG[timeRange].interval;
  }, [timeRange]);

  // Calculate optimal tick interval based on time range and interval type
  const getTickInterval = (timeRange: TimeRange, intervalType: string) => {
    switch (timeRange) {
      case 'day':
        return 12;
      case 'week':
        return 6;
      case 'month':
        return 1;
      default:
        return 1;
    }
  };

  // Update time range effect
  useEffect(() => {
    const config = TIME_RANGE_CONFIG[timeRange];
    const now = toEasternTime(new Date());
    let end = moment.min(now, moment()) as moment.Moment;
    let start: moment.Moment;

    if (timeRange === 'day') {
      start = end.clone().subtract(24, 'hours');
    } else {
      start = end.clone().subtract(config.days - 1, 'days');
    }

    // Convert to UTC for API requests
    setEndDate(moment(end.toDate()).utc().format('YYYY-MM-DD HH:mm:ss'));
    setStartDate(moment(start.toDate()).utc().format('YYYY-MM-DD HH:mm:ss'));
  }, [timeRange]);

  // Update formatDateRange to use consistent timezone handling
  const formatDateRange = () => {
    const formatDate = (date: moment.Moment) => {
      return toEasternTime(date.toDate()).format('ddd, MMM D, h:mm A');
    };

    const now = toEasternTime(new Date());
    let end = moment.min(now, moment()) as moment.Moment;
    let start: moment.Moment;

    if (timeRange === 'day') {
      start = end.clone().subtract(24, 'hours');
    } else {
      start = end.clone().subtract(TIME_RANGE_CONFIG[timeRange].days - 1, 'days');
    }

    return `${formatDate(start)} - ${formatDate(end)}`;
  };

  // Move allMeters calculation into useMemo to prevent recalculation on every render
  const allMeters = useMemo(
    () =>
      conEdCustomerMeters.flatMap((customerMeter) =>
        customerMeter.serviceLocations.flatMap((location) =>
          location.meters.map((meter) => ({
            serialNumber: meter.serialNumber,
            serviceLocationId: location.serviceLocationId,
            customerAgreementId: customerMeter.customerAgreementId,
            meterReadings: meter.meterReadings,
          }))
        )
      ),
    [conEdCustomerMeters]
  );

  // Generate unique colors for each meter
  const meterColors = useMemo(() => {
    const colors = [
      'hsl(var(--chart-1))',
      'hsl(var(--chart-2))',
      'hsl(var(--chart-3))',
      'hsl(var(--chart-4))',
      'hsl(var(--chart-5))',
      'rgb(var(--chart-energy-rgb))',
      'rgb(var(--chart-temperature-rgb))',
      'rgb(var(--chart-cooling-rgb))',
      'rgb(var(--chart-heating-rgb))',
    ];
    return Object.fromEntries(
      allMeters.map((meter, index) => [meter.serialNumber.toString(), colors[index % colors.length]])
    );
  }, [allMeters]);

  useEffect(() => {
    if (allMeters.length > 0 && selectedMeterIds.length === 0) {
      setSelectedMeterIds([allMeters[0].serialNumber.toString()]);
    }
  }, [allMeters, selectedMeterIds.length]);

  const validateDateRange = (start: string, end: string) => {
    if (!start || !end) return false;

    const startMoment = moment(start);
    const endMoment = moment(end);
    const thirtyDays = moment.duration(30, 'days');

    if (moment.duration(endMoment.diff(startMoment)).asMilliseconds() > thirtyDays.asMilliseconds()) {
      setDateError('Date range cannot exceed 30 days');
      return false;
    }

    if (startMoment.isAfter(endMoment)) {
      setDateError('Start date must be before end date');
      return false;
    }

    setDateError('');
    return true;
  };

  useEffect(() => {
    const fetchAggregatedData = async () => {
      if (
        !startDate ||
        !endDate ||
        !validateDateRange(startDate, endDate) ||
        !project?.attributes?.conEdisonSubscriptionId
      ) {
        return;
      }

      try {
        const { publishedMin, publishedMax } = getPublishedDateRange(startDate, endDate);
        const aggregatedResponse = await conedService.getAggregatedIntervalBlocks({
          subscriptionId: project.attributes.conEdisonSubscriptionId,
          publishedMin,
          publishedMax,
          interval: intervalType === '24hr' ? 1440 : intervalType === '1hr' ? 60 : 5,
        } as AggregatedIntervalBlockParams);

        const aggregatedData = aggregatedResponse.intervalBlocks.flatMap((block) => {
          return block.IntervalBlock.readings.map((reading) => ({
            startTime: toEasternTime(reading.timePeriod.start * 1000).toISOString(),
            value: reading.value / 1000,
            meterId: 'all',
          }));
        });

        setIntervalData((prev) => ({ ...prev, all: aggregatedData }));
        setBaseloads((prev) => ({ ...prev, all: aggregatedResponse.baseload }));
      } catch (error) {
        console.error('Error fetching aggregated data:', error);
        setIntervalData((prev) => {
          const { all, ...rest } = prev;
          return rest;
        });
        setBaseloads((prev) => {
          const { all, ...rest } = prev;
          return rest;
        });
      }
    };

    const fetchIndividualMeterData = async () => {
      if (
        !startDate ||
        !endDate ||
        !validateDateRange(startDate, endDate) ||
        !project?.attributes?.conEdisonSubscriptionId ||
        allMeters.length === 0
      ) {
        setAllMeterDataLoaded(false);
        return;
      }

      try {
        const newIntervalData: { [key: string]: IntervalBlock[] } = {};
        const newBaseloads: { [key: string]: number } = {};

        await Promise.all(
          allMeters.map(async (meter) => {
            const meterId = meter.serialNumber.toString();
            if (!meter.serviceLocationId) {
              console.error('Usage point ID not found');
              return;
            }

            const kwhReading = meter.meterReadings.find((reading) => reading.type === 'KWH');
            if (!kwhReading) {
              console.error('KWH reading not found for meter');
              return;
            }
            const { publishedMin, publishedMax } = getPublishedDateRange(startDate, endDate);
            try {
              const response = await conedService.getIntervalBlocks({
                subscriptionId: project.attributes.conEdisonSubscriptionId,
                usagePointId: meter.serviceLocationId,
                meterReadingId: kwhReading.id,
                publishedMin,
                publishedMax,
                interval: intervalType === '24hr' ? 1440 : intervalType === '1hr' ? 60 : 5,
              } as IntervalBlockParams);

              newBaseloads[meterId] = response.baseload;
              const transformedData = response.intervalBlocks.flatMap((block) =>
                block.IntervalBlock.readings.map((reading) => ({
                  startTime: toEasternTime(reading.timePeriod.start * 1000).toISOString(),
                  value: reading.value / 1000,
                  meterId,
                }))
              );
              newIntervalData[meterId] = transformedData;
            } catch (error) {
              console.error(`Error fetching data for meter ${meterId}:`, error);
            }
          })
        );

        setIntervalData((prev) => ({
          ...prev,
          ...newIntervalData,
        }));
        setBaseloads((prev) => ({
          ...prev,
          ...newBaseloads,
        }));
        setAllMeterDataLoaded(true);
      } catch (error) {
        console.error('Error fetching individual meter data:', error);
        setAllMeterDataLoaded(false);
      }
    };

    const fetchAllData = async () => {
      setLoading(true);
      setAllMeterDataLoaded(false);
      try {
        await Promise.all([fetchAggregatedData(), fetchIndividualMeterData()]);
      } finally {
        setLoading(false);
      }
    };

    fetchAllData();
  }, [startDate, endDate, allMeters, project?.attributes?.conEdisonSubscriptionId, intervalType]);

  // Reset allMeterDataLoaded when dependencies change
  useEffect(() => {
    setAllMeterDataLoaded(false);
  }, [startDate, endDate, intervalType]);

  // Update timestamp formatting for tooltips/labels
  const formatTimestamp = (time: string) => {
    if (!time) return '';
    return toEasternTime(time).format('ddd, MMMM D, h:mma');
  };

  const renderUsageChart = (meterId: string, data: IntervalBlock[]) => (
    <div key={meterId} className="rounded-lg border p-4">
      <div className="mb-4 flex items-center gap-2">
        <h4 className="text-base font-medium">{meterId === 'all' ? 'All Meters (Aggregated)' : `Meter ${meterId}`}</h4>
        {baseloads[meterId] > 0 && (
          <div className="group relative flex items-center">
            <svg
              className="h-4 w-4 cursor-help text-muted-foreground"
              fill="none"
              height="24"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="12" cy="12" r="10" />
              <path d="M12 16v-4" />
              <path d="M12 8h.01" />
            </svg>
            <div className="absolute bottom-full left-1/2 mb-2 ml-2 hidden w-[400px] -translate-x-1/2 rounded-lg bg-popover p-4 text-sm text-popover-foreground shadow-md group-hover:block">
              <p>
                Baseload represents your facility's minimum power demand - the energy used by equipment that runs
                continuously.
                <br />
                It is calculated as the 10th percentile of all energy readings, meaning 10% of your readings fall below
                this value.
                <br />A high baseload relative to your average usage may indicate opportunities to reduce energy
                consumption during off-hours.
              </p>
              <div className="absolute left-1/2 top-full -translate-x-1/2 border-8 border-transparent border-t-popover"></div>
            </div>
          </div>
        )}
      </div>
      <div className="h-[400px]">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={data} margin={{ top: 20, right: 30, left: 50, bottom: 80 }}>
            <CartesianGrid {...sharedCartesianGridProps} />
            <XAxis
              {...sharedXAxisProps}
              dataKey="startTime"
              tickFormatter={formatTimestamp}
              tick={
                {
                  fontSize: 11,
                  angle: -45,
                  textAnchor: 'end',
                  dy: 5,
                } as any
              }
              interval={getTickInterval(timeRange, intervalType)}
              height={80}
            />
            <YAxis
              {...createYAxisProps('Energy Consumption (kWh)')}
              label={{
                value: 'Energy Consumption (kWh)',
                angle: -90,
                position: 'insideLeft',
                offset: -35,
                dy: 50,
                style: { fontSize: '12px' },
              }}
              tick={{ fontSize: 12 }}
            />
            <Tooltip
              {...sharedTooltipProps}
              labelFormatter={(time) => {
                if (!time) return '';
                return moment(time).format('ddd, MMMM D, h:mma');
              }}
              formatter={(value, name, props) => {
                const payload = props.payload;
                const allValues = Object.entries(payload)
                  .filter(([key]) => key !== 'startTime')
                  .map(([meterId, value]) => ({
                    meterId,
                    value: value as number,
                    name: `Meter ${meterId}`,
                    color: meterColors[meterId],
                  }))
                  .sort((a, b) => b.value - a.value);

                const currentMeter = allValues.find((item) => item.meterId === props.dataKey);
                if (!currentMeter) return ['', ''];

                return [
                  <span style={{ color: currentMeter.color }}>
                    <span
                      style={{
                        display: 'inline-block',
                        width: '10px',
                        height: '10px',
                        backgroundColor: currentMeter.color,
                        marginRight: '6px',
                        borderRadius: '50%',
                      }}
                    ></span>
                    {currentMeter.value.toFixed(2)} kWh
                  </span>,
                  currentMeter.name,
                ];
              }}
            />
            <Line
              type="monotone"
              dataKey="value"
              stroke={'rgb(var(--chart-energy-rgb))'}
              strokeWidth={2}
              dot={intervalType === '24hr'}
              name="Usage"
              connectNulls={true}
              yAxisId="left"
            />
            {baseloads[meterId] > 0 && (
              <Line
                type="monotone"
                data={data.map((d) => ({ ...d, baseload: baseloads[meterId] / 1000 }))}
                dataKey="baseload"
                stroke="#000000"
                strokeWidth={2}
                dot={false}
                name="Baseload"
                connectNulls={true}
                yAxisId="left"
              />
            )}
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );

  const renderCombinedChart = (intervalData: { [key: string]: IntervalBlock[] }) => {
    const meterData = Object.entries(intervalData).filter(([key]) => key !== 'all');
    if (meterData.length === 0) return null;

    // Show skeleton while loading
    if (!allMeterDataLoaded) {
      return <CombinedChartSkeleton />;
    }

    const normalizedData = normalizeIntervalData(Object.fromEntries(meterData));

    return (
      <div className="rounded-lg border p-4">
        <h4 className="mb-4 text-base font-medium">All Meters Comparison (Normalized)</h4>
        <div className="h-[400px]">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={normalizedData} margin={{ top: 40, right: 30, left: 50, bottom: 80 }}>
              <CartesianGrid {...sharedCartesianGridProps} />
              <XAxis
                {...sharedXAxisProps}
                dataKey="startTime"
                tickFormatter={formatTimestamp}
                tick={
                  {
                    fontSize: 12,
                    angle: -45,
                    textAnchor: 'end',
                    dy: 5,
                  } as any
                }
                interval={getTickInterval(timeRange, intervalType)}
                height={60}
              />
              <YAxis
                {...createYAxisProps('Energy Consumption (kWh)')}
                yAxisId="left"
                label={{
                  value: 'Energy Consumption (kWh)',
                  angle: -90,
                  position: 'insideLeft',
                  offset: -35,
                  dy: 50,
                  style: { fontSize: '12px' },
                }}
                tick={{ fontSize: 12 }}
              />
              <Tooltip
                {...sharedTooltipProps}
                labelFormatter={(time) => {
                  if (!time) return '';
                  return moment(time).format('ddd, MMMM D, h:mma');
                }}
                formatter={(value, name, props) => {
                  const payload = props.payload;
                  const allValues = Object.entries(payload)
                    .filter(([key]) => key !== 'startTime')
                    .map(([meterId, value]) => ({
                      meterId,
                      value: value as number,
                      name: `Meter ${meterId}`,
                      color: meterColors[meterId],
                    }))
                    .sort((a, b) => b.value - a.value);

                  const currentMeter = allValues.find((item) => item.meterId === props.dataKey);
                  if (!currentMeter) return ['', ''];

                  return [
                    <span style={{ color: currentMeter.color }}>
                      <span
                        style={{
                          display: 'inline-block',
                          width: '10px',
                          height: '10px',
                          backgroundColor: currentMeter.color,
                          marginRight: '6px',
                          borderRadius: '50%',
                        }}
                      ></span>
                      {currentMeter.value.toFixed(2)} kWh
                    </span>,
                    currentMeter.name,
                  ];
                }}
              />
              <Legend
                verticalAlign="top"
                height={36}
                wrapperStyle={{
                  paddingTop: '0px',
                  paddingBottom: '20px',
                  fontSize: '12px',
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: '10px',
                  justifyContent: 'center',
                }}
                formatter={(value) => {
                  // Show full meter number
                  const meterNum = value.split(' ')[1];
                  return `Meter ${meterNum}`;
                }}
              />
              {Object.keys(Object.fromEntries(meterData)).map((meterId) => (
                <Line
                  key={meterId}
                  type="monotone"
                  dataKey={meterId}
                  stroke={meterColors[meterId]}
                  strokeWidth={2}
                  dot={false}
                  name={`Meter ${meterId}`}
                  connectNulls={true}
                  yAxisId="left"
                />
              ))}
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    );
  };

  // Handle time range selection
  const handleTimeRangeSelect = (newRange: TimeRange) => {
    setTimeRange(newRange);
  };

  return (
    <div>
      <h3 className="mb-4 text-lg font-semibold">Historical Interval Data</h3>

      {/* Time Range Controls */}
      <div className="mb-6 space-y-4">
        <div className="flex items-center gap-4">
          <div className="flex flex-col gap-3">
            <div className="flex items-center gap-3">
              {[...(Object.entries(TIME_RANGE_CONFIG) as [TimeRange, TimeRangeConfig][])].map(([key, config]) => (
                <Button
                  key={key}
                  variant={timeRange === key ? 'default' : 'outline'}
                  size="default"
                  onClick={() => handleTimeRangeSelect(key)}
                  className={`min-w-[140px] transition-all ${
                    timeRange === key ? 'bg-primary text-primary-foreground shadow-md' : 'hover:bg-muted'
                  }`}
                >
                  {config.label}
                </Button>
              ))}
            </div>
            <div className="text-sm text-muted-foreground">
              {formatDateRange()} • {INTERVAL_LABELS[intervalType]} intervals
            </div>
          </div>
        </div>
      </div>

      {/* Data Gap Warning */}
      {Object.values(intervalData).some((data) => data.length > 0) &&
        (() => {
          const allData = Object.values(intervalData).flat();
          const { hasGaps, gaps, intervalMinutes } = hasDataGaps(allData);
          return hasGaps ? (
            <div className="mb-4 rounded-md bg-yellow-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <svg className="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor">
                    <path
                      fillRule="evenodd"
                      d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-yellow-800">Data Gap Warning</h3>
                  <div className="mt-2 text-sm text-yellow-700">
                    <p>
                      Data points are expected every{' '}
                      {intervalType === '24hr' ? '24 hours' : `${intervalMinutes} minutes`}. Found {gaps.length} gap
                      {gaps.length > 1 ? 's' : ''} in the data:
                    </p>
                    <ul className="mt-2 list-disc pl-5 text-xs">
                      {gaps.slice(0, 3).map((gap, index) => (
                        <li key={index}>
                          Gap from {new Date(gap.start).toLocaleString()} to {new Date(gap.end).toLocaleString()}
                        </li>
                      ))}
                      {gaps.length > 3 && (
                        <li>
                          ...and {gaps.length - 3} more gap{gaps.length - 3 > 1 ? 's' : ''}
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ) : null;
        })()}

      {(loading || isLoadingMeters) && (
        <div className="mt-8 grid grid-cols-1 gap-6">
          <ChartSkeleton />
        </div>
      )}

      {!loading && !isLoadingMeters && (
        <div className="mt-8 space-y-6">
          {/* Grid for charts */}
          <div className="grid grid-cols-1 gap-6">
            {/* Render combined chart that shows all meter trends */}
            {renderCombinedChart(intervalData)}

            {/* Render aggregated chart */}
            {intervalData['all'] && renderUsageChart('all', intervalData['all'])}
          </div>
        </div>
      )}
    </div>
  );
};
