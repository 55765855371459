import { FormEvent } from 'react';
import { Button, Card, CardContent, CardHeader, CardTitle, Label } from '@shadcn/ui';
import { Collapsible } from '@shadcn/custom/Collapsible';
import { PencilIcon, XIcon } from 'lucide-react';
import { cn } from '@shadcn/utils';
import { type ExtendedBuildingInfoData } from 'app/utils/constants/buildingInfoStructure';
import type { ProjectInfoData } from 'app/services/project-services';
import { BuildingDataInputs } from '../building-details/BuildingDataInputs';
import { BuildingServicesInput } from '../building-details/BuildingServicesInput';
import { BuildingOccupancyInput } from '../building-details/BuildingOccupancyInput';
import { BuildingUseTypeInputs } from '../building-details/BuildingUseTypeInputs';
import { BuildingSystems } from '../building-details/BuildingSystems';

interface BuildingEditSheetProps {
  isEditing: boolean;
  buildingInfo: ExtendedBuildingInfoData;
  project: ProjectInfoData;
  steps: Array<{ id: string; label: string }>;
  activeStep: number;
  hasChanges: boolean;
  errors: Partial<Record<keyof ExtendedBuildingInfoData, string>>;
  onEdit: () => void;
  onSubmit: (event?: FormEvent) => Promise<void>;
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setBuildingInfo: React.Dispatch<React.SetStateAction<ExtendedBuildingInfoData>>;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}

export const BuildingEditSheet = ({
  isEditing,
  buildingInfo,
  project,
  steps,
  activeStep,
  hasChanges,
  errors,
  onEdit: handleEdit,
  onSubmit: handleSubmit,
  onInputChange: handleInputChange,
  setBuildingInfo,
  setActiveStep,
}: BuildingEditSheetProps) => {
  // Helper function to set nested object value
  const setNestedValue = (obj: any, path: string, value: any) => {
    const parts = path.split('.');
    const lastPart = parts.pop()!;
    const target = parts.reduce((acc, part) => {
      if (!acc[part]) acc[part] = {};
      return acc[part];
    }, obj);
    target[lastPart] = value;
    return obj;
  };

  // Wrap the input change handler to handle nested fields
  const wrappedHandleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;

    if (name.includes('.')) {
      setBuildingInfo((prev) => {
        const newInfo = { ...prev };
        return setNestedValue(newInfo, name, type === 'checkbox' ? checked : value);
      });
    } else {
      handleInputChange(event);
    }
  };

  const renderStepContent = (step: number) => {
    const currentStep = steps[step];
    if (!currentStep) return null;

    switch (currentStep.id) {
      case 'basic':
        return (
          <BuildingDataInputs
            buildingInfo={buildingInfo}
            errors={errors}
            handleInputChange={wrappedHandleInputChange}
            handleClientServicesChange={(services) => {
              setBuildingInfo((prev) => ({
                ...prev,
                buildingRepresentation: {
                  ...prev.buildingRepresentation,
                  services: services,
                },
              }));
            }}
            isEditing={true}
          />
        );
      case 'services':
        return (
          <BuildingServicesInput
            buildingInfo={buildingInfo}
            errors={errors}
            handleServicesChange={(services) => {
              setBuildingInfo((prev) => ({
                ...prev,
                services,
              }));
            }}
            handleInputChange={(event) => {
              const { name, value, type } = event.target;
              setBuildingInfo((prev) => ({
                ...prev,
                buildingServices: {
                  ...prev.buildingServices,
                  [name]: value,
                  // Clear bmsVendor if BMS is unchecked
                  ...(name === 'hasBMS' && type === 'checkbox' && !value ? { bmsVendor: '' } : {}),
                },
              }));
            }}
            isEditing={true}
          />
        );
      case 'occupancy':
        return (
          <BuildingOccupancyInput
            buildingInfo={buildingInfo}
            errors={errors}
            handleOccupancyChange={(event) => {
              const { name, value, type, checked } = event.target;
              setBuildingInfo((prev) => ({
                ...prev,
                occupancy: {
                  ...prev.occupancy,
                  [name]: type === 'checkbox' ? checked : value,
                  // Clear weekend times if closed on weekends
                  ...(name === 'closedOnWeekends' && checked
                    ? {
                        weekendStartTime: '',
                        weekendEndTime: '',
                      }
                    : {}),
                },
              }));
            }}
            isEditing={true}
          />
        );
      case 'systems_overview':
        return <BuildingSystems projectInfo={buildingInfo as any} setProjectInfo={setBuildingInfo as any} />;
      case 'property_use':
        return (
          <BuildingUseTypeInputs
            projectInfo={buildingInfo}
            handlePropertyUseChange={(propertyUses) => setBuildingInfo((prev) => ({ ...prev, propertyUses }))}
            validationErrors={errors}
          />
        );
      case 'notes':
        return (
          <div className="space-y-4">
            <h3 className="text-lg font-medium">Additional Notes</h3>
            <Card className="p-4">
              <div className="space-y-2">
                <Label className="text-md font-medium">Notes</Label>
                <textarea
                  className="min-h-[200px] w-full rounded-md border border-input bg-background p-3 text-sm"
                  value={buildingInfo.notes || ''}
                  onChange={(e) => {
                    setBuildingInfo((prev) => ({
                      ...prev,
                      notes: e.target.value,
                    }));
                  }}
                  placeholder="Add any additional notes about the building..."
                />
              </div>
            </Card>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="h-full">
      <Collapsible expanded={isEditing}>
        <div className="h-full">
          <div className="h-[calc(100vh-6rem)]">
            <Card className="h-full transform rounded-xl border-none bg-gradient-to-br from-background/80 to-background/40 p-1 shadow-xl transition-all duration-200 hover:shadow-2xl">
              <CardHeader className="flex flex-row items-center justify-between pb-2 pt-4">
                <CardTitle className="flex items-center gap-2 text-lg font-semibold">
                  <PencilIcon className="h-4 w-4 text-primary" />
                  Edit Building Information
                </CardTitle>
                <div className="flex items-center gap-2">
                  <Button
                    size="sm"
                    variant="destructive"
                    className="transition-all duration-200 hover:scale-105"
                    onClick={handleEdit}
                  >
                    <XIcon className="h-4 w-4" />
                    Cancel
                  </Button>
                  <Button
                    size="sm"
                    variant="default"
                    className="transition-all duration-200 hover:scale-105"
                    onClick={handleSubmit}
                    disabled={!hasChanges}
                  >
                    Save Changes
                  </Button>
                </div>
              </CardHeader>
              <CardContent className="h-[calc(100vh-12rem)] overflow-y-auto">
                <form onSubmit={(e) => e.preventDefault()} className="flex h-full flex-col space-y-6">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-4">
                      {steps.map((step, index) => (
                        <div
                          key={step.label}
                          className={cn(
                            'flex items-center gap-2',
                            activeStep === index ? 'text-primary' : 'text-muted-foreground'
                          )}
                        >
                          <div
                            className={cn(
                              'flex h-6 w-6 items-center justify-center rounded-full text-xs font-medium',
                              activeStep === index
                                ? 'bg-primary text-primary-foreground'
                                : 'bg-muted text-muted-foreground'
                            )}
                          >
                            {index + 1}
                          </div>
                          <span className="text-sm font-medium">{step.label}</span>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="flex-1 overflow-y-auto rounded-lg border border-border/30 bg-background/5 p-6">
                    {renderStepContent(activeStep)}
                  </div>

                  <div className="flex items-center justify-between">
                    <Button
                      type="button"
                      variant="outline"
                      onClick={() => setActiveStep((prev) => Math.max(0, prev - 1))}
                      disabled={activeStep === 0}
                    >
                      Previous
                    </Button>
                    <Button
                      type="button"
                      onClick={() => {
                        if (activeStep === steps.length - 1) {
                          handleSubmit();
                        } else {
                          setActiveStep((prev) => Math.min(steps.length - 1, prev + 1));
                        }
                      }}
                    >
                      {activeStep === steps.length - 1 ? 'Save Changes' : 'Next'}
                    </Button>
                  </div>
                </form>
              </CardContent>
            </Card>
          </div>
        </div>
      </Collapsible>
    </div>
  );
};
