import { NoDataFound } from 'app/components/project/NoDataFound';
import { HistoricalIntervalData } from './HistoricalIntervalData';
import { RealTimeIntervalData } from './RealTimeIntervalData';
import type { ConEdAnalysisProps } from './types';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@shadcn/ui';
import { HistoryIcon, ActivityIcon } from 'lucide-react';

export const ConEdAnalysis = ({ project }: ConEdAnalysisProps) => {
  if (!project) {
    return (
      <NoDataFound
        message="No Project Data Available"
        submessage="Please select a project to view Con Edison analysis"
      />
    );
  }

  return (
    <Tabs defaultValue="real-time" className="w-full">
      <TabsList className="mb-6 flex h-12 w-full justify-start gap-2 rounded-lg border border-border/20 bg-muted p-1 shadow-[inset_0_2px_4px_rgba(0,0,0,0.4),0_4px_6px_-1px_rgba(0,0,0,0.3)]">
        <TabsTrigger
          value="real-time"
          className="flex-1 items-center justify-center gap-2 rounded-md border border-border/10 bg-background/40 text-gray-700 transition-all hover:bg-background/60 data-[state=active]:border-primary/30 data-[state=active]:bg-white data-[state=active]:text-primary data-[state=active]:shadow-[0_2px_10px_rgba(124,58,237,0.5)]"
        >
          <ActivityIcon className="h-4 w-4" />
          Real Time
        </TabsTrigger>
        <TabsTrigger
          value="historical"
          className="flex-1 items-center justify-center gap-2 rounded-md border border-border/10 bg-background/40 text-gray-700 transition-all hover:bg-background/60 data-[state=active]:border-primary/30 data-[state=active]:bg-white data-[state=active]:text-primary data-[state=active]:shadow-[0_2px_10px_rgba(124,58,237,0.5)]"
        >
          <HistoryIcon className="h-4 w-4" />
          Historical
        </TabsTrigger>
      </TabsList>

      <TabsContent value="real-time" className="mt-0">
        <RealTimeIntervalData project={project} />
      </TabsContent>

      <TabsContent value="historical" className="mt-0">
        <HistoricalIntervalData project={project} />
      </TabsContent>
    </Tabs>
  );
};
