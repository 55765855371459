import { useEffect, useState } from 'react';
import type { Task } from '../types/task';
import type { ProjectInfoData } from '../services/project-services';
import taskServices from '../services/tasks';
import reportServices from '../services/report-services';
export interface WaterMeterSubtask {
  projectId: string;
  projectName: string;
  projectAddress: string;
  owner?: string;
}

export interface WaterMeterGroup {
  owner: string;
  projects: WaterMeterSubtask[];
}

export interface DraftSubtask {
  projectId: string;
  projectName: string;
  projectAddress: string;
  owner?: string;
  draftId: string;
}

export interface DraftGroup {
  owner: string;
  drafts: DraftSubtask[];
}

export interface TaskWithSubtasks extends Task {
  subtasks?: WaterMeterSubtask[] | DraftSubtask[];
  groups?: WaterMeterGroup[] | DraftGroup[];
}

export const useTasks = (authUser: any | null, projects?: ProjectInfoData[], energyStarProperties?: any[]) => {
  const [tasks, setTasks] = useState<TaskWithSubtasks[]>([]);
  const energyStarAccountId = authUser?.relationships?.organization?.data?.energyStarAccountId;
  useEffect(() => {
    const loadTasks = async () => {
      const newTasks: TaskWithSubtasks[] = [];

      try {
        // Fetch tasks from service
        const serviceTasks = await taskServices.get();
        const drafts = await reportServices.getDrafts();

        // Group drafts by owner
        const draftSubtasks: DraftSubtask[] = [];
        const draftOwnerGroups: Record<string, DraftSubtask[]> = {};
        const ungroupedDrafts: DraftSubtask[] = [];

        // Find matching projects for drafts and group by owner
        for (const draft of drafts) {
          const projectId = draft.relationships?.project?.data?.id;
          const matchingProject = (projects || []).find((project) => project.id === projectId);

          if (matchingProject) {
            const subtask: DraftSubtask = {
              projectId: matchingProject.id,
              projectName: matchingProject.attributes.name,
              projectAddress: matchingProject.attributes.address,
              owner: matchingProject.attributes.buildingRepresentation?.owner,
              draftId: draft.id,
            };

            draftSubtasks.push(subtask);

            if (subtask.owner) {
              if (!draftOwnerGroups[subtask.owner]) {
                draftOwnerGroups[subtask.owner] = [];
              }
              draftOwnerGroups[subtask.owner].push(subtask);
            } else {
              ungroupedDrafts.push(subtask);
            }
          }
        }

        if (draftSubtasks.length > 0) {
          const groups: DraftGroup[] = Object.entries(draftOwnerGroups).map(([owner, drafts]) => ({
            owner,
            drafts,
          }));

          newTasks.push({
            id: 'review-drafts',
            title: 'Review Drafts',
            subtitle: `${draftSubtasks.length} ${draftSubtasks.length === 1 ? 'draft requires' : 'drafts require'} review`,
            description: 'Report drafts are ready for review.',
            groups: groups.length > 0 ? groups : undefined,
            subtasks: groups.length === 0 ? draftSubtasks : ungroupedDrafts,
          });
        }

        // Group water meter tasks by owner
        const waterMeterSubtasks: WaterMeterSubtask[] = [];
        const ownerGroups: Record<string, WaterMeterSubtask[]> = {};
        const ungroupedProjects: WaterMeterSubtask[] = [];

        serviceTasks.forEach((project) => {
          const subtask: WaterMeterSubtask = {
            projectId: project.id,
            projectName: project.attributes.name,
            projectAddress: project.attributes.address,
            owner: project.attributes.buildingRepresentation?.owner,
          };

          waterMeterSubtasks.push(subtask);

          // Group by owner if buildingRepresentation exists
          if (subtask.owner) {
            if (!ownerGroups[subtask.owner]) {
              ownerGroups[subtask.owner] = [];
            }
            ownerGroups[subtask.owner].push(subtask);
          } else {
            ungroupedProjects.push(subtask);
          }
        });

        if (waterMeterSubtasks.length > 0) {
          const groups: WaterMeterGroup[] = Object.entries(ownerGroups).map(([owner, projects]) => ({
            owner,
            projects,
          }));

          newTasks.push({
            id: 'connect-water-meters',
            title: 'Connect Water Meters',
            subtitle: `${waterMeterSubtasks.length} ${waterMeterSubtasks.length === 1 ? 'property requires' : 'properties require'} water meter connection`,
            description: 'Water usage is required for energy benchmarking.',
            groups: groups.length > 0 ? groups : undefined,
            subtasks: groups.length === 0 ? waterMeterSubtasks : ungroupedProjects,
          });
        }

        // TODO: move this logic to API /tasks endpoint
        if (!energyStarAccountId) {
          newTasks.push({
            id: 'connect-energy-star',
            title: 'Connect Energy Star Portfolio Manager',
            subtitle: 'Share your property data with Cosmos to automatically analyze your utility data.',
            description: `
# How to Share Properties in Portfolio Manager

## 1. Connect with Contacts
- Click "Contacts" in the top right corner
- Select "Add New Contacts/Connections"
- Search for Cosmos by organization name
- Send a connection request
- Wait for Cosmos to accept your request

## 2. Share Properties
### Start Sharing
- Go to the Sharing tab
- Click "Share (or Edit Access to) a Property"
- Select the properties you want to share
  - Choose single or multiple properties
  - Use filters if needed

### Choose Sharing Permissions
- Select "Full Access" so Cosmos can view and edit property data

### Select Contacts
- Select Cosmos from your list of contacts
- Set individual or bulk permission levels

**Pro Tip:** As the Property Data Administrator, only you can delete or transfer property ownership.`,
          });
        } else {
          try {
            const projectAddresses = new Set(projects?.map((p) => p.attributes.name?.toLowerCase().trim()));
            const unsyncedProperties = (energyStarProperties || []).filter(
              (prop) => !projectAddresses.has(prop.name?.toLowerCase().trim())
            );

            if (unsyncedProperties.length > 0) {
              newTasks.push({
                id: 'sync-energy-star',
                title: 'Import properties from Energy Star',
                subtitle: `There are ${unsyncedProperties.length + 1} ${unsyncedProperties.length === 1 ? 'property' : 'properties'} available to import.`,
                description: 'Click to import properties to Cosmos.',
              });
            }
          } catch (error) {
            console.error('Error loading Energy Star properties:', error);
          }
        }

        setTasks(newTasks);
      } catch (error) {
        console.error('Error loading tasks:', error);
      }
    };

    loadTasks();
  }, [energyStarAccountId, projects, energyStarProperties]);

  return tasks;
};
