import { useState, useEffect } from 'react';
import conedService from 'app/services/coned';
import { CustomerMeter } from 'app/pages/project/tabs/data-sources/ConEdTab';
import { ProjectInfoData } from 'app/services/project-services';

interface ConEdisonDataReturn {
  customerMeters: CustomerMeter[];
  isLoading: boolean;
  error: Error | null;
  refetch: () => Promise<void>;
}

export const useConEdisonData = (project: ProjectInfoData | null): ConEdisonDataReturn => {
  const [customerMeters, setCustomerMeters] = useState<CustomerMeter[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const fetchCustomerMeters = async () => {
    if (!project?.attributes.conEdisonSubscriptionId) {
      setCustomerMeters([]);
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const conEdAccountId = project.attributes.conEdisonSubscriptionId;
      const response = await conedService.getCustomerMeters({ id: conEdAccountId });
      setCustomerMeters(response?.customerMeters || []);
    } catch (error) {
      console.error('Error fetching customer meters:', error);
      setError(error instanceof Error ? error : new Error('Failed to fetch ConEdison customer meters'));
      setCustomerMeters([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCustomerMeters();
  }, [project?.attributes.conEdisonSubscriptionId]);

  return {
    customerMeters,
    isLoading,
    error,
    refetch: fetchCustomerMeters,
  };
};
