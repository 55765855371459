import { VariantProps } from 'class-variance-authority';
import { FormEvent, useState, useEffect, useMemo, forwardRef } from 'react';
import { Collapsible } from '@shadcn/custom/Collapsible';
import { cn } from '@shadcn/utils';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  toastVariants,
  Dialog,
  DialogContent,
} from '@shadcn/ui';
import {
  BriefcaseBusinessIcon,
  Building2Icon,
  CalendarIcon,
  HomeIcon,
  MapPinIcon,
  PencilIcon,
  XIcon,
  ClipboardCheckIcon,
  BoltIcon,
  FlameIcon,
  DropletIcon,
  FuelIcon,
  CloudFogIcon,
  SearchIcon,
  GaugeIcon,
  RefreshCwIcon,
  UserIcon,
  FileCheckIcon,
  BarChart3Icon,
  MonitorIcon,
  ServerIcon,
  NetworkIcon,
} from 'lucide-react';
import type { EnergyStarMeter, ProjectInfoData } from 'app/services/project-services';
import syncServices from 'app/services/sync-services';
import { formatSquareFeet } from 'app/utils/formatters';
import { Documents } from 'app/types/visualizations';
import { type ExtendedBuildingInfoData, buildingInfoSteps } from 'app/utils/constants/buildingInfoStructure';
import { BuildingEditSheet } from './BuildingEditSheet';
import { createInitialBuildingInfo } from 'app/utils/constants/buildingInfoStructure';
import { ComplianceYear } from './ComplianceYear';
import { ChildProperties } from './ChildProperties';
import { BuildingCharacteristics } from './BuildingCharacteristics';

const baseInfo = createInitialBuildingInfo();

// Update blank info to include extended fields
const blankBuildingInfo: ExtendedBuildingInfoData = {
  ...baseInfo,
  childProperties: [],
  propertyIdentifiers: {
    BBL: [],
    BIN: [],
  },
  complianceRequirements: {
    ll84: {
      required: false,
      requiredToReportWater: false,
    },
    ll97: {
      required: false,
      reportType: '',
    },
    buildingCount: 0,
  },
};

export interface BuildingInfoProps {
  project: ProjectInfoData;
  meterReadings: Documents;
  onUpdateProject: (data: { id: string; data: any }) => Promise<any>;
  onSnackbar: (message: string, variant: VariantProps<typeof toastVariants>['variant']) => void;
}

export const BuildingInfo = forwardRef<HTMLDivElement, BuildingInfoProps>(
  ({ project, meterReadings, onUpdateProject, onSnackbar }, ref) => {
    const [buildingInfo, setBuildingInfo] = useState<ExtendedBuildingInfoData>(blankBuildingInfo);
    const [originalInfo, setOriginalInfo] = useState<ExtendedBuildingInfoData>(blankBuildingInfo);
    const [buildingMeters, setBuildingMeters] = useState<EnergyStarMeter[]>([]);
    const [errors, setErrors] = useState<Partial<Record<keyof ExtendedBuildingInfoData, string>>>({});
    const [isEditing, setIsEditing] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [showSyncFeedbackType, setShowSyncFeedbackType] = useState<'success' | 'error' | null>(null);
    const [syncError, setSyncError] = useState<string>('');
    useEffect(() => {
      if (project && project.attributes) {
        const projectInfo: ExtendedBuildingInfoData = {
          name: project.attributes.name || '',
          buildingRepresentation: project.attributes.buildingRepresentation || {
            owner: '',
            representative: '',
            services: [],
          },
          address: project.attributes.address || '',
          city: project.attributes.city || '',
          state: project.attributes.state || '',
          zipCode: project.attributes.zipCode || '',
          squareFeet: formatSquareFeet(project.attributes.squareFeet) || '',
          yearBuilt: project.attributes.yearBuilt || '',
          notes: project.attributes.notes || '',
          numberOfFloors: project.attributes.numberOfFloors || '',
          services: project.attributes.services || [],
          parentProject: project.attributes?.parentProperty || '',
          childProperties: project.attributes.childProperties || [],
          propertyUses: project.attributes.propertyUses || [],
          propertyIdentifiers: project.attributes.propertyIdentifiers || { BBL: [], BIN: [] },
          complianceRequirements: project.attributes.complianceRequirements || {
            ll84: {
              required: false,
              requiredToReportWater: false,
            },
            ll97: {
              required: false,
              reportType: '',
            },
            buildingCount: 0,
          },
          buildingServices: {
            hasSolar: project.attributes.buildingServices?.hasSolar ?? false,
            hasBMS: project.attributes.buildingServices?.hasBMS ?? false,
            bmsVendor: project.attributes.buildingServices?.bmsVendor ?? '',
          },
          buildingSystems: project.attributes.buildingSystems || {},
          occupancy: {
            weekdayStartTime: project.attributes.occupancy?.weekdayStartTime || '',
            weekdayEndTime: project.attributes.occupancy?.weekdayEndTime || '',
            weekendStartTime: project.attributes.occupancy?.weekendStartTime || '',
            weekendEndTime: project.attributes.occupancy?.weekendEndTime || '',
            occupancyPattern: project.attributes.occupancy?.occupancyPattern || 'daily',
            peakOccupancy: project.attributes.occupancy?.peakOccupancy?.toString() || '',
            averageOccupancy: project.attributes.occupancy?.averageOccupancy?.toString() || '',
            closedOnWeekends: project.attributes.occupancy?.closedOnWeekends ?? false,
          },
        };

        setBuildingInfo(projectInfo);
        setOriginalInfo(projectInfo);
        setBuildingMeters(project.attributes.energyStarMeters || []);
      }
    }, [project]);

    const validateForm = () => {
      let tempErrors: Partial<Record<keyof ExtendedBuildingInfoData, string>> = {};
      tempErrors.name = buildingInfo.name ? '' : 'Building name is required';
      tempErrors.address = buildingInfo.address ? '' : 'Address is required';
      tempErrors.city = buildingInfo.city ? '' : 'City is required';
      tempErrors.state = buildingInfo.state ? '' : 'State is required';
      tempErrors.zipCode = /^\d{5}(-\d{4})?$/.test(buildingInfo.zipCode) ? '' : 'Invalid ZIP code';
      tempErrors.yearBuilt = /^\d{4}$/.test(buildingInfo.yearBuilt) ? '' : 'Invalid year';

      setErrors(tempErrors);
      return Object.values(tempErrors).every((x) => x === '');
    };

    const handleSubmit = async (event?: FormEvent) => {
      if (event) {
        event.preventDefault();
      }

      if (validateForm()) {
        if (!hasChanges) {
          onSnackbar('No changes to save', 'default');
          return;
        }

        // Create a properly structured submission data object
        const submissionData = {
          ...project.attributes,
          name: buildingInfo.name,
          buildingRepresentation: {
            owner: buildingInfo.buildingRepresentation.owner,
            representative: buildingInfo.buildingRepresentation.representative,
            services: buildingInfo.buildingRepresentation.services,
          },
          address: buildingInfo.address,
          city: buildingInfo.city,
          state: buildingInfo.state,
          zipCode: buildingInfo.zipCode,
          yearBuilt: buildingInfo.yearBuilt,
          numberOfFloors: buildingInfo.numberOfFloors,
          services: buildingInfo.services,
          propertyUses: buildingInfo.propertyUses,
          propertyIdentifiers: buildingInfo.propertyIdentifiers || {},
          complianceRequirements: buildingInfo.complianceRequirements,
          buildingServices: buildingInfo.buildingServices,
          buildingSystems: buildingInfo.buildingSystems,
          notes: buildingInfo.notes,
          // Ensure all occupancy fields are included
          occupancy: {
            weekdayStartTime: buildingInfo.occupancy?.weekdayStartTime || '',
            weekdayEndTime: buildingInfo.occupancy?.weekdayEndTime || '',
            weekendStartTime: buildingInfo.occupancy?.weekendStartTime || '',
            weekendEndTime: buildingInfo.occupancy?.weekendEndTime || '',
            occupancyPattern: buildingInfo.occupancy?.occupancyPattern || 'daily',
            peakOccupancy: buildingInfo.occupancy?.peakOccupancy
              ? Number(buildingInfo.occupancy.peakOccupancy)
              : undefined,
            averageOccupancy: buildingInfo.occupancy?.averageOccupancy
              ? Number(buildingInfo.occupancy.averageOccupancy)
              : undefined,
            closedOnWeekends: buildingInfo.occupancy?.closedOnWeekends || false,
          },
        };

        const result = await onUpdateProject({ id: project.id, data: submissionData });
        if (result.success) {
          setIsEditing(false);
          setOriginalInfo(buildingInfo);
          setBuildingInfo(buildingInfo);

          if (result.updatedProject) {
            setBuildingInfo(result.updatedProject.attributes);
            setOriginalInfo(result.updatedProject.attributes);
          }
        }
      } else {
        onSnackbar('Please correct the errors before submitting', 'destructive');
      }
    };

    const handleEdit = () => {
      if (isEditing) {
        setBuildingInfo(originalInfo);
        setErrors({});
      }
      setIsEditing((v) => !v);
      setActiveStep(0);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value, type, checked } = event.target;

      if (type === 'checkbox') {
        setBuildingInfo((prev) => ({
          ...prev,
          [name]: checked,
          // Clear bmsVendor if BMS is unchecked
          ...(name === 'hasBMS' && !checked ? { bmsVendor: '' } : {}),
        }));
      } else {
        setBuildingInfo((prev) => ({
          ...prev,
          [name]: value,
        }));
      }

      setErrors((prev) => ({ ...prev, [name]: '' }));
    };

    const hasChanges = useMemo(() => {
      const deepCompare = (obj1: any, obj2: any): boolean => {
        if (obj1 === obj2) return false;
        if (typeof obj1 !== 'object' || typeof obj2 !== 'object') return obj1 !== obj2;
        if (Array.isArray(obj1) !== Array.isArray(obj2)) return true;

        const keys1 = Object.keys(obj1 || {});
        const keys2 = Object.keys(obj2 || {});

        if (keys1.length !== keys2.length) return true;

        return keys1.some((key) => {
          if (Array.isArray(obj1[key])) {
            if (!Array.isArray(obj2[key]) || obj1[key].length !== obj2[key].length) return true;
            return obj1[key].some((item: any, index: number) => deepCompare(item, obj2[key][index]));
          }
          if (typeof obj1[key] === 'object') {
            return deepCompare(obj1[key], obj2[key]);
          }
          return obj1[key] !== obj2[key];
        });
      };

      return deepCompare(buildingInfo, originalInfo);
    }, [buildingInfo, originalInfo]);

    const handleSync = async () => {
      if (!project.attributes.energyStarId) {
        onSnackbar('No Energy Star ID found for this project', 'destructive');
        return;
      }

      try {
        const result = await syncServices.syncWithEnergyStar(project.id, project.attributes.energyStarId);
        setShowSyncFeedbackType('success');
        // Refresh project data
        setBuildingInfo(result.attributes);
        setBuildingMeters(result.attributes.energyStarMeters || []);
        setOriginalInfo(result.attributes);
      } catch (error) {
        console.error('Failed to sync with Energy Star:', error);
        setSyncError(error instanceof Error ? error.message : 'Failed to sync with Energy Star');
        setShowSyncFeedbackType('error');
      }
    };

    return (
      <div ref={ref} className="mx-4 flex h-full animate-fade-up-in flex-col overflow-scroll pt-4">
        {/* View Mode */}
        <div className="h-full">
          <Collapsible expanded={!isEditing}>
            <div className="h-full">
              <div className="grid h-full grid-cols-1 gap-4 lg:grid-cols-12">
                <div className="flex h-full flex-col gap-4 lg:col-span-7">
                  {/* Building Profile Card */}
                  <Card className="min-h-[550px] transform rounded-xl border-2 border-gray-500 bg-gradient-to-br from-background/80 to-background/40 p-1 shadow-xl transition-all duration-200 hover:shadow-2xl">
                    <CardHeader className="flex flex-row items-center justify-between pb-2 pt-4">
                      <div className="flex flex-col gap-1">
                        <CardTitle className="flex items-center gap-2 text-lg font-semibold">
                          <HomeIcon className="h-4 w-4 text-primary" />
                          Building Profile
                        </CardTitle>
                        <p className="text-sm text-muted-foreground">{project?.attributes?.name || 'Not specified'}</p>
                      </div>
                      <div className="flex gap-2">
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Button
                              size="sm"
                              variant="outline"
                              className="transition-all duration-200 hover:scale-105"
                              onClick={handleSync}
                              disabled={!project.attributes.energyStarId}
                            >
                              <RefreshCwIcon className="mr-1 h-4 w-4" />
                              Sync with Energy Star
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent>
                            {project.attributes.energyStarId
                              ? 'Sync building data with Energy Star'
                              : 'No Energy Star ID available'}
                          </TooltipContent>
                        </Tooltip>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Button
                              size="sm"
                              variant={isEditing ? 'destructive' : 'default'}
                              className="transition-all duration-200 hover:scale-105"
                              onClick={handleEdit}
                            >
                              {isEditing ? <XIcon className="h-4 w-4" /> : <PencilIcon className="h-4 w-4" />}
                              {isEditing ? 'Cancel' : 'Edit'}
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent>{isEditing ? 'Cancel Editing' : 'Edit Building Info'}</TooltipContent>
                        </Tooltip>
                      </div>
                    </CardHeader>
                    <CardContent className="grid gap-4">
                      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                        <div className="rounded-lg bg-gradient-to-br from-background/40 to-transparent p-4 shadow-sm">
                          <h3 className="mb-4 flex items-center gap-2 text-base font-medium text-foreground/90">
                            <MapPinIcon className="h-4 w-4 text-primary/90" />
                            Location
                          </h3>
                          <div className="grid gap-2 text-muted-foreground">
                            <p className="text-foreground">{buildingInfo.address}</p>
                            <p>{`${buildingInfo.city}, ${buildingInfo.state} ${buildingInfo.zipCode}`}</p>
                            {buildingInfo.parentProject && (
                              <div className="mt-2 flex items-center gap-2">
                                <Building2Icon className="h-4 w-4 text-primary/70" />
                                <Button
                                  variant="link"
                                  className="h-auto p-0 text-primary hover:text-primary/80"
                                  onClick={() =>
                                    (window.location.href = `/dashboard/project/${buildingInfo.parentProject}/building-info`)
                                  }
                                >
                                  View Parent Building
                                </Button>
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="rounded-lg bg-gradient-to-br from-background/40 to-transparent p-4 shadow-sm">
                          <h3 className="mb-4 flex items-center gap-2 text-base font-medium text-foreground/90">
                            <SearchIcon className="h-4 w-4 text-primary/90" />
                            Identifiers
                          </h3>
                          <div className="grid gap-2">
                            <div className="grid grid-cols-2 gap-4">
                              <div>
                                <p className="text-sm text-muted-foreground">BBL Numbers</p>
                                {buildingInfo?.propertyIdentifiers?.BBL ? (
                                  <div className="mt-1 grid gap-1">
                                    {Array.isArray(buildingInfo.propertyIdentifiers.BBL) ? (
                                      buildingInfo.propertyIdentifiers.BBL.map((bbl, index) => (
                                        <p key={`bbl-${index}`} className="text-base font-medium">
                                          {bbl}
                                        </p>
                                      ))
                                    ) : (
                                      <p className="text-base font-medium">{buildingInfo.propertyIdentifiers.BBL}</p>
                                    )}
                                  </div>
                                ) : (
                                  <p className="text-base font-medium">Not specified</p>
                                )}
                              </div>
                              <div>
                                <p className="text-sm text-muted-foreground">BIN Numbers</p>
                                {buildingInfo?.propertyIdentifiers?.BIN ? (
                                  <div className="mt-1 grid gap-1">
                                    {Array.isArray(buildingInfo.propertyIdentifiers.BIN) ? (
                                      buildingInfo.propertyIdentifiers.BIN.map((bin, index) => (
                                        <p key={`bin-${index}`} className="text-base font-medium">
                                          {bin}
                                        </p>
                                      ))
                                    ) : (
                                      <p className="text-base font-medium">{buildingInfo.propertyIdentifiers.BIN}</p>
                                    )}
                                  </div>
                                ) : (
                                  <p className="text-base font-medium">Not specified</p>
                                )}
                              </div>
                            </div>
                            <div>
                              <p className="text-sm text-muted-foreground">Energy Star ID</p>
                              <p className="mt-1 text-base font-medium">
                                {project.attributes.energyStarId || 'Not specified'}
                              </p>
                            </div>
                            {Object.entries(buildingInfo.propertyIdentifiers)
                              .filter(([key]) => !['BBL', 'BIN'].includes(key))
                              .map(([key, values]) => (
                                <div key={key}>
                                  <p className="text-sm text-muted-foreground">{key}</p>
                                  {values ? (
                                    <div className="mt-1 grid gap-1">
                                      {Array.isArray(values) ? (
                                        values.map((value, index) => (
                                          <p key={`${key}-${index}`} className="text-base font-medium">
                                            {value}
                                          </p>
                                        ))
                                      ) : (
                                        <p className="text-base font-medium">{values}</p>
                                      )}
                                    </div>
                                  ) : (
                                    <p className="text-base font-medium">Not specified</p>
                                  )}
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>

                      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                        <div className="rounded-lg bg-gradient-to-br from-background/40 to-transparent p-4 shadow-sm">
                          <h3 className="mb-4 flex items-center gap-2 text-base font-medium text-foreground/90">
                            <Building2Icon className="h-4 w-4 text-primary/90" />
                            Details
                          </h3>
                          <div className="grid gap-3">
                            <div className="overflow-hidden rounded-lg border border-border/20">
                              <table className="w-full border-separate border-spacing-0">
                                <tbody>
                                  <tr className="transition-colors hover:bg-background/10">
                                    <td className="border-b border-border/10 p-3 text-sm">
                                      <div className="flex items-center gap-2">
                                        <BriefcaseBusinessIcon className="h-4 w-4 text-primary/70" />
                                        <span className="font-medium text-foreground/90">Property Owner</span>
                                      </div>
                                    </td>
                                    <td className="border-b border-border/10 p-3 text-right text-sm text-muted-foreground">
                                      {buildingInfo.buildingRepresentation?.owner || 'Not specified'}
                                    </td>
                                  </tr>
                                  <tr className="transition-colors hover:bg-background/10">
                                    <td className="border-b border-border/10 p-3 text-sm">
                                      <div className="flex items-center gap-2">
                                        <UserIcon className="h-4 w-4 text-primary/70" />
                                        <span className="font-medium text-foreground/90">Property Representative</span>
                                      </div>
                                    </td>
                                    <td className="border-b border-border/10 p-3 text-right text-sm text-muted-foreground">
                                      {buildingInfo.buildingRepresentation?.representative || 'Not specified'}
                                    </td>
                                  </tr>
                                  <tr className="transition-colors hover:bg-background/10">
                                    <td className="border-b border-border/10 p-3 text-sm">
                                      <div className="flex items-center gap-2">
                                        <CalendarIcon className="h-4 w-4 text-primary/70" />
                                        <span className="font-medium text-foreground/90">Year Built</span>
                                      </div>
                                    </td>
                                    <td className="border-b border-border/10 p-3 text-right text-sm text-muted-foreground">
                                      {buildingInfo.yearBuilt || 'Not specified'}
                                    </td>
                                  </tr>
                                  <tr className="transition-colors hover:bg-background/10">
                                    <td className="border-b border-border/10 p-3 text-sm">
                                      <div className="flex items-center gap-2">
                                        <Building2Icon className="h-4 w-4 text-primary/70" />
                                        <span className="font-medium text-foreground/90">Building Count</span>
                                      </div>
                                    </td>
                                    <td className="border-b border-border/10 p-3 text-right text-sm text-muted-foreground">
                                      {buildingInfo.complianceRequirements.buildingCount || '1'}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>

                        <div className="rounded-lg bg-gradient-to-br from-background/40 to-transparent p-4 shadow-sm">
                          <h3 className="mb-4 flex items-center gap-2 text-base font-medium text-foreground/90">
                            <BriefcaseBusinessIcon className="h-4 w-4 text-primary/90" />
                            Client Services
                          </h3>
                          <div className="grid gap-3">
                            <div className="overflow-hidden rounded-lg border border-border/30">
                              <table className="w-full border-separate border-spacing-0">
                                <tbody>
                                  {buildingInfo.buildingRepresentation?.services?.length > 0 ? (
                                    buildingInfo.buildingRepresentation.services.map((service) => {
                                      const serviceInfo = (() => {
                                        switch (service) {
                                          case 'll84':
                                            return {
                                              icon: ClipboardCheckIcon,
                                              name: 'LL84',
                                              description: 'Energy and Water Benchmarking',
                                            };
                                          case 'll88':
                                            return {
                                              icon: FileCheckIcon,
                                              name: 'LL88',
                                              description: 'Energy Audits & Retro-commissioning',
                                            };
                                          case 'll97':
                                            return {
                                              icon: BarChart3Icon,
                                              name: 'LL97',
                                              description: 'Building Emissions Limits',
                                            };
                                          case 'setup-espm':
                                            return {
                                              icon: MonitorIcon,
                                              name: 'Setup ESPM',
                                              description: 'Energy Star Portfolio Manager Setup',
                                            };
                                          case 'setup-bms':
                                            return {
                                              icon: ServerIcon,
                                              name: 'Setup BMS',
                                              description: 'Building Management System Setup',
                                            };
                                          case 'setup-rtem':
                                            return {
                                              icon: NetworkIcon,
                                              name: 'Setup RTEM',
                                              description: 'Real-Time Energy Management Setup',
                                            };
                                          default:
                                            return {
                                              icon: BriefcaseBusinessIcon,
                                              name: service,
                                              description: '',
                                            };
                                        }
                                      })();
                                      const Icon = serviceInfo.icon;

                                      return (
                                        <tr key={service} className="hover:bg-background/5">
                                          <td className="border-b border-border/30 p-2 text-sm">
                                            <div className="flex items-center gap-2">
                                              <Icon className="h-4 w-4 text-primary/70" />
                                              <span className="font-medium">{serviceInfo.name}</span>
                                            </div>
                                          </td>
                                          <td className="border-b border-border/30 p-2 text-right text-sm text-muted-foreground">
                                            {serviceInfo.description}
                                          </td>
                                        </tr>
                                      );
                                    })
                                  ) : (
                                    <tr>
                                      <td colSpan={2} className="p-2 text-center text-sm text-muted-foreground">
                                        No client services specified
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </CardContent>
                  </Card>

                  <ChildProperties childProperties={buildingInfo.childProperties} />

                  {/* Building Characteristics Card */}
                  <BuildingCharacteristics buildingInfo={buildingInfo} />
                </div>

                <div className="flex h-full flex-col gap-4 lg:col-span-5">
                  {/* Compliance Year Card */}
                  <ComplianceYear buildingInfo={buildingInfo} meterReadings={meterReadings} />

                  {/* Connected Meters Card */}
                  <Card className="transform rounded-xl border-2 border-gray-500 bg-gradient-to-br from-background/80 to-background/40 p-1 shadow-xl transition-all duration-200 hover:shadow-2xl">
                    <CardHeader className="flex flex-row items-center justify-between pb-4 pt-6">
                      <CardTitle className="flex items-center gap-2 text-lg font-semibold text-foreground/90">
                        <GaugeIcon className="h-5 w-5 text-primary/90" />
                        Connected Meters
                      </CardTitle>
                    </CardHeader>
                    <CardContent>
                      {buildingMeters?.length > 0 ? (
                        <div className="grid grid-cols-2 gap-4">
                          {/* Electric Meters */}
                          {(buildingMeters || []).some((meter) => meter.type.toLowerCase() === 'electric') && (
                            <div className="rounded-md bg-background/5 p-3">
                              <h4 className="mb-2 flex items-center gap-2 text-xs font-medium text-muted-foreground">
                                <BoltIcon className="h-3.5 w-3.5 text-yellow-400" />
                                Electric Meters
                              </h4>
                              <div className="grid gap-1.5">
                                {(project.attributes.energyStarMeters || [])
                                  .filter((meter) => meter.type.toLowerCase() === 'electric')
                                  .map((meter) => (
                                    <div
                                      key={meter._id}
                                      className="flex items-center justify-between gap-2 rounded-sm bg-background/5 px-2 py-1"
                                    >
                                      <span className="text-xs font-medium text-muted-foreground">{meter.name}</span>
                                      <span
                                        className={cn('text-xs', {
                                          'text-green-500': meter.inUse,
                                          'text-muted-foreground/70': !meter.inUse,
                                        })}
                                      >
                                        {meter.inUse ? 'Active' : 'Inactive'}
                                      </span>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          )}

                          {/* Gas Meters */}
                          {(project.attributes.energyStarMeters || []).some(
                            (meter) => meter.type.toLowerCase() === 'gas' || meter.type === 'Natural Gas'
                          ) && (
                            <div className="rounded-md bg-background/5 p-3">
                              <h4 className="mb-2 flex items-center gap-2 text-xs font-medium text-muted-foreground">
                                <FlameIcon className="h-3.5 w-3.5 text-orange-400" />
                                Gas Meters
                              </h4>
                              <div className="grid gap-1.5">
                                {(project.attributes.energyStarMeters || [])
                                  .filter((meter) => meter.type.toLowerCase() === 'gas' || meter.type === 'Natural Gas')
                                  .map((meter) => (
                                    <div
                                      key={meter._id}
                                      className="flex items-center justify-between gap-2 rounded-sm bg-background/5 px-2 py-1"
                                    >
                                      <span className="text-xs font-medium text-muted-foreground">{meter.name}</span>
                                      <span
                                        className={cn('text-xs', {
                                          'text-green-500': meter.inUse,
                                          'text-muted-foreground/70': !meter.inUse,
                                        })}
                                      >
                                        {meter.inUse ? 'Active' : 'Inactive'}
                                      </span>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          )}

                          {/* Steam Meters */}
                          {(project.attributes.energyStarMeters || []).some(
                            (meter) => meter.type.toLowerCase() === 'steam' || meter.type === 'District Steam'
                          ) && (
                            <div className="rounded-md bg-background/5 p-3">
                              <h4 className="mb-2 flex items-center gap-2 text-xs font-medium text-muted-foreground">
                                <CloudFogIcon className="h-3.5 w-3.5 text-blue-400" />
                                Steam Meters
                              </h4>
                              <div className="grid gap-1.5">
                                {(project.attributes.energyStarMeters || [])
                                  .filter(
                                    (meter) => meter.type.toLowerCase() === 'steam' || meter.type === 'District Steam'
                                  )
                                  .map((meter) => (
                                    <div
                                      key={meter._id}
                                      className="flex items-center justify-between gap-2 rounded-sm bg-background/5 px-2 py-1"
                                    >
                                      <span className="text-xs font-medium text-muted-foreground">{meter.name}</span>
                                      <span
                                        className={cn('text-xs', {
                                          'text-green-500': meter.inUse,
                                          'text-muted-foreground/70': !meter.inUse,
                                        })}
                                      >
                                        {meter.inUse ? 'Active' : 'Inactive'}
                                      </span>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          )}

                          {/* Water Meters */}
                          {(project.attributes.energyStarMeters || []).some(
                            (meter) =>
                              meter.type.toLowerCase() === 'water' ||
                              meter.type === 'Municipally Supplied Potable Water - Indoor' ||
                              meter.type.toLowerCase().includes('water')
                          ) && (
                            <div className="rounded-md bg-background/5 p-3">
                              <h4 className="mb-2 flex items-center gap-2 text-xs font-medium text-muted-foreground">
                                <DropletIcon className="h-3.5 w-3.5 text-blue-400" />
                                Water Meters
                              </h4>
                              <div className="grid gap-1.5">
                                {(project.attributes.energyStarMeters || [])
                                  .filter(
                                    (meter) =>
                                      meter.type.toLowerCase().includes('water') ||
                                      meter.type === 'Municipally Supplied Potable Water - Indoor'
                                  )
                                  .map((meter) => (
                                    <div
                                      key={meter._id}
                                      className="flex items-center justify-between gap-2 rounded-sm bg-background/5 px-2 py-1"
                                    >
                                      <span className="text-xs font-medium text-muted-foreground">{meter.name}</span>
                                      <span
                                        className={cn('text-xs', {
                                          'text-green-500': meter.inUse,
                                          'text-muted-foreground/70': !meter.inUse,
                                        })}
                                      >
                                        {meter.inUse ? 'Active' : 'Inactive'}
                                      </span>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          )}

                          {/* Oil Meters */}
                          {(project.attributes.energyStarMeters || []).some((meter) =>
                            meter.type.toLowerCase().includes('oil')
                          ) && (
                            <div className="rounded-md bg-background/5 p-3">
                              <h4 className="mb-2 flex items-center gap-2 text-xs font-medium text-muted-foreground">
                                <FuelIcon className="h-3.5 w-3.5 text-green-400" />
                                Oil Meters
                              </h4>
                              <div className="grid gap-1.5">
                                {(project.attributes.energyStarMeters || [])
                                  .filter((meter) => meter.type.toLowerCase().includes('oil'))
                                  .map((meter) => (
                                    <div
                                      key={meter._id}
                                      className="flex items-center justify-between gap-2 rounded-sm bg-background/5 px-2 py-1"
                                    >
                                      <span className="text-xs font-medium text-muted-foreground">{meter.name}</span>
                                      <span
                                        className={cn('text-xs', {
                                          'text-green-500': meter.inUse,
                                          'text-muted-foreground/70': !meter.inUse,
                                        })}
                                      >
                                        {meter.inUse ? 'Active' : 'Inactive'}
                                      </span>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          )}
                        </div>
                      ) : (
                        <p className="text-muted-foreground">No meters connected</p>
                      )}
                    </CardContent>
                  </Card>

                  {/* Notes Card */}
                  {buildingInfo.notes && (
                    <Card className="transform rounded-xl border-2 border-gray-500 bg-gradient-to-br from-background/80 to-background/40 p-1 shadow-xl transition-all duration-200 hover:shadow-2xl">
                      <CardHeader className="flex flex-row items-center justify-between pb-2 pt-4">
                        <CardTitle className="flex items-center gap-2 text-lg font-semibold">
                          <PencilIcon className="h-4 w-4 text-primary" />
                          Notes
                        </CardTitle>
                      </CardHeader>
                      <CardContent>
                        <div className="rounded-md bg-background/5 p-3">
                          <p className="whitespace-pre-wrap text-muted-foreground">{buildingInfo.notes}</p>
                        </div>
                      </CardContent>
                    </Card>
                  )}
                </div>
              </div>
            </div>
          </Collapsible>

          {/* Edit Mode */}
          <BuildingEditSheet
            isEditing={isEditing}
            buildingInfo={buildingInfo}
            project={project}
            steps={buildingInfoSteps}
            activeStep={activeStep}
            hasChanges={hasChanges}
            errors={errors}
            onEdit={handleEdit}
            onSubmit={handleSubmit}
            onInputChange={handleInputChange}
            setBuildingInfo={setBuildingInfo}
            setActiveStep={setActiveStep}
          />

          {/* Sync Feedback Dialog */}
          <Dialog open={showSyncFeedbackType !== null} onOpenChange={() => setShowSyncFeedbackType(null)}>
            <DialogContent className="sm:max-w-md">
              <div className="flex flex-col items-center justify-center gap-4 p-4 text-center">
                {showSyncFeedbackType === 'success' ? (
                  <>
                    <div className="rounded-full bg-green-500/20 p-3">
                      <RefreshCwIcon className="h-6 w-6 text-green-500" />
                    </div>
                    <div className="space-y-2">
                      <h3 className="text-lg font-medium">Sync Successful</h3>
                      <p className="text-sm text-muted-foreground">
                        Building data has been successfully synchronized with Energy Star.
                      </p>
                    </div>
                  </>
                ) : showSyncFeedbackType === 'error' ? (
                  <>
                    <div className="rounded-full bg-destructive/20 p-3">
                      <XIcon className="h-6 w-6 text-destructive" />
                    </div>
                    <div className="space-y-2">
                      <h3 className="text-lg font-medium">Sync Failed</h3>
                      <p className="text-sm text-destructive">
                        {syncError || 'Failed to sync with Energy Star. Please try again.'}
                      </p>
                    </div>
                  </>
                ) : null}
              </div>
            </DialogContent>
          </Dialog>
        </div>
      </div>
    );
  }
);
