import { VariantProps } from 'class-variance-authority';
import { DownloadIcon, EllipsisVerticalIcon, LoaderCircleIcon, TrashIcon, EditIcon } from 'lucide-react';
import { forwardRef, useCallback, useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  Badge,
  Button,
  ScrollArea,
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  toastVariants,
} from '@shadcn/ui';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';

import reportServices from 'app/services/report-services';
import { extractError } from 'app/utils/appHelpers';
import { NewReportButton } from '../components/NewReportButton';

type ReportOutputType = 'xlsx' | 'pdf';

type Report = {
  id: string;
  attributes: {
    name: string;
    status: string;
    createdAt: string;
    outputType: ReportOutputType;
  };
};

const getBadgeVariant = (status: string) => {
  switch (status.toLowerCase()) {
    case 'completed':
      return 'positive';
    case 'in progress':
      return 'warning';
    case 'failed':
      return 'destructive';
    case 'draft':
      return 'outline';
    default:
      return 'default';
  }
};

interface ReportsProps {
  projectId: string;
  onSnackbar: (message: string, variant: VariantProps<typeof toastVariants>['variant']) => void;
}

const ReportCard = ({
  report,
  onDownload,
  onDelete,
  onEdit,
  isDeleting,
}: {
  report: Report;
  onDownload: (report: Report) => void;
  onDelete: (report: Report) => void;
  onEdit: (report: Report) => void;
  isDeleting: boolean;
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <Card className="relative overflow-hidden">
      <CardContent className="flex items-center justify-between p-4">
        <div className="flex items-center gap-4">
          <div className="flex flex-col">
            <h3 className="font-medium">{report.attributes.name}</h3>
            <p className="text-sm text-muted-foreground">
              Created {format(new Date(report.attributes.createdAt), 'MMM d, yyyy')}
            </p>
          </div>
        </div>
        <div className="flex items-center gap-2">
          <Badge variant={getBadgeVariant(report.attributes.status)}>{report.attributes.status.toUpperCase()}</Badge>
          <DropdownMenu open={isMenuOpen} onOpenChange={setIsMenuOpen}>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" size="icon" disabled={isDeleting}>
                <EllipsisVerticalIcon className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {report.attributes.status === 'draft' ? (
                <DropdownMenuItem onClick={() => onEdit(report)}>
                  <EditIcon className="mr-2 h-4 w-4" />
                  Edit Draft
                </DropdownMenuItem>
              ) : (
                <DropdownMenuItem onClick={() => onDownload(report)}>
                  <DownloadIcon className="mr-2 h-4 w-4" />
                  Download
                </DropdownMenuItem>
              )}
              <DropdownMenuItem className="text-destructive" onClick={() => onDelete(report)} disabled={isDeleting}>
                <TrashIcon className="mr-2 h-4 w-4" />
                {isDeleting ? 'Deleting...' : 'Delete'}
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </CardContent>
    </Card>
  );
};

export const Exports = forwardRef<HTMLDivElement, ReportsProps>(({ projectId, onSnackbar }, ref) => {
  const navigate = useNavigate();
  const [reports, setReports] = useState<Report[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openNewReportDialog, setOpenNewReportDialog] = useState(false);
  const [selectedReport, setSelectedReport] = useState<Report | null>(null);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [selectedReportType, setSelectedReportType] = useState<ReportOutputType | null>(null);

  const fetchReports = useCallback(async () => {
    try {
      return await reportServices.getByProjectId(projectId);
    } catch (error) {
      onSnackbar('An unexpected error occurred while fetching the reports', 'destructive');
    }
  }, [projectId]);

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    const fetchData = async () => {
      try {
        const fetchedReports = await fetchReports();

        if (isMounted) {
          setReports(fetchedReports);
          setIsLoading(false);
        }
      } catch (error) {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [fetchReports, projectId, refreshTrigger]);

  const handleCloseMenu = () => {
    setSelectedReport(null);
  };

  const downloadFile = async (url, fileName) => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = fileName;

      link.click();
      window.URL.revokeObjectURL(blobUrl);
    } catch (error: unknown) {
      if (error instanceof Error) {
        onSnackbar(error.message || 'Failed to download report', 'destructive');
      } else {
        onSnackbar('Failed to download report', 'destructive');
      }
    }
  };

  const handleDownload = async (report: Report) => {
    const { id: reportId } = report;
    try {
      const downloadUrl = await reportServices.getDownloadLink(reportId);
      downloadFile(downloadUrl, reportId);
      handleCloseMenu();
    } catch (e) {
      onSnackbar('Failed to downlaod report.', 'destructive');
    }
  };

  const handleDeleteReport = async (report: Report) => {
    setIsDeleting(true);
    try {
      await reportServices.delete(report.id);
      onSnackbar('Report deleted successfully', 'positive');
      setRefreshTrigger((prev) => prev + 1);
    } catch (error) {
      onSnackbar(extractError(error) || 'Failed to delete the report', 'destructive');
    } finally {
      setIsDeleting(false);
      setSelectedReport(null);
    }
  };

  const handleEditDraft = (report: Report) => {
    navigate(`/dashboard/project/${projectId}/report-builder?draftId=${report.id}`);
  };

  if (isLoading) {
    return (
      <div className="flex h-full grow items-center justify-center">
        <LoaderCircleIcon className="animate-spin" />
      </div>
    );
  }

  return (
    <div ref={ref} className="flex grow animate-fade-up-in flex-col overflow-hidden px-4 pt-6">
      <div className="mb-6 flex items-center justify-between">
        <h2 className="text-2xl font-bold">Reports</h2>
        <NewReportButton
          projectId={projectId}
          outputType="pdf"
          isDeleting={isDeleting}
          setRefreshTrigger={setRefreshTrigger}
          selectedReportType={selectedReportType}
          setSelectedReportType={setSelectedReportType}
          openNewReportDialog={openNewReportDialog}
          setOpenNewReportDialog={setOpenNewReportDialog}
        />
      </div>
      <ScrollArea className="flex-1">
        <div className="grid gap-4 pb-36">
          {reports.map((report) => (
            <ReportCard
              key={report.id}
              report={report}
              onDownload={handleDownload}
              onDelete={handleDeleteReport}
              onEdit={handleEditDraft}
              isDeleting={isDeleting}
            />
          ))}
        </div>
      </ScrollArea>
    </div>
  );
});
