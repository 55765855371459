import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { useState, ReactNode } from 'react';
import { Loader2 } from 'lucide-react';

interface AppLayout {
  sidebarOpen: boolean;
  setSidebarOpen: Dispatch<SetStateAction<boolean>>;
  toggleSidebar: () => void;
}

export const AppLayoutContext = createContext<AppLayout>({
  sidebarOpen: true,
  setSidebarOpen: () => {
    throw new Error('Not implemented');
  },
  toggleSidebar: () => {
    throw new Error('Not implemented');
  },
});

export const AppLayoutProvider = ({ children }: { children: ReactNode }) => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const toggleSidebar = () => setSidebarOpen(!sidebarOpen);

  return (
    <AppLayoutContext.Provider value={{ sidebarOpen, setSidebarOpen, toggleSidebar }}>
      {isLoading ? <Loader2 className="m-auto h-[10vmin] w-[10vmin] animate-spin" /> : children}
    </AppLayoutContext.Provider>
  );
};

export const useAppLayout = () => useContext(AppLayoutContext);
