import {
  HomeIcon,
  Building2Icon,
  MapPinIcon,
  CalendarIcon,
  PencilIcon,
  PersonStandingIcon,
  ChartNoAxesColumn,
  HotelIcon,
  ActivityIcon,
  BriefcaseBusinessIcon,
  UserIcon,
} from 'lucide-react';
import { ClientService } from 'app/services/project-services';

// Core types that represent the fundamental data model
export type PropertyUse = {
  propertyUse: string;
  totalGrossFloorArea: number;
};

// TEMPORARY: Alias for backward compatibility during migration
// TODO: Remove this after migrating all components to use BuildingInfo
export type BasicBuildingInfoData = BuildingInfo;
export type ExtendedBuildingInfoData = BuildingInfo;

// Type for storing building system data
export type BuildingSystemData = {
  systemId: string;
  specifications: Record<string, any>;
};

// Type for system definition/template
export type BuildingSystemDefinition = {
  id: string;
  name: string;
  components: Array<{
    id: string;
    name: string;
  }>;
};

export type BuildingIdentifiers = {
  BBL: string[];
  BIN: string[];
  [key: string]: string[];
};

export type ComplianceRequirements = {
  ll84: {
    required: boolean;
    requiredToReportWater: boolean;
  };
  ll97: {
    required: boolean;
    reportType: string;
  };
  buildingCount: number;
};

// Base building information - core properties that every building must have
export type BaseBuildingInfo = {
  name: string;
  squareFeet: string;
  yearBuilt: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  numberOfFloors?: string;
  buildingRepresentation: {
    owner: string;
    representative: string;
    services: ClientService[];
  };
};

// Building services and systems information
export type BuildingServicesInfo = {
  services: string[];
  buildingServices: {
    hasSolar: boolean;
    hasBMS: boolean;
    bmsVendor: string;
  };
  buildingSystems?: {
    hvac?: BuildingSystemData[];
    lighting?: BuildingSystemData[];
    plug_load?: BuildingSystemData[];
    electric?: BuildingSystemData[];
    gas?: BuildingSystemData[];
    oil?: BuildingSystemData[];
    steam?: BuildingSystemData[];
  };
};

// Occupancy-related information
export type BuildingOccupancyInfo = {
  weekdayStartTime?: string;
  weekdayEndTime?: string;
  weekendStartTime?: string;
  weekendEndTime?: string;
  occupancyPattern?: string;
  peakOccupancy?: string;
  averageOccupancy?: string;
  closedOnWeekends?: boolean;
};

// Property use and type information
export type BuildingPropertyInfo = {
  propertyIdentifiers: BuildingIdentifiers;
  propertyUses: PropertyUse[];
  parentProject?: string;
  childProperties?: Array<{
    name: string;
    energyStarId: string;
  }>;
};

// Complete building information type that combines all aspects
export type BuildingInfo = BaseBuildingInfo &
  BuildingServicesInfo &
  BuildingOccupancyInfo &
  BuildingPropertyInfo & {
    complianceRequirements: ComplianceRequirements;
    notes?: string;
    occupancy?: BuildingOccupancyInfo;
  };

// Initial state factory function
export const createInitialBuildingInfo = (): BuildingInfo => ({
  // Base info
  name: '',
  squareFeet: '',
  yearBuilt: '',
  address: '',
  city: '',
  state: '',
  zipCode: '',
  numberOfFloors: '',
  buildingRepresentation: {
    owner: '',
    representative: '',
    services: [],
  },

  // Services info
  services: [],
  buildingServices: {
    hasSolar: false,
    hasBMS: false,
    bmsVendor: '',
  },
  buildingSystems: {
    hvac: [],
    lighting: [],
    plug_load: [],
  },

  // Occupancy info
  occupancy: {
    weekdayStartTime: '',
    weekdayEndTime: '',
    weekendStartTime: '',
    weekendEndTime: '',
    occupancyPattern: 'daily',
    peakOccupancy: '',
    averageOccupancy: '',
    closedOnWeekends: false,
  },

  // Property info
  propertyUses: [],
  childProperties: [],

  // Additional info
  propertyIdentifiers: { BBL: [], BIN: [] },
  complianceRequirements: {
    ll84: { required: false, requiredToReportWater: false },
    ll97: { required: false, reportType: '' },
    buildingCount: 0,
  },
  notes: '',
});

type BuildingInfoField = {
  name: string;
  label: string;
  icon: any;
  validation: (value: string) => string;
  type?: string;
  maxLength?: number;
  pattern?: string;
  transform?: (value: string) => string;
  fullWidth?: boolean;
  isSelect?: boolean;
  dependsOn?: Record<string, any>;
};

// Field definitions with labels, icons, and validation rules
export const buildingInfoFields: BuildingInfoField[] = [
  {
    name: 'name',
    label: 'Name',
    icon: Building2Icon,
    validation: (value: string) => (value ? '' : 'Name is required'),
  },
  {
    name: 'buildingRepresentation.owner',
    label: 'Property Owner',
    icon: BriefcaseBusinessIcon,
    validation: (value: string) => (value ? '' : 'Building owner is required'),
  },
  {
    name: 'buildingRepresentation.representative',
    label: 'Property Representative',
    icon: UserIcon,
    validation: (value: string) => (value ? '' : 'Building representative is required'),
  },
  {
    name: 'address',
    label: 'Address',
    icon: Building2Icon,
    validation: (value: string) => (value ? '' : 'Address is required'),
  },
  {
    name: 'city',
    label: 'City',
    icon: Building2Icon,
    validation: (value: string) => (value ? '' : 'City is required'),
  },
  {
    name: 'state',
    label: 'State',
    icon: MapPinIcon,
    validation: (value: string) => (value.length === 2 ? '' : 'State must be exactly 2 characters'),
    maxLength: 2,
    transform: (value: string) => value.toUpperCase(),
  },
  {
    name: 'zipCode',
    label: 'Zip Code',
    icon: MapPinIcon,
    validation: (value: string) => (/^\d{5}$/.test(value) ? '' : 'Zip code must be exactly 5 numbers'),
    maxLength: 5,
    pattern: '\\d*',
    transform: (value: string) => value.replace(/\D/g, '').slice(0, 5),
  },
  {
    name: 'yearBuilt',
    label: 'Year Built',
    icon: CalendarIcon,
    validation: (value: string) => (/^\d{4}$/.test(value) ? '' : 'Year built must be exactly 4 numbers'),
    maxLength: 4,
    pattern: '\\d*',
  },
  {
    name: 'numberOfFloors',
    label: 'Number of Floors',
    icon: Building2Icon,
    type: 'number',
    validation: () => '', // Optional field
  },
];

// Building services field definitions
export const buildingServicesFields: BuildingInfoField[] = [
  {
    name: 'hasSolar',
    label: 'Has Solar Installation',
    icon: Building2Icon,
    type: 'boolean',
    validation: () => '', // Optional field
  },
  {
    name: 'hasBMS',
    label: 'Has Building Management System (BMS)',
    icon: Building2Icon,
    type: 'boolean',
    validation: () => '', // Optional field
  },
  {
    name: 'bmsVendor',
    label: 'BMS Vendor/Type',
    icon: Building2Icon,
    type: 'text',
    validation: () => '', // Optional field
    dependsOn: {
      hasBMS: true,
    },
  },
];

// Occupancy field definitions
export const occupancyInfoFields: BuildingInfoField[] = [
  {
    name: 'weekdayStartTime',
    label: 'Weekday Start Time',
    icon: CalendarIcon,
    type: 'time',
    validation: () => '', // Optional field
  },
  {
    name: 'weekdayEndTime',
    label: 'Weekday End Time',
    icon: CalendarIcon,
    type: 'time',
    validation: () => '', // Optional field
  },
  {
    name: 'weekendStartTime',
    label: 'Weekend Start Time',
    icon: CalendarIcon,
    type: 'time',
    validation: () => '', // Optional field
  },
  {
    name: 'weekendEndTime',
    label: 'Weekend End Time',
    icon: CalendarIcon,
    type: 'time',
    validation: () => '', // Optional field
  },
  {
    name: 'occupancyPattern',
    label: 'Occupancy Pattern',
    icon: Building2Icon,
    isSelect: true,
    validation: () => '', // Optional field
  },
  {
    name: 'peakOccupancy',
    label: 'Peak Occupancy (number of people)',
    icon: ChartNoAxesColumn,
    type: 'number',
    pattern: '\\d*',
    validation: () => '', // Optional field
  },
  {
    name: 'averageOccupancy',
    label: 'Average Occupancy (number of people)',
    icon: PersonStandingIcon,
    type: 'number',
    pattern: '\\d*',
    validation: () => '', // Optional field
  },
];

// Shared validation function
export const validateBuildingInfo = (info: Partial<BuildingInfo>) => {
  const errors: Partial<Record<keyof BuildingInfo, string>> = {};

  buildingInfoFields.forEach((field) => {
    const value = info[field.name as keyof BuildingInfo] || '';
    const error = field.validation(value.toString());
    if (error) {
      errors[field.name as keyof BuildingInfo] = error;
    }
  });

  // Validate occupancy fields
  if (occupancyInfoFields) {
    occupancyInfoFields.forEach((field) => {
      // Skip weekend time validation if closed on weekends
      if ((field.name === 'weekendStartTime' || field.name === 'weekendEndTime') && info.closedOnWeekends) {
        return;
      }

      const value = info[field.name as keyof BuildingInfo] || '';
      const error = field.validation(value.toString());
      if (error) {
        errors[field.name as keyof BuildingInfo] = error;
      }
    });
  }

  // Validate services separately
  if (info.services && info.services.length === 0) {
    errors.services = 'At least 1 service is required';
  }

  // Validate property uses
  if (info.propertyUses && info.propertyUses.length > 0) {
    // Check if any property use is incomplete
    const hasIncompletePropertyUse = info.propertyUses.some((use) => !use.propertyUse || use.totalGrossFloorArea <= 0);

    if (hasIncompletePropertyUse) {
      errors.propertyUses = 'All property uses must have a type and floor area greater than 0';
    }
  }

  // Validate building systems
  if (info.buildingSystems) {
    // Check each category of building systems
    Object.entries(info.buildingSystems).forEach(([category, systems]) => {
      if (!systems || systems.length === 0) return;

      // Check each system in the category
      const incompleteSystems = systems.filter((system) => {
        // For utility-based systems
        if (['electric', 'gas', 'oil', 'steam'].includes(category)) {
          return !system.systemId;
        }

        // For core building systems
        const coreSystem = CORE_BUILDING_SYSTEMS.find((cs) => cs.category === category);
        if (!coreSystem) return true;

        // Check required specifications
        const requiredSpecs = coreSystem.specifications.filter((spec) => spec.required);
        return requiredSpecs.some((spec) => {
          const value = system.specifications[spec.id];
          return value === undefined || value === '' || value === null;
        });
      });

      if (incompleteSystems.length > 0) {
        errors.buildingSystems = `Some ${category} systems are incomplete. Please fill in all required fields.`;
      }
    });
  }

  return errors;
};

// Update the building systems related types
type BuildingSystemType = {
  id: string;
  name: string;
  category: 'hvac' | 'lighting' | 'plug_load';
  specifications: BuildingSystemSpecification[];
};

export const BUILDING_SYSTEM_CATEGORIES = {
  hvac: 'HVAC & Ventilation',
  lighting: 'Lighting',
  plug_load: 'Plug Loads & Equipment',
} as const;

// Additional building information types
type DependencyValue = string | boolean | number;
type DependencyCondition = DependencyValue | ((value: any) => boolean);

export type BuildingSystemSpecification = {
  id: string;
  name: string;
  type: 'number' | 'text' | 'select' | 'boolean' | 'multiselect';
  options?: string[];
  unit?: string;
  required?: boolean;
  description?: string;
  dependsOn?: {
    [key: string]: DependencyCondition[];
  };
  min?: number;
  max?: number;
};

// Define core building systems that we want to collect information about
export const CORE_BUILDING_SYSTEMS: BuildingSystemType[] = [
  {
    id: 'ventilation',
    name: 'Ventilation',
    category: 'hvac',
    specifications: [
      {
        id: 'system_type',
        name: 'Primary Ventilation Type',
        type: 'select',
        options: ['Constant Volume', 'VAV', 'DOAS'],
        required: true,
        description: 'Select the main type of ventilation system used in the building',
      },
      {
        id: 'has_ahu',
        name: 'Has Air Handling Units',
        type: 'boolean',
        description: 'Does the system include air handling units?',
        dependsOn: {
          system_type: ['Constant Volume', 'VAV'],
        },
      },
      {
        id: 'ahu_count',
        name: 'Number of Air Handling Units',
        type: 'number',
        unit: 'units',
        description: 'How many air handling units are installed?',
        min: 1,
        max: 100,
        dependsOn: {
          system_type: ['Constant Volume', 'VAV'],
          has_ahu: ['true'],
        },
      },
      {
        id: 'economizer_type',
        name: 'Economizer Type',
        type: 'select',
        options: ['No Economizer', 'Dry-Bulb', 'Enthalpy'],
        description: 'What type of economizer is installed?',
        dependsOn: {
          system_type: ['Constant Volume', 'VAV'],
          has_ahu: ['true'],
        },
      },
      {
        id: 'has_erv',
        name: 'Has Energy Recovery',
        type: 'boolean',
        description: 'Is energy recovery ventilation (ERV) installed?',
      },
      {
        id: 'erv_type',
        name: 'Energy Recovery Type',
        type: 'select',
        options: ['Heat Wheel', 'Plate Heat Exchanger', 'Run-Around Loop'],
        description: 'What type of energy recovery is installed?',
        dependsOn: {
          has_erv: ['true'],
        },
      },
      {
        id: 'has_exhaust_fans',
        name: 'Has Exhaust Fans',
        type: 'boolean',
        description: 'Are there any exhaust fans installed?',
      },
      {
        id: 'exhaust_fan_count',
        name: 'Number of Exhaust Fans',
        type: 'number',
        unit: 'units',
        description: 'How many exhaust fans are installed?',
        min: 1,
        max: 100,
        dependsOn: {
          has_exhaust_fans: ['true'],
        },
      },
      {
        id: 'exhaust_fan_types',
        name: 'Exhaust Fan Types',
        type: 'multiselect',
        options: ['Bathroom Exhaust', 'Kitchen Exhaust', 'Parking Garage Exhaust', 'General Building Exhaust'],
        description: 'What types of exhaust fans are installed?',
        dependsOn: {
          has_exhaust_fans: ['true'],
        },
      },
      {
        id: 'has_vfd',
        name: 'Has Variable Frequency Drives (VFDs)',
        type: 'boolean',
        description: 'Are any VFDs installed on HVAC equipment?',
      },
      {
        id: 'vfd_applications',
        name: 'VFD Applications',
        type: 'multiselect',
        options: ['AHU Supply Fans', 'AHU Return Fans', 'Exhaust Fans', 'Pumps', 'Cooling Tower Fans'],
        description: 'Where are VFDs installed?',
        dependsOn: {
          has_vfd: ['true'],
        },
      },
    ],
  },
  {
    id: 'interior_lighting',
    name: 'Interior Lighting',
    category: 'lighting',
    specifications: [
      {
        id: 'primary_type',
        name: 'Primary Lighting Type',
        type: 'select',
        options: ['LED', 'Fluorescent', 'Mixed'],
        required: true,
      },
      {
        id: 'controls',
        name: 'Control Types',
        type: 'multiselect',
        options: ['Manual', 'Occupancy sensors', 'Daylight sensors', 'Schedule', 'Lighting control system'],
        required: true,
      },
    ],
  },
  {
    id: 'plug_loads',
    name: 'Plug Loads',
    category: 'plug_load',
    specifications: [
      {
        id: 'major_equipment',
        name: 'Major Equipment Types',
        type: 'multiselect',
        options: ['Data Center', 'Commercial Kitchen', 'Laboratory', 'Office Equipment'],
        description: 'Select all major energy-consuming equipment present',
      },
      {
        id: 'has_controls',
        name: 'Advanced Controls',
        type: 'boolean',
        description: 'Are plug loads controlled by schedules, occupancy, or power management systems?',
      },
    ],
  },
];

// Update the steps structure to better organize the flow
export const buildingInfoSteps = [
  {
    id: 'basic',
    label: 'Basic Info',
    icon: HomeIcon,
    fields: buildingInfoFields.map((field) => field.name),
    description: 'Enter basic building information like address and size',
  },
  {
    id: 'services',
    label: 'Utility Services',
    icon: Building2Icon,
    fields: ['services', ...buildingServicesFields.map((field) => field.name)],
    description: 'Select which utility services are available to the building',
  },
  {
    id: 'occupancy',
    label: 'Schedule & Occupancy',
    icon: CalendarIcon,
    fields: occupancyInfoFields.map((field) => field.name),
    description: 'Define when and how the building is typically occupied',
  },
  {
    id: 'property_use',
    label: 'Property Uses',
    icon: HotelIcon,
    fields: ['propertyUses'],
    description: 'Specify how the building space is used',
  },
  {
    id: 'systems_overview',
    label: 'Building Systems',
    icon: ActivityIcon,
    fields: ['buildingSystems'],
    description: 'Provide information about key building systems',
    categories: [
      {
        id: 'hvac',
        name: BUILDING_SYSTEM_CATEGORIES.hvac,
        description: 'Heating, cooling, and ventilation systems',
      },
      {
        id: 'lighting',
        name: BUILDING_SYSTEM_CATEGORIES.lighting,
        description: 'Interior and exterior lighting systems',
      },
      {
        id: 'plug_load',
        name: BUILDING_SYSTEM_CATEGORIES.plug_load,
        description: 'Major equipment and plug load management',
      },
    ],
  },
  {
    id: 'notes',
    label: 'Notes',
    icon: PencilIcon,
    fields: ['notes'],
  },
];

// Format input values based on field definitions
export const formatFieldValue = (name: string, value: string) => {
  const field = buildingInfoFields.find((f) => f.name === name);
  if (field && field.transform) {
    return field.transform(value);
  }
  return value;
};

type BuildingService = {
  id: string;
  name: string;
  systems: BuildingSystemDefinition[];
};

// Constants representing the building services structure
export const BUILDING_SERVICES: BuildingService[] = [
  {
    id: 'electric',
    name: 'Electric',
    systems: [
      {
        id: 'electric-heating',
        name: 'Heating',
        components: [
          { id: 'boiler-with-pumps', name: 'Boilers with Pumps' },
          { id: 'electric-duct-heaters', name: 'Electric Duct Heaters' },
          { id: 'heat-pump', name: 'Heat Pump' },
        ],
      },
      {
        id: 'electric-cooling',
        name: 'Cooling',
        components: [
          { id: 'air-source-heat-pump', name: 'Air Source Heat Pump' },
          { id: 'air-source-chiller', name: 'Air Source Chiller' },
          { id: 'water-cooled-chiller', name: 'Water Cooled Chiller' },
          { id: 'packaged-ac-unit', name: 'Packaged AC Unit' },
          { id: 'roof-top-unit', name: 'Roof Top Unit' },
          { id: 'water-source-heat-pump', name: 'Water Source Heat Pump' },
          { id: 'cooling-tower', name: 'Cooling Tower' },
        ],
      },
      {
        id: 'electric-dhw',
        name: 'Domestic Hot Water',
        components: [
          { id: 'hot-water-tank', name: 'Hot Water Tank' },
          { id: 'dhw-heat-pump', name: 'Domestic Hot Water Heat Pump' },
        ],
      },
    ],
  },
  {
    id: 'gas',
    name: 'Gas',
    systems: [
      {
        id: 'gas-heating',
        name: 'Heating',
        components: [
          { id: 'gas-boiler-steam', name: 'Boiler (Steam)' },
          { id: 'gas-boiler-hot-water', name: 'Boiler (Hot Water with Pump)' },
          { id: 'gas-unit-heater', name: 'Unit Heater' },
        ],
      },
      {
        id: 'gas-cooling',
        name: 'Cooling',
        components: [{ id: 'absorption-chiller', name: 'Absorption Chillers' }],
      },
      {
        id: 'gas-dhw',
        name: 'Domestic Hot Water',
        components: [{ id: 'gas-water-heater', name: 'Hot Water Heater' }],
      },
      {
        id: 'gas-cooking',
        name: 'Cooking',
        components: [{ id: 'gas-stove', name: 'Gas Stove' }],
      },
    ],
  },
  {
    id: 'oil',
    name: 'Oil',
    systems: [
      {
        id: 'oil-heating',
        name: 'Heating',
        components: [{ id: 'oil-boiler', name: 'Boiler' }],
      },
      {
        id: 'oil-dhw',
        name: 'Domestic Hot Water',
        components: [{ id: 'oil-water-heater', name: 'Water Heater' }],
      },
    ],
  },
  {
    id: 'steam',
    name: 'Steam',
    systems: [
      {
        id: 'steam-heating',
        name: 'Heating',
        components: [
          { id: 'steam-coils', name: 'Coils' },
          { id: 'radiator', name: 'Radiator' },
          { id: 'convectors', name: 'Convectors' },
        ],
      },
      {
        id: 'steam-cooling',
        name: 'Cooling',
        components: [
          { id: 'steam-absorption-chiller', name: 'Absorption Chiller' },
          { id: 'steam-turbine', name: 'Steam Turbine' },
        ],
      },
      {
        id: 'steam-dhw',
        name: 'Domestic Hot Water',
        components: [
          { id: 'storage-tank', name: 'Storage Tank' },
          { id: 'on-demand-water-heater', name: 'On-demand Water Heater' },
        ],
      },
    ],
  },
];
