import { forwardRef } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '@shadcn/ui';
import { BarChartIcon, AlertCircle, FileCheckIcon, TornadoIcon, InfoIcon } from 'lucide-react';
import { DropletIcon, ClipboardCheckIcon, CheckCircle2Icon } from 'lucide-react';
import { cn } from '@shadcn/utils';
import { type ExtendedBuildingInfoData } from 'app/utils/constants/buildingInfoStructure';
import { Documents } from 'app/types/visualizations';

export interface ComplianceYearProps {
  buildingInfo: ExtendedBuildingInfoData;
  meterReadings: Documents;
}

// Add this helper function at the top level after other imports
const getDaysUntilNextMayFirst = () => {
  const today = new Date();
  const currentYear = today.getFullYear();
  const nextDeadline = new Date(currentYear, 4, 1); // Month is 0-based, so 4 = May

  // If we've passed this year's deadline, use next year's
  if (today > nextDeadline) {
    nextDeadline.setFullYear(currentYear + 1);
  }

  const diffTime = nextDeadline.getTime() - today.getTime();
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

// Add this helper function after getDaysUntilNextMayFirst
const getPastYear = () => {
  const currentYear = new Date().getFullYear();
  const pastYear = currentYear - 1;
  return pastYear;
};

export const ComplianceYear = forwardRef<HTMLDivElement, ComplianceYearProps>(
  ({ buildingInfo, meterReadings }, ref) => {
    const daysRemaining = getDaysUntilNextMayFirst();
    const isUrgent = daysRemaining <= 30;
    const showCountdown =
      buildingInfo.complianceRequirements.ll84.required || buildingInfo.complianceRequirements.ll97.required;

    return (
      <Card
        ref={ref}
        className="min-h-[550px] transform rounded-xl border-2 border-gray-500 bg-gradient-to-br from-background/80 to-background/40 p-1 shadow-xl transition-all duration-200 hover:shadow-2xl"
      >
        <CardHeader className="pb-4 pt-6">
          <CardTitle className="flex items-center justify-between">
            <div className="flex items-center gap-2 text-lg font-semibold text-foreground/90">
              <FileCheckIcon className="h-5 w-5 text-primary/90" />
              Compliance Year {getPastYear()}
            </div>
            {showCountdown ? (
              <div
                className={cn(
                  'flex items-center gap-2 rounded-full px-3 py-1 text-sm font-medium',
                  isUrgent ? 'bg-destructive/10 text-destructive' : 'bg-primary/10 text-primary'
                )}
              >
                <AlertCircle className="h-4 w-4" />
                {daysRemaining} days remaining
              </div>
            ) : (
              <div className="flex items-center gap-2 rounded-full bg-muted/20 px-3 py-1 text-sm font-medium text-muted-foreground">
                <InfoIcon className="h-4 w-4" />
                Compliance for this property is not required
              </div>
            )}
          </CardTitle>
        </CardHeader>
        <CardContent className="grid grid-cols-1 gap-6 lg:grid-cols-2">
          {/* Energy Benchmarking Section */}
          <div className="flex flex-col gap-4">
            <div className="flex items-center justify-between rounded-lg bg-background/50 p-4">
              <div className="flex items-center gap-2">
                <BarChartIcon className="h-5 w-5 text-primary/90" />
                <h3 className="text-base font-medium">Energy Benchmarking</h3>
              </div>
              {buildingInfo.complianceRequirements.ll84.required && (
                <div className="flex items-center gap-2">
                  <span className="text-sm text-muted-foreground">LL84</span>
                  <CheckCircle2Icon className="h-5 w-5 text-green-500" />
                </div>
              )}
            </div>

            {buildingInfo.complianceRequirements.ll84.required &&
              buildingInfo.complianceRequirements.ll84.requiredToReportWater && (
                <div className="flex items-center gap-2 rounded-lg bg-blue-500/10 p-3">
                  <DropletIcon className="h-4 w-4 text-blue-400" />
                  <p className="text-sm text-blue-400">Water Reporting Required</p>
                </div>
              )}

            <div className="overflow-hidden rounded-lg border border-border/20">
              <table className="w-full border-separate border-spacing-0 text-sm">
                <thead>
                  <tr className="bg-background/10">
                    <th className="p-2 text-left text-xs font-medium text-muted-foreground">SERVICE</th>
                    <th className="p-2 text-right text-xs font-medium text-muted-foreground">USAGE</th>
                    <th className="p-2 text-right text-xs font-medium text-muted-foreground">EMISSIONS</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries({
                    Electric: {
                      value:
                        meterReadings?.yearlyData?.[getPastYear()]?.energyUsageTotalsByService?.electric?.totalUsage,
                      unit: 'kWh',
                      emissions:
                        meterReadings?.yearlyData?.[getPastYear()]?.energyUsageTotalsByService?.electric?.emissions,
                    },
                    Gas: {
                      value: meterReadings?.yearlyData?.[getPastYear()]?.energyUsageTotalsByService?.gas?.totalUsage,
                      unit: 'Therms',
                      emissions: meterReadings?.yearlyData?.[getPastYear()]?.energyUsageTotalsByService?.gas?.emissions,
                    },
                    Water: {
                      value: meterReadings?.yearlyData?.[getPastYear()]?.energyUsageTotalsByService?.water?.totalUsage,
                      unit: 'CCF',
                      emissions:
                        meterReadings?.yearlyData?.[getPastYear()]?.energyUsageTotalsByService?.water?.emissions,
                    },
                    Oil: {
                      value: meterReadings?.yearlyData?.[getPastYear()]?.energyUsageTotalsByService?.oil?.totalUsage,
                      unit: 'Gallons',
                      emissions: meterReadings?.yearlyData?.[getPastYear()]?.energyUsageTotalsByService?.oil?.emissions,
                    },
                    Steam: {
                      value: meterReadings?.yearlyData?.[getPastYear()]?.energyUsageTotalsByService?.steam?.totalUsage,
                      unit: 'Therms',
                      emissions:
                        meterReadings?.yearlyData?.[getPastYear()]?.energyUsageTotalsByService?.steam?.emissions,
                    },
                  }).map(([service, data]) => (
                    <tr key={service} className="hover:bg-background/5">
                      <td className="border-b border-border/30 p-2 font-medium">{service}</td>
                      <td className="border-b border-border/30 p-2 text-right">
                        {data.value?.toLocaleString() || '0'} {data.unit}
                      </td>
                      <td className="border-b border-border/30 p-2 text-right">
                        {data.emissions?.toLocaleString() || '0'}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          {/* Emissions Reporting Section */}
          <div className="flex flex-col gap-4">
            <div className="flex items-center justify-between rounded-lg bg-background/50 p-4">
              <div className="flex items-center gap-2">
                <TornadoIcon className="h-5 w-5 text-primary/90" />
                <h3 className="text-base font-medium">Emissions Reporting</h3>
              </div>
              {buildingInfo.complianceRequirements.ll97.required && (
                <div className="flex items-center gap-2">
                  <span className="text-sm text-muted-foreground">LL97</span>
                  <CheckCircle2Icon className="h-5 w-5 text-green-500" />
                </div>
              )}
            </div>

            {buildingInfo.complianceRequirements.ll97.required && (
              <div className="flex items-center justify-between gap-2 rounded-lg bg-primary/5 p-3">
                <div className="flex items-center gap-2">
                  <ClipboardCheckIcon className="h-4 w-4 text-primary" />
                  <p className="text-sm text-primary">Report Type</p>
                </div>
                <p className="text-sm font-medium text-primary">
                  {buildingInfo.complianceRequirements.ll97.reportType
                    ? buildingInfo.complianceRequirements.ll97.reportType.charAt(0).toUpperCase() +
                      buildingInfo.complianceRequirements.ll97.reportType.slice(1)
                    : 'Simple'}
                </p>
              </div>
            )}

            <div className="overflow-hidden rounded-lg border border-border/20">
              <table className="w-full border-separate border-spacing-0 text-sm">
                <tbody>
                  <tr className="hover:bg-background/5">
                    <td className="border-b border-border/30 p-3 font-medium">Actual Emissions</td>
                    <td className="border-b border-border/30 p-3 text-right font-medium">
                      {meterReadings?.yearlyData?.[getPastYear()]?.actualEmissions?.toLocaleString() || '0'} tCO2e
                    </td>
                  </tr>
                  {showCountdown && (
                    <>
                      <tr className="hover:bg-background/5">
                        <td className="border-b border-border/30 p-3 font-medium">Threshold Limit</td>
                        <td className="border-b border-border/30 p-3 text-right font-medium">
                          {meterReadings?.yearlyData?.[getPastYear()]?.threshold?.toLocaleString() || '0'} tCO2e
                        </td>
                      </tr>
                      <tr className="hover:bg-background/5">
                        <td className="border-b border-border/30 p-3 font-medium">Delta</td>
                        <td
                          className={cn('border-b border-border/30 p-3 text-right font-medium', {
                            'text-destructive':
                              meterReadings?.yearlyData?.[getPastYear()]?.threshold &&
                              meterReadings?.yearlyData?.[getPastYear()]?.actualEmissions &&
                              meterReadings.yearlyData[getPastYear()].threshold -
                                meterReadings.yearlyData[getPastYear()].actualEmissions <
                                0,
                            'text-green-500':
                              meterReadings?.yearlyData?.[getPastYear()]?.threshold &&
                              meterReadings?.yearlyData?.[getPastYear()]?.actualEmissions &&
                              meterReadings.yearlyData[getPastYear()].threshold -
                                meterReadings.yearlyData[getPastYear()].actualEmissions >=
                                0,
                          })}
                        >
                          {meterReadings?.yearlyData?.[getPastYear()]?.threshold &&
                          meterReadings?.yearlyData?.[getPastYear()]?.actualEmissions
                            ? `${(
                                meterReadings.yearlyData[getPastYear()].threshold -
                                meterReadings.yearlyData[getPastYear()].actualEmissions
                              ).toLocaleString()} tCO2e`
                            : 'N/A'}
                        </td>
                      </tr>
                      <tr className="hover:bg-background/5">
                        <td className="border-b border-border/30 p-3 font-medium">Annual Penalty</td>
                        <td className="border-b border-border/30 p-3 text-right font-medium text-destructive">
                          {meterReadings?.yearlyData?.[getPastYear()]?.estimatedAnnualPenalty
                            ? `$${meterReadings.yearlyData[getPastYear()].estimatedAnnualPenalty.toLocaleString()}`
                            : 'N/A'}
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </CardContent>
      </Card>
    );
  }
);
