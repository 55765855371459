import { Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { NoDataFound } from 'app/components/project/NoDataFound';
import { BarChartIcon, TrendingUpIcon, TrendingDownIcon } from 'lucide-react';
import { sharedLegendProps } from 'app/components/charts/shared/chartProps';

export const ENERGY_COLORS = {
  steam: '#3B82F6', // Blue
  gas: '#F59E0B', // Amber
  electric: '#10B981', // Emerald
  oil: '#6366F1', // Indigo
  water: '#60A5FA', // Light Blue
};

interface EnergySums {
  steam: number;
  gas: number;
  electric: number;
  oil: number;
  water: number;
  [key: string]: number;
}

interface YearData {
  startDate: string;
  energySumsKBTU: EnergySums;
  energyUsageTotalsByService: {
    water: {
      totalUsage: Record<string, number>;
    };
  };
}

interface EnergyUsageBreakdownProps {
  yearsData?: YearData[];
}

const EnergyUsageBreakdown = ({ yearsData }: EnergyUsageBreakdownProps) => {
  if (!yearsData?.length) {
    return (
      <NoDataFound
        message="No energy usage breakdown data available"
        submessage="There is no energy usage data to display for this time period"
        icon={<BarChartIcon className="h-8 w-8 text-gray-400" />}
      />
    );
  }

  const calculatePercentage = (value: number, total: number) => {
    return ((value / total) * 100).toFixed(2);
  };

  const getPieChartData = (yearData: any) => {
    const energySums = yearData.energySumsKBTU;
    // Filter out water before calculating total
    const filteredSums = Object.entries(energySums).reduce(
      (acc, [key, value]) => {
        if (key !== 'water' && (value as number) > 0) {
          acc[key] = value as number;
        }
        return acc;
      },
      {} as Record<string, number>
    );

    const total = Object.values(filteredSums).reduce((sum: number, value: number) => sum + value, 0);

    // If there's no data after filtering, return null
    if (total === 0) return null;

    return Object.entries(filteredSums).map(([key, value]) => ({
      name: key.charAt(0).toUpperCase() + key.slice(1),
      value: value,
      percentage: calculatePercentage(value, total),
    }));
  };

  const getPieChartTooltipContent = (props: any) => {
    if (!props.payload?.length) return null;
    const data = props.payload[0];

    return (
      <div className="rounded-lg border border-gray-200 bg-white p-3 shadow-lg">
        <div className="flex items-center gap-2 text-sm">
          <div className="h-3 w-3 rounded-full" style={{ backgroundColor: data.payload.fill }}></div>
          <span>
            {data.name}: {data.value.toLocaleString()} kBTU ({data.payload.percentage}%)
          </span>
        </div>
      </div>
    );
  };

  const calculateMetrics = (yearData: YearData) => {
    const energySums = yearData.energySumsKBTU;
    // Filter out water before calculating total and service usage
    const filteredSums = Object.entries(energySums).reduce(
      (acc, [key, value]) => {
        if (key !== 'water' && (value as number) > 0) {
          acc[key] = value as number;
        }
        return acc;
      },
      {} as Record<string, number>
    );

    const total = Object.values(filteredSums).reduce((sum: number, value: number) => sum + value, 0);

    // Find highest and least usage services (excluding water and zero values)
    const servicesWithUsage = Object.entries(filteredSums);
    const highestService = servicesWithUsage.reduce(
      (max, [key, value]) => (value > max.value ? { name: key, value } : max),
      { name: '', value: 0 }
    );
    const leastService = servicesWithUsage.reduce(
      (min, [key, value]) => (value < min.value ? { name: key, value } : min),
      { name: servicesWithUsage[0][0], value: servicesWithUsage[0][1] }
    );

    // Calculate previous year comparison if available
    const prevYearData = yearsData[yearsData.indexOf(yearData) + 1];
    const prevTotal = prevYearData
      ? Object.values(prevYearData.energySumsKBTU).reduce((sum: number, value: number) => sum + value, 0)
      : null;
    const yearOverYearChange = prevTotal ? ((total - prevTotal) / prevTotal) * 100 : null;

    return {
      totalEnergy: total,
      yearOverYearChange,
      highestService: {
        name: highestService.name.charAt(0).toUpperCase() + highestService.name.slice(1),
        value: highestService.value,
        percentage: calculatePercentage(highestService.value, total),
      },
      leastService: {
        name: leastService.name.charAt(0).toUpperCase() + leastService.name.slice(1),
        value: leastService.value,
        percentage: calculatePercentage(leastService.value, total),
      },
    };
  };

  return (
    <div className="space-y-8">
      {/* Energy Usage by Service Section */}
      <section className="space-y-4">
        {yearsData
          .sort((a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime())
          .slice(0, 1)
          .map((yearData, index) => {
            const pieData = getPieChartData(yearData);
            const metrics = calculateMetrics(yearData);
            if (!pieData) return null;

            return (
              <div key={yearData.startDate} className="space-y-4">
                {/* Metrics Cards */}
                <div className="w-full bg-white shadow-sm">
                  <div className="p-6">
                    <div className="grid grid-cols-3 gap-4">
                      {/* Total Energy Usage */}
                      <div className="flex flex-col items-center justify-center rounded-lg p-6">
                        <h3 className="mb-2 text-sm font-medium text-gray-500">Total Energy Usage</h3>
                        <div className="flex items-baseline gap-1">
                          <p className="text-2xl font-semibold text-gray-900">{metrics.totalEnergy.toLocaleString()}</p>
                          <span className="text-sm text-gray-500">kBTU</span>
                        </div>
                        {metrics.yearOverYearChange !== null && (
                          <div className="mt-2 flex items-center text-sm">
                            {metrics.yearOverYearChange >= 0 ? (
                              <TrendingUpIcon className="mr-1 h-4 w-4 text-red-500" />
                            ) : (
                              <TrendingDownIcon className="mr-1 h-4 w-4 text-green-500" />
                            )}
                            <span className={metrics.yearOverYearChange >= 0 ? 'text-red-500' : 'text-green-500'}>
                              {Math.abs(metrics.yearOverYearChange).toFixed(2)}% vs last year
                            </span>
                          </div>
                        )}
                      </div>

                      {/* Highest Usage Service */}
                      <div className="flex flex-col items-center justify-center rounded-lg p-6">
                        <h3 className="mb-2 text-sm font-medium text-gray-500">Highest Usage Service</h3>
                        <div className="flex flex-col items-center">
                          <p className="text-2xl font-semibold text-gray-900">{metrics.highestService.name}</p>
                          <p className="mt-2 text-sm text-gray-500">
                            {metrics.highestService.percentage}% of total usage
                          </p>
                        </div>
                      </div>

                      {/* Least Usage Service */}
                      <div className="flex flex-col items-center justify-center rounded-lg p-6">
                        <h3 className="mb-2 text-sm font-medium text-gray-500">Least Usage Service</h3>
                        <div className="flex flex-col items-center">
                          <p className="text-2xl font-semibold text-gray-900">{metrics.leastService.name}</p>
                          <p className="mt-2 text-sm text-gray-500">
                            {metrics.leastService.percentage}% of total usage
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Chart Container */}
                <div className="rounded-lg border border-gray-200 bg-white p-6 shadow-sm">
                  <h3 className="mb-6 text-base font-medium text-gray-700">Energy Usage by Service</h3>
                  <div className="h-[380px]">
                    <ResponsiveContainer width="100%" height="100%">
                      <PieChart>
                        <Pie
                          data={pieData}
                          cx="50%"
                          cy="50%"
                          innerRadius={80}
                          outerRadius={120}
                          paddingAngle={2}
                          dataKey="value"
                          label={({ name, percentage }) => `${name} ${percentage}%`}
                          labelLine={true}
                        >
                          {pieData.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={ENERGY_COLORS[entry.name.toLowerCase() as keyof typeof ENERGY_COLORS]}
                              stroke="#FFFFFF"
                              strokeWidth={2}
                            />
                          ))}
                        </Pie>
                        <Tooltip
                          content={getPieChartTooltipContent}
                          contentStyle={{
                            backgroundColor: 'white',
                            border: '1px solid #E5E7EB',
                            borderRadius: '6px',
                            boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                            padding: '8px 12px',
                          }}
                        />
                        <Legend
                          {...sharedLegendProps}
                          wrapperStyle={{
                            paddingTop: '20px',
                            fontSize: '12px',
                            color: '#6B7280',
                          }}
                        />
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
            );
          })}
      </section>
    </div>
  );
};

export default EnergyUsageBreakdown;
