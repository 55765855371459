import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, BarChart, Bar } from 'recharts';
import { MonthlyEmissions } from 'app/components/service/types';

interface EmissionsTrendAnalysisProps {
  monthlyEmissionsData?: MonthlyEmissions[];
}

const EmissionsTrendAnalysis = ({ monthlyEmissionsData }: EmissionsTrendAnalysisProps) => {
  return (
    <div className="flex flex-col gap-8">
      <div className="rounded-lg border border-gray-200 bg-white p-6 shadow-sm">
        <h2 className="mb-6 text-base font-medium text-gray-700">Month over Month Emissions</h2>
        <ResponsiveContainer width="100%" height={400}>
          <BarChart data={monthlyEmissionsData} margin={{ top: 10, right: 30, left: 20, bottom: 30 }}>
            <CartesianGrid strokeDasharray="3 3" stroke="#E5E7EB" vertical={false} />
            <XAxis
              dataKey="month"
              height={60}
              tickLine={false}
              axisLine={{ stroke: '#E5E7EB' }}
              tick={{ fill: '#6B7280', fontSize: 12 }}
              tickMargin={8}
            />
            <YAxis
              label={{
                value: 'Emissions (tCO₂e)',
                angle: -90,
                position: 'insideLeft',
                offset: 0,
                style: {
                  textAnchor: 'middle',
                  fill: '#6B7280',
                  fontSize: 12,
                  fontWeight: 500,
                },
              }}
              yAxisId="left"
              tick={{ fill: '#6B7280', fontSize: 12 }}
              tickLine={false}
              axisLine={{ stroke: '#E5E7EB' }}
              tickCount={6}
            />
            <Tooltip
              contentStyle={{
                backgroundColor: 'white',
                border: '1px solid #E5E7EB',
                borderRadius: '6px',
                boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                padding: '8px 12px',
              }}
              formatter={(value: any) => [`${value} tCO₂e`, 'Emissions']}
              cursor={{ fill: 'rgba(59, 130, 246, 0.1)' }}
            />
            <Bar dataKey="emissions" fill="#3B82F6" radius={[4, 4, 0, 0]} yAxisId="left" name="Monthly Emissions" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default EmissionsTrendAnalysis;
