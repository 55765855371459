import jwtAuthAxios from './auth/jwtAuth';

const conedService = {
  exchangeCodeForAccessToken: async ({ body }) => {
    const { data } = await jwtAuthAxios.post(`/v1/coned/access-token`, body);
    return data;
  },

  getConEdAccounts: async () => {
    const { data } = await jwtAuthAxios.get(`/v1/coned/customers/accounts`);
    return data;
  },

  getUsagePoints: async (params: { id: string }) => {
    const { data } = await jwtAuthAxios.get(`/v1/coned/customers/${params.id}/usage-points`);
    return data;
  },

  getCustomerMeters: async (params: { id: string }) => {
    const { data } = await jwtAuthAxios.get(`/v1/coned/customers/${params.id}/meters`);
    return data;
  },

  getIntervalBlocks: async (params: {
    subscriptionId: string;
    usagePointId: string;
    meterReadingId: string;
    publishedMin?: string;
    publishedMax?: string;
    interval?: number;
  }) => {
    let url = `/v1/coned/customers/${params.subscriptionId}/usage-points/${params.usagePointId}/meter-readings/${params.meterReadingId}/interval-blocks`;
    if (params.publishedMin && params.publishedMax) {
      url += `?publishedMin=${params.publishedMin}&publishedMax=${params.publishedMax}`;
    }
    if (params.interval) {
      url += `&interval=${params.interval}`;
    }
    console.log('getIntervalBlocks url', url);

    const { data } = await jwtAuthAxios.get(url);
    return data;
  },

  getAggregatedIntervalBlocks: async (params: {
    subscriptionId: string;
    publishedMin?: string;
    publishedMax?: string;
    interval?: number;
  }) => {
    let url = `/v1/coned/customers/${params.subscriptionId}/usage-points/meter-readings/interval-blocks`;
    if (params.publishedMin && params.publishedMax) {
      url += `?publishedMin=${params.publishedMin}&publishedMax=${params.publishedMax}`;
    }
    if (params.interval) {
      url += `&interval=${params.interval}`;
    }
    console.log('getAggregatedIntervalBlocks url', url);

    const { data } = await jwtAuthAxios.get(url);
    return data;
  },

  getRealTimeIntervalBlocks: async (params: {
    subscriptionId: string;
    usagePointId: string;
    meterSerialNumber: string;
  }) => {
    const { data } = await jwtAuthAxios.get(
      `/v1/coned/customers/${params.subscriptionId}/usage-points/${params.usagePointId}/meter-readings/real-time/${params.meterSerialNumber}/interval-blocks`
    );
    return data;
  },

  getAggregatedRealTimeIntervalBlocks: async (params: { subscriptionId: string }) => {
    const { data } = await jwtAuthAxios.get(
      `/v1/coned/customers/${params.subscriptionId}/usage-points/meter-readings/real-time/interval-blocks`
    );
    return data;
  },
};

export default conedService;
