import { Button } from '@shadcn/ui/button';
import { Link } from 'react-router-dom';
import { ProjectInfoData } from 'app/services/project-services';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { cn } from '@shadcn/utils';
import React from 'react';

interface EnergyStarChildProperty {
  id: string;
  name: string;
  parentId?: string;
}

interface EnergyStarProperty {
  id: string;
  name: string;
  childProperties?: EnergyStarChildProperty[];
}

interface EnergyStarPropertiesTableProps {
  properties: EnergyStarProperty[];
  syncedProperties: Set<string>;
  projects: ProjectInfoData[];
  setRefreshProjects: Dispatch<SetStateAction<boolean>>;
  onPropertySync: (customerId: string, propertyId: string, parentId?: string) => void;
  onSnackbar: (message: string, variant: 'default' | 'destructive' | 'positive') => void;
}

export const EnergyStarPropertiesTable = ({
  properties,
  setRefreshProjects,
  projects,
  onPropertySync,
  onSnackbar,
}: EnergyStarPropertiesTableProps) => {
  const { authUser } = useJumboAuth();

  const matchedProperties = useMemo(() => {
    const projectMap = new Map<string, string>(); // energyStarId -> projectId map

    // Map parent properties
    projects.forEach((project) => {
      if (project.attributes.energyStarId) {
        projectMap.set(project.attributes.energyStarId, project.id);
      }
      // Map child properties
      project.attributes.childProperties?.forEach((childProp) => {
        if (childProp.energyStarId) {
          projectMap.set(childProp.energyStarId, project.id);
        }
      });
    });

    // Map and filter the properties to only show unsynced ones
    const mappedProperties = properties.map((property) => ({
      ...property,
      projectId: projectMap.get(property.id),
      childProperties: (property.childProperties || [])
        .map((child) => ({
          ...child,
          projectId: projectMap.get(child.id),
        }))
        .sort((a, b) => a.name.localeCompare(b.name)),
    }));

    // Sort parent properties alphabetically ascending
    return mappedProperties.sort((a, b) => a.name.localeCompare(b.name));
  }, [properties, projects]);

  const handleSync = async (property: EnergyStarProperty, parentId?: string) => {
    try {
      const energyStarAccountId = authUser?.relationships?.organization?.data?.energyStarAccountId;
      if (!energyStarAccountId) {
        throw new Error('Energy Star Account ID not found');
      }

      await onPropertySync(energyStarAccountId, property.id, parentId);
      // After sync, update the projects list which will automatically update the UI
      setRefreshProjects((prev) => !prev);
      onSnackbar('Property successfully imported to Cosmos', 'positive');
    } catch (error) {
      console.log('Error linking Energy Star property:', error);
      onSnackbar('Failed to import Energy Star property', 'destructive');
    }
  };

  const renderPropertyRow = (
    property: (EnergyStarProperty | EnergyStarChildProperty) & { projectId?: string },
    isChild: boolean = false,
    index: number,
    parentId?: string
  ) => {
    const isSynced = property.projectId;

    return (
      <tr
        key={property.id}
        className={cn('transition-colors', {
          'hover:bg-background/10': !isChild,
          'hover:bg-background/20': isChild,
          'bg-background/5': index % 2 === 0 && !isChild,
          'bg-background/10': isChild && index % 2 === 0,
          'bg-background/[0.07]': isChild && index % 2 !== 0,
        })}
      >
        <td className="py-1.5 pl-3 pr-1 text-sm">
          <div className={`flex items-center ${isChild ? 'ml-6' : ''}`}>
            {isChild && <span className="mr-2 text-muted-foreground/60">└</span>}
            <span className={cn('font-medium text-foreground', { 'text-muted-foreground/80': isChild })}>
              {property.name}
            </span>
          </div>
        </td>
        <td className="px-1 py-1.5 text-sm text-muted-foreground/80">{property.id}</td>
        <td className="py-1.5 pl-1 pr-3">
          {isSynced ? (
            <Link to={`/dashboard/project/${property.projectId}/building-info`}>
              <Button
                variant="outline"
                size="sm"
                className="h-7 border-primary/30 bg-primary/10 text-primary hover:bg-primary/20"
              >
                View in Cosmos
              </Button>
            </Link>
          ) : (
            <Button
              variant="outline"
              size="sm"
              className="h-7 border-primary/30 bg-primary/10 text-primary hover:bg-primary/20"
              onClick={() => handleSync(property as EnergyStarProperty, parentId)}
            >
              Import
            </Button>
          )}
        </td>
      </tr>
    );
  };

  if (matchedProperties.length === 0) {
    return (
      <div className="flex h-32 items-center justify-center text-sm text-muted-foreground">
        All properties have been synced with Cosmos.
      </div>
    );
  }

  return (
    <div className="mx-auto w-full overflow-hidden rounded-lg border border-border/20">
      <div className="max-h-[750px] overflow-y-auto">
        <table className="w-full text-left">
          <thead className="sticky top-0 z-10 bg-background">
            <tr className="border-b border-border/20">
              <th className="py-2 pl-3 pr-1 text-sm font-medium text-muted-foreground/80">Property Name</th>
              <th className="px-1 py-2 text-sm font-medium text-muted-foreground/80">Energy Star ID</th>
              <th className="py-2 pl-1 pr-3 text-sm font-medium text-muted-foreground/80"></th>
            </tr>
          </thead>
          <tbody className="divide-y divide-border/10">
            {matchedProperties.map((property, index) => (
              <React.Fragment key={property.id}>
                {renderPropertyRow(property, false, index)}
                {property.childProperties.map((childProperty, childIndex) =>
                  renderPropertyRow(childProperty, true, index + childIndex + 0.5, property.id)
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
