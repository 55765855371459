import { AxiosError } from 'axios';

export const isAxiosError = (error: unknown): error is AxiosError => {
  return error instanceof Error && 'isAxiosError' in error;
};

export const extractError = (error: unknown): string | null => {
  if (error instanceof Error) {
    if (isAxiosError(error)) {
      return error.response?.data?.message;
    }
    return error.message;
  }

  return null;
};
