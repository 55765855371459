import { cn } from '@shadcn/utils';
import { Fragment, useEffect, useState } from 'react';
import { useToast } from '@shadcn/hooks/use-toast';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogFooter,
  DialogHeader,
  DialogBody,
  Card,
  Label,
} from '@shadcn/ui';
import projectServices from 'app/services/project-services';
import { ArrowLeftIcon, ArrowRightIcon, CheckCircleIcon, EllipsisVerticalIcon } from 'lucide-react';
import { AxiosError } from 'axios';

import {
  BasicBuildingInfoData,
  PropertyUse,
  createInitialBuildingInfo,
  buildingInfoSteps,
  validateBuildingInfo,
  formatFieldValue,
  buildingServicesFields,
  occupancyInfoFields,
} from 'app/utils/constants/buildingInfoStructure';
import { BuildingDataInputs } from './tabs/building-details/BuildingDataInputs';
import { BuildingServicesInput } from './tabs/building-details/BuildingServicesInput';
import { BuildingOccupancyInput } from './tabs/building-details/BuildingOccupancyInput';
import { BuildingUseTypeInputs } from './tabs/building-details/BuildingUseTypeInputs';
import { BuildingSystems } from './tabs/building-details/BuildingSystems';

// Use the shared steps structure
const steps = buildingInfoSteps;

interface ApiErrorResponse {
  errors: Array<{
    title: string;
    detail: string;
  }>;
}

export const ProjectModal = ({ open, setOpen }: { open: boolean; setOpen: (v: boolean) => void }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [projectInfo, setProjectInfo] = useState<BasicBuildingInfoData>(() => createInitialBuildingInfo());
  const [isLoading, setIsLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState<Partial<Record<keyof BasicBuildingInfoData, string>>>({});
  const [isCompleted, setIsCompleted] = useState(true);
  const { toast } = useToast();

  useEffect(() => {
    if (open) {
      setActiveStep(0);
      setIsCompleted(false);
    }
  }, [open]);

  const validateStep = (step) => {
    // Use the shared validation function
    const errors = validateBuildingInfo(projectInfo);

    // Filter errors based on the current step
    const stepFields = steps[step].fields;
    const stepErrors: Partial<Record<keyof BasicBuildingInfoData, string>> = {};

    Object.entries(errors).forEach(([key, value]) => {
      if (stepFields.includes(key)) {
        stepErrors[key as keyof BasicBuildingInfoData] = value;
      }
    });

    setValidationErrors(stepErrors);
    return Object.keys(stepErrors).length === 0;
  };

  const handleNext = () => {
    if (validateStep(activeStep)) {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;

    if (type === 'checkbox') {
      if (name === 'closedOnWeekends') {
        setProjectInfo((prev) => ({
          ...prev,
          occupancy: {
            ...prev.occupancy,
            [name]: type === 'checkbox' ? checked : value,
            ...(name === 'closedOnWeekends' && checked
              ? {
                  weekendStartTime: '',
                  weekendEndTime: '',
                }
              : {}),
          },
        }));
      } else if (buildingServicesFields.some((field) => field.name === name)) {
        setProjectInfo((prev) => ({
          ...prev,
          buildingServices: {
            ...prev.buildingServices,
            [name]: value,
            ...(name === 'hasBMS' && type === 'checkbox' && !value ? { bmsVendor: '' } : {}),
          },
        }));
      }
    } else if (occupancyInfoFields.some((field) => field.name === name)) {
      setProjectInfo((prev) => ({
        ...prev,
        occupancy: {
          ...prev.occupancy,
          [name]: value,
        },
      }));
    } else if (name === 'bmsVendor') {
      setProjectInfo((prev) => ({
        ...prev,
        buildingServices: {
          ...prev.buildingServices,
          bmsVendor: value,
        },
      }));
    } else {
      // Use the shared format function for input values
      const formattedValue = formatFieldValue(name, value);
      setProjectInfo((prev) => ({ ...prev, [name]: formattedValue }));
    }

    setValidationErrors((prev) => ({ ...prev, [name]: '' }));
  };

  const handleServicesChange = (services: string[]) => {
    setProjectInfo((prev) => ({ ...prev, services }));
    setValidationErrors((prev) => ({ ...prev, services: '' }));
  };

  const handlePropertyUseChange = (propertyUses: PropertyUse[]) => {
    setProjectInfo((prev) => ({ ...prev, propertyUses }));
    setValidationErrors((prev) => ({ ...prev, propertyUses: '' }));
  };

  const handleCreateProject = async () => {
    if (!validateStep(activeStep)) {
      return;
    }

    setIsLoading(true);

    try {
      const params = {
        ...projectInfo,
        buildingRepresentation: {
          owner: projectInfo.buildingRepresentation.owner,
          representative: projectInfo.buildingRepresentation.representative,
          services: projectInfo.buildingRepresentation.services,
        },
        yearBuilt: parseInt(projectInfo.yearBuilt),
        numberOfFloors: projectInfo.numberOfFloors ? parseInt(projectInfo.numberOfFloors) : undefined,
        peakOccupancy: projectInfo.peakOccupancy ? parseInt(projectInfo.peakOccupancy) : undefined,
        propertyUses: (projectInfo.propertyUses || []).map((use) => ({
          ...use,
          totalGrossFloorArea: Number(use.totalGrossFloorArea),
        })),
      };

      const project = await projectServices.create(params);

      if (project) {
        setIsCompleted(true);
      } else {
        throw new Error('Failed to create project. Please try again.');
      }
    } catch (err) {
      const error = err as AxiosError<ApiErrorResponse>;
      const errorData = error.response?.data?.errors?.[0] ?? {
        title: 'Error',
        detail: 'An unexpected error occurred',
      };

      toast({
        title: 'Project Creation Failed',
        description: errorData.detail,
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const closeAndClearModal = () => {
    setProjectInfo(createInitialBuildingInfo());
    setValidationErrors({});
    setActiveStep(0);
    setIsCompleted(false);
    setOpen(false);
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <BuildingDataInputs
            buildingInfo={projectInfo}
            errors={validationErrors}
            handleInputChange={handleInputChange}
            isEditing={true}
          />
        );
      case 1:
        return (
          <BuildingServicesInput
            buildingInfo={projectInfo}
            errors={validationErrors}
            handleServicesChange={handleServicesChange}
            handleInputChange={handleInputChange}
            isEditing={true}
          />
        );
      case 2:
        return (
          <BuildingOccupancyInput
            buildingInfo={projectInfo}
            errors={validationErrors}
            handleOccupancyChange={handleInputChange}
            isEditing={true}
          />
        );
      case 3:
        return (
          <BuildingUseTypeInputs
            projectInfo={projectInfo}
            handlePropertyUseChange={handlePropertyUseChange}
            validationErrors={validationErrors}
          />
        );
      case 4:
        return <BuildingSystems projectInfo={projectInfo} setProjectInfo={setProjectInfo} />;
      case 5:
        return (
          <div className="space-y-4">
            <h3 className="text-lg font-medium">Additional Notes</h3>
            <Card className="p-4">
              <div className="space-y-2">
                <Label className="text-md font-medium">Notes</Label>
                <textarea
                  className="min-h-[200px] w-full rounded-md border border-input bg-background p-3 text-sm"
                  value={projectInfo.notes || ''}
                  onChange={(e) => {
                    setProjectInfo((prev) => ({
                      ...prev,
                      notes: e.target.value,
                    }));
                  }}
                  placeholder="Add any additional notes about the building..."
                />
              </div>
            </Card>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Dialog
      open={open}
      onOpenChange={(v) => {
        if (!v) {
          closeAndClearModal();
        }
      }}
    >
      <DialogContent className="max-w-[900px]">
        <DialogHeader>
          <DialogTitle className="text-xl font-bold text-gray-200">Create New Project</DialogTitle>
        </DialogHeader>
        <DialogBody className="flex-row">
          <div className="grid auto-cols-min auto-rows-min grid-cols-[auto_1fr] content-start items-center gap-2">
            {steps.map((step, index) => (
              <Fragment key={step.label}>
                <step.icon
                  className={cn('transition-300 text-muted-foreground transition-colors ease-in-out', {
                    'text-primary': activeStep === index,
                  })}
                />
                <p
                  className={cn('whitespace-nowrap text-muted-foreground', {
                    'font-bold text-foreground': activeStep === index,
                  })}
                >
                  {step.label}
                </p>
                {index < steps.length - 1 ? (
                  <>
                    <EllipsisVerticalIcon className="text-muted-foreground" />
                    <div />
                  </>
                ) : null}
              </Fragment>
            ))}
          </div>
          <div
            className={cn('flex grow flex-col justify-start', {
              'items-center justify-center': isCompleted,
            })}
          >
            {isCompleted ? (
              <div className="flex flex-col items-center justify-center gap-4">
                <CheckCircleIcon className="h-10 w-10 text-chart-2" />
                <h5 className="text-foreground">Project Created Successfully!</h5>
              </div>
            ) : (
              <div className="ml-4 h-[60vh] overflow-y-auto pr-2">{renderStepContent(activeStep)}</div>
            )}
          </div>
        </DialogBody>
        <DialogFooter>
          {!isCompleted ? (
            <>
              <Button variant="secondary" disabled={activeStep === 0} onClick={handleBack} className="text-foreground">
                <ArrowLeftIcon /> Back
              </Button>
              <Button
                type="submit"
                className="bg-primary hover:bg-primary/90"
                onClick={activeStep === steps.length - 1 ? handleCreateProject : handleNext}
                disabled={isLoading || isCompleted}
                loading={isLoading}
              >
                <span className="text-gray-200">{activeStep === steps.length - 1 ? 'Create Project' : 'Next'}</span>
                {activeStep === steps.length - 1 ? null : <ArrowRightIcon className="ml-2 text-gray-200" />}
              </Button>
            </>
          ) : (
            <Button onClick={closeAndClearModal}>Close</Button>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
