import { AlertTriangle } from 'lucide-react';
import { ScrollArea } from '@shadcn/ui';
import { InsightCardsPanel } from 'app/components/panels/InsightCardsPanel';
import { UsageTrendChart } from 'app/components/charts/UsageTrendChart';
import { UsageDegreeChart } from 'app/components/charts/UsageDegreeChart';
import { PeakDemandChart } from 'app/components/charts/PeakDemandChart';
import { formatCurrency, getUsageUnit } from 'app/utils/formatters';
import { ALL, ELECTRIC } from 'app/utils/constants/utilityServices';
import { DataSource } from 'app/types/visualizations';
import { PenaltyData } from 'app/types/penalty-data';
import { Documents, WeatherProps } from 'app/types/visualizations';
import { PropertyMetrics, FlattenedAlert } from 'app/services/energy-star-services';
import { useState } from 'react';
import { EfficiencyMeasure } from 'app/types/efficiency-measures';
import { EfficiencyDashboard } from '../efficiency/EfficiencyDashboard';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@shadcn/ui/tabs';
import { Card, CardContent, CardHeader, CardTitle } from '@shadcn/ui';
import EmissionsTrendAnalysis from 'app/pages/project/tabs/analysis/EmissionsTrendAnalysis';
import EnergyUsageBreakdown from 'app/pages/project/tabs/analysis/EnergyUsageBreakdown';
import EmissionsByService from 'app/pages/project/tabs/analysis/EmissionsByService';
import { FEATURE_FLAG_EFFICIENCY_MEASURES } from 'app/utils/constants/featureFlags';
import { BillingPeriod, MonthlyEmissions } from 'app/components/service/types';
interface ServiceContentProps {
  serviceType: typeof ALL | typeof ELECTRIC | string;
  dataSource: DataSource;
  documents: Documents;
  meterReadings: Documents;
  trendDataByService: any;
  baseloads: any;
  meterReadingsBaseloads: any;
  baseTemperature: number;
  isUpdatingDegreeData: boolean;
  weather: WeatherProps;
  onBaseTemperatureChange: (value: number[]) => void;
  project: any;
  emissionsFromUtilityBills: PenaltyData | null;
  emissionsFromMeterReadings: PenaltyData | null;
  isEmissionsLoading: boolean;
  projectId: string;
  energyStarScoreByDateRange?: { [dateRange: string]: PropertyMetrics };
  noScoreReason?: FlattenedAlert[];
  theme: any;
}

// Sample efficiency measure
const sampleMeasure: EfficiencyMeasure = {
  id: '1',
  name: 'Chiller Upgrade',
  description: 'Replacement of old chiller system with high-efficiency model',
  startDate: new Date('2024-02-01'),
  endDate: new Date('2024-09-30'),
  impactedServices: {
    electric: true,
    gas: false,
    steam: false,
    oil: false,
    water: false,
  },
  expectedSavings: {
    electric: { amount: 150000, unit: 'kWh' },
  },
  baselinePeriod: {
    startDate: new Date('2023-06-01'),
    endDate: new Date('2024-02-01'),
  },
};

export const ServiceContent = ({
  serviceType,
  dataSource,
  documents,
  meterReadings,
  trendDataByService,
  baseloads,
  meterReadingsBaseloads,
  baseTemperature,
  isUpdatingDegreeData,
  weather,
  onBaseTemperatureChange,
  project,
  theme,
}: ServiceContentProps) => {
  const [efficiencyMeasures, setEfficiencyMeasures] = useState<EfficiencyMeasure[]>([sampleMeasure]);
  const [activeTab, setActiveTab] = useState<'overview' | 'efficiency'>('overview');

  const data = dataSource === 'utility-bills' ? documents : meterReadings;

  const yearlyData = data?.yearlyData;
  let annualThresholdCO2: number | null = null;
  let actualEmissions: number | null = null;
  let estimatedPenalty: number | null = null;
  if (yearlyData) {
    const sortedYears = Object.keys(yearlyData).sort((a, b) => new Date(b).getTime() - new Date(a).getTime());

    const yearWithFullData = sortedYears.find((year) => {
      const yearData = yearlyData[year];
      return Object.entries(yearData.monthsCoveredByService || {}).some(
        ([service, months]) => service !== 'water' && months === 12
      );
    });

    if (yearWithFullData) {
      const yearData = yearlyData[new Date().getFullYear()];
      annualThresholdCO2 = yearData?.threshold;
      actualEmissions = yearData?.actualEmissions;
      estimatedPenalty = yearData?.estimatedAnnualPenalty;
    }
  }

  const yearsData = Object.values(yearlyData);

  const handleAddMeasure = () => {
    // Implementation for adding new measure
    const newMeasure: EfficiencyMeasure = {
      id: String(efficiencyMeasures.length + 1),
      name: 'New Measure',
      description: 'Description of the new measure',
      startDate: new Date(),
      endDate: new Date(Date.now() + 180 * 24 * 60 * 60 * 1000), // 180 days from now
      impactedServices: {
        electric: true,
        gas: false,
        steam: false,
        oil: false,
        water: false,
      },
      expectedSavings: {
        electric: { amount: 0, unit: 'kWh' },
      },
      baselinePeriod: {
        startDate: new Date(Date.now() - 180 * 24 * 60 * 60 * 1000), // 180 days ago
        endDate: new Date(),
      },
    };
    setEfficiencyMeasures([...efficiencyMeasures, newMeasure]);
  };

  const processMonthlyEmissions = (data?: Documents): MonthlyEmissions[] => {
    if (!data) return [];

    const year2025Data: MonthlyEmissions[] = [];
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const monthlyEmissions = new Array(12).fill(0);

    // Process electric data
    (data.electric.billingPeriods as unknown as BillingPeriod[]).forEach((period) => {
      const startDate = new Date(period.billing_start_date);
      if (startDate.getFullYear() === 2025) {
        const month = startDate.getMonth();
        monthlyEmissions[month] += period.emissions;
      }
    });

    // Process gas data
    (data.gas.billingPeriods as unknown as BillingPeriod[]).forEach((period) => {
      const startDate = new Date(period.billing_start_date);
      if (startDate.getFullYear() === 2025) {
        const month = startDate.getMonth();
        monthlyEmissions[month] += period.emissions;
      }
    });

    // Process steam data
    (data.steam.billingPeriods as unknown as BillingPeriod[]).forEach((period) => {
      const startDate = new Date(period.billing_start_date);
      if (startDate.getFullYear() === 2025) {
        const month = startDate.getMonth();
        monthlyEmissions[month] += period.emissions;
      }
    });

    // Create final data array
    monthlyEmissions.forEach((emissions, index) => {
      year2025Data.push({
        month: monthNames[index],
        emissions: Number(emissions.toFixed(2)),
      });
    });

    return year2025Data;
  };

  const monthlyEmissionsData = processMonthlyEmissions(data);

  if (serviceType === ALL) {
    return (
      <ScrollArea orientation="vertical" className="grow p-4">
        <Tabs value={activeTab} onValueChange={(value) => setActiveTab(value as 'overview' | 'efficiency')}>
          <TabsList>
            <TabsTrigger value="overview">Overview</TabsTrigger>
            {FEATURE_FLAG_EFFICIENCY_MEASURES(project?.attributes?.name || '') && (
              <TabsTrigger value="efficiency">Efficiency Measures</TabsTrigger>
            )}
          </TabsList>

          <TabsContent value="overview">
            <div className="space-y-4">
              {/* Emissions Status Card */}
              <Card className="w-full border border-gray-200 bg-white shadow-sm">
                <CardHeader className="border-b border-gray-100 pb-4">
                  <CardTitle className="text-center text-lg font-medium text-gray-700">
                    Emissions as of {new Date().toLocaleString('default', { month: 'long' })},{' '}
                    {new Date().getFullYear()}
                  </CardTitle>
                </CardHeader>
                <CardContent className="pt-6">
                  <div className="space-y-8">
                    {/* Metrics Row */}
                    <div className="grid grid-cols-3 gap-4">
                      {/* Emissions Threshold */}
                      <div className="flex flex-col items-center justify-center rounded-lg p-6">
                        <h3 className="mb-2 text-sm font-medium text-gray-500">Emissions Threshold</h3>
                        <div className="flex items-baseline gap-1">
                          <p className="text-2xl font-semibold text-gray-900">
                            {annualThresholdCO2?.toLocaleString() || 'N/A'}
                          </p>
                          <span className="text-sm text-gray-500">tCO₂e</span>
                        </div>
                      </div>
                      {/* Actual Emissions */}
                      <div className="flex flex-col items-center justify-center rounded-lg p-6">
                        <h3 className="mb-2 text-sm font-medium text-gray-500">Current Emissions</h3>
                        <div className="flex flex-col items-center gap-2">
                          <div className="flex items-baseline gap-1">
                            <p className="text-2xl font-semibold text-gray-900">
                              {actualEmissions?.toLocaleString() || 'N/A'}
                            </p>
                            <span className="text-sm text-gray-500">tCO₂e</span>
                            {annualThresholdCO2 && actualEmissions && actualEmissions > annualThresholdCO2 && (
                              <AlertTriangle className="ml-1 h-5 w-5 text-amber-500" />
                            )}
                          </div>
                          {annualThresholdCO2 && actualEmissions && (
                            <div className="flex w-full max-w-[200px] flex-col items-center gap-1.5">
                              <div className="h-1.5 w-full rounded-full bg-gray-100">
                                <div
                                  className={`h-full rounded-full transition-all ${
                                    actualEmissions > annualThresholdCO2 ? 'bg-amber-500' : 'bg-blue-500'
                                  }`}
                                  style={{
                                    width: `${Math.min((actualEmissions / annualThresholdCO2) * 100, 100)}%`,
                                  }}
                                />
                              </div>
                              <p className="text-xs text-gray-500">
                                {((actualEmissions / annualThresholdCO2) * 100).toFixed(1)}% of threshold
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                      {/* Emissions Penalty */}
                      <div className="flex flex-col items-center justify-center rounded-lg p-6">
                        <h3 className="mb-2 text-sm font-medium text-gray-500">Estimated Penalty</h3>
                        <div className="flex items-baseline gap-1">
                          <p className="text-2xl font-semibold text-gray-900">
                            {estimatedPenalty ? formatCurrency(estimatedPenalty) : 'N/A'}
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* Charts Row */}
                    <div className="grid grid-cols-2 gap-8">
                      {/* Month over Month Emissions */}
                      <div className="h-[500px]">
                        <EmissionsTrendAnalysis monthlyEmissionsData={monthlyEmissionsData} />
                      </div>

                      {/* Emissions By Service */}
                      <div className="h-[500px]">
                        <EmissionsByService
                          sortedYears={Object.values(yearlyData).sort(
                            (a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>

              {/* Energy Usage Card */}
              <Card className="w-full border border-gray-200 bg-white shadow-sm">
                <CardHeader className="border-b border-gray-100 pb-4">
                  <CardTitle className="text-center text-lg font-medium text-gray-700">
                    Energy Usage as of {new Date().toLocaleString('default', { month: 'long' })},{' '}
                    {new Date().getFullYear()}
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="space-y-8">
                    {/* Energy Usage Breakdown */}
                    <div className="rounded-lg bg-background/5 p-4">
                      <EnergyUsageBreakdown yearsData={yearsData} />
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
          </TabsContent>

          {FEATURE_FLAG_EFFICIENCY_MEASURES(project?.attributes?.name || '') && (
            <TabsContent value="efficiency">
              <EfficiencyDashboard
                project={project}
                measures={efficiencyMeasures}
                onAddMeasure={handleAddMeasure}
                utilityBillsData={trendDataByService[ELECTRIC]?.cdd?.trendDataByBillPeriod || []}
                meterReadingsData={trendDataByService[ELECTRIC]?.meterReadingsCdd?.trendDataByBillPeriod || []}
                theme={theme}
              />
            </TabsContent>
          )}
        </Tabs>
      </ScrollArea>
    );
  }

  return (
    <div>
      {/* Service-specific insights panel */}
      <Card className="mb-6 border-2">
        <CardContent>
          <InsightCardsPanel
            serviceType={serviceType}
            documents={documents}
            meterReadings={meterReadings}
            baseloads={baseloads}
            meterReadingsBaseloads={meterReadingsBaseloads}
            renderLoadMetric={getUsageUnit}
            selectedDataSource={dataSource as DataSource}
          />
        </CardContent>
      </Card>

      {dataSource === 'utility-bills' && (
        <div className="space-y-6">
          <UsageTrendChart
            utilityBillsData={trendDataByService[serviceType].cdd.trendDataByBillPeriod}
            meterReadingsData={[]}
            units={getUsageUnit(serviceType)}
            efficiencyMeasures={serviceType === ELECTRIC ? efficiencyMeasures : []}
          />

          <UsageDegreeChart
            coolingDegreeData={trendDataByService[serviceType].cdd.trendDataByBillPeriod}
            heatingDegreeData={trendDataByService[serviceType].hdd.trendDataByBillPeriod}
            meterReadingsCddData={[]}
            meterReadingsHddData={[]}
            baseloads={baseloads}
            meterReadingsBaseloads={meterReadingsBaseloads}
            serviceType={serviceType}
            baseTemperature={baseTemperature}
            onBaseTemperatureChange={onBaseTemperatureChange}
            isUpdatingDegreeData={isUpdatingDegreeData}
            renderLoadMetric={getUsageUnit}
          />

          {serviceType === ELECTRIC && (
            <PeakDemandChart
              utilityBillsData={trendDataByService[serviceType].cdd.trendDataByBillPeriod}
              weather={weather}
            />
          )}
        </div>
      )}

      {dataSource === 'energy-star' && (
        <div className="space-y-6">
          <UsageTrendChart
            utilityBillsData={[]}
            meterReadingsData={trendDataByService[serviceType].meterReadingsCdd.trendDataByBillPeriod}
            units={getUsageUnit(serviceType)}
            efficiencyMeasures={serviceType === ELECTRIC ? efficiencyMeasures : []}
          />

          <UsageDegreeChart
            coolingDegreeData={[]}
            heatingDegreeData={[]}
            meterReadingsCddData={trendDataByService[serviceType].meterReadingsCdd.trendDataByBillPeriod}
            meterReadingsHddData={trendDataByService[serviceType].meterReadingsHdd.trendDataByBillPeriod}
            baseloads={baseloads}
            meterReadingsBaseloads={meterReadingsBaseloads}
            serviceType={serviceType}
            baseTemperature={baseTemperature}
            onBaseTemperatureChange={onBaseTemperatureChange}
            isUpdatingDegreeData={isUpdatingDegreeData}
            renderLoadMetric={getUsageUnit}
          />
        </div>
      )}
    </div>
  );
};
