import { Card, CardContent, CardHeader, CardTitle } from '@shadcn/ui/card';
import { Button } from '@shadcn/ui/button';
import { ProjectsTable } from 'app/components/projects/ProjectsTable';
import { EnergyStarPropertiesTable } from 'app/components/energy-star/EnergyStarPropertiesTable';
import type { Task } from 'app/types/task';
import type { ProjectInfoData } from 'app/services/project-services';
import { Dispatch, SetStateAction } from 'react';
import { X } from 'lucide-react';

interface MainContentProps {
  tasks: Task[];
  projects: ProjectInfoData[];
  showProjectsTable: boolean;
  showEnergyStarProperties: boolean;
  propertyScores: Record<string, number>;
  reviewingProject: { projectId: string; isVisible: boolean } | null;
  energyStarProperties: any[];
  syncedProperties: Set<string>;
  setRefreshProjects: Dispatch<SetStateAction<boolean>>;
  onTaskClick: (task: Task) => void;
  onReviewClick: (project: ProjectInfoData) => void;
  onSubmitScore: (propertyName: string) => void;
  onPropertySync: (customerId: string, propertyId: string) => void;
  onReviewClose: () => void;
  onSnackbar: (message: string, variant: 'default' | 'destructive' | 'positive') => void;
  onSyncWithEnergyStar: () => Promise<void>;
  onDismissEnergyStarProperties: () => void;
}

export const MainContent = ({
  projects,
  showProjectsTable,
  showEnergyStarProperties,
  propertyScores,
  energyStarProperties,
  syncedProperties,
  setRefreshProjects,
  onReviewClick,
  onSubmitScore,
  onPropertySync,
  onSnackbar,
  onSyncWithEnergyStar,
  onDismissEnergyStarProperties,
}: MainContentProps) => {
  const handleProjectSync = (projectId: string) => {
    onPropertySync(projectId, projectId);
  };

  return (
    <div className="flex h-full flex-1 flex-col">
      <div className="flex-1 overflow-y-auto bg-background/5">
        <div className="space-y-4 p-4">
          <div className="space-y-6">
            {showProjectsTable && (
              <Card className="rounded-xl border-border/20 bg-background/5 shadow-lg backdrop-blur-sm">
                <CardHeader>
                  <CardTitle className="text-foreground">Properties for Benchmark Report</CardTitle>
                </CardHeader>
                <CardContent>
                  <ProjectsTable
                    projects={projects}
                    propertyScores={propertyScores}
                    onReviewClick={onReviewClick}
                    onSubmitScore={onSubmitScore}
                    onSync={handleProjectSync}
                  />
                </CardContent>
              </Card>
            )}

            {showEnergyStarProperties && (
              <Card className="mx-auto flex flex-col rounded-xl border-border/20 bg-background/5 shadow-lg backdrop-blur-sm">
                <CardContent className="h-full overflow-y-auto p-0">
                  <div className="flex items-center justify-between px-6 py-4">
                    <h3 className="text-base font-semibold">Energy Star Properties</h3>
                    <Button
                      variant="ghost"
                      size="icon"
                      className="h-8 w-8 rounded-full opacity-70 transition-opacity hover:opacity-100"
                      onClick={onDismissEnergyStarProperties}
                    >
                      <X className="h-4 w-4" />
                    </Button>
                  </div>
                  <EnergyStarPropertiesTable
                    properties={energyStarProperties}
                    syncedProperties={syncedProperties}
                    projects={projects}
                    setRefreshProjects={setRefreshProjects}
                    onPropertySync={onPropertySync}
                    onSnackbar={onSnackbar}
                  />
                </CardContent>
              </Card>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
