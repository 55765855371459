import { Card, CardContent, CardHeader, CardTitle } from '@shadcn/ui';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@shadcn/ui';

export const MVChart = () => {
  // Dummy data for the chart
  const data = [
    { month: '2023-09', baseline: 45000, actual: 45200, normalized: 45100 },
    { month: '2023-10', baseline: 48000, actual: 47000, normalized: 47500 },
    { month: '2023-11', baseline: 52000, actual: 48000, normalized: 48500 },
    { month: '2023-12', baseline: 55000, actual: 49000, normalized: 49200 },
    { month: '2024-01', baseline: 54000, actual: 47000, normalized: 47500 },
    { month: '2024-02', baseline: 51000, actual: 44000, normalized: 44500 },
  ];

  const savingsMetrics = {
    projectedAnnual: '$72,000',
    actualToDate: '$68,400',
    weatherNormalized: '$70,200',
    confidence: '95%',
  };

  return (
    <Card className="border border-border/50">
      <CardHeader className="flex flex-row items-center justify-between">
        <CardTitle className="text-xl font-semibold">Measurement & Verification</CardTitle>
        <div className="flex items-center gap-4">
          <Select defaultValue="hvac">
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="Select measure" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="hvac">HVAC Optimization</SelectItem>
              <SelectItem value="lighting">LED Lighting</SelectItem>
              <SelectItem value="steam">Steam System</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </CardHeader>
      <CardContent>
        <div className="space-y-6">
          {/* M&V Chart */}
          <div className="h-[300px] w-full">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="baseline" stroke="#94a3b8" name="Baseline Usage" strokeDasharray="5 5" />
                <Line type="monotone" dataKey="actual" stroke="#22c55e" name="Actual Usage" />
                <Line type="monotone" dataKey="normalized" stroke="#3b82f6" name="Weather Normalized" />
              </LineChart>
            </ResponsiveContainer>
          </div>

          {/* Savings Summary */}
          <div className="grid grid-cols-4 gap-4 rounded-lg border border-border/50 p-4">
            <div>
              <p className="text-sm text-muted-foreground">Projected Annual</p>
              <p className="text-xl font-bold">{savingsMetrics.projectedAnnual}</p>
            </div>
            <div>
              <p className="text-sm text-muted-foreground">Actual to Date</p>
              <p className="text-xl font-bold text-green-500">{savingsMetrics.actualToDate}</p>
            </div>
            <div>
              <p className="text-sm text-muted-foreground">Weather Normalized</p>
              <p className="text-xl font-bold text-blue-500">{savingsMetrics.weatherNormalized}</p>
            </div>
            <div>
              <p className="text-sm text-muted-foreground">Confidence Level</p>
              <p className="text-xl font-bold">{savingsMetrics.confidence}</p>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
