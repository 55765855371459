import { useState, forwardRef, useMemo, useEffect } from 'react';
import { ScrollArea } from '@shadcn/ui';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@shadcn/ui';
import { cn } from '@shadcn/utils';
import {
  Loader2Icon,
  GaugeIcon,
  FileTextIcon,
  LightbulbIcon,
  ListIcon,
  BoltIcon,
  FlameIcon,
  DropletIcon,
  FuelIcon,
} from 'lucide-react';
import { ALL, UTILITY_SERVICES, ELECTRIC, GAS, STEAM, OIL, WATER } from 'app/utils/constants/utilityServices';
import { VisualizationsProps, WeatherProps } from 'app/types/visualizations';
import { getTrendData, getTrendDataMeterReadings } from 'app/utils/trendDataHelpers';
import { theme } from 'app/utils/theme';
import { WeatherSection } from 'app/components/weather/WeatherSection';
import { ServiceContent } from 'app/components/service/ServiceContent';
import { useEnergyStarData } from 'app/hooks/useEnergyStarData';
import type { ProjectDataReturn } from 'app/hooks/useProjectData';
import { NoDataFound } from 'app/components/project/NoDataFound';
import { METERS, CONED, UTILITY_BILLS } from 'app/utils/constants/dataSources';
import { ConEdAnalysis } from './ConEdAnalysis';
interface AnalysisProps extends VisualizationsProps {
  projectData: ProjectDataReturn;
}

export const Analysis = forwardRef<HTMLDivElement, AnalysisProps>(({ projectId, onSnackbar, projectData }, ref) => {
  const [isWeatherOpen, setIsWeatherOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [baseTemperature, setBaseTemperature] = useState(65);
  const [selectedView, setSelectedView] = useState('meters');
  const [selectedService, setSelectedService] = useState(ALL);
  const [localWeather, setLocalWeather] = useState<WeatherProps>({ address: '', days: [] });
  const [localZipCode, setLocalZipCode] = useState('');
  const [localStartDate, setLocalStartDate] = useState('');
  const [localEndDate, setLocalEndDate] = useState('');
  const {
    baseloads,
    documents,
    emissionsFromUtilityBills,
    emissionsFromMeterReadings,
    meterReadings,
    startDate,
    endDate,
    degreeData,
    degreeDataByBillingPeriod,
    meterReadingDegreeDataByBillingPeriod,
    meterReadingsBaseloads,
    project,
    isLoading,
    isEmissionsLoading,
    isUpdatingDegreeData,
    error,
    weather,
    zipCode,
  } = projectData;

  // Initialize local state with values from the hook
  useEffect(() => {
    setLocalWeather(weather);
    setLocalZipCode(zipCode);
    setLocalStartDate(startDate);
    setLocalEndDate(endDate);
  }, [weather, zipCode, startDate, endDate]);

  const allServices = [ALL, ...UTILITY_SERVICES];

  const trendDataByService = useMemo(() => {
    const result = {};

    UTILITY_SERVICES.forEach((serviceType) => {
      const serviceBaseload = baseloads[serviceType] || {};

      result[serviceType] = {
        cdd: getTrendData(
          documents,
          serviceBaseload,
          weather,
          degreeData,
          degreeDataByBillingPeriod,
          serviceType,
          'cdd'
        ),
        hdd: getTrendData(
          documents,
          serviceBaseload,
          weather,
          degreeData,
          degreeDataByBillingPeriod,
          serviceType,
          'hdd'
        ),
        meterReadingsCdd: getTrendDataMeterReadings(
          meterReadings || {},
          serviceBaseload,
          weather,
          degreeData,
          meterReadingDegreeDataByBillingPeriod,
          serviceType,
          'cdd'
        ),
        meterReadingsHdd: getTrendDataMeterReadings(
          meterReadings || {},
          serviceBaseload,
          weather,
          degreeData,
          meterReadingDegreeDataByBillingPeriod,
          serviceType,
          'hdd'
        ),
      };
    });
    return result;
  }, [
    documents,
    baseloads,
    weather,
    degreeData,
    degreeDataByBillingPeriod,
    meterReadings,
    meterReadingDegreeDataByBillingPeriod,
  ]);

  const handleSliderChange = (value: number[]) => {
    const newValue = value[0];
    setBaseTemperature(newValue);
  };

  const {
    energyStarScoreByDateRange,
    noScoreReason,
    error: energyStarError,
  } = useEnergyStarData(project?.attributes?.energyStarId || null, meterReadings);

  const getServiceIcon = (service: string) => {
    switch (service.toLowerCase()) {
      case 'all':
        return <ListIcon className="h-4 w-4 text-gray-400" />;
      case ELECTRIC.toLowerCase():
        return <BoltIcon className="h-4 w-4 text-yellow-400" />;
      case GAS.toLowerCase():
        return <FlameIcon className="h-4 w-4 text-orange-400" />;
      case STEAM.toLowerCase():
        return <DropletIcon className="h-4 w-4 text-blue-400" />;
      case OIL.toLowerCase():
        return <FuelIcon className="h-4 w-4 text-green-400" />;
      case WATER.toLowerCase():
        return <DropletIcon className="h-4 w-4 text-blue-400" />;
      default:
        return <ListIcon className="h-4 w-4 text-gray-400" />;
    }
  };

  if (isLoading) {
    return (
      <div className="flex h-[100%] items-center justify-center py-8">
        <Loader2Icon className={cn('h-20 w-20 animate-spin text-primary')} />
      </div>
    );
  }

  if (error) {
    console.log('--- Analysis error ', error);
    return (
      <div className="flex h-[100%] items-center justify-center py-8 text-red-500">
        <NoDataFound
          message="Error loading project data"
          submessage={error.message}
          icon={<Loader2Icon className="h-8 w-8 animate-spin text-muted-foreground" />}
        />
      </div>
    );
  }

  return (
    <div ref={ref} className="flex grow animate-fade-up-in flex-col overflow-hidden px-4 pt-6 text-gray-700">
      <div className="flex h-full w-full flex-col items-center justify-between gap-2 px-4 pb-2">
        <div className="flex h-full w-full flex-col">
          <div className="flex flex-row space-x-10">
            <div className="w-1/3">
              <Select value={selectedView} onValueChange={(value) => setSelectedView(value)}>
                <SelectTrigger className="h-12 w-full gap-2 rounded-lg border border-border/20 bg-muted/5 p-3 text-sm font-medium text-foreground shadow-[inset_0_1px_2px_rgba(0,0,0,0.1)] transition-all hover:border-primary/30 hover:bg-muted/10 focus:border-primary/50 focus:ring-2 focus:ring-primary/20">
                  <SelectValue>
                    <div className="flex items-center gap-3">
                      {(() => {
                        switch (selectedView) {
                          case METERS:
                            return (
                              <>
                                <span>Data Source:</span>
                                <span>Energy Star</span>
                                <GaugeIcon className="h-4 w-4 text-primary" />
                              </>
                            );
                          case CONED:
                            return (
                              <>
                                <span>Data Source:</span>
                                <span>ConEdison</span>
                                <LightbulbIcon className="h-4 w-4 text-primary" />
                              </>
                            );
                          case UTILITY_BILLS:
                            return (
                              <>
                                <span>Data Source:</span>
                                <span>Utility Bills</span>
                                <FileTextIcon className="h-4 w-4 text-primary" />
                              </>
                            );
                          default:
                            return 'Select view';
                        }
                      })()}
                    </div>
                  </SelectValue>
                </SelectTrigger>
                <SelectContent className="max-h-[300px] overflow-y-auto rounded-lg border border-border/20 bg-background p-1 shadow-lg">
                  <SelectItem
                    value={METERS}
                    className="relative flex cursor-pointer select-none items-center rounded-md px-3 py-2 text-sm outline-none transition-colors hover:bg-muted focus:bg-muted"
                  >
                    <div className="flex w-full items-center gap-2">
                      <GaugeIcon className="h-4 w-4 text-primary" />
                      <span>Energy Star</span>
                    </div>
                  </SelectItem>
                  <SelectItem
                    value={CONED}
                    className="relative flex cursor-pointer select-none items-center rounded-md px-3 py-2 text-sm outline-none transition-colors hover:bg-muted focus:bg-muted"
                  >
                    <div className="flex w-full items-center gap-2">
                      <LightbulbIcon className="h-4 w-4 text-primary" />
                      <span>ConEdison</span>
                    </div>
                  </SelectItem>
                  <SelectItem
                    value={UTILITY_BILLS}
                    className="relative flex cursor-pointer select-none items-center rounded-md px-3 py-2 text-sm outline-none transition-colors hover:bg-muted focus:bg-muted"
                  >
                    <div className="flex w-full items-center gap-2">
                      <FileTextIcon className="h-4 w-4 text-primary" />
                      <span>Utility Bills</span>
                    </div>
                  </SelectItem>
                </SelectContent>
              </Select>
            </div>
            <div className="w-1/3">
              <Select value={selectedService} onValueChange={(value) => setSelectedService(value)}>
                <SelectTrigger className="h-12 w-full gap-2 rounded-lg border border-border/20 bg-muted/5 p-3 text-sm font-medium text-foreground shadow-[inset_0_1px_2px_rgba(0,0,0,0.1)] transition-all hover:border-primary/30 hover:bg-muted/10 focus:border-primary/50 focus:ring-2 focus:ring-primary/20">
                  <SelectValue>
                    <div className="flex items-center gap-3">
                      <span>Service:</span>
                      <span className="capitalize">{selectedService}</span>
                      {getServiceIcon(selectedService)}
                    </div>
                  </SelectValue>
                </SelectTrigger>
                <SelectContent className="max-h-[300px] overflow-y-auto rounded-lg border border-border/20 bg-background p-1 shadow-lg">
                  {allServices.map((service) => (
                    <SelectItem
                      key={service.toLowerCase()}
                      value={service.toLowerCase()}
                      className="relative flex cursor-pointer select-none items-center rounded-md px-3 py-2 text-sm outline-none transition-colors hover:bg-muted focus:bg-muted"
                    >
                      <div className="ml-4 flex w-full items-center gap-2">
                        {getServiceIcon(service)}
                        <span className="capitalize">{service}</span>
                      </div>
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <div className="w-2/3">
              <WeatherSection
                projectId={projectId}
                documents={documents}
                meterReadings={meterReadings}
                weather={localWeather}
                isWeatherOpen={isWeatherOpen}
                isWeatherLoading={isLoading}
                isEditing={isEditing}
                zipCode={localZipCode}
                startDate={localStartDate}
                endDate={localEndDate}
                onWeatherChange={setLocalWeather}
                onWeatherOpenChange={setIsWeatherOpen}
                onEditingChange={setIsEditing}
                onZipCodeChange={setLocalZipCode}
                onStartDateChange={setLocalStartDate}
                onEndDateChange={setLocalEndDate}
                onSnackbar={onSnackbar}
              />
            </div>
          </div>

          <ScrollArea className="flex-1 p-2 text-gray-700">
            {selectedView === METERS && (
              <div className="w-full">
                <ServiceContent
                  serviceType={selectedService}
                  dataSource="energy-star"
                  documents={documents}
                  meterReadings={meterReadings}
                  trendDataByService={trendDataByService}
                  baseloads={baseloads}
                  meterReadingsBaseloads={meterReadingsBaseloads}
                  baseTemperature={baseTemperature}
                  isUpdatingDegreeData={isUpdatingDegreeData}
                  weather={weather}
                  onBaseTemperatureChange={handleSliderChange}
                  project={project}
                  emissionsFromUtilityBills={emissionsFromUtilityBills}
                  emissionsFromMeterReadings={emissionsFromMeterReadings}
                  isEmissionsLoading={isEmissionsLoading}
                  projectId={projectId}
                  energyStarScoreByDateRange={energyStarScoreByDateRange}
                  noScoreReason={noScoreReason}
                  theme={theme}
                />
              </div>
            )}

            {selectedView === CONED && <ConEdAnalysis project={project} />}

            {selectedView === UTILITY_BILLS && (
              <div className="w-full">
                <ServiceContent
                  serviceType={selectedService}
                  dataSource="utility-bills"
                  documents={documents}
                  meterReadings={meterReadings}
                  trendDataByService={trendDataByService}
                  baseloads={baseloads}
                  meterReadingsBaseloads={meterReadingsBaseloads}
                  baseTemperature={baseTemperature}
                  isUpdatingDegreeData={isUpdatingDegreeData}
                  weather={weather}
                  onBaseTemperatureChange={handleSliderChange}
                  project={project}
                  emissionsFromUtilityBills={emissionsFromUtilityBills}
                  emissionsFromMeterReadings={emissionsFromMeterReadings}
                  isEmissionsLoading={isEmissionsLoading}
                  projectId={projectId}
                  energyStarScoreByDateRange={energyStarScoreByDateRange}
                  noScoreReason={noScoreReason}
                  theme={theme}
                />
              </div>
            )}
          </ScrollArea>
        </div>
      </div>
    </div>
  );
});
