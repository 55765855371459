import { useEffect, useState } from 'react';
import { Tabs, TabsContent, Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@shadcn/ui';
import { ServiceTabs } from 'app/components/service/ServiceTabs';
import { UTILITY_SERVICES } from 'app/utils/constants/utilityServices';
import { LightbulbIcon, Loader2 } from 'lucide-react';
import { NoDataFound } from 'app/components/project/NoDataFound';
import { useConEdisonData } from 'app/hooks/useConEdisonData';
import { ProjectInfoData } from 'app/services/project-services';

interface ServiceLocation {
  serviceLocationId: string;
  meters: Array<{
    serialNumber: number;
    meterReadings: Array<{
      id: string;
      type: string;
      intervalDuration: string;
    }>;
  }>;
}

export interface CustomerMeter {
  customerAgreementId: string;
  serviceLocations: ServiceLocation[];
}

interface ConEdTabProps {
  project: ProjectInfoData | null;
}

export const ConEdTab = ({ project }: ConEdTabProps) => {
  const allServices = ['All'];
  const { customerMeters: conEdCustomerMeters, isLoading } = useConEdisonData(project);

  if (isLoading) {
    return (
      <div className="flex h-[100%] items-center justify-center py-8">
        <Loader2 className="h-20 w-20 animate-spin text-primary" />
      </div>
    );
  }

  if (conEdCustomerMeters.length === 0) {
    return (
      <NoDataFound
        message="No ConEd data found"
        submessage="Please connect this project to a ConEd account to get started"
        icon={<LightbulbIcon className="h-8 w-8 text-muted-foreground" />}
      />
    );
  }

  return (
    <Tabs defaultValue="all" className="w-full">
      <ServiceTabs allServices={allServices} />

      {allServices.map((serviceType) => (
        <TabsContent key={serviceType} value={serviceType.toLowerCase()}>
          <div className="space-y-4">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Service Location</TableHead>
                  <TableHead>Meters</TableHead>
                  <TableHead>Available Readings</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {conEdCustomerMeters.flatMap((meter) =>
                  meter.serviceLocations.map((location) => (
                    <TableRow key={location.serviceLocationId}>
                      <TableCell className="font-medium">{location.serviceLocationId}</TableCell>
                      <TableCell>{location.meters.map((meter) => meter.serialNumber).join(', ')}</TableCell>
                      <TableCell>
                        {location.meters.map((meter) => (
                          <div key={meter.serialNumber} className="space-y-1">
                            {meter.meterReadings
                              .filter((reading) => reading.type !== 'Unknown')
                              .map((reading) => (
                                <div key={reading.id} className="text-sm">
                                  {reading.type} - {reading.intervalDuration}
                                </div>
                              ))}
                          </div>
                        ))}
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </div>
        </TabsContent>
      ))}
    </Tabs>
  );
};
