import { useEffect, useState } from 'react';
import { Card, Label } from '@shadcn/ui';
import { InputWrapper } from '@shadcn/custom/InputWrapper';
import { Button } from '@shadcn/ui';
import { RulerIcon, XIcon } from 'lucide-react';

import projectServices from 'app/services/project-services';
import { PropertyUse } from 'app/services/project-services';
import { BasicBuildingInfoData } from 'app/utils/constants/buildingInfoStructure';

interface BuildingUseTypeInputsProps {
  projectInfo: BasicBuildingInfoData & {
    propertyUses: PropertyUse[];
  };
  handlePropertyUseChange: (propertyUses: PropertyUse[]) => void;
  validationErrors: Partial<Record<keyof BasicBuildingInfoData, string>>;
}

export const BuildingUseTypeInputs = ({
  projectInfo,
  handlePropertyUseChange,
  validationErrors,
}: BuildingUseTypeInputsProps) => {
  const [propertyTypeList, setPropertyTypeList] = useState<string[]>([]);

  useEffect(() => {
    let isMounted = true;

    const fetchPropertyList = async () => {
      try {
        const propertyTypes = await projectServices.getPropertyTypes();
        if (isMounted) {
          setPropertyTypeList(propertyTypes);
        }
      } catch (error) {
        console.error('Failed to fetch property types:', error);
      }
    };

    fetchPropertyList();

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div className="flex flex-col gap-4">
      <div className="mt-4">
        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <Label className="text-lg font-medium">Property Uses</Label>
            <Button
              type="button"
              variant="outline"
              onClick={() => {
                const newPropertyUses = [
                  ...(projectInfo.propertyUses || []),
                  { propertyUse: '', totalGrossFloorArea: 0 },
                ];
                handlePropertyUseChange(newPropertyUses);
              }}
              className="border-primary/20 bg-primary/5 text-primary transition-all duration-200 hover:scale-105 hover:bg-primary/10"
            >
              Add Property Use
            </Button>
          </div>

          {!projectInfo.propertyUses || projectInfo.propertyUses.length === 0 ? (
            <div className="rounded-md bg-muted/50 p-6 text-center">
              <p className="text-muted-foreground">
                No property uses added yet. Click "Add Property Use" to get started.
              </p>
            </div>
          ) : (
            projectInfo.propertyUses.map((propertyUse, index) => (
              <Card key={index} className="p-4">
                <div className="grid gap-4">
                  <div className="flex items-center justify-between">
                    <h4 className="text-md font-medium">Property Use {index + 1}</h4>
                    <Button
                      type="button"
                      variant="ghost"
                      onClick={() => {
                        const newPropertyUses = projectInfo.propertyUses.filter((_, i) => i !== index);
                        handlePropertyUseChange(newPropertyUses);
                      }}
                      className="text-destructive hover:bg-destructive/10"
                    >
                      <XIcon className="h-4 w-4" />
                    </Button>
                  </div>

                  <div>
                    <Label htmlFor={`propertyUse-${index}`}>Property Use Type</Label>
                    <div className="mt-1">
                      <select
                        id={`propertyUse-${index}`}
                        className="w-full rounded-md border border-input bg-background px-3 py-2"
                        value={propertyUse.propertyUse}
                        onChange={(e) => {
                          const newPropertyUses = [...projectInfo.propertyUses];
                          newPropertyUses[index] = { ...propertyUse, propertyUse: e.target.value };
                          handlePropertyUseChange(newPropertyUses);
                        }}
                      >
                        <option value="">Select Property Use</option>
                        {(propertyTypeList || []).map((propertyType) => (
                          <option key={propertyType} value={propertyType}>
                            {propertyType}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <InputWrapper
                    label="Total Gross Floor Area (sq ft)"
                    inputStartAdornment={<RulerIcon className="stroke-foreground" />}
                    type="number"
                    step="0.01"
                    value={propertyUse.totalGrossFloorArea.toString()}
                    onChange={(e) => {
                      const newPropertyUses = [...projectInfo.propertyUses];
                      newPropertyUses[index] = {
                        ...propertyUse,
                        totalGrossFloorArea: parseFloat(e.target.value) || 0,
                      };
                      handlePropertyUseChange(newPropertyUses);
                    }}
                  />
                </div>
              </Card>
            ))
          )}

          {projectInfo.propertyUses && projectInfo.propertyUses.length > 0 && (
            <div className="mt-4 rounded-md bg-muted/50 p-4">
              <div className="flex items-center justify-between">
                <h4 className="text-md font-medium">Total Gross Floor Area</h4>
                <span className="text-lg font-semibold">
                  {projectInfo.propertyUses.reduce((sum, use) => sum + use.totalGrossFloorArea, 0).toLocaleString()} sq
                  ft
                </span>
              </div>
            </div>
          )}

          {validationErrors.propertyUses && (
            <div className="rounded-md bg-destructive/10 p-3 text-destructive">{validationErrors.propertyUses}</div>
          )}
        </div>
      </div>
    </div>
  );
};
