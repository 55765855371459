import { Button, Card } from '@shadcn/ui';
import { Label } from '@shadcn/ui';
import {
  BasicBuildingInfoData,
  BUILDING_SERVICES,
  BuildingSystemData,
  CORE_BUILDING_SYSTEMS,
  BuildingSystemSpecification,
} from 'app/utils/constants/buildingInfoStructure';
import { XIcon } from 'lucide-react';

// Define valid system categories
type ServiceId = (typeof BUILDING_SERVICES)[number]['id'];
type CoreSystemCategory = 'hvac' | 'lighting' | 'plug_load';
type SystemCategory = ServiceId | CoreSystemCategory;

// Define the extended building info type
interface ExtendedBuildingInfo extends BasicBuildingInfoData {
  services: string[];
  buildingSystems?: {
    [key in SystemCategory]?: BuildingSystemData[];
  };
}

// Component props are now much cleaner
interface BuildingSystemsProps {
  projectInfo: ExtendedBuildingInfo;
  setProjectInfo: React.Dispatch<React.SetStateAction<ExtendedBuildingInfo>>;
}

const getServiceById = (serviceId: string) => BUILDING_SERVICES.find((service) => service.id === serviceId);

const getSystemById = (serviceId: string, systemId: string) =>
  getServiceById(serviceId)?.systems.find((system) => system.id === systemId);

export const BuildingSystems = ({ projectInfo, setProjectInfo }: BuildingSystemsProps) => {
  // Filter building services to only show those that are selected in services
  const availableServices = BUILDING_SERVICES.filter((service) => projectInfo.services.includes(service.id));

  // If no services are selected, show a message
  if (availableServices.length === 0) {
    return (
      <div className="rounded-md bg-muted/50 p-6 text-center">
        <p className="text-muted-foreground">Please select utility services first to configure building systems.</p>
      </div>
    );
  }

  const addBuildingSystem = (category: SystemCategory) => {
    setProjectInfo((prev) => ({
      ...prev,
      buildingSystems: {
        ...prev.buildingSystems,
        [category]: [...(prev.buildingSystems?.[category] || []), { systemId: '', specifications: {} }],
      },
    }));
  };

  const removeBuildingSystem = (category: SystemCategory, index: number) => {
    setProjectInfo((prev) => ({
      ...prev,
      buildingSystems: {
        ...prev.buildingSystems,
        [category]: (prev.buildingSystems?.[category] || []).filter((_, i) => i !== index),
      },
    }));
  };

  const updateBuildingSystem = (category: SystemCategory, index: number, data: Partial<BuildingSystemData>) => {
    setProjectInfo((prev) => ({
      ...prev,
      buildingSystems: {
        ...prev.buildingSystems,
        [category]: (prev.buildingSystems?.[category] || []).map((system, i) =>
          i === index ? { ...system, ...data } : system
        ),
      },
    }));
  };

  const shouldShowSpecification = (spec: BuildingSystemSpecification, system: BuildingSystemData) => {
    if (!spec.dependsOn) return true;

    for (const [field, allowedValues] of Object.entries(spec.dependsOn)) {
      const currentValue = system.specifications[field]?.toString();
      if (!currentValue) return false;

      const matches = allowedValues.some((allowedValue) => {
        if (typeof allowedValue === 'function') {
          return allowedValue(currentValue);
        }
        return allowedValue.toString() === currentValue;
      });

      if (!matches) return false;
    }
    return true;
  };

  return (
    <div className="flex flex-col gap-6">
      {/* Utility-based Systems */}
      {availableServices.map((service) => (
        <div key={service.id} className="space-y-4">
          <div className="flex items-center justify-between">
            <Label className="text-lg font-medium">{service.name} Systems</Label>
            <Button
              type="button"
              variant="outline"
              onClick={() => addBuildingSystem(service.id as SystemCategory)}
              className="border-primary/20 bg-primary/5 text-primary transition-all duration-200 hover:scale-105 hover:bg-primary/10"
            >
              Add System
            </Button>
          </div>

          {!projectInfo.buildingSystems?.[service.id] || projectInfo.buildingSystems[service.id]?.length === 0 ? (
            <div className="rounded-md bg-muted/50 p-6 text-center">
              <p className="text-muted-foreground">No systems added yet. Click "Add System" to get started.</p>
            </div>
          ) : (
            projectInfo.buildingSystems[service.id]?.map((system, index) => (
              <Card key={index} className="p-4">
                <div className="grid gap-4">
                  <div className="flex items-center justify-between">
                    <h4 className="text-md font-medium">System {index + 1}</h4>
                    <Button
                      type="button"
                      variant="ghost"
                      onClick={() => removeBuildingSystem(service.id as SystemCategory, index)}
                      className="text-destructive hover:bg-destructive/10"
                    >
                      <XIcon className="h-4 w-4" />
                    </Button>
                  </div>

                  {/* System Selection */}
                  <div>
                    <Label className="flex items-center gap-1">
                      System Type
                      <span className="text-destructive">*</span>
                    </Label>
                    <select
                      className="w-full rounded-md border border-input bg-background px-3 py-2"
                      value={system.systemId}
                      onChange={(e) => {
                        updateBuildingSystem(service.id as SystemCategory, index, {
                          systemId: e.target.value,
                          specifications: {},
                        });
                      }}
                    >
                      <option value="">Select System</option>
                      {service.systems.map((sys) => (
                        <option key={sys.id} value={sys.id}>
                          {sys.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* Component Selection */}
                  {system.systemId && (
                    <div>
                      <Label>Components</Label>
                      <div className="mt-2 space-y-2">
                        {getSystemById(service.id, system.systemId)?.components.map((component) => (
                          <div key={component.id} className="flex items-center space-x-2">
                            <input
                              type="checkbox"
                              id={`component-${index}-${component.id}`}
                              checked={system.specifications[component.id] || false}
                              onChange={(e) => {
                                updateBuildingSystem(service.id as SystemCategory, index, {
                                  specifications: {
                                    ...system.specifications,
                                    [component.id]: e.target.checked,
                                  },
                                });
                              }}
                              className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                            />
                            <Label htmlFor={`component-${index}-${component.id}`} className="text-sm">
                              {component.name}
                            </Label>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </Card>
            ))
          )}
        </div>
      ))}

      {/* Core Building Systems */}
      {CORE_BUILDING_SYSTEMS.map((coreSystem) => (
        <div key={coreSystem.id} className="space-y-4">
          <div className="flex items-center justify-between">
            <Label className="text-lg font-medium">{coreSystem.name}</Label>
            <Button
              type="button"
              variant="outline"
              onClick={() => addBuildingSystem(coreSystem.category)}
              className="border-primary/20 bg-primary/5 text-primary transition-all duration-200 hover:scale-105 hover:bg-primary/10"
            >
              Add System
            </Button>
          </div>

          {!projectInfo.buildingSystems?.[coreSystem.category] ||
          projectInfo.buildingSystems[coreSystem.category]?.length === 0 ? (
            <div className="rounded-md bg-muted/50 p-6 text-center">
              <p className="text-muted-foreground">No systems added yet. Click "Add System" to get started.</p>
            </div>
          ) : (
            projectInfo.buildingSystems[coreSystem.category]?.map((system, index) => (
              <Card key={index} className="p-4">
                <div className="grid gap-4">
                  <div className="flex items-center justify-between">
                    <h4 className="text-md font-medium">
                      {coreSystem.name} {index + 1}
                    </h4>
                    <Button
                      type="button"
                      variant="ghost"
                      onClick={() => removeBuildingSystem(coreSystem.category, index)}
                      className="text-destructive hover:bg-destructive/10"
                    >
                      <XIcon className="h-4 w-4" />
                    </Button>
                  </div>

                  {/* Specifications */}
                  {coreSystem.specifications.map(
                    (spec) =>
                      shouldShowSpecification(spec, system) && (
                        <div key={spec.id}>
                          <Label className="flex items-center gap-1">
                            {spec.name}
                            {spec.required && <span className="text-destructive">*</span>}
                          </Label>
                          {spec.type === 'select' && (
                            <select
                              className="w-full rounded-md border border-input bg-background px-3 py-2"
                              value={system.specifications[spec.id] || ''}
                              onChange={(e) => {
                                updateBuildingSystem(coreSystem.category, index, {
                                  specifications: {
                                    ...system.specifications,
                                    [spec.id]: e.target.value,
                                  },
                                });
                              }}
                            >
                              <option value="">Select {spec.name}</option>
                              {spec.options?.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          )}
                          {spec.type === 'number' && (
                            <div className="flex items-center space-x-2">
                              <input
                                type="number"
                                id={`spec-${index}-${spec.id}`}
                                value={system.specifications[spec.id] || ''}
                                min={spec.min}
                                max={spec.max}
                                onChange={(e) => {
                                  const value = e.target.value ? parseInt(e.target.value) : '';
                                  updateBuildingSystem(coreSystem.category, index, {
                                    specifications: {
                                      ...system.specifications,
                                      [spec.id]: value,
                                    },
                                  });
                                }}
                                className="w-24 rounded-md border border-input bg-background px-3 py-2"
                              />
                              {spec.unit && <span className="text-sm text-muted-foreground">{spec.unit}</span>}
                            </div>
                          )}
                          {spec.type === 'boolean' && (
                            <div className="flex items-center space-x-2">
                              <input
                                type="checkbox"
                                id={`spec-${index}-${spec.id}`}
                                checked={system.specifications[spec.id] || false}
                                onChange={(e) => {
                                  updateBuildingSystem(coreSystem.category, index, {
                                    specifications: {
                                      ...system.specifications,
                                      [spec.id]: e.target.checked,
                                    },
                                  });
                                }}
                                className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                              />
                              <Label htmlFor={`spec-${index}-${spec.id}`} className="text-sm">
                                {spec.description || spec.name}
                              </Label>
                            </div>
                          )}
                          {spec.type === 'multiselect' && (
                            <div className="mt-2 space-y-2">
                              {spec.options?.map((option) => (
                                <div key={option} className="flex items-center space-x-2">
                                  <input
                                    type="checkbox"
                                    id={`spec-${index}-${spec.id}-${option}`}
                                    checked={
                                      Array.isArray(system.specifications[spec.id]) &&
                                      system.specifications[spec.id].includes(option)
                                    }
                                    onChange={(e) => {
                                      const currentValues = Array.isArray(system.specifications[spec.id])
                                        ? system.specifications[spec.id]
                                        : [];
                                      const newValues = e.target.checked
                                        ? [...currentValues, option]
                                        : currentValues.filter((v) => v !== option);
                                      updateBuildingSystem(coreSystem.category, index, {
                                        specifications: {
                                          ...system.specifications,
                                          [spec.id]: newValues,
                                        },
                                      });
                                    }}
                                    className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                                  />
                                  <Label htmlFor={`spec-${index}-${spec.id}-${option}`} className="text-sm">
                                    {option}
                                  </Label>
                                </div>
                              ))}
                            </div>
                          )}
                          {spec.description && spec.type !== 'boolean' && (
                            <p className="mt-1 text-sm text-muted-foreground">{spec.description}</p>
                          )}
                        </div>
                      )
                  )}
                </div>
              </Card>
            ))
          )}
        </div>
      ))}
    </div>
  );
};
