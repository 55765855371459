import { useState } from 'react';
import { Card } from '@shadcn/ui/card';
import { Button } from '@shadcn/ui/button';
import { Badge } from '@shadcn/ui';
import { PlusIcon, TargetIcon, CalendarIcon, PencilRulerIcon } from 'lucide-react';
import type { EfficiencyMeasure } from 'app/types/efficiency-measures';
import { UsageTrendChart } from '../charts/UsageTrendChart';
import { formatNumber } from 'app/utils/formatters';
import { ProjectInfoData } from 'app/services/project-services';

interface EfficiencyDashboardProps {
  project: ProjectInfoData;
  measures: EfficiencyMeasure[];
  onAddMeasure: () => void;
  utilityBillsData: any[];
  meterReadingsData: any[];
  theme: any;
}

export const EfficiencyDashboard = ({
  measures,
  onAddMeasure,
  utilityBillsData,
  meterReadingsData,
  theme,
  project,
}: EfficiencyDashboardProps) => {
  const [selectedMeasure, setSelectedMeasure] = useState<string | null>(measures[0]?.id || null);

  const getTotalSavings = () => {
    return measures.reduce(
      (acc, measure) => {
        Object.entries(measure.expectedSavings).forEach(([service, savings]) => {
          if (!acc[service]) acc[service] = { amount: 0, unit: savings.unit };
          acc[service].amount += savings.amount;
        });
        return acc;
      },
      {} as Record<string, { amount: number; unit: string }>
    );
  };

  const getImplementationStatus = (measure: EfficiencyMeasure) => {
    const now = new Date();
    if (now < measure.startDate) return 'Planned';
    if (now > measure.endDate) return 'Completed';
    return 'In Progress';
  };

  return (
    <div className="space-y-8">
      {/* Overview Section */}
      <section>
        <h2 className="mb-4 flex items-center gap-2 text-2xl font-bold" style={{ color: theme.text.primary }}>
          Efficiency Measures Overview
          <Badge variant="secondary" className="text-xs font-normal">
            Beta
          </Badge>
        </h2>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
          <Card className="p-6">
            <div className="flex items-center gap-4">
              <PencilRulerIcon className="h-8 w-8 text-green-500" />
              <div>
                <h3 className="text-sm font-medium text-gray-500">Total Measures</h3>
                <p className="text-2xl font-bold">{measures.length}</p>
              </div>
            </div>
          </Card>
          <Card className="p-6">
            <div className="flex items-center gap-4">
              <TargetIcon className="h-8 w-8 text-blue-500" />
              <div>
                <h3 className="text-sm font-medium text-gray-500">Expected Savings</h3>
                <div className="space-y-1">
                  {Object.entries(getTotalSavings()).map(([service, savings]) => (
                    <p key={service} className="text-lg font-semibold">
                      {formatNumber(savings.amount)} {savings.unit} ({service})
                    </p>
                  ))}
                </div>
              </div>
            </div>
          </Card>
          <Card className="p-6">
            <div className="flex items-center gap-4">
              <CalendarIcon className="h-8 w-8 text-purple-500" />
              <div>
                <h3 className="text-sm font-medium text-gray-500">Active Measures</h3>
                <p className="text-2xl font-bold">
                  {measures.filter((m) => getImplementationStatus(m) === 'In Progress').length}
                </p>
              </div>
            </div>
          </Card>
        </div>
      </section>

      {/* Measures List */}
      <section>
        <div className="mb-4 flex items-center justify-between">
          <h2 className="text-2xl font-bold" style={{ color: theme.text.primary }}>
            Efficiency Measures
          </h2>
          <Button onClick={onAddMeasure} className="gap-2">
            <PlusIcon className="h-4 w-4" />
            Add Measure
          </Button>
        </div>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3">
          {measures.map((measure) => (
            <Card
              key={measure.id}
              className={`ml-4 cursor-pointer p-6 transition-all hover:shadow-lg ${
                selectedMeasure === measure.id ? 'ring-2 ring-blue-500' : ''
              }`}
              onClick={() => setSelectedMeasure(measure.id)}
            >
              <h3 className="mb-2 text-lg font-semibold">{measure.name}</h3>
              <p className="mb-4 text-sm text-gray-500">{measure.description}</p>
              <div className="space-y-2">
                <div className="flex justify-between">
                  <span className="text-sm text-gray-500">Status</span>
                  <span
                    className={`rounded-full px-2 py-1 text-xs font-medium ${
                      getImplementationStatus(measure) === 'In Progress'
                        ? 'bg-blue-100 text-blue-800'
                        : getImplementationStatus(measure) === 'Completed'
                          ? 'bg-green-100 text-green-800'
                          : 'bg-gray-100 text-gray-800'
                    }`}
                  >
                    {getImplementationStatus(measure)}
                  </span>
                </div>
                <div className="flex justify-between">
                  <span className="text-sm text-gray-500">Timeline</span>
                  <span className="text-sm">
                    {measure.startDate.toLocaleDateString()} - {measure.endDate.toLocaleDateString()}
                  </span>
                </div>
                <div className="space-y-1">
                  <span className="text-sm text-gray-500">Expected Savings</span>
                  {Object.entries(measure.expectedSavings).map(([service, savings]) => (
                    <div key={service} className="flex justify-between">
                      <span className="text-sm capitalize">{service}</span>
                      <span className="text-sm font-medium">
                        {formatNumber(savings.amount)} {savings.unit}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </Card>
          ))}
        </div>
      </section>

      {/* Analysis Section */}
      <section>
        <h2 className="mb-4 text-2xl font-bold" style={{ color: theme.text.primary }}>
          Impact Analysis
        </h2>
        <Card className="p-6">
          <UsageTrendChart
            project={project}
            utilityBillsData={utilityBillsData}
            meterReadingsData={meterReadingsData}
            units="kWh"
            efficiencyMeasures={measures.filter((m) => selectedMeasure === m.id)}
          />
        </Card>
      </section>
    </div>
  );
};
