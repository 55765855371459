import { Label } from '@shadcn/ui';
import { ServiceSelectionTiles } from 'app/components/shared/ServiceSelectionTiles';
import { UTILITY_SERVICES } from 'app/utils/constants/utilityServices';
import { BasicBuildingInfoData, buildingServicesFields } from 'app/utils/constants/buildingInfoStructure';
import { InputWrapper } from '@shadcn/custom/InputWrapper';

interface BuildingServicesInputProps {
  buildingInfo: BasicBuildingInfoData;
  errors: Partial<Record<keyof BasicBuildingInfoData, string>>;
  handleServicesChange: (services: string[]) => void;
  handleInputChange: (event: any) => void;
  isEditing: boolean;
}

export const BuildingServicesInput = ({
  buildingInfo,
  errors,
  handleServicesChange,
  handleInputChange,
  isEditing,
}: BuildingServicesInputProps) => {
  return (
    <div className="flex flex-col gap-4">
      <div className="mt-4">
        <Label className="mb-4 block text-lg font-medium">Choose your utility services</Label>
        <ServiceSelectionTiles
          services={UTILITY_SERVICES}
          selectedServices={buildingInfo.services}
          onChange={handleServicesChange}
          error={errors.services}
          disabled={!isEditing}
        />
      </div>

      <div className="mt-4">
        <Label className="mb-4 block text-lg font-medium">Additional Building Services</Label>
        <div className="grid gap-4">
          {buildingServicesFields.map((field) => (
            <div key={field.name}>
              {field.type === 'boolean' ? (
                <div className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    id={field.name}
                    name={field.name}
                    className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                    checked={buildingInfo.buildingServices?.[field.name]}
                    onChange={(e) =>
                      handleInputChange({
                        target: {
                          name: field.name,
                          value: e.target.checked,
                          type: 'checkbox',
                        },
                      })
                    }
                    disabled={!isEditing}
                  />
                  <Label htmlFor={field.name} className="text-sm font-medium">
                    {field.label}
                  </Label>
                </div>
              ) : (
                field.dependsOn &&
                buildingInfo.buildingServices?.[Object.keys(field.dependsOn)[0]] && (
                  <InputWrapper
                    label={field.label}
                    inputStartAdornment={<field.icon className="stroke-foreground" />}
                    name={field.name}
                    type={field.type}
                    value={buildingInfo.buildingServices?.[field.name]}
                    onChange={handleInputChange}
                    error={!!errors[field.name]}
                    helperText={errors[field.name]}
                    disabled={!isEditing}
                  />
                )
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
