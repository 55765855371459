import { ResponsiveContainer, ComposedChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar, Line } from 'recharts';
import { BaseTemperatureCard } from '../cards/BaseTemperatureCard';
import { NoDataFound } from '../project/NoDataFound';
import { BarChartIcon, Thermometer } from 'lucide-react';
import { CollapsibleChart } from './CollapsibleChart';
import { TrendDataPoint } from 'app/types/visualizations';
import { useState, useMemo } from 'react';
import { Label } from '@shadcn/ui/label';
import { Switch } from '../ui/Switch';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@shadcn/ui/select';
import {
  sharedXAxisProps,
  createYAxisProps,
  sharedCartesianGridProps,
  sharedTooltipProps,
  sharedLegendProps,
} from './shared/chartProps';
import { DataSource } from 'app/types/visualizations';

interface UsageDegreeChartProps {
  coolingDegreeData: TrendDataPoint[];
  heatingDegreeData: TrendDataPoint[];
  meterReadingsCddData: TrendDataPoint[];
  meterReadingsHddData: TrendDataPoint[];
  baseloads: any;
  meterReadingsBaseloads: any;
  serviceType: string;
  baseTemperature: number;
  onBaseTemperatureChange: (value: number[]) => void;
  isUpdatingDegreeData: boolean;
  renderLoadMetric: (serviceType: string) => string;
}

export const UsageDegreeChart = ({
  coolingDegreeData,
  heatingDegreeData,
  meterReadingsCddData,
  meterReadingsHddData,
  baseloads,
  meterReadingsBaseloads,
  serviceType,
  baseTemperature,
  onBaseTemperatureChange,
  isUpdatingDegreeData,
  renderLoadMetric,
}: UsageDegreeChartProps) => {
  const [showCoolingDegrees, setShowCoolingDegrees] = useState(true);
  const [showHeatingDegrees, setShowHeatingDegrees] = useState(false);
  const [selectedMeter, setSelectedMeter] = useState<string>('');
  const [selectedYear, setSelectedYear] = useState<string>('all');
  const [dataSource, setDataSource] = useState<DataSource>(() => {
    const hasUtilityBillsData = coolingDegreeData.length > 0 || heatingDegreeData.length > 0;
    const hasMeterReadingsData = meterReadingsCddData.length > 0 || meterReadingsHddData.length > 0;

    if (hasUtilityBillsData) return 'utility-bills';
    if (hasMeterReadingsData) return 'energy-star';
    return 'utility-bills'; // default fallback
  });

  const meterNames = useMemo(() => {
    const names = new Set<string>();
    [...meterReadingsCddData, ...meterReadingsHddData].forEach((reading) => {
      if (reading.meterName) {
        names.add(reading.meterName);
      }
    });
    return Array.from(names);
  }, [meterReadingsCddData, meterReadingsHddData]);

  // Initialize selected meter if empty and meter data exists
  useMemo(() => {
    if (!selectedMeter && meterNames.length > 0) {
      setSelectedMeter(meterNames[0]);
    }
  }, [meterNames, selectedMeter]);

  // Extract available years from the data
  const availableYears = useMemo(() => {
    const years = new Set<string>();
    const data =
      dataSource === 'utility-bills'
        ? [...coolingDegreeData, ...heatingDegreeData]
        : [...meterReadingsCddData, ...meterReadingsHddData];

    data.forEach((item) => {
      const year = new Date(item.monthYear).getFullYear().toString();
      years.add(year);
    });

    return Array.from(years).sort().reverse();
  }, [dataSource, coolingDegreeData, heatingDegreeData, meterReadingsCddData, meterReadingsHddData]);

  // Filter data by selected year
  const filterDataByYear = (data: any[]) => {
    if (selectedYear === 'all') return data;

    return data.filter((item) => {
      const itemYear = new Date(item.monthYear).getFullYear().toString();
      return itemYear === selectedYear;
    });
  };

  const handleSwitchChange = (isHeating: boolean, value: boolean) => {
    if (isHeating) {
      setShowHeatingDegrees(value);
      if (value) {
        setShowCoolingDegrees(false);
      }
    } else {
      setShowCoolingDegrees(value);
      if (value) {
        setShowHeatingDegrees(false);
      }
    }
  };

  const getDegreeLabel = () => {
    if (showCoolingDegrees) return 'Cooling Degree Days';
    if (showHeatingDegrees) return 'Heating Degree Days';
    return 'Degree Days';
  };

  const getChartData = () => {
    if (dataSource === 'utility-bills') {
      const sourceData = {
        mainData: showCoolingDegrees ? coolingDegreeData : heatingDegreeData || [],
        coolingData: coolingDegreeData || [],
        heatingData: heatingDegreeData || [],
      };

      return filterDataByYear(
        sourceData.mainData.filter(Boolean).map((d) => ({
          ...d,
          coolingDegreeDays: showCoolingDegrees ? d.degreeDayByBillingPeriod : null,
          heatingDegreeDays: showHeatingDegrees ? d.degreeDayByBillingPeriod : null,
          totalUsage: d.totalUsage || 0,
          baseload: baseloads?.[serviceType]?.baseload || 0,
          monthYear: d.monthYear,
        }))
      ).sort((a, b) => new Date(a.monthYear).getTime() - new Date(b.monthYear).getTime());
    } else {
      // For meter readings, filter by selected meter
      const combinedData = new Map();
      const sourceData = showCoolingDegrees ? meterReadingsCddData : meterReadingsHddData;

      filterDataByYear(sourceData).forEach((reading) => {
        const meterName = reading.meterName || 'Unknown Meter';
        if (meterName !== selectedMeter) return;

        if (!combinedData.has(reading.monthYear)) {
          combinedData.set(reading.monthYear, {
            monthYear: reading.monthYear,
            totalUsage: reading.totalUsage,
            baseload: meterReadingsBaseloads?.[serviceType]?.baseload || 0,
            coolingDegreeDays: showCoolingDegrees ? reading.degreeDayByBillingPeriod : null,
            heatingDegreeDays: showHeatingDegrees ? reading.degreeDayByBillingPeriod : null,
          });
        }
      });

      return Array.from(combinedData.values()).sort(
        (a, b) => new Date(a.monthYear).getTime() - new Date(b.monthYear).getTime()
      );
    }
  };

  const renderMeterSelector = () => {
    if (dataSource !== 'energy-star' || !meterNames.length) return null;

    return (
      <div className="mb-4 flex items-center gap-2">
        <Label className="text-sm font-medium text-muted-foreground">Select Meter:</Label>
        <Select value={selectedMeter} onValueChange={setSelectedMeter}>
          <SelectTrigger className="w-[400px]">
            <SelectValue placeholder="Select meter" />
          </SelectTrigger>
          <SelectContent>
            {meterNames.map((meterName) => (
              <SelectItem key={meterName} value={meterName}>
                {meterName}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    );
  };

  const renderYearFilter = () => {
    if (!availableYears.length) return null;

    return (
      <div className="flex items-center gap-2">
        <Label className="text-sm font-medium text-muted-foreground">Filter by Year:</Label>
        <Select value={selectedYear} onValueChange={setSelectedYear}>
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder="Select year" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="all">All Years</SelectItem>
            {availableYears.map((year) => (
              <SelectItem key={year} value={year}>
                {year}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    );
  };

  return (
    <>
      <CollapsibleChart title={'Degree Days'}>
        <div className="mb-4 space-y-4">
          <div className="flex items-center justify-between">
            <BaseTemperatureCard
              baseTemperature={baseTemperature}
              onValueChange={onBaseTemperatureChange}
              isUpdating={isUpdatingDegreeData}
            />
            {renderYearFilter()}
          </div>

          {renderMeterSelector()}

          <div className="flex items-center space-x-4 pl-1">
            <div className="flex items-center space-x-4 rounded-lg bg-muted/50 p-2">
              <div className="flex items-center space-x-2">
                <Switch
                  id="cooling-degree"
                  checked={showCoolingDegrees}
                  onCheckedChange={(value) => handleSwitchChange(false, value)}
                />
                <div className="flex items-center space-x-1">
                  <Thermometer className="h-4 w-4 text-[rgb(var(--chart-cooling-rgb))]" />
                  <Label htmlFor="cooling-degree">Cooling Degree Days</Label>
                </div>
              </div>

              <div className="h-8 w-[1px] bg-border" />

              <div className="flex items-center space-x-2">
                <Switch
                  id="heating-degree"
                  checked={showHeatingDegrees}
                  onCheckedChange={(value) => handleSwitchChange(true, value)}
                />
                <div className="flex items-center space-x-1">
                  <Thermometer className="h-4 w-4 text-[rgb(var(--chart-heating-rgb))]" />
                  <Label htmlFor="heating-degree">Heating Degree Days</Label>
                </div>
              </div>
            </div>
          </div>
        </div>

        {(dataSource === 'utility-bills' ? coolingDegreeData?.length > 0 : meterReadingsCddData?.length > 0) ? (
          <div className="h-[500px] w-full">
            <ResponsiveContainer width="100%" height="100%">
              <ComposedChart data={getChartData()} margin={{ top: 20, right: 50, left: 50, bottom: 60 }}>
                <CartesianGrid {...sharedCartesianGridProps} />
                <XAxis {...sharedXAxisProps} />
                <YAxis
                  {...createYAxisProps(`Energy Consumption (${renderLoadMetric(serviceType)})`)}
                  tickCount={20}
                  interval={0}
                />
                {(showCoolingDegrees || showHeatingDegrees) && (
                  <YAxis
                    {...createYAxisProps(getDegreeLabel(), {
                      orientation: 'right',
                      yAxisId: 'right',
                    })}
                    tickCount={15}
                    interval={0}
                  />
                )}
                <Tooltip {...sharedTooltipProps} />
                <Legend {...sharedLegendProps} />
                {dataSource === 'utility-bills' ? (
                  <>
                    {showCoolingDegrees && (
                      <Bar
                        yAxisId="right"
                        dataKey="coolingDegreeDays"
                        fill="rgb(var(--chart-cooling-rgb))"
                        name="Cooling Degree Days"
                        radius={[4, 4, 0, 0]}
                        stackId="degree-days"
                      />
                    )}
                    {showHeatingDegrees && (
                      <Bar
                        yAxisId="right"
                        dataKey="heatingDegreeDays"
                        fill="rgb(var(--chart-heating-rgb))"
                        name="Heating Degree Days"
                        radius={[4, 4, 0, 0]}
                        stackId="degree-days"
                      />
                    )}
                    <Line
                      yAxisId="left"
                      type="monotone"
                      dataKey="totalUsage"
                      stroke="rgb(var(--chart-energy-rgb))"
                      name={`Energy Consumption (${renderLoadMetric(serviceType)})`}
                      strokeWidth={2}
                      dot={{ fill: 'rgb(var(--chart-energy-rgb))', r: 4 }}
                      activeDot={{ r: 6, fill: 'rgb(var(--chart-energy-rgb))' }}
                    />
                    <Line
                      yAxisId="left"
                      type="monotone"
                      dataKey="baseload"
                      stroke="hsl(var(--foreground))"
                      name={`Baseload (${renderLoadMetric(serviceType)})`}
                      strokeWidth={2}
                      dot={{ fill: 'hsl(var(--foreground))', r: 4 }}
                      activeDot={{ r: 6, fill: 'hsl(var(--foreground))' }}
                    />
                  </>
                ) : (
                  // Render lines and bars for selected meter
                  <>
                    {showCoolingDegrees && (
                      <Bar
                        yAxisId="right"
                        dataKey="coolingDegreeDays"
                        fill="rgb(var(--chart-cooling-rgb))"
                        name={`${selectedMeter} Cooling Degree Days`}
                        radius={[4, 4, 0, 0]}
                        stackId="degree-days"
                      />
                    )}
                    {showHeatingDegrees && (
                      <Bar
                        yAxisId="right"
                        dataKey="heatingDegreeDays"
                        fill="rgb(var(--chart-heating-rgb))"
                        name={`${selectedMeter} Heating Degree Days`}
                        radius={[4, 4, 0, 0]}
                        stackId="degree-days"
                      />
                    )}
                    <Line
                      yAxisId="left"
                      type="monotone"
                      dataKey="totalUsage"
                      stroke="rgb(var(--chart-energy-rgb))"
                      name={`${selectedMeter} (${renderLoadMetric(serviceType)})`}
                      strokeWidth={2}
                      dot={{ fill: 'rgb(var(--chart-energy-rgb))', r: 4 }}
                      activeDot={{ r: 6 }}
                    />
                    <Line
                      yAxisId="left"
                      type="monotone"
                      dataKey="baseload"
                      stroke="hsl(var(--foreground))"
                      name={`${selectedMeter} Baseload (${renderLoadMetric(serviceType)})`}
                      strokeWidth={2}
                      dot={{ fill: 'hsl(var(--foreground))', r: 4 }}
                      activeDot={{ r: 6 }}
                    />
                  </>
                )}
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        ) : (
          <NoDataFound
            message="No degree day data available"
            submessage="There is no degree day data to display for this time period"
            icon={<BarChartIcon className="h-8 w-8 text-muted-foreground" />}
          />
        )}
      </CollapsibleChart>
    </>
  );
};
