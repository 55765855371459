import { TabsList, TabsTrigger } from '@shadcn/ui';
import { ListIcon, BoltIcon, FlameIcon, DropletIcon, FuelIcon } from 'lucide-react';
import { ELECTRIC, GAS, STEAM, OIL, WATER } from 'app/utils/constants/utilityServices';

interface ServiceTabsProps {
  allServices: string[];
}

export const ServiceTabs = ({ allServices }: ServiceTabsProps) => {
  return (
    <TabsList className="mb-6 flex h-10 w-full justify-start gap-2 rounded-lg border border-border/20 bg-muted/50 p-1">
      {allServices.map((serviceType) => (
        <TabsTrigger
          key={serviceType}
          value={serviceType.toLowerCase()}
          className="flex-1 items-center justify-center rounded-md border border-border/10 bg-background/40 text-gray-800 transition-all hover:bg-background/60 data-[state=active]:border-primary/30 data-[state=active]:bg-white data-[state=active]:text-primary data-[state=active]:shadow-[0_2px_10px_rgba(124,58,237,0.5)]"
        >
          {serviceType === 'All' && <ListIcon className="mr-2 h-4 w-4 text-gray-400" />}
          {serviceType === ELECTRIC && <BoltIcon className="mr-2 h-4 w-4 text-yellow-400" />}
          {serviceType === GAS && <FlameIcon className="mr-2 h-4 w-4 text-orange-400" />}
          {serviceType === STEAM && <DropletIcon className="mr-2 h-4 w-4 text-blue-400" />}
          {serviceType === OIL && <FuelIcon className="mr-2 h-4 w-4 text-green-400" />}
          {serviceType === WATER && <DropletIcon className="mr-2 h-4 w-4 text-blue-400" />}
          <span className="font-medium">{serviceType.charAt(0).toUpperCase() + serviceType.slice(1)}</span>
        </TabsTrigger>
      ))}
    </TabsList>
  );
};
