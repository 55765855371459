import { cn } from '@shadcn/utils';
import { CheckIcon, LightbulbIcon, DropletIcon, FlameIcon, WindIcon, LeafIcon } from 'lucide-react';

// Map of service types to their respective icons
const serviceIcons = {
  electricity: LightbulbIcon,
  water: DropletIcon,
  gas: FlameIcon,
  steam: WindIcon,
  other: LeafIcon,
};

export type ServiceTileProps = {
  services: string[];
  selectedServices: string[];
  onChange: (services: string[]) => void;
  error?: string;
  disabled?: boolean;
};

export const ServiceSelectionTiles = ({
  services,
  selectedServices,
  onChange,
  error,
  disabled = false,
}: ServiceTileProps) => {
  const toggleService = (service: string) => {
    if (disabled) return;

    if (selectedServices.includes(service)) {
      onChange(selectedServices.filter((s) => s !== service));
    } else {
      onChange([...selectedServices, service]);
    }
  };

  // Function to get the appropriate icon for a service
  const getServiceIcon = (service: string) => {
    const key = Object.keys(serviceIcons).find((k) => service.toLowerCase().includes(k));
    const Icon = key ? serviceIcons[key] : serviceIcons.other;
    return Icon;
  };

  return (
    <div className="space-y-4">
      <div className="grid grid-cols-2 gap-4 md:grid-cols-3">
        {services.map((service) => {
          const isSelected = selectedServices.includes(service);
          const ServiceIcon = getServiceIcon(service);

          return (
            <div
              key={service}
              onClick={() => toggleService(service)}
              className={cn(
                'relative flex flex-col items-center justify-center rounded-xl border-2 p-6 transition-all duration-200',
                'h-32 cursor-pointer text-center hover:shadow-md',
                isSelected ? 'border-primary bg-primary/10' : 'border-border bg-background hover:border-primary/50',
                disabled && 'cursor-not-allowed opacity-60'
              )}
            >
              {isSelected && (
                <div className="absolute right-2 top-2 rounded-full bg-primary p-0.5">
                  <CheckIcon className="h-4 w-4 text-white" />
                </div>
              )}

              <ServiceIcon className={cn('mb-2 h-8 w-8', isSelected ? 'text-primary' : 'text-muted-foreground')} />

              <span className={cn('font-medium', isSelected ? 'text-primary' : 'text-foreground')}>
                {service.charAt(0).toUpperCase() + service.slice(1)}
              </span>
            </div>
          );
        })}
      </div>

      {error && <p className="mt-2 text-sm text-destructive">{error}</p>}
      {!error && <p className="mt-2 text-xs text-muted-foreground">Select all utility services that apply</p>}
    </div>
  );
};
