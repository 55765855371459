import { useEffect, useRef, useState } from 'react';
import { PDFDocumentProxy } from 'pdfjs-dist';
import * as pdfjsLib from 'pdfjs-dist';
import { Button } from '@shadcn/ui';

export const DocumentViewer = ({ url }: { url?: string }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [pdfDoc, setPdfDoc] = useState<PDFDocumentProxy | null>(null);
  const [pageNum, setPageNum] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    const loadPDF = async () => {
      try {
        const loadingTask = pdfjsLib.getDocument({ url });
        const pdf = await loadingTask.promise;
        setPdfDoc(pdf);
        setPageCount(pdf.numPages);
      } catch (error) {
        console.error('Error loading PDF:', error);
      }
    };

    loadPDF();
  }, [url]);

  useEffect(() => {
    const renderPage = async () => {
      if (pdfDoc) {
        const page = await pdfDoc.getPage(pageNum);
        const scale = 1.5;
        const viewport = page.getViewport({ scale });

        const canvas = canvasRef.current;

        if (!canvas) {
          return;
        }

        const context = canvas.getContext('2d');

        if (!context) {
          throw new Error('Failed to get 2d context');
        }

        canvas.height = viewport.height;
        canvas.width = viewport.width;

        const renderContext: Parameters<typeof page.render>[0] = {
          canvasContext: context,
          viewport: viewport,
        };

        page.render(renderContext);
      }
    };

    renderPage();
  }, [pdfDoc, pageNum]);

  const changePage = (offset: number) => {
    setPageNum((prevPageNum) => {
      const newPageNum = prevPageNum + offset;
      return Math.min(Math.max(1, newPageNum), pageCount);
    });
  };

  return (
    <div>
      <canvas ref={canvasRef}></canvas>
      <div className="mt-4 flex flex-row items-center gap-4">
        <Button variant="secondary" onClick={() => changePage(-1)} disabled={pageNum <= 1}>
          Prev
        </Button>
        <span>{`Page ${pageNum} of ${pageCount}`}</span>
        <Button variant="default" onClick={() => changePage(1)} disabled={pageNum >= pageCount}>
          Next
        </Button>
      </div>
    </div>
  );
};
