import jwtAuthAxios from './auth/jwtAuth';
import { DataSource } from '../types/visualizations';
import type { PenaltyData } from '../types/penalty-data';
import { BuildingSystemData } from '../utils/constants/buildingInfoStructure';

export type WeatherParams = { id?: string; data: { zipCode: string; startDate: string; endDate: string } };

export type EmissionsParams = { id?: string; query: { dataSource: DataSource } };

export type PropertyUse = {
  propertyUse: string;
  totalGrossFloorArea: number;
};

export interface EnergyStarMeter {
  _id: string;
  type: string;
  name: string;
  metered: boolean;
  unitOfMeasure: string;
  firstBillDate: string;
  inUse: boolean;
  accessLevel: string;
}

export type ClientService = 'll84' | 'll88' | 'll97' | 'setup-espm' | 'setup-bms' | 'setup-rtem';

interface BuildingRepresentation {
  owner: string;
  representative: string;
  services: ClientService[];
}

export interface ProjectAttributes {
  name: string;
  buildingRepresentation: BuildingRepresentation;
  conEdisonSubscriptionId: string;
  energyStarId: string;
  energyStarMeters: EnergyStarMeter[];
  createdAt: string;
  squareFeet: number;
  yearBuilt: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  numberOfFloors?: string;
  parentProperty?: string;
  childProperties?: Array<{
    name: string;
    energyStarId: string;
  }>;
  services: string[];
  propertyUses: PropertyUse[];
  // Occupancy data
  occupancy?: {
    weekdayStartTime?: string;
    weekdayEndTime?: string;
    weekendStartTime?: string;
    weekendEndTime?: string;
    occupancyPattern?: string;
    peakOccupancy?: number;
    averageOccupancy?: number;
    closedOnWeekends?: boolean;
  };
  // Building services data
  buildingServices?: {
    hasSolar?: boolean;
    hasBMS?: boolean;
    bmsVendor?: string;
  };
  buildingSystems?: {
    hvac?: BuildingSystemData[];
    lighting?: BuildingSystemData[];
    plug_load?: BuildingSystemData[];
    electric?: BuildingSystemData[];
    gas?: BuildingSystemData[];
    oil?: BuildingSystemData[];
    steam?: BuildingSystemData[];
  };
  notes?: string;
  conEdisonUsagePoints: any[];
  propertyIdentifiers: {
    BBL: string[];
    BIN: string[];
    [key: string]: string[];
  };
  complianceRequirements: {
    ll84: {
      required: boolean;
      requiredToReportWater: boolean;
    };
    ll97: {
      required: boolean;
      reportType: string;
    };
    buildingCount: number;
  };
}

export interface ProjectsResponse {
  data: ProjectInfoData[];
  pagination: {
    limit: number;
    page: number;
    pages: number;
    total: number;
  };
}

export interface ProjectInfoData {
  id: string;
  type: string;
  attributes: ProjectAttributes;
  relationships?: {
    [key: string]: {
      data: any;
    };
  };
}

export const allowedSortFields = [
  'name',
  'createdAt',
  'owner',
  'representative',
  'll84Required',
  'll97Required',
] as const;

export type SortField = (typeof allowedSortFields)[number];
export type SortOrder = 'asc' | 'desc';

const projectServices = {
  create: async (params) => {
    const { data } = await jwtAuthAxios.post('/v1/projects', params);
    return data;
  },

  get: async (params?: {
    page?: number;
    limit?: number;
    sortField?: SortField;
    sortOrder?: SortOrder;
  }): Promise<ProjectsResponse> => {
    const { data } = await jwtAuthAxios.get('/v1/projects', {
      params: {
        page: params?.page || 1,
        limit: params?.limit || 10,
        sortField: params?.sortField || 'name',
        sortOrder: params?.sortOrder || 'asc',
      },
    });
    return data;
  },

  getById: async (id) => {
    const { data } = await jwtAuthAxios.get(`/v1/projects/${id}`);
    return data;
  },

  update: async (params) => {
    const { data } = await jwtAuthAxios.put(`/v1/projects/${params.id}`, params.data);
    return data;
  },

  delete: async (id) => {
    const data = await jwtAuthAxios.delete(`/v1/projects/${id}`);
    return data;
  },

  addWeather: async (params: WeatherParams) => {
    if (!params.id) {
      throw new Error('Project ID is required');
    }
    const { data } = await jwtAuthAxios.post(`/v1/projects/weather/${params.id}`, params.data);
    return data;
  },

  fetchProjectInsights: async (params) => {
    const { id } = params;
    const { data } = await jwtAuthAxios.post(`/v1/projects/insights/${id}`, params.data);
    return data;
  },
  getPropertyTypes: async () => {
    const { data } = await jwtAuthAxios.get('/v1/projects/propertyTypes');
    return data;
  },

  getEstimatedPenalties: async (id) => {
    const { data } = await jwtAuthAxios.get(`/v1/projects/penalties/${id}`);
    return data;
  },

  getEmissions: async (params: EmissionsParams) => {
    const { id, query } = params;
    const { data } = await jwtAuthAxios.get(`/v1/projects/${id}/emissions`, { params: query });
    return data as PenaltyData;
  },

  getMeterReadings: async (id: string) => {
    const { data } = await jwtAuthAxios.get(`/v1/projects/insights/${id}/meters`);
    return data;
  },

  syncConEdAccount: async (params: { id: string; conEdisonSubscriptionId: string }) => {
    const { data } = await jwtAuthAxios.post(`/v1/projects/${params.id}/coned/sync`, {
      conEdisonSubscriptionId: params.conEdisonSubscriptionId,
    });
    return data;
  },
};

export default projectServices;
