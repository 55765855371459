import { ReportPageData } from 'app/pages/project/tabs/report-builder/ReportPreview';
import jwtAuthAxios from './auth/jwtAuth';

export interface ReportSection {
  id: string;
  title: string;
  content: string;
  isEditable: boolean;
  isEditing?: boolean;
  isIncluded: boolean;
}

export interface ReportPage {
  pageNumber: number;
  title: string;
  sections: ReportSection[];
}

type ReportPreviewOptions = {
  dataSource: 'utility-bills' | 'energy-star';
  startDate: string;
  endDate: string;
};

interface CreateDraftParams {
  project: string;
  name: string;
  pages?: ReportPageData[];
  dataSource: string;
  startDate?: string;
  endDate?: string;
  generateWithAI?: boolean;
  logo?: string | ArrayBuffer | null;
  buildingImage?: string | ArrayBuffer | null;
}

const reportServices = {
  create: async (params) => {
    try {
      const { data } = await jwtAuthAxios.post('/v1/reports', params);
      return data;
    } catch (error: any) {
      if (error.response?.data?.errors?.[0]?.detail) {
        throw new Error(error.response.data.errors[0].detail);
      }
      throw new Error('An error occurred while creating the report');
    }
  },

  createDraft: async (params: CreateDraftParams) => {
    try {
      const { data } = await jwtAuthAxios.post('/v1/reports/draft', params);
      return data;
    } catch (error: any) {
      if (error.response?.data?.errors?.[0]?.detail) {
        throw new Error(error.response.data.errors[0].detail);
      }
      throw new Error('An error occurred while creating the draft report');
    }
  },

  getByProjectId: async (id) => {
    const { data } = await jwtAuthAxios.get(`/v1/reports/project/${id}`);
    return data;
  },

  getDownloadLink: async (id) => {
    const { data } = await jwtAuthAxios.get(`/v1/reports/download/${id}`);
    return data;
  },

  delete: async (id) => {
    const data = await jwtAuthAxios.delete(`/v1/reports/${id}`);
    return data;
  },

  getReportPreview: async (id: string, options: ReportPreviewOptions): Promise<ReportPageData[]> => {
    try {
      const { data } = await jwtAuthAxios.post(`/v1/reports/preview/${id}`, options);
      return data;
    } catch (error: any) {
      if (error.response?.data?.errors?.[0]?.detail) {
        throw new Error(error.response.data.errors[0].detail);
      }
      throw new Error('An error occurred while fetching the report preview');
    }
  },

  getDraft: async (id: string) => {
    try {
      const { data } = await jwtAuthAxios.get(`/v1/reports/draft/${id}`);
      return data;
    } catch (error: any) {
      if (error.response?.data?.errors?.[0]?.detail) {
        throw new Error(error.response.data.errors[0].detail);
      }
      throw new Error('An error occurred while fetching the draft report');
    }
  },

  getDrafts: async () => {
    try {
      const { data } = await jwtAuthAxios.get(`/v1/reports/drafts`);
      return data;
    } catch (error: any) {
      if (error.response?.data?.errors?.[0]?.detail) {
        throw new Error(error.response.data.errors[0].detail);
      }
      throw new Error('An error occurred while fetching draft reports');
    }
  },

  updateDraft: async (id: string, params: CreateDraftParams) => {
    try {
      const { data } = await jwtAuthAxios.put(`/v1/reports/draft/${id}`, params);
      return data;
    } catch (error: any) {
      if (error.response?.data?.errors?.[0]?.detail) {
        throw new Error(error.response.data.errors[0].detail);
      }
      throw new Error('An error occurred while updating the draft report');
    }
  },
};

export default reportServices;
