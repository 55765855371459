import { ChangeEvent, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Badge,
  Button,
  Dialog,
  DialogTitle,
  DialogFooter,
  DialogContent,
  DialogBody,
  DialogHeader,
  DialogTrigger,
  Avatar,
  AvatarImage,
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  ScrollArea,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@shadcn/ui';
import { useToast } from '@shadcn/hooks/use-toast';
import { InputWrapper } from '@shadcn/custom/InputWrapper';
import { MailIcon, UserPlusIcon, Building2Icon, StarIcon, UsersIcon, BoltIcon, CrownIcon } from 'lucide-react';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import userServices from 'app/services/user-services';
import conedService from 'app/services/coned';

const defaultAvatarUrl =
  'https://static.vecteezy.com/system/resources/thumbnails/001/840/618/small/picture-profile-icon-male-icon-human-or-people-sign-and-symbol-free-vector.jpg';

// Generate a random state string for OAuth security
const generateRandomState = () => {
  const array = new Uint32Array(8);
  window.crypto.getRandomValues(array);
  return Array.from(array, (x) => x.toString(16)).join('');
};

// ConEd OAuth configuration
const CLIENT_ID = process.env.REACT_APP_CONED_CLIENT_ID;
const REDIRECT_URI = `https://app.golivecosmos.com`;
const CONED_APPLICATION_ID = process.env.REACT_APP_CONED_APPLICATION_ID;

interface TeamMember {
  id: string;
  name: string;
  email: string;
  avatar: string;
  isCurrentUser: boolean;
}

interface ConEdisonAccount {
  authorizationURI: string;
  customerResourceURI: string;
  resourceURI: string;
  subscriptionId: string;
  scope: string;
  tokenExpiresAt: string;
}

interface ConEdCustomerAccount {
  id: number;
  customerAccountId: number;
  streetAddress: string;
  city: string;
  state: string;
  postalCode: number;
}

export const Profile = () => {
  const location = useLocation();
  const { toast } = useToast();
  const { authUser } = useJumboAuth();
  // @ts-ignore
  const user = authUser?.attributes;
  const organizationName = authUser?.relationships?.organization?.data?.name;
  const organizationId = authUser?.relationships?.organization?.data?.id;
  const avatar = user?.avatar;

  const [energyStarAccount, setEnergyStarAccount] = useState<string | null>(null);
  const [conEdisonAccounts, setConEdAccounts] = useState<ConEdisonAccount[]>([]);
  const [conEdCustomerAccounts, setConEdCustomerAccounts] = useState<ConEdCustomerAccount[]>([]);
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [inviteSent, setInviteSent] = useState(false);
  const [inviteError, setInviteError] = useState('');
  const [teamMembers, setTeamMembers] = useState<TeamMember[]>([]);
  const [isLoadingTeamMembers, setIsLoadingTeamMembers] = useState(false);

  useEffect(() => {
    const fetchTeamMembers = async () => {
      if (!organizationId) return;

      setIsLoadingTeamMembers(true);
      try {
        const data = await userServices.getTeamMembers(organizationId);
        const formattedMembers = data.map((member) => ({
          id: member.id,
          name: member.attributes?.firstName || 'Unknown User',
          email: member.attributes?.email,
          avatar: member.attributes?.avatar || defaultAvatarUrl,
          isCurrentUser: member.id === user?.id,
        }));
        setTeamMembers(formattedMembers);
      } catch (error) {
        console.error('Error fetching team members:', error);
        toast({
          description: 'Failed to load team members. Please try again.',
          variant: 'destructive',
        });
      } finally {
        setIsLoadingTeamMembers(false);
      }
    };

    fetchTeamMembers();
  }, [organizationId, user?.id]);

  // Function to initiate ConEd connection
  const initiateConEdConnection = () => {
    // Validate configuration
    if (!CLIENT_ID) {
      toast({
        description: 'ConEd integration not properly configured. Please contact support.',
        variant: 'destructive',
      });
      return;
    }

    try {
      // Standard scope for requesting authorization
      const scope =
        'FB=1_3_4_5_7_10_15_16_51_53_56_57_58_60' +
        ';IntervalDuration=Monthly_3600_900_300' +
        ';BlockDuration=Monthly_Daily' +
        ';HistoryLength=63072000' +
        `;BR=${CONED_APPLICATION_ID}`;

      // Generate random state for security
      const state = generateRandomState();

      // Store state in session for verification later
      sessionStorage.setItem('coned_state', state);

      // Build authorization URL
      const baseUrl = 'https://www.coned.com/en';
      const authUrl =
        `${baseUrl}/oauth/authorize?` +
        `scope=${encodeURIComponent(scope)}` +
        `&response_type=code` +
        `&client_id=${CLIENT_ID}` +
        `&redirect_uri=${encodeURIComponent(REDIRECT_URI)}` +
        `&state=${state}`;

      // Redirect user to ConEd
      window.location.href = authUrl;
    } catch (error) {
      console.error('Error initiating ConEd connection:', error);
      toast({
        description: 'Failed to initiate ConEd connection. Please try again.',
        variant: 'destructive',
      });
    }
  };

  useEffect(() => {
    if (authUser?.relationships?.organization?.data?.energyStarAccountId) {
      setEnergyStarAccount(authUser.relationships.organization.data.energyStarAccountId);
    }

    if (authUser?.relationships?.organization?.data?.conEdisonAccounts) {
      setConEdAccounts(authUser.relationships.organization.data.conEdisonAccounts);
    }
  }, [authUser]);

  useEffect(() => {
    const fetchConEdAccounts = async () => {
      try {
        const response = await conedService.getConEdAccounts();
        if (response?.customerAccounts) {
          setConEdCustomerAccounts(response.customerAccounts);
        }
      } catch (error) {
        console.error('Error fetching ConEd accounts:', error);
        toast({
          description: 'Failed to fetch ConEd accounts. Please try again.',
          variant: 'destructive',
        });
      }
    };

    if (conEdisonAccounts?.length > 0) {
      fetchConEdAccounts();
    }
  }, [conEdisonAccounts]);

  // Handle ConEd callback messages
  useEffect(() => {
    const state = location.state as { message?: string; error?: string } | null;
    if (state?.message) {
      toast({
        description: state.message,
        variant: 'positive',
      });
    } else if (state?.error) {
      toast({
        description: state.error,
        variant: 'destructive',
      });
    }
  }, [location, toast]);

  const handleClickOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    setEmail('');
    setEmailError(false);
    setInviteSent(false);
    setInviteError('');
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setEmailError(false);
  };

  const handleSendRequest = async () => {
    if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError(true);
    } else {
      try {
        await userServices.invite({ email });
        setInviteSent(true);
        toast({
          description: 'Invitation sent successfully!',
          variant: 'positive',
        });
        handleClose();
      } catch (error) {
        setInviteError('Failed to send invite. Please try again.');
      }
    }
  };

  return (
    <ScrollArea className="grow">
      <div className="container mx-auto max-w-6xl space-y-8 p-8">
        {/* Profile Section */}
        <Card className="overflow-hidden border-border/20 bg-background/5 shadow-lg backdrop-blur-sm">
          <div className="relative h-32 bg-gradient-to-r from-primary/30 to-primary/20">
            <div className="absolute -bottom-12 left-8">
              <Avatar className="h-24 w-24 border-4 border-border/20 bg-background/5 shadow-xl backdrop-blur-sm">
                <AvatarImage src={avatar || defaultAvatarUrl} alt={user?.firstName || 'User'} />
              </Avatar>
            </div>
          </div>
          <CardContent className="mt-16">
            <div className="flex flex-col gap-1">
              <h2 className="text-2xl font-bold">{user?.firstName || 'User Name'}</h2>
              <p className="flex items-center gap-2 text-muted-foreground/80">
                <MailIcon className="h-4 w-4" />
                {user?.email}
              </p>
            </div>
          </CardContent>
        </Card>

        {/* Organization Section */}
        <Card className="border-border/20 bg-background/5 shadow-lg backdrop-blur-sm">
          <CardHeader>
            <CardTitle className="flex items-center gap-2">
              <Building2Icon className="h-5 w-5" />
              Organization
            </CardTitle>
          </CardHeader>
          <CardContent className="space-y-6">
            {/* Details Section */}
            <div>
              <h3 className="mb-4 text-sm font-medium text-muted-foreground/80">Details</h3>
              <div className="rounded-lg border border-border/20 bg-background/5 p-4">
                <div className="flex items-center justify-between">
                  <div>
                    <p className="text-sm text-muted-foreground/80">Organization Name</p>
                    <p className="text-lg font-semibold">{organizationName}</p>
                  </div>
                </div>
              </div>
            </div>

            {/* Integrations Section */}
            <div>
              <h3 className="mb-4 text-sm font-medium text-muted-foreground/80">Integrations</h3>
              <div className="space-y-4">
                {/* Energy Star Integration */}
                <div className="rounded-lg border border-border/20 bg-background/5 p-4">
                  <div className="mb-3 flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <StarIcon className="h-5 w-5 text-yellow-400" />
                      <h4 className="font-medium">Energy Star Portfolio Manager</h4>
                    </div>
                    <Badge
                      variant="outline"
                      className={
                        energyStarAccount ? 'bg-emerald-500/20 text-emerald-400' : 'bg-muted text-muted-foreground/80'
                      }
                    >
                      {energyStarAccount ? 'Connected' : 'Not Connected'}
                    </Badge>
                  </div>
                  {energyStarAccount && (
                    <p className="text-sm text-muted-foreground/80">Account ID: {energyStarAccount}</p>
                  )}
                </div>

                {/* ConEd Integration */}
                <div className="rounded-lg border border-border/20 bg-background/5 p-4">
                  <div className="mb-3 flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <BoltIcon className="h-5 w-5 text-yellow-400" />
                      <h4 className="font-medium">ConEdison Real-Time Data</h4>
                    </div>
                    <div className="relative">
                      <Badge
                        variant="outline"
                        className={`absolute -top-6 right-0 z-10 w-fit ${
                          conEdisonAccounts?.length
                            ? 'bg-emerald-500/20 text-emerald-400'
                            : 'bg-muted text-muted-foreground/80'
                        }`}
                      >
                        {conEdisonAccounts?.length ? 'Connected' : 'Not Connected'}
                      </Badge>
                      <Button
                        onClick={initiateConEdConnection}
                        variant="outline"
                        size="sm"
                        className="mt-4 border-emerald-500/30 bg-emerald-500/20 text-emerald-400 hover:bg-emerald-500/30"
                      >
                        {conEdisonAccounts?.length ? 'Connect Another Account' : 'Connect'}
                      </Button>
                    </div>
                  </div>
                  {conEdisonAccounts?.length > 0 ? (
                    <div className="mt-4 space-y-3">
                      <p className="text-sm text-muted-foreground/80">Connected Accounts:</p>
                      {conEdCustomerAccounts.map((account) => (
                        <div key={account.id} className="rounded-md border border-border/20 bg-background/5 p-3">
                          <div className="flex items-center justify-between">
                            <div>
                              <p className="font-medium">Account ID: {account.customerAccountId}</p>
                              <p className="mt-1 text-sm text-muted-foreground/80">
                                {account.streetAddress}, {account.city}, {account.state} {account.postalCode}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p className="text-sm text-muted-foreground/80">
                      Connect to ConEdison to access real-time utility data.
                    </p>
                  )}
                </div>
              </div>
            </div>
          </CardContent>
        </Card>

        {/* Team Members Section */}
        <Card className="border-border/20">
          <CardHeader>
            <div className="flex items-center justify-between">
              <CardTitle className="flex items-center gap-2">
                <UsersIcon className="h-5 w-5" />
                Team Members
              </CardTitle>
              <Dialog open={open} onOpenChange={(newOpen) => (newOpen ? handleClickOpen() : handleClose())}>
                <DialogTrigger asChild>
                  <Button
                    onClick={handleClickOpen}
                    variant="outline"
                    size="sm"
                    className="border-border/20 bg-background/5 hover:bg-background/10"
                  >
                    <UserPlusIcon className="mr-2 h-4 w-4" />
                    Invite Member
                  </Button>
                </DialogTrigger>
                <DialogContent className="border-border/20 bg-background/95 shadow-xl backdrop-blur-sm">
                  <DialogHeader>
                    <DialogTitle>Invite to {organizationName}</DialogTitle>
                  </DialogHeader>
                  <DialogBody>
                    <p className="text-sm text-muted-foreground/80">
                      Enter the email address of the user you'd like to invite to join the organization.
                    </p>
                    <InputWrapper
                      autoFocus
                      id="email"
                      label="Email Address"
                      type="email"
                      value={email}
                      onChange={handleEmailChange}
                      error={emailError}
                      helperText={emailError ? 'Please enter a valid email' : undefined}
                      className="bg-background/50"
                    />
                    {inviteSent ? <p className="text-sm text-emerald-400">Invitation sent successfully!</p> : null}
                    {inviteError ? <p className="text-sm text-red-400">{inviteError}</p> : null}
                  </DialogBody>
                  <DialogFooter>
                    <Button
                      onClick={handleClose}
                      variant="secondary"
                      className="bg-background/5 hover:bg-background/10"
                    >
                      {inviteSent ? 'Close' : 'Cancel'}
                    </Button>
                    <Button
                      onClick={handleSendRequest}
                      disabled={!email || inviteSent}
                      className="bg-primary/90 text-primary-foreground hover:bg-primary"
                    >
                      Send Invitation
                    </Button>
                  </DialogFooter>
                </DialogContent>
              </Dialog>
            </div>
          </CardHeader>
          <CardContent>
            <Table>
              <TableHeader>
                <TableRow className="border-border/20">
                  <TableHead className="text-muted-foreground/80">Member</TableHead>
                  <TableHead className="text-muted-foreground/80">Role</TableHead>
                  <TableHead className="text-muted-foreground/80">Email</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {isLoadingTeamMembers ? (
                  <TableRow className="border-border/20">
                    <TableCell colSpan={3} className="py-8 text-center">
                      <div className="flex items-center justify-center gap-2">
                        <UsersIcon className="h-4 w-4 animate-pulse text-muted-foreground" />
                        <span className="text-muted-foreground/80">Loading team members...</span>
                      </div>
                    </TableCell>
                  </TableRow>
                ) : teamMembers.length === 0 ? (
                  <TableRow className="border-border/20">
                    <TableCell colSpan={3} className="py-8 text-center">
                      <div className="flex flex-col items-center justify-center gap-2">
                        <UsersIcon className="h-8 w-8 text-muted-foreground" />
                        <span className="text-muted-foreground/80">No team members found</span>
                      </div>
                    </TableCell>
                  </TableRow>
                ) : (
                  teamMembers.map((member) => (
                    <TableRow key={member.id} className="border-border/20">
                      <TableCell>
                        <div className="flex items-center gap-3">
                          <Avatar className="h-8 w-8 border border-border/20">
                            <AvatarImage src={member.avatar} />
                          </Avatar>
                          <div>
                            <p className="font-medium">{member.name}</p>
                            {member.isCurrentUser && (
                              <Badge variant="secondary" className="mt-0.5 border-0 bg-primary/20 text-primary">
                                You
                              </Badge>
                            )}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <Badge
                          variant="secondary"
                          className="flex w-fit items-center gap-1 border-0 bg-primary/20 text-primary"
                        >
                          <CrownIcon className="h-3 w-3" />
                          Member
                        </Badge>
                      </TableCell>
                      <TableCell className="text-muted-foreground/80">{member.email}</TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </div>
    </ScrollArea>
  );
};
