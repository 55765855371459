import { IntervalType, TimeRange, TimeRangeConfig } from './types';

export const TIME_RANGE_CONFIG: Record<TimeRange, TimeRangeConfig> = {
  day: {
    label: 'Last 24 Hours',
    days: 1,
    interval: '5min',
  },
  week: {
    label: 'Last 7 Days',
    days: 7,
    interval: '1hr',
  },
  month: {
    label: 'Last 30 Days',
    days: 30,
    interval: '24hr',
  },
};

export const INTERVAL_LABELS: Record<IntervalType, string> = {
  '5min': '5 Minute',
  '1hr': '1 Hour',
  '24hr': '24 Hour',
};
