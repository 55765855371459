import { useState, useMemo } from 'react';
import { format } from 'date-fns';
import type { Meter, ConsumptionData } from 'app/types/energy-star';
import { NoDataFound } from '../../../../components/project/NoDataFound';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow, Button } from '@shadcn/ui';
import { GaugeIcon, ArrowUpDown, ArrowUp, ArrowDown } from 'lucide-react';
import { getUsageUnit } from 'app/utils/formatters';
import { TagSelector } from 'app/pages/project/tabs/components/TagSelector';

interface BaseMeterReading {
  lastUpdated: string;
  lastUpdatedBy: string;
  meterName: string;
  meterType: string;
  value: number;
  cost?: number;
  type: 'consumption' | 'delivery';
}

interface ConsumptionMeterReading extends BaseMeterReading {
  type: 'consumption';
  startDate: string;
  endDate: string;
}

interface DeliveryMeterReading extends BaseMeterReading {
  type: 'delivery';
  deliveryDate: string;
}

type MeterReading = ConsumptionMeterReading | DeliveryMeterReading;

type SortColumn = 'name' | 'billingPeriod' | 'vendor' | 'usage' | 'type' | null;
type SortDirection = 'asc' | 'desc' | null;

type EnergyStarMetersProps = {
  meters: Meter[];
  consumptionData: { [key: string]: ConsumptionData };
  theme: any;
  displayEmptyMeters?: boolean;
  selectedService?: string;
};

export const EnergyStarMeters = ({
  meters = [],
  consumptionData = {},
  theme,
  displayEmptyMeters = true,
  selectedService = 'All',
}: EnergyStarMetersProps) => {
  const [sort, setSort] = useState<{ column: SortColumn; direction: SortDirection }>({
    column: null,
    direction: null,
  });
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const meterReadings = useMemo(() => {
    if (!meters || !consumptionData) return [];

    return meters.reduce((acc: { meter: Meter; reading: MeterReading }[], meter) => {
      if (!meter?.id) return acc;

      const meterConsumption = consumptionData[meter.id]?.meterConsumption || [];
      const meterDelivery = consumptionData[meter.id]?.meterDelivery || [];

      // Skip meters with no data if displayEmptyMeters is false
      if (!displayEmptyMeters && meterConsumption.length === 0 && meterDelivery.length === 0) {
        return acc;
      }

      const consumptionReadings = meterConsumption.map((reading) => ({
        meter,
        reading: {
          type: 'consumption' as const,
          startDate: reading.startDate,
          endDate: reading.endDate,
          value: parseFloat(reading.usage || '0'),
          cost: reading.cost ? parseFloat(reading.cost) : undefined,
          meterName: meter.name || 'Unknown',
          meterType: meter.type || 'Unknown',
          lastUpdated: reading?.lastUpdatedDate || reading?.endDate,
          lastUpdatedBy: reading?.lastUpdatedBy || 'System',
        },
      }));

      const deliveryReadings = meterDelivery.map((delivery) => ({
        meter,
        reading: {
          type: 'delivery' as const,
          deliveryDate: delivery.deliveryDate,
          value: parseFloat(delivery.quantity || '0'),
          cost: delivery.cost ? parseFloat(delivery.cost) : undefined,
          meterName: meter.name || 'Unknown',
          meterType: meter.type || 'Unknown',
          lastUpdated: delivery?.lastUpdatedDate || delivery?.deliveryDate,
          lastUpdatedBy: delivery?.lastUpdatedBy || 'System',
        },
      }));

      return [...acc, ...consumptionReadings, ...deliveryReadings];
    }, []);
  }, [meters, consumptionData, displayEmptyMeters]);

  const getAllMeterNames = useMemo(() => {
    const meterNames = new Set<string>();
    meters.forEach((meter) => {
      if (meter.name) {
        meterNames.add(meter.name);
      }
    });
    return Array.from(meterNames);
  }, [meters]);

  const filteredReadings = useMemo(() => {
    if (selectedTags.length === 0) return meterReadings;
    return meterReadings.filter((reading) => selectedTags.includes(reading.meter.name || ''));
  }, [meterReadings, selectedTags]);

  const sortedReadings = useMemo(() => {
    if (!sort.direction || !sort.column) return filteredReadings;

    return [...filteredReadings].sort((a, b) => {
      const direction = sort.direction === 'asc' ? 1 : -1;

      switch (sort.column) {
        case 'name':
          return direction * (a.meter.name || '').localeCompare(b.meter.name || '');
        case 'type':
          return direction * (a.reading.type || '').localeCompare(b.reading.type || '');
        case 'billingPeriod':
          const aDate =
            a.reading.type === 'consumption' ? new Date(a.reading.startDate) : new Date(a.reading.deliveryDate);
          const bDate =
            b.reading.type === 'consumption' ? new Date(b.reading.startDate) : new Date(b.reading.deliveryDate);
          return direction * (aDate.getTime() - bDate.getTime());
        case 'vendor':
          return direction * (a.reading.lastUpdatedBy || '').localeCompare(b.reading.lastUpdatedBy || '');
        case 'usage':
          return direction * ((a.reading.value || 0) - (b.reading.value || 0));
        default:
          return 0;
      }
    });
  }, [filteredReadings, sort]);

  const handleSort = (column: SortColumn) => {
    setSort((current) => ({
      column,
      direction:
        current.column === column
          ? current.direction === null
            ? 'asc'
            : current.direction === 'asc'
              ? 'desc'
              : null
          : 'asc',
    }));
  };

  const handleTagSelect = (tag: string) => {
    setSelectedTags((prev) => {
      if (prev.includes(tag)) {
        return prev.filter((t) => t !== tag);
      }
      return [...prev, tag];
    });
  };

  if (meterReadings.length === 0) {
    return (
      <NoDataFound
        message="No meter data available"
        submessage="Sync this project with a property in Energy Star to see meter data."
        icon={<GaugeIcon className="h-8 w-8 text-muted-foreground" />}
      />
    );
  }

  return (
    <div className="space-y-4">
      <TagSelector
        availableTags={getAllMeterNames}
        selectedTags={selectedTags}
        onTagSelect={handleTagSelect}
        onClearTags={() => setSelectedTags([])}
      />
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>
              <Button
                variant="ghost"
                onClick={() => handleSort('name')}
                className="flex items-center gap-1 hover:bg-transparent"
              >
                Meter Name
                {sort.column !== 'name' && <ArrowUpDown size={16} className="opacity-50" />}
                {sort.column === 'name' && sort.direction === 'asc' && <ArrowUp size={16} />}
                {sort.column === 'name' && sort.direction === 'desc' && <ArrowDown size={16} />}
              </Button>
            </TableHead>
            <TableHead>
              <Button
                variant="ghost"
                onClick={() => handleSort('type')}
                className="flex items-center gap-1 hover:bg-transparent"
              >
                Type
                {sort.column !== 'type' && <ArrowUpDown size={16} className="opacity-50" />}
                {sort.column === 'type' && sort.direction === 'asc' && <ArrowUp size={16} />}
                {sort.column === 'type' && sort.direction === 'desc' && <ArrowDown size={16} />}
              </Button>
            </TableHead>
            <TableHead>
              <Button
                variant="ghost"
                onClick={() => handleSort('billingPeriod')}
                className="flex items-center gap-1 hover:bg-transparent"
              >
                Date
                {sort.column !== 'billingPeriod' && <ArrowUpDown size={16} className="opacity-50" />}
                {sort.column === 'billingPeriod' && sort.direction === 'asc' && <ArrowUp size={16} />}
                {sort.column === 'billingPeriod' && sort.direction === 'desc' && <ArrowDown size={16} />}
              </Button>
            </TableHead>
            <TableHead>
              <Button
                variant="ghost"
                onClick={() => handleSort('vendor')}
                className="flex items-center gap-1 hover:bg-transparent"
              >
                Updated By
                {sort.column !== 'vendor' && <ArrowUpDown size={16} className="opacity-50" />}
                {sort.column === 'vendor' && sort.direction === 'asc' && <ArrowUp size={16} />}
                {sort.column === 'vendor' && sort.direction === 'desc' && <ArrowDown size={16} />}
              </Button>
            </TableHead>
            <TableHead>
              <Button
                variant="ghost"
                onClick={() => handleSort('usage')}
                className="flex items-center gap-1 hover:bg-transparent"
              >
                Value {selectedService !== 'All' ? `(${getUsageUnit(selectedService)})` : ''}
                {sort.column !== 'usage' && <ArrowUpDown size={16} className="opacity-50" />}
                {sort.column === 'usage' && sort.direction === 'asc' && <ArrowUp size={16} />}
                {sort.column === 'usage' && sort.direction === 'desc' && <ArrowDown size={16} />}
              </Button>
            </TableHead>
            <TableHead>Cost</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {sortedReadings.map(({ meter, reading }, index) => (
            <TableRow key={`${meter.id}-${index}`}>
              <TableCell className="font-medium">
                <div className="flex items-center gap-2">{meter.name}</div>
              </TableCell>
              <TableCell className="capitalize">{reading.type}</TableCell>
              <TableCell>
                {reading.type === 'consumption' ? (
                  <div className="flex flex-col">
                    <span>{format(new Date(reading.startDate), 'MMM d, yyyy')}</span>
                    <span className="text-sm text-muted-foreground">to</span>
                    <span>{format(new Date(reading.endDate), 'MMM d, yyyy')}</span>
                  </div>
                ) : (
                  <span>{format(new Date(reading.deliveryDate), 'MMM d, yyyy')}</span>
                )}
              </TableCell>
              <TableCell>{reading.lastUpdatedBy}</TableCell>
              <TableCell>{reading.value}</TableCell>
              <TableCell>{reading.cost ? `$${reading.cost.toLocaleString()}` : '-'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
