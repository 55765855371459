import { Card, CardContent, CardHeader, CardTitle } from '@shadcn/ui';
import { Leaf, TrendingDown, DollarSign } from 'lucide-react';

export const SustainabilityMetrics = () => {
  // Dummy data for sustainability metrics
  const metrics = {
    emissions: {
      current: '245.8',
      reduction: '32.4',
      target: '40.0',
      unit: 'metric tons CO2e',
    },
    energyReduction: {
      current: '28.5%',
      target: '35%',
      timeline: '2024 Target',
    },
    costSavings: {
      total: '$128,500',
      perSqFt: '$1.28',
      yearOverYear: '+24.5%',
    },
  };

  return (
    <Card className="border border-border/50">
      <CardHeader>
        <CardTitle className="text-xl font-semibold">Sustainability Impact</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-6">
          {/* Emissions Section */}
          <div className="rounded-lg border border-border/50 p-4">
            <div className="mb-3 flex items-center gap-3">
              <Leaf className="h-5 w-5 text-green-500" />
              <h3 className="font-medium">Carbon Emissions</h3>
            </div>
            <div className="space-y-2">
              <div className="flex justify-between">
                <span className="text-sm text-muted-foreground">Current Emissions</span>
                <span className="font-medium">
                  {metrics.emissions.current} {metrics.emissions.unit}
                </span>
              </div>
              <div className="flex justify-between">
                <span className="text-sm text-muted-foreground">Reduction to Date</span>
                <span className="font-medium text-green-500">
                  {metrics.emissions.reduction} {metrics.emissions.unit}
                </span>
              </div>
              <div className="flex justify-between">
                <span className="text-sm text-muted-foreground">Target Reduction</span>
                <span className="font-medium">
                  {metrics.emissions.target} {metrics.emissions.unit}
                </span>
              </div>
            </div>
          </div>

          {/* Energy Reduction Section */}
          <div className="rounded-lg border border-border/50 p-4">
            <div className="mb-3 flex items-center gap-3">
              <TrendingDown className="h-5 w-5 text-blue-500" />
              <h3 className="font-medium">Energy Reduction Progress</h3>
            </div>
            <div className="space-y-2">
              <div className="flex justify-between">
                <span className="text-sm text-muted-foreground">Current Reduction</span>
                <span className="font-medium">{metrics.energyReduction.current}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-sm text-muted-foreground">Target</span>
                <span className="font-medium">{metrics.energyReduction.target}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-sm text-muted-foreground">Timeline</span>
                <span className="font-medium">{metrics.energyReduction.timeline}</span>
              </div>
            </div>
          </div>

          {/* Cost Savings Section */}
          <div className="rounded-lg border border-border/50 p-4">
            <div className="mb-3 flex items-center gap-3">
              <DollarSign className="h-5 w-5 text-yellow-500" />
              <h3 className="font-medium">Cost Savings</h3>
            </div>
            <div className="space-y-2">
              <div className="flex justify-between">
                <span className="text-sm text-muted-foreground">Total Savings</span>
                <span className="font-medium text-green-500">{metrics.costSavings.total}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-sm text-muted-foreground">Per Square Foot</span>
                <span className="font-medium">{metrics.costSavings.perSqFt}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-sm text-muted-foreground">Year over Year</span>
                <span className="font-medium text-green-500">{metrics.costSavings.yearOverYear}</span>
              </div>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
