import { useState, useEffect } from 'react';
import energyStarServices from 'app/services/energy-star-services';
import { PenaltyData } from 'app/types/penalty-data';
import type { FlattenedAlert, PropertyMetrics } from 'app/services/energy-star-services';
import type { Meter, ConsumptionData } from '../types/energy-star';
import { Documents } from 'app/types/visualizations';

const fallbackData = {
  meters: [],
  consumptions: {},
  energyStarScore: { score: null },
  energyStarScoreByDateRange: {},
  noScoreReason: [
    {
      name: 'Project not connected to Energy Star',
      description: 'Sync project with a property in Energy Star to see score.',
    },
  ],
  error: null,
};

export const useEnergyStarData = (energyStarId: string | null, meterReadings: Documents) => {
  const [meterData, setMeterData] = useState<{
    meters: Meter[];
    consumptions: { [meterId: string]: ConsumptionData };
    energyStarScoreByDateRange: { [dateRange: string]: PropertyMetrics };
    energyStarScore?: PropertyMetrics;
    noScoreReason?: FlattenedAlert[];
  }>(fallbackData);
  const [error, setError] = useState<string | null>(null);
  const [dateRanges, setDateRanges] = useState<Date[]>([]);

  useEffect(() => {
    if (!energyStarId) {
      setMeterData(fallbackData);
      return;
    }

    const fetchMeterData = async () => {
      try {
        let dateRanges: Date[] = [];
        let energyStarScores: PropertyMetrics[] = [];
        let energyStarScoreByDateRange = {};

        if (meterReadings?.yearlyData) {
          dateRanges = Object.values(meterReadings.yearlyData).map((year) => new Date(year.endDate));
          setDateRanges(dateRanges);

          energyStarScores = await Promise.all(
            dateRanges.map((dateRange) =>
              energyStarServices.getPropertyScore(
                energyStarId,
                dateRange.getFullYear().toString(),
                (dateRange.getMonth() + 1).toString()
              )
            )
          );
          energyStarScoreByDateRange = Object.values(meterReadings.yearlyData || {}).reduce(
            (acc, year, index) => {
              const dateRangeKey = `${new Date(year.startDate).toLocaleDateString()} - ${new Date(year.endDate).toLocaleDateString()}`;
              acc[dateRangeKey] = energyStarScores[index];
              return acc;
            },
            {} as { [dateRange: string]: PropertyMetrics }
          );
        }

        const noScoreReasonResponse = await energyStarServices.getNoScoreReasons(energyStarId);
        const metersResponse = await energyStarServices.getMeters(energyStarId);
        const meters = metersResponse['meters'];
        const consumptions = await Promise.all(
          meters.map(async (meter) => {
            try {
              const consumption = await energyStarServices.getMeterConsumption(meter.id);
              return { meterId: meter.id, consumption };
            } catch (err) {
              console.warn(`Failed to fetch consumption for meter ${meter.id}:`, err);
              return { meterId: meter.id, consumption: { meterConsumption: [] } };
            }
          })
        );

        const consumptionMap = consumptions.reduce(
          (acc, { meterId, consumption }) => {
            acc[meterId] = consumption;
            return acc;
          },
          {} as { [key: string]: ConsumptionData }
        );

        setMeterData({
          meters,
          consumptions: consumptionMap,
          energyStarScoreByDateRange,
          energyStarScore: energyStarScores.length > 0 ? energyStarScores[energyStarScores.length - 1] : undefined,
          noScoreReason: noScoreReasonResponse,
        });
      } catch (err) {
        console.error('Error fetching Energy Star data:', err);
      }
    };

    fetchMeterData();
  }, [energyStarId, meterReadings]);

  return { ...meterData, error };
};
