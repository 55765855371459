import { cn } from '@shadcn/utils';
import { PropsWithChildren, useEffect, useRef } from 'react';
import { ResizablePanelGroup, ResizablePanel } from '@shadcn/ui';
import { Header } from 'app/layouts/shared/header';
import { Sidebar } from 'app/layouts/shared/sidebar';
import { useAppLayout } from 'app/utils/AppLayout';

// Smooth easing function
const easeOutCubic = (t: number) => 1 - Math.pow(1 - t, 3);

const COLLAPSED_PCT = 5;
const EXPANDED_PCT = 10;
const ANIM_DURATION = 150;

export const VerticalDefault = ({ children }: PropsWithChildren<Record<never, never>>) => {
  const { sidebarOpen } = useAppLayout();

  const sidebarPanelRef = useRef<any>(null);
  const intervalRef = useRef<number>(0);

  useEffect(() => {
    if (!sidebarPanelRef.current) {
      return;
    }

    // Use declarative Resizeable components API to animate the sidebar when `sidebarOpen` changes
    let currentPct = sidebarPanelRef.current.getSize();
    const targetPct = sidebarOpen ? EXPANDED_PCT : COLLAPSED_PCT;
    let start = 0;

    const step = (timestamp: number) => {
      if (!start) {
        start = timestamp;
      }

      const elapsed = timestamp - start;
      const progress = Math.min(elapsed / ANIM_DURATION, 1);

      // Calculate new percentage using easing
      const newPct = currentPct + (targetPct - currentPct) * easeOutCubic(progress);

      sidebarPanelRef.current.resize(newPct);

      if (progress < 1) {
        intervalRef.current = window.requestAnimationFrame(step);
      } else {
        // Ensure we land exactly on target
        sidebarPanelRef.current.resize(targetPct);
        window.cancelAnimationFrame(intervalRef.current);
      }
    };

    intervalRef.current = window.requestAnimationFrame(step);

    return () => {
      window.cancelAnimationFrame(intervalRef.current);
    };
  }, [sidebarOpen]);

  return (
    <div className="grid h-screen w-screen grid-cols-1 grid-rows-[1fr_auto]">
      <ResizablePanelGroup direction="horizontal" className="transition-all duration-150">
        <ResizablePanel
          ref={sidebarPanelRef}
          collapsible
          collapsedSize={COLLAPSED_PCT}
          minSize={COLLAPSED_PCT}
          defaultSize={EXPANDED_PCT}
          className="transition-all duration-150"
        >
          <div className="flex flex-1 flex-col">
            <Sidebar />
          </div>
        </ResizablePanel>
        <ResizablePanel defaultSize={100 - EXPANDED_PCT} className={cn('flex flex-col transition-all duration-150')}>
          <Header />
          {children}
        </ResizablePanel>
      </ResizablePanelGroup>
    </div>
  );
};
